import axios from './axios'

export interface Recipient {
  id: number
  account_id: number
  name: string
  email: string
  email_verified_at: string | string
  phone: string | null
  phone_verified_at: string | null
  phone_verify_token: string | null
  wants_email: number
  wants_sms: number
  wants_whatsapp: number
  created_at: string
  updated_at: string
}

export const getRecipients = ({
  sort = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  search = '',
  currentPage = 1,
  hasPhone = 0,
  hasEmail = 0,
}: {
  sort?: string
  sortDirection?: string
  pageSize?: number
  search?: string
  currentPage?: number
  hasPhone?: number
  hasEmail?: number
}) => {
  const qr = `/api/recipients?limit=${pageSize}&order_by=${sort}&sort=${sortDirection}&filters[has_phone]=${hasPhone}&filters[has_email]=${hasEmail}&filters[search]=${search}&page=${currentPage}`
  return axios.get(qr)
}

export const getRecipientProfile = (recipientId: number) => {
  return axios.get(`api/recipients/${recipientId}`)
}

export const createRecipient = (data: {
  name: string
  email: string
  phone?: string
  account_id: number
  wants_email: boolean
  wants_sms: boolean
  wants_whatsapp: boolean
}) => {
  return axios.post('api/recipients', data)
}

export const updateRecipientProfile = (
  recipientId: number,
  data: {
    name: string
    email: string
    phone?: string
    account_id: number
  },
) => {
  return axios.put(`/api/recipients/${recipientId}`, data)
}

export const deleteRecipient = (recipientId: number) => {
  return axios.delete(`/api/recipients/${recipientId}`)
}

export const verifyRecipientEmail = ({
  id,
  hash,
}: {
  id: string
  hash: string
}) => {
  return axios.get(`/api/recipient/email/verify/${id}/${hash}`)
}
