import React, { useEffect, useReducer, useState } from 'react'
import cx from 'classnames'
import { Button, Collapse, Input } from 'antd'
import { DownOutlined, SearchOutlined, SyncOutlined } from '@ant-design/icons'

import './index.scss'
import { DeviceInfo, Layout, NoResult, Spinner } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { notiError } from 'utils/notification'
import { getDeviceStatus, groupDevicesByLocation } from 'utils/device'
import { Device, getDevices } from 'services/devices'
import { useAppSelector } from 'store'

interface State {
  loading: boolean
  search: string
  devices: Device[]
}

interface Props {
  max: number
  index: number
  mode: 'livestream' | 'playback'
  devices: (Device | undefined)[]
  onChange: (value: (Device | undefined)[]) => void
  onCancel: () => void
}

const initState: State = {
  loading: true,
  search: '',
  devices: []
}

function ModalAddDevices({ onChange, onCancel, mode, ...props }: Props) {
  const [selected, setSelected] = useState<Device[]>([])
  const [{ loading, search, devices }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState
  )

  const { stats } = useAppSelector(state => state.stats)
  const canSearch = (stats?.camera_licenses.total || 0) > 0
  const grouppedDevices = groupDevicesByLocation(devices)

  useEffect(() => {
    if (canSearch) handleGetDevices()
    // eslint-disable-next-line
  }, [])

  const handleGetDevices = async (s?: string) => {
    try {
      setState({ loading: true })
      const { data } = await getDevices({
        search: s || search || undefined,
        currentPage: 1,
        pageSize: 9999
      })
      setState({
        devices: data.data.filter((d: Device) => d.type === 'Security Camera')
      })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setState({ loading: false })
    }
  }

  const handleResetSearch = () => {
    setState({ search: '' })
    handleGetDevices(' ')
  }

  const handleSelect = (d: Device) => {
    setSelected(prev => {
      if (prev.find(p => p.id === d.id)) {
        return prev.filter(p => p.id !== d.id)
      }
      return props.devices.filter(d => !!d).length + prev.length < props.max
        ? [...prev, d]
        : prev
    })
  }

  const renderEmptyList = () => (
    <NoResult style={{ marginTop: '5rem' }}>
      <h3 className="title">No result matches</h3>
      <div className="sub-title text-placeholder">Please try other keyword</div>
      <Button size="large" type="primary" onClick={handleResetSearch}>
        <SyncOutlined /> Reset All Filters
      </Button>
    </NoResult>
  )

  const renderDeviceList = () => {
    return Object.keys(grouppedDevices).map(k => {
      const { devices, locationName } = grouppedDevices[k]
      return (
        <Collapse
          key={k}
          accordion
          bordered
          ghost
          defaultActiveKey={k}
          className="device-list-group"
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? -180 : 0} />
          )}
        >
          <Collapse.Panel key={k} header={locationName}>
            {devices.map((d, idx) => {
              const deviceLength = devices.length
              const isOnline = getDeviceStatus(d) === 'online'
              const isActive = selected.find(s => s?.id === d.id)
              return (
                <React.Fragment key={d.id}>
                  <div
                    onClick={
                      !isOnline && mode === 'livestream'
                        ? () => {}
                        : () => handleSelect(d)
                    }
                    className={cx('device-item', {
                      hoverable: isOnline,
                      active: !!isActive
                    })}
                  >
                    <DeviceInfo device={d} />{' '}
                    {props.devices.find(pd => pd?.id === d.id) && (
                      <i>(Viewing)</i>
                    )}
                  </div>
                  {deviceLength > idx + 1 && (
                    <hr className="divider" style={{ margin: '8px 0' }} />
                  )}
                </React.Fragment>
              )
            })}
          </Collapse.Panel>
        </Collapse>
      )
    })
  }

  const handleChange = () => {
    let newArr = props.devices.slice()
    if (selected.length === 1) {
      newArr[props.index] = selected[0]
    } else {
      const s = selected.slice()
      newArr = Array.from({ length: props.max }).map((_, idx) => {
        if (props.devices[idx]) return props.devices[idx]
        return s.shift()
      })
    }
    onChange(newArr)
    onCancel()
  }

  return (
    <BaseModal
      noPadding
      height="100vh"
      className="modal-add-devices"
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={onCancel}
          actionRight={
            <button
              type="submit"
              className="base-btn text-primary hoverable text-500"
              disabled={loading}
              onClick={!loading ? handleChange : undefined}
            >
              Done
            </button>
          }
        >
          Add Devices
        </Layout.Header>
        <Layout.Body>
          <Input
            allowClear
            size="large"
            placeholder="Search devices"
            value={search}
            onChange={e => setState({ search: e.target.value })}
            suffix={<SearchOutlined />}
            style={{ marginBottom: '1.5rem' }}
            onKeyDown={e =>
              e.key === 'Enter' && canSearch && handleGetDevices()
            }
          />
          {loading ? (
            <Spinner height={200} />
          ) : !!Object.keys(grouppedDevices).length ? (
            renderDeviceList()
          ) : (
            renderEmptyList()
          )}
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalAddDevices
