import { notification } from 'antd'
import React from 'react'

interface NotiMessage {
  message?: React.ReactNode | string
  description?: React.ReactNode | string
  duration?: number
}

interface NotiErrorMessage extends NotiMessage {
  description?:
    | React.ReactNode
    | string
    | {
        [key: string]: string[]
      }
}

const NOTIF_DURATION = 5

function formatErrorDesc(desc: NotiErrorMessage['description']) {
  if (!desc) {
    return ' '
  }
  if (typeof desc === 'string' || React.isValidElement(desc)) {
    return desc
  }
  return Object.values(desc).map(msg => <div key={msg[0]}>{msg[0]}</div>)
}

export function notiError(config: NotiErrorMessage) {
  return notification.error({
    message: config.message || 'Error',
    description: formatErrorDesc(config.description),
    duration: config.duration || NOTIF_DURATION
  })
}

export function notiSuccess(config: NotiMessage) {
  return notification.success({
    duration: NOTIF_DURATION,
    message: config.message || 'Success',
    description: config.description
  })
}
