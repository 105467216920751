import { useState } from 'react'
import { omit } from 'lodash'
import { Tooltip } from 'antd'

import '../index.scss'
import { InfoCircle } from 'components/Icons'
import { Layout } from 'components/Shared'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import { days as DAYS } from 'utils/selectOptions'
import { notiError, notiSuccess } from 'utils/notification'
import { deviceCreateWizard } from 'services/devices'
import { useAppSelector } from 'store'

import DeviceDetails from './DeviceDetails'
import Schedule from './Schedule'
import CloudSurveillance from './CloudSurveillance'
import { steps, FormValues, formValues, getOptionsId } from './FormHelpers'

interface Props {
  onSuccess?: (usedLicense: boolean) => void
  onCancel: () => void
}

function ModalDeviceWizard({ onSuccess, onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<FormValues>(formValues)
  const [stepIndex, setStepIndex] = useState(0)

  const { currentUser } = useAppSelector(state => state.auth)
  const currentStep = steps[stepIndex]

  const handleGoBack = () => {
    if (stepIndex === 0) return onCancel()
    setStepIndex(prev => prev - 1)
  }

  const handleStepChange = (num: number) => {
    setStepIndex(prev => prev + num)
  }

  const handleSkip = () => {
    const key = currentStep.key as keyof FormValues
    handleSetValues(key, formValues[key])
    handleStepChange(1)
  }

  const handleSetValues = <T extends keyof FormValues>(
    k: T,
    v: FormValues[T],
  ) => {
    setValues(prev => ({ ...prev, [k]: v }))
  }

  const handleCreateDevice = async (v?: FormValues['cloudSurveillance']) => {
    const newDevice = {
      ...omit(values.deviceDetails, 'location'),
      location_id: values.deviceDetails.location?.value,
      account_id: currentUser!.account_id,
      stream_url: v?.stream_url,
      is_licensed: !!v?.stream_url,
    }

    const newSchedules = []
    if (values.notifications.name) {
      const { dateTime, ...schedule } = values.notifications
      const { is247, startTime, endTime, selectedDays } = dateTime
      newSchedules.push({
        name: schedule.name,
        is_24_7: is247,
        start_time: startTime || undefined,
        end_time: endTime || undefined,
        days: (is247 ? DAYS : selectedDays).map(d => d.toUpperCase()),
        is_active: true,
        channels: schedule.channels,
        email_recipient_ids: getOptionsId(schedule.email_recipients),
        phone_recipient_ids: getOptionsId(schedule.phone_recipients),
        whatsapp_recipient_ids: getOptionsId(schedule.whatsapp_recipients),
        webhook: schedule.webhook,
        slack_webhook: schedule.slack_webhook,
        teams_webbhook: schedule.teams_webbhook,
      })
    }

    try {
      setLoading(true)
      await deviceCreateWizard({
        device: newDevice,
        deviceSchedules: newSchedules,
      })
      notiSuccess({ message: `${newDevice.name} was added` })
      onCancel()
      onSuccess?.(!!v?.stream_url)
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const renderFormStep = () => {
    switch (stepIndex) {
      case 0:
        return (
          <DeviceDetails
            initValue={values.deviceDetails}
            onChange={handleSetValues}
            onStepChange={handleStepChange}
          />
        )
      case 1:
        return (
          <Schedule
            initValue={values.notifications}
            onChange={handleSetValues}
            onStepChange={handleStepChange}
          />
        )
      default:
        return (
          <CloudSurveillance
            loading={loading}
            initValue={values.cloudSurveillance}
            onSubmit={handleCreateDevice}
          />
        )
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      onCancel={onCancel}
      className='modal-device-wizard'
    >
      <Layout>
        <Layout.Header
          bordered
          sticky
          goBack
          onGoBack={loading ? () => {} : handleGoBack}
          actionRight={
            !loading &&
            currentStep.canSkip && (
              <span
                className='text-500 hoverable text-primary'
                onClick={handleSkip}
              >
                Skip
              </span>
            )
          }
        >
          <h4>Create Device</h4>
          <span className='text-400 text-placeholder'>
            Step {stepIndex + 1}/{steps.length}: {currentStep.title}
            {!!currentStep.toolTip && (
              <Tooltip
                placement='bottom'
                title={currentStep.toolTip}
                getPopupContainer={getPopupContainer}
              >
                <InfoCircle color='#808080' />
              </Tooltip>
            )}
          </span>
        </Layout.Header>
        <Layout.Body>{renderFormStep()}</Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalDeviceWizard
