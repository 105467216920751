import { FilterFilled, TagFilled } from 'components/Icons'
import { Tag } from 'components/Shared'
import { Tag as ITag } from 'services/tags'

interface Props {
  tag: ITag
}

function TagDetails({ tag }: Props) {
  return (
    <div style={{ padding: '1rem 8px' }}>
      <div className='detail-title'>
        <TagFilled type='primary' />
        <h3>Tag Info</h3>
      </div>
      <Tag
        strokeColor={tag.stroke_color}
        textColor={tag.text_color}
        color={tag.color}
      >
        {tag.name}
      </Tag>
      <div className='divider' />
      <div className='detail-title'>
        <FilterFilled type='primary' />
        <h3>Matching Criteria</h3>
      </div>
      {!!tag.subject_keywords && (
        <div className='detail-group'>
          <div>In Title</div>
          <div>
            <div>{tag.subject_keywords}</div>
          </div>
        </div>
      )}
      <div className='detail-group'>
        <div>Matching Method</div>
        <div>
          <div>
            {tag.search_in === 'subject_and_body'
              ? 'And'
              : tag.search_in === 'subject_or_body'
              ? 'Or'
              : tag.search_in === 'subject'
              ? 'Title'
              : 'Content'}
          </div>
        </div>
      </div>
      {!!tag.body_keywords && (
        <div className='detail-group'>
          <div>In Content</div>
          <div>
            <div>{tag.body_keywords}</div>
          </div>
        </div>
      )}
      {!!tag.has_attachments && (
        <div className='detail-group'>
          <div>Other Criteria</div>
          <div>Contains attachment</div>
        </div>
      )}
    </div>
  )
}

export default TagDetails
