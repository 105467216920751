import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import './index.scss'
import Upgrade from 'assets/images/upgrade.svg'
import Available from 'assets/images/available.svg'

import { Layout, NoResult, Spinner } from 'components/Shared'
import { notiError } from 'utils/notification'
import { Device, getDevices } from 'services/devices'
import { useAppSelector } from 'store'

import PageLivestream from './Livestream'

function LivestreamWrapper() {
  const [loading, setLoading] = useState(true)
  const [devices, setDevices] = useState<Device[]>([])

  const { stats } = useAppSelector(state => state.stats)
  const camLicenseCount = stats?.camera_licenses.total || 0

  useEffect(() => {
    if (!camLicenseCount) return setLoading(false)
    handleGetDevices()
    // eslint-disable-next-line
  }, [])

  const handleGetDevices = async () => {
    try {
      const { data } = await getDevices({ currentPage: 1, pageSize: 9999 })
      setDevices(data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const renderChildren = () => {
    if (!camLicenseCount) {
      return (
        <NoResult style={{ maxWidth: 320 }} imgSrc={Upgrade}>
          <h3 className='title'>You need camera licenses</h3>
          <div className='sub-title text-placeholder'>
            to view your livestreams
          </div>
          <Link
            to='/settings/payment-settings/plans'
            className='ant-btn ant-btn-primary ant-btn-lg'
          >
            Get Camera License
          </Link>
        </NoResult>
      )
    }

    const remaning =
      (stats?.camera_licenses.total || 0) - (stats?.camera_licenses.used || 0)

    return (
      <NoResult style={{ maxWidth: 320 }} imgSrc={Available}>
        <h3 className='title'>Please assign licenses to your cameras</h3>
        <div className='sub-title text-placeholder'>
          to view your livestreams
          <div className='license-available'>
            {remaning} {remaning > 2 ? 'licenses' : 'license'} available
          </div>
        </div>
        <Link
          to='/devices?tab=device-list'
          className='ant-btn ant-btn-primary ant-btn-lg'
        >
          Assign License
        </Link>
      </NoResult>
    )
  }

  if (!loading && !!camLicenseCount && devices.some(d => !!d.is_licensed)) {
    return <PageLivestream />
  }

  return (
    <Layout className='page-livestream-wrapper'>
      <Layout.Header bordered={!loading}>Livestream</Layout.Header>
      <Layout.Body>{loading ? <Spinner /> : renderChildren()}</Layout.Body>
    </Layout>
  )
}

export default LivestreamWrapper
