import { useEffect, useReducer, useRef } from 'react'
import Hls from 'hls.js'

import { DeviceInfo, Spinner } from 'components/Shared'
import {
  getRecordingPlaybackV2,
  PlaybackSegment,
  RecordPlaybackV2,
} from 'services/recordingPlayback'
import { getDeviceStatus, statusColors } from 'utils/device'
import { formatTime, hmsToSeconds, YYYY_MM_DD_HH_MM } from 'utils/dateTime'

import { Player, usePlaybackContext } from './PlaybackContext'

interface Props {
  player: Player
}

interface State {
  loading: boolean
  error: string
  segments: PlaybackSegment[]
  segmentIdx: number
}

const initState: State = {
  loading: false,
  error: '',
  segments: [],
  segmentIdx: -1,
}

function PlaybackPlayer({ player }: Props) {
  const { timeRange, currentTime, seekTime, dispatch } = usePlaybackContext()
  const [{ loading, segments, segmentIdx, error }, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  const videoRef = useRef<HTMLVideoElement>(null)
  const hlsRef = useRef<Hls>()
  const { device, index, muted } = player

  useEffect(() => {
    if (!timeRange.length) {
      return setState({ error: 'Please select duration' })
    }
    resetPlayer()
    handleGetRecording()
    // eslint-disable-next-line
  }, [timeRange])

  useEffect(() => {
    removeSource()
    removeHls()
    if (!segments.length) return
    if (segmentIdx === -1) {
      return handlePlayerError({ message: 'No video at this time' })
    }
    loadSource()
    // eslint-disable-next-line
  }, [segments, segmentIdx])

  useEffect(() => {
    if (!segments.length || !videoRef.current) return
    handleCurrentTimeChange()
    // eslint-disable-next-line
  }, [currentTime])

  useEffect(() => {
    return removeHls
  }, [])

  const handleGetRecording = async () => {
    setState({ loading: true, error: '' })
    dispatch({ type: 'UPDATE_PLAYER', payload: { index, status: 'loading' } })

    try {
      const response = await getRecordingPlaybackV2(device.id, {
        start_timestamp: formatTime(timeRange[0], YYYY_MM_DD_HH_MM),
        end_timestamp: formatTime(timeRange[1], YYYY_MM_DD_HH_MM),
      })
      const data = response.data.data as RecordPlaybackV2
      if (!data.playback.length) {
        // eslint-disable-next-line
        throw { status: 404 }
      }
      if (hmsToSeconds(data.playback[0].starttime) === 0) {
        setState({ segmentIdx: 0 })
      }
      setState({ segments: data.playback })
      dispatch({
        type: 'UPDATE_PLAYER',
        payload: {
          index,
          segments: data.playback,
          notifs: data.notifications,
        },
      })
    } catch (e: any) {
      handlePlayerError(e)
    }
  }

  const handleCurrentTimeChange = () => {
    const videoEl = videoRef.current!
    if (currentTime === 0 && segmentIdx === 0) {
      videoEl.currentTime = 0
    }

    const newSegmentIdx = segments.findIndex(
      seg =>
        hmsToSeconds(seg.starttime) <= currentTime &&
        currentTime < hmsToSeconds(seg.endtime),
    )

    if (newSegmentIdx === -1) {
      return setState({ segmentIdx: -1 })
    }

    if (currentTime === seekTime) {
      videoEl.currentTime =
        currentTime - hmsToSeconds(segments[newSegmentIdx].starttime)
    }
    setState({ segmentIdx: newSegmentIdx })
  }

  const loadSource = () => {
    setState({ error: '', loading: true })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, status: 'loading' },
    })

    const videoEl = videoRef.current!

    if (Hls.isSupported()) {
      const hls = new Hls()
      hls.loadSource(segments[segmentIdx].url)
      hls.attachMedia(videoEl!)
      hlsRef.current = hls
    } else {
      videoEl.src = segments[segmentIdx].url
    }

    videoEl.currentTime =
      currentTime - hmsToSeconds(segments[segmentIdx].starttime)
  }

  const handlePlayerError = (err?: { status?: number; message?: string }) => {
    setState({
      loading: false,
      error:
        err && err.status === 404
          ? 'No video found'
          : err?.message || 'Video unavailable',
    })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, status: 'idle' },
    })
  }

  const removeHls = () => {
    if (hlsRef.current) {
      hlsRef.current.detachMedia()
      hlsRef.current.stopLoad()
      hlsRef.current.destroy()
      hlsRef.current = undefined
    }
  }

  const removeSource = () => {
    if (videoRef.current) {
      videoRef.current.removeAttribute('src')
      videoRef.current.load()
    }
  }

  const resetPlayer = () => {
    removeHls()
    removeSource()
    setState({ loading: false, error: '', segments: [], segmentIdx: -1 })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: {
        index,
        segments: [],
        notifs: [],
        status: 'idle',
      },
    })
  }

  const handleCanPlay = () => {
    setState({ loading: false })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, status: 'ready', video: videoRef.current },
    })
  }

  const handleWaiting = () => {
    setState({ loading: true })
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: { index, status: 'loading' },
    })
  }

  return (
    <div className='playback-player'>
      <div className='player-info'>
        {device && (
          <DeviceInfo device={device}>
            <span
              className='text-bold'
              style={{ color: statusColors[getDeviceStatus(device)] }}
            >
              #{index + 1}
            </span>
            <span className='device-name'>{device.name}</span>
          </DeviceInfo>
        )}
      </div>
      {loading && <Spinner />}
      {error && <div className='player-message'>{error}</div>}
      <video
        className='video'
        muted={muted}
        ref={videoRef}
        onCanPlay={handleCanPlay}
        onWaiting={handleWaiting}
        style={{ zIndex: error ? -1 : 1 }}
      />
    </div>
  )
}

export default PlaybackPlayer
