import TableListHeader from './TableListHeader'
import TableList from './TableList'
import VaultContextProvider from './VaultContext'

interface Props {
  deviceId: number
}

function VideoVault({ deviceId }: Props) {
  return (
    <VaultContextProvider>
      <TableListHeader />
      <TableList deviceId={deviceId} />
    </VaultContextProvider>
  )
}

export default VideoVault
