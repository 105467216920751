import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ElectronicDevice({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M17.9825 7.2664C17.4 5.55776 15.7802 4.32495 13.8769 4.32495C11.9736 4.32495 10.354 5.55776 9.77139 7.2664H0V14.0153H1.92655V15.675H4.31812V14.0153H15.6819V15.675H18.0734V14.0153H20V7.2664H17.9825ZM8.32557 12.2176H2.12293V10.9775H8.32557V12.2176ZM8.32557 9.85581H2.12293V8.6157H8.32557V9.85581ZM13.8769 11.2059C12.4743 11.2059 11.3333 10.0648 11.3333 8.66224C11.3333 7.25968 12.4743 6.11862 13.8769 6.11862C15.2795 6.11862 16.4206 7.25968 16.4206 8.66224C16.4206 10.0648 15.2795 11.2059 13.8769 11.2059Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
