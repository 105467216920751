import moment from 'moment'
import { Switch } from 'antd'

import {
  Attachment,
  CameraFilled,
  ClockFilled,
  Location,
  TagFilled,
  TextFilled,
  BellFilled,
} from 'components/Icons'
import { Tag } from 'components/Shared'
import { InboundNoti } from 'services/notifications'
import { Note as INote } from 'services/notes'
import { useAppSelector } from 'store'

import NotifNote from './NotifNote'
import React from 'react'

interface Props {
  previewMode: boolean
  setPreviewMode: (v: boolean) => void
  notifDetail: InboundNoti
  notifNotes: INote[]
}

function DetailAndNote({
  notifDetail,
  notifNotes,
  previewMode,
  setPreviewMode,
}: Props) {
  const { currentUser } = useAppSelector(state => state.auth)

  const renderTags = () => {
    return notifDetail.tags!.map(tag => (
      <Tag
        key={tag.id}
        textColor={tag.text_color}
        strokeColor={tag.stroke_color}
        color={tag.color}
      >
        {tag.name}
      </Tag>
    ))
  }

  const getAttachmentName = (attachment: string) => {
    const paths = attachment.split('/')
    return paths[paths.length - 1]
  }

  const renderAttachments = () => {
    return notifDetail.attachments!.map((attachment, idx) => (
      <a
        className='attachment'
        key={attachment}
        href={notifDetail.attachment_urls[idx]}
        target='_blank'
        rel='noreferrer'
      >
        <Attachment />
        {getAttachmentName(attachment)}
      </a>
    ))
  }

  return (
    <div style={{ padding: '1rem 8px' }}>
      <div className='title-group'>
        <div>
          {!previewMode && (
            <React.Fragment>
              <BellFilled type='primary' />
              <span className='text-500'>Notification Detail</span>
            </React.Fragment>
          )}
        </div>
        <div>
          <Switch checked={previewMode} onChange={setPreviewMode} /> Preview
          Mode
        </div>
      </div>
      {currentUser?.account.brand_logo_url && previewMode && (
        <div className='preview-brand-logo'>
          <img src={currentUser.account.brand_logo_url} alt='brand-logo' />
        </div>
      )}
      {previewMode && (
        <div className='title-group'>
          <div>
            <BellFilled type='primary' />
            <span className='text-500'>Notification Detail</span>
          </div>
        </div>
      )}
      <div className='detail-group'>
        <div className='detail-group-title'>
          <TextFilled color='#BABBBF' />
          <span className='text-500'>Title</span>
        </div>
        <div className='detail-group-content'>{notifDetail.subject}</div>
      </div>
      <hr className='divider' />
      <div className='detail-group'>
        <div className='detail-group-title'>
          <TextFilled color='#BABBBF' />
          <span className='text-500'>Content</span>
        </div>
        <div className='detail-group-content'>{notifDetail.message}</div>
      </div>
      <hr className='divider' />
      <div className='detail-group'>
        <div className='detail-group-title'>
          <ClockFilled color='#BABBBF' />
          <span className='text-500'>Timestamp</span>
        </div>
        <div className='detail-group-content'>
          {moment(notifDetail.created_at).format('MMM DD, YYYY hh:mm:ss A')}
        </div>
      </div>
      <hr className='divider' />
      <div className='detail-group'>
        <div className='detail-group-title'>
          <CameraFilled color='#BABBBF' />
          <span className='text-500'>Device Name</span>
        </div>
        <div className='detail-group-content'>
          {notifDetail.device?.name || '---'}
        </div>
      </div>
      <hr className='divider' />
      <div className='detail-group'>
        <div className='detail-group-title'>
          <Location color='#BABBBF' />
          <span className='text-500'>Location</span>
        </div>
        <div className='detail-group-content'>
          {notifDetail.device?.location?.name || '---'}
        </div>
      </div>
      <hr className='divider' />
      <div className='detail-group'>
        <div className='detail-group-title'>
          <TagFilled color='#BABBBF' />
          <span className='text-500'>Tags</span>
        </div>
        <div className='detail-group-content'>
          {!!notifDetail.tags && !!notifDetail.tags.length
            ? renderTags()
            : '---'}
        </div>
      </div>
      <hr className='divider' />
      <div className='detail-group'>
        <div className='detail-group-title'>
          <Attachment color='#BABBBF' />
          <span className='text-500'>Attachments</span>
        </div>
        <div className='detail-group-content'>
          {!!notifDetail.attachments && !!notifDetail.attachments.length
            ? renderAttachments()
            : '---'}
        </div>
      </div>
      {!!notifNotes.length && (
        <React.Fragment>
          <div className='divider' />
          <NotifNote previewMode={previewMode} notifNotes={notifNotes} />
        </React.Fragment>
      )}
    </div>
  )
}

export default DetailAndNote
