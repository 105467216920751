import { Link } from 'react-router-dom'

import Upgrade from 'assets/images/upgrade.svg'

interface Props {
  title: string
  desc: string
  [key: string]: any
}

export function AccessDenied({ title, desc, ...props }: Props) {
  return (
    <div className='access-denied-wrapper' {...props}>
      <div className='img'>
        <img src={Upgrade} alt='access-denied' />
      </div>
      <div className='message'>
        <h1>{title}</h1>
        <span className='text-placeholder'>{desc}</span>
      </div>
      <Link
        to='/settings/payment-settings/plans'
        className='ant-btn ant-btn-primary ant-btn-lg'
      >
        Upgrade Plan
      </Link>
    </div>
  )
}
