import { useState } from 'react'
import { useHistory } from 'react-router'
import { AnimatePresence } from 'framer-motion'
import { Tooltip } from 'antd'

import { InfoCircle, TagFilled } from 'components/Icons'
import CheckoutForm from 'components/CheckoutForm'
import ModalPlanUpdated from 'components/Modal/Checkout/ModalPlanUpdated'
import { CHARGE_PER_CAM } from 'utils/device'
import { notiError } from 'utils/notification'
import {
  updateDefaultCreditCard,
  updateSubscription,
} from 'services/subscription'
import { useAppSelector } from 'store'

type RouteParams = {
  planId: number
  camCount: number
}

function Difference(price: string, title: string) {
  return (
    <>
      <li className='divider' />
      <li>
        <span>Difference</span>
        <span className='text-500 text-body with-tooltip'>
          <span style={{ fontSize: '1rem', width: 'auto' }}>{price}</span>
          <Tooltip title={title}>
            <InfoCircle type='success' />
          </Tooltip>
        </span>
      </li>
    </>
  )
}

function UpdatePlan() {
  const { location } = useHistory()
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { plans } = useAppSelector(state => state.plans)
  const { subscription } = useAppSelector(state => state.subscription)
  const { stats } = useAppSelector(state => state.stats)

  const { planId, camCount } = location.state as RouteParams
  const selectedPlan = plans.find(p => p.id === planId)!

  const handleSubmit = async (v?: { paymentId: string }) => {
    try {
      setCheckoutLoading(true)
      if (v?.paymentId) {
        await updateDefaultCreditCard(v.paymentId)
      }
      await updateSubscription({ plan_id: planId, camera_licenses: camCount })
      setCheckoutLoading(false)
      setShowModal(true)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ description: errors || message })
      setCheckoutLoading(false)
    }
  }

  const updatePlanOnly = () => (
    <ul className='summary'>
      <li>
        <span>Base Plan</span>
        <span className='with-tooltip'>
          <b className='text-500 text-body' style={{ fontSize: '1rem' }}>
            ${selectedPlan.price / 100}
          </b>
          /month
          <Tooltip title='Your billing cycle is being reset. Existing notification quotas will carry over.'>
            <InfoCircle type='success' />
          </Tooltip>
        </span>
      </li>
      <li className='divider' style={{ borderStyle: 'solid' }} />
      <li>
        <span>Total</span>
        <span>
          <b style={{ fontSize: '1.5rem' }} className='text-500 text-body'>
            ${selectedPlan.price / 100}
          </b>
          /month
        </span>
      </li>
    </ul>
  )

  const updatePlanWithCamCount = () => (
    <ul className='summary'>
      <li>
        <span>Camera Licenses</span>
        <span>
          <b className='text-500 text-body' style={{ fontSize: '1rem' }}>
            {camCount} x ${CHARGE_PER_CAM}
          </b>
          /month
        </span>
      </li>
      <li className='divider' />
      <li>
        <span>Base Plan</span>
        <span>
          <b className='text-500 text-body' style={{ fontSize: '1rem' }}>
            ${selectedPlan.price / 100}
          </b>
          /month
        </span>
      </li>
      <li className='divider' />
      <li>
        <span>Monthly Total</span>
        <span>
          <b className='text-500 text-body' style={{ fontSize: '1rem' }}>
            ${selectedPlan.price / 100 + camCount * CHARGE_PER_CAM}
          </b>
          /month
        </span>
      </li>
      {renderDifference()}
    </ul>
  )

  const renderDifference = () => {
    if (!subscription || !subscription.subscription || !stats) {
      return null
    }
    const currentPlan = subscription.subscription.plan!
    const currentCamCount = stats.camera_licenses.total
    // not show diffirent on initial purchase
    if (currentPlan.id === 1) {
      return null
    }
    // CASE 1 & 2
    if (currentPlan.id === planId) {
      const diff = (camCount - currentCamCount) * CHARGE_PER_CAM
      return Difference(
        `${diff > -1 ? '+' : '-'}$${Math.abs(diff)}`,
        camCount > currentCamCount
          ? 'You will be charged a prorated amount today'
          : 'You will be credited towards your next billing cycle on a prorated basis',
      )
    }

    // CASE 3, 4, 5 & 6
    const planDiff = (selectedPlan.price - currentPlan.price) / 100
    const camDiff = (camCount - currentCamCount) * CHARGE_PER_CAM
    const diff = planDiff + camDiff

    return Difference(
      `${diff > -1 ? '+' : '-'}$${Math.abs(diff)}`,
      'Your billing cycle will be reset and you will be credited on a prorated basis',
    )
  }

  if (!stats || !subscription || !plans.length) {
    return null
  }

  const isUpdatePlanOnly =
    subscription.subscription!.plan_id !== planId &&
    camCount === stats.camera_licenses.total

  return (
    <div className='checkout-wrapper'>
      <div className='checkout-details'>
        <h3>Selected Plan</h3>
        <div className='plan-info'>
          <span className='plan-label text-500'>{selectedPlan.label}</span>
          {selectedPlan.id === 4 && (
            <span className='popular text-primary text-bold'>
              <TagFilled type='primary' /> Popular
            </span>
          )}
        </div>
        {isUpdatePlanOnly ? updatePlanOnly() : updatePlanWithCamCount()}
      </div>
      <div className='divider' style={{ margin: '2rem 0 1.5rem 0' }} />
      <CheckoutForm loading={checkoutLoading} onSubmit={handleSubmit} />
      <AnimatePresence>
        {showModal && (
          <ModalPlanUpdated
            title='Your plan has been updated'
            description={
              isUpdatePlanOnly
                ? `You are now subscribed to the ${selectedPlan.label} plan.`
                : `You have updated to the ${selectedPlan.label} plan and ${
                    camCount - stats.camera_licenses.total > 0
                      ? 'added'
                      : 'removed'
                  } ${Math.abs(
                    camCount - stats.camera_licenses.total,
                  )} camera licenses.`
            }
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default UpdatePlan
