import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Livestream({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='20'
        viewBox='0 0 24 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M21.3958 15.6667H2.60417C1.71958 15.6667 1 14.9471 1 14.0625V2.60417C1 1.71958 1.71958 1 2.60417 1H21.3958C22.2804 1 23 1.71958 23 2.60417V14.0625C23 14.9471 22.2804 15.6667 21.3958 15.6667ZM2.60417 2.375C2.47767 2.375 2.375 2.47767 2.375 2.60417V14.0625C2.375 14.189 2.47767 14.2917 2.60417 14.2917H21.3958C21.5223 14.2917 21.625 14.189 21.625 14.0625V2.60417C21.625 2.47767 21.5223 2.375 21.3958 2.375H2.60417Z'
          fill={c}
          stroke={c}
          strokeWidth='0.5'
        />
        <path
          d='M15.8959 19.3334H8.10425C7.72475 19.3334 7.41675 19.0254 7.41675 18.6459C7.41675 18.2664 7.72475 17.9584 8.10425 17.9584H15.8959C16.2754 17.9584 16.5834 18.2664 16.5834 18.6459C16.5834 19.0254 16.2754 19.3334 15.8959 19.3334Z'
          fill={c}
          stroke={c}
          strokeWidth='0.5'
        />
        <path
          d='M12 19.3333C11.6205 19.3333 11.3125 19.0253 11.3125 18.6458V14.9791C11.3125 14.5996 11.6205 14.2916 12 14.2916C12.3795 14.2916 12.6875 14.5996 12.6875 14.9791V18.6458C12.6875 19.0253 12.3795 19.3333 12 19.3333Z'
          fill={c}
          stroke={c}
          strokeWidth='0.5'
        />
        <path
          d='M19.1042 9.02081H16.8125C16.433 9.02081 16.125 8.71281 16.125 8.33331C16.125 7.95381 16.433 7.64581 16.8125 7.64581H19.1042C19.4837 7.64581 19.7917 7.95381 19.7917 8.33331C19.7917 8.71281 19.4837 9.02081 19.1042 9.02081Z'
          fill={c}
        />
        <path
          d='M19.1042 11.0833H17.2708C16.6392 11.0833 16.125 10.5691 16.125 9.93748V6.72915C16.125 6.09756 16.6392 5.58331 17.2708 5.58331H19.1042C19.4837 5.58331 19.7917 5.89131 19.7917 6.27081C19.7917 6.65031 19.4837 6.95831 19.1042 6.95831H17.5V9.70831H19.1042C19.4837 9.70831 19.7917 10.0163 19.7917 10.3958C19.7917 10.7753 19.4837 11.0833 19.1042 11.0833Z'
          fill={c}
        />
        <path
          d='M9.9375 11.0833C9.558 11.0833 9.25 10.7753 9.25 10.3958V6.27081C9.25 5.89131 9.558 5.58331 9.9375 5.58331C10.317 5.58331 10.625 5.89131 10.625 6.27081V10.3958C10.625 10.7753 10.317 11.0833 9.9375 11.0833Z'
          fill={c}
        />
        <path
          d='M7.64591 11.0833H5.35425C4.97475 11.0833 4.66675 10.7753 4.66675 10.3958V6.27081C4.66675 5.89131 4.97475 5.58331 5.35425 5.58331C5.73375 5.58331 6.04175 5.89131 6.04175 6.27081V9.70831H7.64591C8.02541 9.70831 8.33341 10.0163 8.33341 10.3958C8.33341 10.7753 8.02541 11.0833 7.64591 11.0833Z'
          fill={c}
        />
        <path
          d='M13.3749 11.0833C13.066 11.0833 12.7956 10.8771 12.7121 10.5801L11.5663 6.45506C11.4646 6.08931 11.6791 5.7098 12.0448 5.60805C12.4087 5.5063 12.7901 5.7208 12.8909 6.08564L13.3749 7.82731L13.858 6.08655C13.9597 5.72172 14.3392 5.5063 14.7041 5.60897C15.0698 5.71072 15.2843 6.08931 15.1826 6.45597L14.0367 10.581C13.9542 10.8771 13.6838 11.0833 13.3749 11.0833Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
