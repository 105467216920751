import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { Button, notification } from 'antd'

import { Spinner } from 'components/Shared'
import AppMenu from 'components/Menu'
import { notiError } from 'utils/notification'
import { downloadFile } from 'utils/functions'

import axios from 'services/axios'
import { BroadcastNoti } from 'services/notifications'
import { useAppDispatch, useAppSelector } from 'store'
import { getCurrentUserInfo } from 'store/slice/authSlice'
import { getAccountStats } from 'store/slice/statsSlice'
import { getSubscription } from 'store/slice/subscriptionSlice'
import { getAppConfig } from 'store/slice/configSlice'
import { addNewNotif, getSystemNotis } from 'store/slice/notificationSlice'

window.Pusher = require('pusher-js')

declare global {
  interface Window {
    Echo: Echo
    Pusher: Pusher
  }
}

const MainLayout: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)
  const { config } = useAppSelector(state => state.appConfig)

  useEffect(() => {
    init()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!currentUser || window.Echo || !config) {
      return
    }

    window.Echo = new Echo({
      broadcaster: 'pusher',
      cluster: config.pusher_app_cluster,
      key: config.pusher_app_key,
      encrypted: true,
      authorizer: (channel: { name: string }) => {
        return {
          authorize: (socketId: string, callback: any) => {
            axios
              .post('/api/broadcasting/auth', {
                socket_id: socketId,
                channel_name: channel.name
              })
              .then(response => {
                callback(false, response.data)
              })
              .catch(error => {
                callback(true, error)
              })
          }
        }
      }
    })

    window.Echo.private(`App.User.${currentUser.id}`).notification(
      handleAddNotif
    )
    return () => window.Echo.leave(`App.User.${currentUser.id}`)
    // eslint-disable-next-line
  }, [currentUser, config])

  const handleAddNotif = (notif: BroadcastNoti) => {
    dispatch(
      addNewNotif({
        created_at: new Date().toISOString(),
        data: notif.data,
        read_at: null
      })
    )
    if (notif.data.url) {
      return notification.success({
        message: '',
        description: (
          <React.Fragment>
            {notif.data.message}
            <br />
            <Button
              type="primary"
              style={{ marginTop: 6, fontSize: 13 }}
              onClick={() => downloadFile(notif.data.url!)}
            >
              Download
            </Button>
          </React.Fragment>
        )
      })
    }
  }

  const init = async () => {
    try {
      await Promise.all([
        dispatch(getCurrentUserInfo()),
        dispatch(getAccountStats()),
        dispatch(getSubscription()),
        dispatch(getAppConfig()),
        dispatch(getSystemNotis(1))
      ])
    } catch (err: any) {
      setError(true)
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner height="100vh" />
  }
  if (!!error) {
    return null
  }
  if (currentUser && !currentUser.email_verified_at) {
    return <Redirect to="/verify-email" />
  }

  return (
    <React.Fragment>
      <div style={{ paddingBottom: 55 }}>{children}</div>
      <AppMenu />
    </React.Fragment>
  )
}

export default MainLayout
