import cx from 'classnames'

import { TableColProps } from './TableTypes'

const TableCol: React.FC<TableColProps> = ({
  className,
  width,
  onColClick,
  children,
}) => {
  return (
    <div
      onClick={onColClick}
      className={cx('m-table-col', className)}
      style={{
        width,
        minWidth: width,
        maxWidth: width,
      }}
    >
      {children}
    </div>
  )
}

export default TableCol
