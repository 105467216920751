import axios from './axios'

export interface ActivityLog {
  account_id: number
  causer_id: number | null
  causer_type: string | null
  created_at: string
  description: string
  id: number
  log_name: string | null
  properties: {
    ip: string
  } | null
  subject_id: number
  subject_type: string
  updated_at: string
}

export const getAllLogs = ({
  sort,
  sortDirection,
  pageSize,
  currentPage,
  search,
  userId,
  accountId,
  dateRange,
}: {
  sort: string
  sortDirection: string
  pageSize: number
  currentPage: number
  search?: string
  userId?: number
  accountId?: number
  dateRange?: string
}) => {
  let filters = ''
  if (search) {
    filters += `&filters[search]=${search}`
  }
  if (userId) {
    filters += `&filters[user_ids]=${userId}`
  }
  if (accountId) {
    filters += `&filters[account_id]=${accountId}`
  }
  if (dateRange) {
    filters += `&filters[date_range]=${dateRange}`
  }

  return axios.get(
    `/api/activityLogs?order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}${filters}`,
  )
}

export const getDeviceLogs = (
  deviceId: number,
  {
    startDate,
    endDate,
    sort,
    sortDirection,
    currentPage,
    pageSize,
    search,
  }: {
    startDate: string
    endDate: string
    sort: string
    sortDirection: string
    pageSize: number
    currentPage: number
    search: string
  },
) => {
  return axios.get(
    `/api/activityLogs/devices/${deviceId}?filters[date_range]=${
      startDate && endDate ? `${startDate},${endDate}` : ''
    }&filters[search]=${search}&order_by=${sort}&sort=${sortDirection}&page=${currentPage}&limit=${pageSize}`,
  )
}
