import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function StorageFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='14'
        height='13'
        viewBox='0 0 14 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M7.41016 7.38281C8.08973 7.38281 8.64062 6.83191 8.64062 6.15234C8.64062 5.47277 8.08973 4.92188 7.41016 4.92188C6.73059 4.92188 6.17969 5.47277 6.17969 6.15234C6.17969 6.83191 6.73059 7.38281 7.41016 7.38281Z'
          fill='url(#paint0_linear)'
        />
        <path
          d='M0.410156 10.2539H0.820312V11.0742C0.820312 11.7527 1.37227 12.3047 2.05078 12.3047H12.7695C13.448 12.3047 14 11.7527 14 11.0742V1.23047C14 0.551961 13.448 0 12.7695 0H2.05078C1.37227 0 0.820312 0.551961 0.820312 1.23047V2.05078H0.410156C0.183449 2.05078 0 2.23423 0 2.46094V4.92188C0 5.14858 0.183449 5.33203 0.410156 5.33203H0.820312V6.97266H0.410156C0.183449 6.97266 0 7.15611 0 7.38281V9.84375C0 10.0705 0.183449 10.2539 0.410156 10.2539ZM11.5117 5.33203C11.5117 5.10532 11.6952 4.92188 11.9219 4.92188C12.1486 4.92188 12.332 5.10532 12.332 5.33203V6.97266C12.332 7.19936 12.1486 7.38281 11.9219 7.38281C11.6952 7.38281 11.5117 7.19936 11.5117 6.97266V5.33203ZM5.35938 6.15234C5.35938 5.73508 5.48609 5.34745 5.70104 5.02321L3.8389 3.16107C3.67869 3.00087 3.67869 2.74129 3.8389 2.58109C3.99911 2.42088 4.25868 2.42088 4.41889 2.58109L6.28102 4.44322C6.60527 4.22827 6.99289 4.10156 7.41016 4.10156C7.82742 4.10156 8.21505 4.22827 8.53929 4.44322L10.4014 2.58109C10.5616 2.42088 10.8212 2.42088 10.9814 2.58109C11.1416 2.74129 11.1416 3.00087 10.9814 3.16107L9.11928 5.02321C9.33423 5.34745 9.46094 5.73508 9.46094 6.15234C9.46094 6.56961 9.33423 6.95723 9.11928 7.28148L10.9814 9.14361C11.1416 9.30382 11.1416 9.56339 10.9814 9.7236C10.9013 9.80372 10.7964 9.84378 10.6914 9.84378C10.5865 9.84378 10.4815 9.80372 10.4015 9.7236L8.53932 7.86146C8.21505 8.07641 7.82742 8.20312 7.41016 8.20312C6.99289 8.20312 6.60527 8.07641 6.28102 7.86146L4.41889 9.7236C4.33877 9.80372 4.23382 9.84378 4.12891 9.84378C4.02399 9.84378 3.91902 9.80372 3.83893 9.7236C3.67872 9.56339 3.67872 9.30382 3.83893 9.14361L5.70106 7.28148C5.48609 6.95723 5.35938 6.56961 5.35938 6.15234Z'
          fill='url(#paint1_linear)'
        />
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='6.17969'
            y1='4.92188'
            x2='6.17969'
            y2='7.38281'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='paint1_linear'
            x1='0'
            y1='0'
            x2='0'
            y2='12.3047'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
