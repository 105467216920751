import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function FilterFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='14'
        height='15'
        viewBox='0 0 14 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.546904 0.373047H13.4532C13.7552 0.373047 14 0.617859 14 0.919923C14 2.44285 13.3471 3.89677 12.2088 4.9086L9.81373 7.03735C9.39842 7.4065 9.16022 7.93692 9.16022 8.49266V11.6746C9.16022 11.8574 9.06878 12.0281 8.91667 12.1296L5.69005 14.2805C5.32698 14.5226 4.83989 14.2614 4.83989 13.8256V8.49266C4.83989 7.93692 4.60169 7.4065 4.18638 7.03735L1.79122 4.9086C0.652867 3.89677 0 2.44285 0 0.919923C0 0.617859 0.244825 0.373047 0.546904 0.373047ZM9.08752 6.21992L11.4826 4.09106C12.2503 3.40874 12.7401 2.47371 12.8713 1.46669H1.12964C1.26081 2.47371 1.75078 3.40874 2.51837 4.09116L4.91343 6.21992C5.56202 6.79649 5.93406 7.62482 5.93406 8.49266V12.8037L8.06688 11.3819V8.49256C8.06688 7.62482 8.43893 6.79649 9.08752 6.21992Z'
          fill='url(#filter_filled0_linear)'
        />
        <path
          d='M0.5 0.873047H13.5007L12.5006 3.87305L10.0005 5.87305L8.50041 7.87305V11.873L5.50026 13.873V8.87305L4.5002 6.87305L1.50005 3.87305L0.5 0.873047Z'
          fill='url(#filter_filled1_linear)'
        />
        <defs>
          <linearGradient
            id='filter_filled0_linear'
            x1='0'
            y1='0.373047'
            x2='0'
            y2='14.373'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='filter_filled1_linear'
            x1='0.5'
            y1='0.873047'
            x2='0.5'
            y2='13.873'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
