import React, { useRef, useEffect } from 'react'
import moment from 'moment'
import cx from 'classnames'
import { Button } from 'antd'
import {
  CheckOutlined,
  DownloadOutlined,
  InfoOutlined,
} from '@ant-design/icons'

import './index.scss'
import EmptyBox from 'assets/images/empty-box.svg'

import {
  RecordStart,
  RecordStop,
  WarningFilled,
  WifiOffline,
  WifiOnline,
} from 'components/Icons'
import { Layout, Spinner } from 'components/Shared'
import { downloadFile } from 'utils/functions'
import { markAllNotifAsRead, SystemNoti } from 'services/notifications'
import {
  updateCurrentPage,
  getSystemNotis,
  markAllAsRead,
} from 'store/slice/notificationSlice'
import { useAppSelector, useAppDispatch } from 'store'

function SystemNotifications() {
  const dispatch = useAppDispatch()
  const { newNotifications, notifications, currentPage, totalPage, loading } =
    useAppSelector(state => state.notification)

  const obElRef = useRef<HTMLDivElement>(null)
  const currentPageRef = useRef(currentPage)

  useEffect(() => {
    if (!obElRef.current) return
    const observer = new IntersectionObserver(handleLoadMore)
    observer.observe(obElRef.current!)
    return () => observer.disconnect()
    // eslint-disable-next-line
  }, [])

  const handleLoadMore: IntersectionObserverCallback = event => {
    const { isIntersecting } = event[0]
    if (isIntersecting && currentPageRef.current < totalPage && !loading) {
      const newPage = currentPageRef.current + 1
      currentPageRef.current = newPage
      dispatch(getSystemNotis(newPage))
      dispatch(updateCurrentPage(newPage))
    }
  }

  const renderNotifIcon = (message: string, level: string) => {
    let Icon: any = null
    if (message.endsWith('started')) {
      Icon = RecordStart
    }
    if (message.endsWith('stopped')) {
      Icon = RecordStop
    }
    if (message.endsWith('online')) {
      Icon = WifiOnline
    }
    if (message.endsWith('offline')) {
      Icon = WifiOffline
    }
    if (message.endsWith('ready for download')) {
      Icon = DownloadOutlined
    }

    if (!Icon) {
      if (level === 'success') {
        Icon = CheckOutlined
      }
      if (level === 'info') {
        Icon = InfoOutlined
      }
      if (level === 'warning') {
        Icon = WarningFilled
      }
    }

    return <Icon type={level} className={`text-${level}`} />
  }

  const renderNotif = (notifList: SystemNoti[]) => {
    return notifList.map(({ data, created_at, read_at }, idx) => (
      <div
        key={idx}
        className='notif-item'
        style={{
          marginBottom: idx + 1 < notifList.length ? '1.1rem' : 0,
        }}
      >
        <div className={cx('notif-icon', data.level)}>
          {renderNotifIcon(data.message, data.level)}
        </div>
        <div className={cx('notif-content', !read_at && 'unread')}>
          <div className='message'>{data.message}</div>
          <div className='datetime'>{moment(created_at).fromNow()}</div>
          {!!data.url && (
            <Button type='primary' onClick={() => downloadFile(data.url!)}>
              Download
            </Button>
          )}
        </div>
        <div className={cx('red-dot', !read_at && 'unread')}>
          <div />
        </div>
      </div>
    ))
  }

  const handleMarkAllAsRead = () => {
    markAllNotifAsRead()
    dispatch(markAllAsRead())
  }

  const hasNoti = !!newNotifications.length || !!notifications.length

  return (
    <Layout className='system-notifications'>
      <Layout.Header
        goBack
        bordered
        actionRight={
          hasNoti && (
            <span
              className='mark-all-as-read text-primary text-link'
              onClick={handleMarkAllAsRead}
            >
              Done
            </span>
          )
        }
      >
        System Notification
      </Layout.Header>
      <Layout.Body>
        {hasNoti && (
          <React.Fragment>
            {!!newNotifications.length && (
              <div className='notif-list' style={{ marginBottom: '2rem' }}>
                <h4>New</h4>
                {renderNotif(newNotifications)}
              </div>
            )}
            <div className='notif-list'>
              <h4>Earlier</h4>
              {renderNotif(notifications)}
            </div>
            {loading && <Spinner size='small' style={{ marginTop: 8 }} />}
            <div ref={obElRef} />
          </React.Fragment>
        )}
        {!hasNoti && (
          <div className='no-notif'>
            <img src={EmptyBox} alt='empty' />
            <span className='text-placeholder'>
              There are no notification at the moment
            </span>
          </div>
        )}
      </Layout.Body>
    </Layout>
  )
}

export default SystemNotifications
