import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Button, Switch, Badge } from 'antd'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'

import Refresh from 'assets/images/refresh.svg'
import { InfoCircle } from 'components/Icons'
import Pagination from 'components/Pagination'
import ModalSearch from 'components/Modal/ModalSearch'
import ModalFilterDevices from 'components/Modal/QuickFilters/FilterDevices'
import ModalInfo from 'components/Modal/ModalInfo'

import { useDeviceContext } from './DeviceListContext'

function TableListHeader() {
  const {
    loading,
    search,
    liveUpdate,
    totalPage,
    currentPage,
    deviceType,
    locationId,
    dispatch,
  } = useDeviceContext()

  const [showSearch, setShowSearch] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [showInfo, setShowInfo] = useState(false)

  const handleLiveUpdateChage = (checked: boolean) => {
    dispatch({
      type: 'SET_LIVE_UPDATE',
      payload: checked,
    })
  }

  const handlePageChange = (newPage: number) => {
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: newPage,
    })
  }

  const handleSearch = (value: string) => {
    if (value !== search) {
      dispatch({ type: 'SET_SEARCH', payload: value })
    }
  }

  const handleFilter = (filters: {
    deviceType?: string
    locationId?: number
  }) => {
    dispatch({
      type: 'SET_FILTER',
      payload: filters,
    })
  }

  const renderLiveUpdateInfoContent = () => (
    <React.Fragment>
      <div>
        When Live update is{' '}
        <span className={cx('text-bold', { 'text-success': liveUpdate })}>
          {liveUpdate ? 'ON' : 'OFF'}
        </span>{' '}
        the system will{' '}
        <span className='text-bold'>
          {!liveUpdate && 'NOT'} automatically update
        </span>{' '}
        with the latest data every 5 mins
      </div>
      <div style={{ marginTop: '1rem' }}>
        In case you find it inconvenient, you can{' '}
        <span
          className='text-link text-primary text-underline text-bold'
          onClick={() => {
            handleLiveUpdateChage(!liveUpdate)
            setShowInfo(false)
          }}
        >
          Turn it {liveUpdate ? 'off' : 'on'}
        </span>
      </div>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <div className='table-list-header sticky'>
        <div className='left'>
          <Pagination
            disabled={loading}
            current={currentPage}
            total={totalPage}
            onPageChange={handlePageChange}
          />
        </div>
        <div className='right'>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowFilter(true)}
          >
            <Badge dot={!!deviceType || !!locationId}>
              <FilterOutlined />
            </Badge>
          </Button>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowSearch(true)}
          >
            <Badge dot={!!search}>
              <SearchOutlined />
            </Badge>
          </Button>
        </div>
      </div>
      <div className='live-updater'>
        <Switch
          disabled={loading}
          checked={liveUpdate}
          onChange={handleLiveUpdateChage}
        />
        <span className='text-500' style={{ marginRight: 4 }}>
          Live update
        </span>
        (
        <span
          className={cx(liveUpdate && 'text-success')}
          style={{ marginRight: 4 }}
        >
          {liveUpdate ? 'ON' : 'OFF'}
        </span>
        <InfoCircle
          className='hoverable'
          color='#808080'
          onClick={() => setShowInfo(true)}
        />
        )
      </div>
      <AnimatePresence>
        {showSearch && (
          <ModalSearch
            title='Search Devices'
            placeholder='Search devices'
            value={search}
            onSearch={handleSearch}
            onCancel={() => setShowSearch(false)}
          />
        )}
        {showFilter && (
          <ModalFilterDevices
            deviceType={deviceType}
            locationId={locationId}
            onChange={handleFilter}
            onCancel={() => setShowFilter(false)}
          />
        )}
        {showInfo && (
          <ModalInfo
            img={<img src={Refresh} alt='live-update' />}
            title='Live Update'
            content={renderLiveUpdateInfoContent()}
            onCancel={() => setShowInfo(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableListHeader
