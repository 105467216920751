import axios from './axios'

import { Device } from 'services/devices'

export interface Tag {
  id: number
  account_id: number
  name: string
  is_active: number
  search_in: string
  subject_keywords: string
  body_keywords: string
  color: string | null
  stroke_color: string | null
  text_color: string | null
  created_at: string
  updated_at: string
  is_blocked: number
  channels?: string[] | null
  apply_to_all: number
  has_attachments: number
  devices?: Device[] | null
  email_recipients?: any[] | null
  phone_recipients?: any[] | null
  whatsapp_recipients?: any[] | null
  slack_webhook?: string | null
  teams_webbhook?: string | null
  webhook?: string | null
}

export interface TagSummary {
  color: string
  id: number
  name: string
  notifications_count: number
  stroke_color: string
  text_color: string
}

export const getTags = async ({
  pageSize = 10,
  sort = 'id',
  sortDirection = 'desc',
  search = '',
  currentPage = 1,
}: {
  pageSize: number
  sort?: string
  sortDirection?: string
  search?: string
  currentPage: number
}) => {
  return axios.get(
    `/api/tags?filters[search]=${search}&order_by=${sort}&sort=${sortDirection}&page=${currentPage}&limit=${pageSize}`,
  )
}

export const getTagDetails = (tagId: number) => {
  return axios.get(
    `api/tags/${tagId}?include=devices,emailRecipients,phoneRecipients,whatsappRecipients`,
  )
}

export const createTag = async (tag: {
  name: string
  is_active: boolean
  is_blocked: boolean
  has_attachments: boolean
  apply_to_all: boolean
  search_in: string
  subject_keywords?: string
  body_keywords?: string
  account_id: number
  channels: string[]
  webhook?: string
  slack_webhook?: string
  teams_webbhook?: string
  email_recipient_ids?: number[]
  phone_recipient_ids?: number[]
  whatsapp_recipient_ids?: number[]
  device_ids: number[]
  stroke_color: string
  text_color: string
  color: string
}) => {
  await axios.get('/api/csrf-cookie')
  return axios.post('/api/tags', tag)
}

export const updateTag = async (
  tagId: number,
  tag: {
    name?: string
    is_active?: boolean
    is_blocked?: boolean
    has_attachments?: boolean
    apply_to_all?: boolean
    search_in?: string
    subject_keywords?: string
    body_keywords?: string
    account_id?: number
    channels?: string[]
    webhook?: string
    slack_webhook?: string
    teams_webbhook?: string
    email_recipient_ids?: number[]
    phone_recipient_ids?: number[]
    whatsapp_recipient_ids?: number[]
    device_ids?: number[]
    stroke_color?: string
    text_color?: string
    color?: string
  },
) => {
  await axios.get('/api/csrf-cookie')
  return axios.put(`/api/tags/${tagId}`, tag)
}

export const deleteTag = (tagId: number) => {
  return axios.delete(`/api/tags/${tagId}`)
}

export const getTagSummary = () => {
  return axios.get('/api/cards/tags')
}
