import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as config from 'services/config'

export const getAppConfig: any = createAsyncThunk(
  'auth/getAppConfig',
  async () => {
    const responses = await config.getConfig()
    return responses
  },
)

interface State {
  loading: boolean
  error: boolean
  config: config.Config | null
}

const initialState: State = {
  loading: false,
  error: false,
  config: null,
}

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: {
    [getAppConfig.pending.type]: state => {
      state.loading = true
      state.error = false
    },
    [getAppConfig.rejected.type]: state => {
      state.loading = false
      state.error = true
    },
    [getAppConfig.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.config = payload.data.data
    },
  },
})

export default configSlice.reducer
