import { LoadingOutlined } from '@ant-design/icons'
import { Spin, SpinProps } from 'antd'
import cx from 'classnames'
import React from 'react'

interface Props extends SpinProps {
  height?: string | number
  style?: React.CSSProperties
}

export function Spinner({ height, size = 'default', className, style }: Props) {
  return (
    <Spin
      size={size}
      style={{ height, ...style }}
      className={cx('spinner', size, className)}
      indicator={
        <LoadingOutlined
          style={{
            fontSize:
              size === 'small' ? '1rem' : size === 'default' ? '2rem' : '3rem',
          }}
          spin
        />
      }
    />
  )
}
