import { useState } from 'react'
import { Input, Button, Checkbox, Form } from 'antd'

import { verify2FACode } from 'services/2fa'

interface Props {
  onSuccess: () => void
  onFail: () => void
}

function Verify2FACode({ onSuccess, onFail }: Props) {
  const [useCode, setUseCode] = useState(true)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (value: { code: string | undefined }) => {
    const { code } = value
    if (!code) {
      return
    }
    try {
      setLoading(true)
      await verify2FACode({ [useCode ? 'code' : 'recovery_code']: code })
      setLoading(false)
      onSuccess()
    } catch (e) {
      setLoading(false)
      onFail()
    }
  }

  return (
    <div className='two-fa-verification'>
      <h3>Enter login code to continue</h3>
      <p>
        You’ve asked us to require a 6-digit login code when anyone tries to
        access your account from a new device or browser
      </p>
      <Form onFinish={handleSubmit} layout='vertical' requiredMark={false}>
        <Form.Item name='code'>
          <Input
            size='large'
            placeholder={useCode ? 'Enter 6-digit code' : 'Enter recovery code'}
          />
        </Form.Item>
        <Checkbox checked={!useCode} onChange={() => setUseCode(prev => !prev)}>
          Use recovery code
        </Checkbox>
        <Button
          disabled={loading}
          htmlType='submit'
          type='primary'
          block
          size='large'
        >
          Verify
        </Button>
      </Form>
    </div>
  )
}

export default Verify2FACode
