import React, { useEffect, useRef, useReducer } from 'react'
import Hls, { ErrorData, Events } from 'hls.js'
import cx from 'classnames'
import moment from 'moment'
import { DoubleRightOutlined } from '@ant-design/icons'

import './index.scss'
import {
  PlayFilled,
  PauseFilled,
  ScreenshotFilled,
  SpeakerLouderFilled,
  SpeakerMutedFilled,
  Fullscreen,
  ExitFullscreen,
  Live,
} from 'components/Icons'

import { useFullscreen } from 'hooks'
import { DeviceInfo, Spinner } from 'components/Shared'
import ModalPlayer from 'components/Modal/ModalPlayer'
import { Device, getDeviceDetails } from 'services/devices'
import { videoCapture } from 'utils/functions'

interface Props {
  deviceId: number
  onCancel: () => void
}

interface State {
  slideUp: boolean
  loading: boolean
  error: string
  device: Device | null
  muted: boolean
  showControls: boolean
  videoLoading: boolean
  playing: boolean
  currentTime: number
  retryCount: number
}

const initState: State = {
  slideUp: true,
  loading: true,
  error: '',
  device: null,
  muted: true,
  showControls: true,
  videoLoading: false,
  playing: false,
  currentTime: 0,
  retryCount: 0,
}

function ModalLivestream({ deviceId, onCancel }: Props) {
  const { isFullscreen, toggleFullscreen } = useFullscreen()
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  const livestreamRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const hlsRef = useRef<Hls | null>(null)

  const { loading, error, device, ...s } = state
  const tz = device?.timezone || 'America/New_York'
  const url = device?.deviceLiveStream?.playlist_url

  useEffect(() => {
    handleGetDeviceInfo()
    // eslint-disable-next-line
  }, [deviceId])

  useEffect(() => {
    if (!url) return
    setTimeout(init, 0)
    return () => cleanUp()
    // eslint-disable-next-line
  }, [url])

  const handleGetDeviceInfo = async () => {
    try {
      setState({ loading: true })
      const response = await getDeviceDetails(deviceId)
      const data: Device = response.data.data

      if (!data.deviceLiveStream || !data.deviceLiveStream.playlist_url) {
        return setState({
          error: 'This device does not support live streaming.',
        })
      }
      if (data.deviceLiveStream.meta?.codec === 'H265') {
        return setState({
          error: 'H265 streaming is not supported by browsers.',
        })
      }
      setState({ device: data, error: '' })
    } catch (err: any) {
      setState({
        error:
          err?.status === 404
            ? `Device with id: ${deviceId} does not exist.`
            : 'Something went wrong, please try again.',
      })
    } finally {
      setState({ loading: false })
    }
  }

  const init = () => {
    const videoEl = videoRef.current
    if (!videoEl || !url) return

    setState({ videoLoading: true })
    if (!Hls.isSupported()) {
      videoEl.src = url
    } else {
      const newHls = new Hls()
      newHls.loadSource(url)
      newHls.attachMedia(videoEl)
      newHls.on(Events.ERROR, handleRetry)
      hlsRef.current = newHls
    }
  }

  const cleanUp = () => {
    hlsRef.current?.stopLoad()
    hlsRef.current?.detachMedia()
    hlsRef.current?.destroy()
    hlsRef.current = null
  }

  const handleRetry = (_: any, errData: ErrorData) => {
    if (!errData.fatal) return
    cleanUp()

    const newRetryCount = s.retryCount + 1
    if (newRetryCount > 3) {
      return setState({
        loading: false,
        videoLoading: false,
        error: 'Cannot load this livestream',
      })
    }
    setState({
      ...initState,
      slideUp: false,
      retryCount: newRetryCount,
      error: 'Reconnecting...',
    })
    handleGetDeviceInfo()
  }

  const handleReady = () => {
    videoRef.current!.play().catch(console.error)
    setState({ videoLoading: false })
  }

  const handleTimeUpdate = () => {
    setState({ currentTime: videoRef.current?.currentTime || 0 })
  }

  const handlePlay = () => {
    const videoEl = videoRef.current!
    videoEl.currentTime = videoEl.duration
    videoEl.play()
    setState({ playing: true })
  }

  const handlePause = () => {
    videoRef.current!.pause()
    setState({ playing: false })
  }

  return (
    <ModalPlayer
      className='modal-livestream'
      onAnimationEnd={() => setState({ slideUp: false })}
      onCancel={onCancel}
    >
      <div
        ref={livestreamRef}
        className={cx('player-wrapper', { show: !s.slideUp })}
      >
        <div className='device-info'>
          {device ? <DeviceInfo device={device} /> : <div />}
          <Live type='danger' />
        </div>

        {(loading || s.videoLoading) && <Spinner />}
        {!!error && <div className='player-message'>{error}</div>}

        <video
          ref={videoRef}
          muted={s.muted}
          onLoadedMetadata={handleReady}
          onTimeUpdate={handleTimeUpdate}
          onPlaying={() => setState({ playing: true })}
          onPause={() => setState({ playing: false })}
          onCanPlay={() => setState({ videoLoading: false })}
          onWaiting={() => setState({ videoLoading: true })}
        />

        {!loading && !error && device && (
          <React.Fragment>
            <div className='current-datetime'>
              <span className='date' style={{ marginRight: 4 }}>
                {moment().tz(tz).format('MM/DD/YYYY')}
              </span>
              <span className='time'>{moment().tz(tz).format('HH:mm:ss')}</span>
            </div>
            <div className='controls-wrapper'>
              <div className={cx('controls', { hide: !s.showControls })}>
                <div onClick={s.playing ? handlePause : handlePlay}>
                  {s.playing ? (
                    <PauseFilled color='#ffffff' />
                  ) : (
                    <PlayFilled color='#ffffff' />
                  )}
                </div>
                <div onClick={() => videoCapture(videoRef.current!)}>
                  <ScreenshotFilled color='#ffffff' />
                </div>
                <div onClick={() => setState({ muted: !s.muted })}>
                  {s.muted ? (
                    <SpeakerMutedFilled color='#ffffff' />
                  ) : (
                    <SpeakerLouderFilled color='#ffffff' />
                  )}
                </div>
                <div className='divider' />
                <div onClick={() => toggleFullscreen(livestreamRef.current!)}>
                  {isFullscreen ? (
                    <ExitFullscreen color='#ffffff' />
                  ) : (
                    <Fullscreen color='#ffffff' />
                  )}
                </div>
                <div className='divider' />
                <div
                  onClick={() => setState({ showControls: !s.showControls })}
                >
                  <DoubleRightOutlined rotate={90} />
                </div>
              </div>
              <div className={cx('show-controls', { show: !s.showControls })}>
                <div
                  onClick={() => setState({ showControls: !s.showControls })}
                >
                  <DoubleRightOutlined rotate={-90} />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </ModalPlayer>
  )
}

export default ModalLivestream
