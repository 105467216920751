import { useState } from 'react'
import { Form, Input, Button } from 'antd'

import './index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Avatar, Layout } from 'components/Shared'
import { Recipient, updateRecipientProfile } from 'services/recipients'
import { notiError, notiSuccess } from 'utils/notification'

const rules = [{ required: true, message: 'This field is required' }]

interface FormValues {
  name: string
  phone: string
  email: string
}

interface Props {
  recipient: Recipient
  onSuccess?: () => void
  onCancel: () => void
}

function ModalUpdateRecipient({ recipient, onSuccess, onCancel }: Props) {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true)
      await updateRecipientProfile(recipient.id, {
        ...values,
        phone: values.phone || undefined,
        account_id: recipient!.account_id,
      })
      setLoading(false)
      notiSuccess({ message: 'Recipient updated' })
      onCancel()
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      onCancel={onCancel}
      className='modal-update-recipient'
    >
      <Layout className='user-profile'>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          {recipient.name}
        </Layout.Header>
        <div className='profile-cover' />
        <Layout.Body>
          <Avatar fontSize='2rem' username={recipient.name} />
          <Form
            layout='vertical'
            onFinish={handleSubmit}
            initialValues={{
              name: recipient.name,
              phone: recipient.phone,
              email: recipient.email,
            }}
          >
            <Form.Item name='name' label='Name' rules={rules}>
              <Input size='large' placeholder='Enter your name' />
            </Form.Item>
            <Form.Item
              name='email'
              label='Email'
              rules={[
                ...rules,
                { type: 'email', message: 'Invalid email address' },
              ]}
            >
              <Input size='large' placeholder='Enter your email' />
            </Form.Item>
            <Form.Item name='phone' label='Phone Number' rules={rules}>
              <Input size='large' placeholder='Enter your phone number' />
            </Form.Item>
            <Button
              disabled={loading}
              htmlType='submit'
              size='large'
              block
              type='primary'
            >
              Save
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalUpdateRecipient
