import './index.scss'
import { Layout } from 'components/Shared'
import { Location } from 'services/locations'
import BaseModal from '../BaseModal'

interface Props {
  location: Location
  onCancel: () => void
}

function ModalLocationDetail({ location, onCancel }: Props) {
  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-location-detail'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header sticky bordered goBack onGoBack={onCancel}>
          Location Detail
        </Layout.Header>
        <Layout.Body>
          <div>
            <h4>Location Name</h4>
            <span>{location.name}</span>
          </div>
          <hr className='divider' />
          <div>
            <h4>ZIP Code</h4>
            <span>{location.zipcode || '---'}</span>
          </div>
          <hr className='divider' />
          <div>
            <h4>No. of devices in this location</h4>
            <span>{location.devices_count}</span>
          </div>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalLocationDetail
