import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

import { Spinner, Tag } from 'components/Shared'
import ModalAddNotifTag from 'components/Modal/NotificationTag/CreateTag'
import { notiError } from 'utils/notification'
import { numberWithCommas } from 'utils/functions'
import { getTagSummary, TagSummary } from 'services/tags'

function NotifTagSummary() {
  const [loading, setLoading] = useState(true)
  const [sort, setSort] = useState('desc')
  const [tags, setTags] = useState<TagSummary[]>([])
  const [showAddTag, setShowAddTag] = useState(false)

  useEffect(() => {
    handleGetTagSummary()
    // eslint-disable-next-line
  }, [])

  const handleGetTagSummary = async () => {
    try {
      setLoading(true)
      const response = await getTagSummary()
      const data = response.data.data as TagSummary[]
      setTags(data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const renderTagSummary = () => {
    return tags
      .slice(0, 8)
      .sort((a, b) =>
        sort === 'desc'
          ? b.notifications_count - a.notifications_count
          : a.notifications_count - b.notifications_count,
      )
      .map(tag => (
        <div className='tag-summary-item' key={tag.id}>
          <span className='notif-count text-500'>
            {numberWithCommas(tag.notifications_count)}
          </span>
          <Tag
            color={tag.color}
            strokeColor={tag.stroke_color}
            textColor={tag.text_color}
          >
            {tag.name}
          </Tag>
        </div>
      ))
  }

  return (
    <React.Fragment>
      <div className='notif-tag-summary'>
        <h3>Device Notification By Tags</h3>
        <span
          className='text-primary hoverable text-500'
          onClick={() => setSort(prev => (prev === 'desc' ? 'asc' : 'desc'))}
        >
          Sort: {sort === 'desc' ? 'High to Low' : 'Low to High'}
        </span>
        <div className='summary-actions'>
          <Link
            to='/notifications?tab=notification-tag'
            className='ant-btn ant-btn-lg ant-btn-secondary lighter'
          >
            Manage Tag
          </Link>
          <Button
            size='large'
            className='ant-btn-secondary lighter'
            onClick={() => setShowAddTag(true)}
          >
            <PlusOutlined /> Add Tag
          </Button>
        </div>
        <div className='tag-summary'>
          {loading && <Spinner />}
          {!loading && !!tags.length && renderTagSummary()}
          {!loading && !tags.length && (
            <div className='text-placeholder text-center'>No tag to show</div>
          )}
        </div>
      </div>
      <AnimatePresence>
        {showAddTag && (
          <ModalAddNotifTag
            onSuccess={handleGetTagSummary}
            onCancel={() => setShowAddTag(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default NotifTagSummary
