import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Dashboard({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='22'
        height='18'
        viewBox='0 0 22 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.859375 0H21.1406C21.6153 0 22 0.390209 22 0.871671V17.0847C22 17.5662 21.6153 17.9564 21.1406 17.9564H0.859375C0.384705 17.9564 0 17.5662 0 17.0847V0.871671C0 0.390209 0.384705 0 0.859375 0ZM13.5207 1.74334H8.47911V6.05862H13.5207V1.74334ZM1.71875 1.74334H6.76036V6.05862H1.71875V1.74334ZM1.71875 16.2131H20.2812V7.80196H1.71875V16.2131ZM20.2812 6.05862H15.2396V1.74334H20.2812V6.05862ZM15.0945 8.97003L11.9012 12.209L10.1947 10.4779C9.85931 10.1378 9.31482 10.1376 8.97929 10.4779L5.69017 13.8141C5.35464 14.1544 5.35464 14.7064 5.69017 15.0469C6.02586 15.3872 6.56985 15.3872 6.90555 15.0469L9.5869 12.3272L11.2934 14.0581C11.6289 14.3982 12.1732 14.3984 12.5088 14.0581L16.3098 10.2028C16.6454 9.86231 16.6454 9.31053 16.3098 8.97003C15.9741 8.62971 15.43 8.62971 15.0945 8.97003Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
