import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as plan from 'services/subscription'

export const getPlans: any = createAsyncThunk('auth/getPlans', async () => {
  const responses = await plan.getPlans()
  return responses
})

interface State {
  loading: boolean
  error: boolean
  plans: plan.Plan[]
}

const initialState: State = {
  loading: false,
  error: false,
  plans: [],
}

const plansSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: {
    [getPlans.pending.type]: state => {
      state.loading = true
      state.error = false
    },
    [getPlans.rejected.type]: state => {
      state.loading = false
      state.error = true
    },
    [getPlans.fulfilled.type]: (state, { payload }) => {
      state.plans = payload.data.data
      state.loading = false
    },
  },
})

export default plansSlice.reducer
