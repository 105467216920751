import { Tabs } from 'antd'

import './index.scss'
import { Layout } from 'components/Shared'

import DeviceNotification from './DeviceNotification'
import NotifTagSummary from './NotifTagSummary'
import ResourceSummary from './ResourceSummary'
import LastestDeviceNotifs from './LastestDeviceNotifs'

function Dashboard() {
  return (
    <Layout className='dashboard-page'>
      <Layout.Header>Dashboard</Layout.Header>
      <Layout.Body>
        <Tabs animated={{ inkBar: true, tabPane: true }}>
          <Tabs.TabPane tab='Device Notification' key='1'>
            <DeviceNotification />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Device Notification By Tags' key='2'>
            <NotifTagSummary />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Resource Summary' key='3'>
            <ResourceSummary />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Latest Device Notifications' key='4'>
            <LastestDeviceNotifs />
          </Tabs.TabPane>
        </Tabs>
      </Layout.Body>
    </Layout>
  )
}

export default Dashboard
