import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import cx from 'classnames'
import { Button, Tooltip } from 'antd'

import Note from 'assets/images/note.svg'
import Table from 'components/Table'
import ModalViewInboundNotifDetail from 'components/Modal/InboundNotification/InboundDetail'
import ModalAddNote from 'components/Modal/InboundNotification/AddNote'
import { notiError } from 'utils/notification'
import { getInboundNotis, InboundNoti } from 'services/notifications'
import { Tag } from 'services/tags'

import { useNotifContext } from './NotifContext'
import { DeviceInfo } from 'components/Shared'

function TableList() {
  const {
    loading,
    notifications,
    currentPage,
    search,
    tags,
    device,
    dateRange,
    dispatch,
  } = useNotifContext()

  const [viewNotifId, setViewNotifId] = useState<number | null>(null)
  const [addNote, setAddNote] = useState<number | null>(null)

  useEffect(() => {
    handleGetInboundNotifications()
    // eslint-disable-next-line
  }, [search, currentPage, tags, device, dateRange])

  const handleGetInboundNotifications = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const response = await getInboundNotis({
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        search,
        currentPage,
        tagIds: (tags || []).map((tag: Tag) => tag.id).join(','),
        deviceId: device?.value || '',
        dateRange: dateRange ? `${dateRange[0]},${dateRange[1]}` : '',
      })
      dispatch({ type: 'SET_NOTIFICATIONS', payload: response.data })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = (notif: InboundNoti) => (
    <React.Fragment>
      <Button
        className='ant-btn-ternary'
        onClick={() => setViewNotifId(notif.id)}
      >
        Preview
      </Button>
      <Button className='ant-btn-ternary' onClick={() => setAddNote(notif.id)}>
        Add Note
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        loading={loading}
        empty={!notifications.length}
        className='table-notification'
      >
        {notifications.map((notif: InboundNoti) => (
          <Table.Row
            key={notif.id}
            rowKey={notif.id}
            rowActions={renderRowActions(notif)}
          >
            <Table.Col>
              <div className='col-detail'>
                <div
                  className={cx('notif-tag', {
                    'no-tag': !notif.tags?.length,
                  })}
                >
                  {(notif.tags || []).map(
                    ({ id, stroke_color, color }, idx) => {
                      const tagLength = (notif.tags || []).length
                      return (
                        <div
                          key={id}
                          style={{
                            background: stroke_color!,
                            borderRadius:
                              tagLength === 1
                                ? 8
                                : idx === 0
                                ? '8px 8px 0px 0px'
                                : idx + 1 === tagLength
                                ? '0px 0px 8px 8px'
                                : 0,
                          }}
                        >
                          <div
                            style={{
                              padding: 0,
                              background: color!,
                              borderRadius:
                                tagLength === 1
                                  ? 8
                                  : idx === 0
                                  ? '8px 8px 0px 0px'
                                  : idx + 1 === tagLength
                                  ? '0px 0px 8px 8px'
                                  : 0,
                            }}
                          />
                        </div>
                      )
                    },
                  )}
                </div>
                <div className='notif-detail'>
                  <div className='text-500' style={{ marginBottom: 8 }}>
                    <span>{notif.subject}</span>
                    {!!notif.notes.length && (
                      <Tooltip
                        title={`${notif.notes.length} note${
                          notif.notes.length > 1 ? 's' : ''
                        } added`}
                      >
                        <img style={{ marginLeft: 8 }} src={Note} alt='note' />
                      </Tooltip>
                    )}
                  </div>
                  <div className='device-info'>
                    <span>Device</span>
                    {notif.device ? (
                      <DeviceInfo
                        status='default'
                        device={notif.device}
                        to={`/devices/${notif.device_id}?tab=device-details`}
                      />
                    ) : (
                      '---'
                    )}
                  </div>
                  <div className='recipient-count'>
                    <span>No. of Recipient</span>
                    <span>{notif.recipients_count}</span>
                  </div>
                  <div className='timestamp'>
                    <span>Timestamp</span>
                    <span>
                      {moment(notif.created_at).format(
                        'MMM DD, YYYY hh:mm:ss A',
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {viewNotifId && (
          <ModalViewInboundNotifDetail
            notifId={viewNotifId}
            onCancel={() => setViewNotifId(null)}
          />
        )}
        {addNote && (
          <ModalAddNote
            notifId={addNote}
            onSuccess={handleGetInboundNotifications}
            onCancel={() => setAddNote(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
