import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import moment from 'moment'
import { Button, Switch } from 'antd'
import { DeleteFilled } from '@ant-design/icons'

import Table from 'components/Table'
import ModalViewSchedule from 'components/Modal/Schedule/ViewSchedule'
import ModalCreateUpdateSchedule from 'components/Modal/Schedule/CreateUpdateSchedule'
import ModalConfirm from 'components/Modal/ModalConfirm'
import { notiError } from 'utils/notification'
import {
  Schedule,
  getDeviceSchedules,
  updateSchedule,
  deleteSchedule,
} from 'services/schedules'

import { useScheduleContext } from './ScheduleContext'

interface Props {
  showCreateSchedule: boolean
  setShowCreateSchedule: (show: boolean) => void
  deviceId: number
}

function TableList({ deviceId, ...props }: Props) {
  const { loading, schedules, currentPage, search, dispatch } =
    useScheduleContext()

  const [viewScheduleId, setViewScheduleId] = useState<number | null>(null)
  const [updateScheduleId, setUpdateScheduleId] = useState<number | null>(null)
  const [deleteScheduleId, setDeleteScheduleId] = useState<number | null>(null)

  useEffect(() => {
    handleGetDeviveSchedules()
    // eslint-disable-next-line
  }, [currentPage, search])

  const handleGetDeviveSchedules = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      const response = await getDeviceSchedules({
        deviceId,
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        search,
        currentPage,
      })
      dispatch({ type: 'SET_SCHEDULES', payload: response.data })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleActiveStatusChange = (id: number) => {
    return async (checked: boolean) => {
      dispatch({ type: 'SET_LOADING', payload: true })
      try {
        await updateSchedule(id, { is_active: checked })
        handleGetDeviveSchedules()
      } catch (err: any) {
        const { message, errors } = err
        notiError({ message, description: errors })
        dispatch({ type: 'SET_LOADING', payload: false })
      }
    }
  }

  const handleDeleteSchedule = async () => {
    if (!deleteScheduleId) return
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      await deleteSchedule(deleteScheduleId)
      dispatch({ type: 'SET_SELECTED_ID', payload: [] })
      if (schedules.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetDeviveSchedules()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const formatTime = (timeStr: string) => {
    const [hour, minute, second] = timeStr.split(':')
    return moment(new Date(0, 0, 0, +hour, +minute, +second)).format('hh:mm A')
  }

  const renderScheduleTime = (s: Schedule) => {
    const { is_24_7, start_time, end_time, days } = s
    if (is_24_7) return 'All the time (24/7)'
    const d = (days || []).map(
      (d: string) => d.substring(0, 1) + d.substring(1, 3).toLocaleLowerCase(),
    )
    return (
      <React.Fragment>
        {formatTime(start_time!)} - {formatTime(end_time!)} <br />
        Every {d.join(', ')}
      </React.Fragment>
    )
  }

  const renderRowActions = (s: Schedule) => (
    <React.Fragment>
      <Button
        className='ant-btn-ternary'
        onClick={() => setViewScheduleId(s.id)}
      >
        View
      </Button>
      <Button
        className='ant-btn-ternary'
        onClick={() => setUpdateScheduleId(s.id)}
      >
        Edit
      </Button>
      <Button
        className='ant-btn-ternary text-danger'
        onClick={() => setDeleteScheduleId(s.id)}
      >
        Delete
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        loading={loading}
        empty={!schedules.length}
        className='table-schedule'
      >
        {schedules.map((s: Schedule) => (
          <Table.Row key={s.id} rowKey={s.id} rowActions={renderRowActions(s)}>
            <Table.Col>
              <div className='schedule-name text-500'>{s.name}</div>
              <div className='schedule-time'>
                <span>Schedule Time</span>
                <span>{renderScheduleTime(s)}</span>
              </div>
              <div className='recipient-count'>
                <span>No. of Recipient</span>
                <span>{s.recipients_count}</span>
              </div>
              <div className='status' style={{ margin: 0 }}>
                <span>Active Status</span>
                <span
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  <Switch
                    defaultChecked={!!s.is_active}
                    onChange={handleActiveStatusChange(s.id)}
                  />
                  <span
                    className={cx('text-500', { 'text-primary': s.is_active })}
                  >
                    {s.is_active ? 'Active' : 'Inactive'}
                  </span>
                </span>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {viewScheduleId && (
          <ModalViewSchedule
            scheduleId={viewScheduleId}
            onCancel={() => setViewScheduleId(null)}
          />
        )}
        {(!!props.showCreateSchedule || !!updateScheduleId) && (
          <ModalCreateUpdateSchedule
            deviceId={deviceId}
            scheduleId={updateScheduleId || undefined}
            onSuccess={handleGetDeviveSchedules}
            onCancel={() => {
              setUpdateScheduleId(null)
              props.setShowCreateSchedule(false)
            }}
          />
        )}
        {deleteScheduleId && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this notification schedule?'
            description='Action can’t be undone'
            onConfirm={handleDeleteSchedule}
            onCancel={() => setDeleteScheduleId(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
