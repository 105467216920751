import { Link } from 'react-router-dom'

import './index.scss'
import Checked from 'assets/images/checked.svg'
import { ClockFilled, CreditCardFilled, PlanFilled } from 'components/Icons'

import { Layout } from 'components/Shared'
import StatisticsCard from 'components/Cards/StatisticsCard'
import WarningCard from 'components/Cards/WarningCard'
import { isPlanExpired } from 'utils/plan'
import { useAppSelector } from 'store'

import PlanFeatures from './PlanFeatures'

function PaymentSettings() {
  const { stats } = useAppSelector(state => state.stats)
  const { subscription: sub } = useAppSelector(state => state.subscription)

  const renderCredit = () => {
    const credit = sub?.customer?.balance
    if (!credit) {
      return '---'
    }
    return '$' + Math.abs(credit) / 100
  }

  return (
    <Layout className='payment-settings-page'>
      <Layout.Header
        goBack
        bordered
        actionRight={
          <Link to='/settings/payment-settings/plans' className='text-500'>
            Update Plan
          </Link>
        }
      >
        Payment Settings
      </Layout.Header>
      <Layout.Body>
        {isPlanExpired(sub) && (
          <WarningCard
            action={
              <Link
                className='ant-btn ant-btn-primary ant-btn-lg'
                to='/settings/payment-settings/plans'
              >
                Update Subscription Plan
              </Link>
            }
          >
            <span className='text-500'>Your plan has expired</span>
          </WarningCard>
        )}
        <StatisticsCard
          icon={<PlanFilled type='primary' />}
          title='Current Plan'
          content={sub?.subscription?.plan?.label || '---'}
        />
        <StatisticsCard
          icon={<ClockFilled type='primary' />}
          title='Renews on'
          content={sub?.upcoming_invoice?.date || '---'}
        />
        <StatisticsCard
          imgSrc={Checked}
          title='Total Cameras'
          content={stats?.camera_licenses.total || 0}
          action={{
            content: (
              <Link to='/settings/payment-settings/plans'>Add / Remove</Link>
            ),
          }}
        />
        <StatisticsCard
          style={{ border: 0 }}
          icon={<CreditCardFilled type='primary' />}
          title='Credit'
          content={renderCredit()}
        />
        <div className='divider' style={{ margin: 0 }} />
        <PlanFeatures />
      </Layout.Body>
    </Layout>
  )
}

export default PaymentSettings
