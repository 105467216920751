import LogContextProvider from './LogContext'
import TableListHeader from './TableListHeader'
import TableList from './TableList'

interface Props {
  deviceId: number
}

function ActivityLog({ deviceId }: Props) {
  return (
    <LogContextProvider>
      <TableListHeader />
      <TableList deviceId={deviceId} />
    </LogContextProvider>
  )
}

export default ActivityLog
