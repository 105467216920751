import React, { createContext, useContext, useReducer } from 'react'

import { OptionValue } from 'components/Modal/Schedule/FormHelpers'

interface State {
  loading: boolean
  videos: []
  totalPage: number
  currentPage: number
  device?: OptionValue
  from?: string
  to?: string
}

interface Action {
  type: string
  payload?: any
}

const initState: State = {
  loading: false,
  videos: [],
  totalPage: 1,
  currentPage: 1,
  device: undefined,
  from: undefined,
  to: undefined,
}

const VideoSearchContext = createContext<any>(null)

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      }
    case 'SET_VIDEOS':
      return {
        ...state,
        loading: false,
        videos: payload.data,
        totalPage: payload.meta.last_page,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        device: payload.device,
        from: payload.from,
        to: payload.to,
      }
    default:
      return state
  }
}

const VideoSearchContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  return (
    <VideoSearchContext.Provider value={{ ...state, dispatch }}>
      {children}
    </VideoSearchContext.Provider>
  )
}

const useVideoSearchContext = () => {
  return useContext(VideoSearchContext)
}

export { VideoSearchContextProvider as default, useVideoSearchContext }
