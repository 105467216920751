import {
  Route as ReactRouter,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'

import { usePageTitle } from 'hooks'
import { useAppSelector } from 'store'

import MainLayout from 'layouts/MainLayout'
import BlankLayout from 'layouts/BlankLayout/BlankLayout'
import BlankLayoutWithAuth from 'layouts/BlankLayout/BlankLayoutWithAuth'
import BlankLayoutWithoutAuth from 'layouts/BlankLayout/BlankLayoutWithoutAuth'

const Layouts = {
  MainLayout,
  BlankLayout,
  BlankLayoutWithAuth,
  BlankLayoutWithoutAuth,
}

interface Props extends RouteProps {
  title?: string
  layout?: keyof typeof Layouts
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
}

function Route({
  title,
  component: Component,
  layout = 'MainLayout',
  ...props
}: Props) {
  const { currentUser } = useAppSelector(state => state.auth)
  usePageTitle(
    props.path === '/me' && currentUser?.name
      ? `${currentUser.name} | Broadflow Streams`
      : title,
  )

  return (
    <ReactRouter
      {...props}
      render={routeProps => {
        const Layout = Layouts[layout]
        return (
          <Layout>
            <Component {...routeProps} />
          </Layout>
        )
      }}
    />
  )
}

export default Route
