import { ICONS_SOLIDS } from 'configs/iconColors'
import { Device } from 'services/devices'

export const MAX_VIDEO_LENGTH = 4 * 60
export const MAX_VIDEO_LENGTH_SEC = 4 * 60 * 60

export const playbackRates = [1, 2, 3, 4, 8, 16]

export const CHARGE_PER_CAM = 6

export const statusColors = {
  online: ICONS_SOLIDS.success,
  offline: ICONS_SOLIDS.danger,
  default: '#BDBDBD'
}

export const getDeviceStatus = <T extends Device>(device: T) => {
  if (!device.is_licensed) {
    return 'offline'
  }
  if (
    !device.deviceLiveStream ||
    !device.deviceLiveStream.playlist_url ||
    !device.deviceLiveStream.is_streaming
  ) {
    return 'offline'
  }
  return 'online'
}

export const isH265 = <T extends Device>(device: T) => {
  return device.deviceLiveStream?.meta?.codec === 'H265'
}

type Groupped<T> = { [key: string]: { locationName: string; devices: T[] } }
export const groupDevicesByLocation = <T extends Device>(
  devices: T[]
): Groupped<T> => {
  const grouped = devices.reduce<Groupped<T>>((acc, device) => {
    const key = device?.location?.name || 'Unknown'
    acc[key] = {
      locationName: key,
      devices: [...(acc[key]?.devices || []), device]
    }
    return acc
  }, {})

  const { Unknown, ...rest } = grouped
  const sorted = Object.keys(rest)
    .sort()
    .reduce<Groupped<T>>((acc, key) => {
      acc[key] = rest[key]
      return acc
    }, {})

  return !!Unknown ? { Unknown, ...sorted } : sorted
}
