import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Video({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g opacity='0.5'>
          <path
            d='M16.6881 0.75H13.406L7.78076 4.96875H11.0628L16.6881 0.75Z'
            fill={c}
          />
          <path
            d='M22.3598 0.75H19.031L13.4058 4.96875H16.6878L22.3598 0.75Z'
            fill={c}
          />
          <path
            d='M19.0308 4.96878H23.9999V1.45315C23.9999 1.39892 23.9807 1.35101 23.9691 1.30029L19.0308 4.96878Z'
            fill={c}
          />
          <path
            d='M2.10889 4.96875H5.43781L11.0631 0.75H7.781L2.10889 4.96875Z'
            fill={c}
          />
          <path
            d='M0 22.5469C0 22.9355 0.314484 23.25 0.703125 23.25H23.2969C23.6855 23.25 24 22.9355 24 22.5469V6.375H0V22.5469ZM8.48438 9.89062C8.48438 9.63727 8.62031 9.40378 8.84076 9.27881C9.06122 9.15384 9.33173 9.15727 9.54938 9.28772L16.5806 13.5065C16.7921 13.6335 16.9219 13.8622 16.9219 14.1093C16.9219 14.3565 16.7921 14.5852 16.5806 14.7122L9.54938 18.9309C9.33159 19.0613 9.06098 19.0647 8.84076 18.9398C8.62031 18.815 8.48438 18.5815 8.48438 18.3281V9.89062Z'
            fill={c}
          />
          <path
            d='M0.703125 0.75C0.314484 0.75 0 1.06448 0 1.45312V4.7933L5.43825 0.75H0.703125Z'
            fill={c}
          />
          <path
            d='M9.89062 11.1327V17.0859L14.8523 14.1093L9.89062 11.1327Z'
            fill={c}
          />
        </g>
      </svg>
    </SVGUniqueID>
  )
}
