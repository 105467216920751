import React from 'react'
import cx from 'classnames'
import { Form, Input } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

import MicrosoftTeam from 'assets/brand-logos/microsoft-team.svg'
import Slack from 'assets/brand-logos/slack.svg'
import Webhook from 'assets/brand-logos/webhook.svg'
import WhatsApp from 'assets/brand-logos/whatsapp.svg'

import { EmailFilled, SmsFilled } from 'components/Icons'
import { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { getRecipients, Recipient } from 'services/recipients'
import { Device } from 'services/devices'

export type OptionValue = { id: number; label: string; value: number }

export interface FormValues {
  name: string
  dateTime: {
    is247: boolean
    selectedDays: string[]
    startTime: string
    endTime: string
  }
  is_active: boolean
  channels: string[]
  phone_recipients: OptionValue[]
  email_recipients: OptionValue[]
  whatsapp_recipients: OptionValue[]
  webhook: string
  slack_webhook: string
  teams_webbhook: string
}

export const initValues: FormValues = {
  name: '',
  dateTime: {
    is247: false,
    selectedDays: [],
    startTime: '',
    endTime: '',
  },
  is_active: true,
  channels: [],
  phone_recipients: [],
  email_recipients: [],
  whatsapp_recipients: [],
  webhook: '',
  slack_webhook: '',
  teams_webbhook: '',
}

export const rules = [{ required: true, message: 'This field is required' }]

export const totalMsg = (sms: number, email: number, whatsapp: number) => (
  <React.Fragment>
    You have{' '}
    <span className={cx('text-bold', { 'text-danger': !sms })}>
      {email} email
    </span>
    <span className={cx('text-bold', { 'text-danger': !sms })}>
      , {sms} SMS
    </span>
    <span
      className={cx('text-bold', {
        'text-danger': !whatsapp,
      })}
    >
      {' '}
      and {whatsapp} WhatsApp
    </span>{' '}
    notifications remaining
  </React.Fragment>
)

export const mapValuesToOptions = (
  values?: Device[] | Recipient[] | null,
): OptionValue[] => {
  return (values || []).map(v => ({
    id: v.id,
    label: v.name,
    value: v.id,
  }))
}

export const getOptionsId = (options?: OptionValue[]) => {
  return (options || []).map(r => r.id || r.value)
}

export const getRecipientsByType = (config?: {
  phone?: number
  email?: number
}) => {
  return async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getRecipients({
        search,
        currentPage: page,
        hasPhone: config?.phone || 0,
        hasEmail: config?.email || 0,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }
}

const getChannelIcon = (channelName: string) => {
  let Icon: any = null
  if (channelName === 'phone_recipients') {
    Icon = <SmsFilled type='primary' />
  }
  if (channelName === 'whatsapp_recipients') {
    Icon = <img src={WhatsApp} alt='WhatsApp' />
  }
  if (channelName === 'email_recipients') {
    Icon = <EmailFilled type='primary' />
  }
  if (channelName === 'slack_webhook') {
    Icon = <img src={Slack} alt='Slack' />
  }
  if (channelName === 'webhook') {
    Icon = <img src={Webhook} alt='Webhook' />
  }
  if (channelName === 'teams_webbhook') {
    Icon = <img src={MicrosoftTeam} alt='MicrosoftTeam' />
  }
  return Icon
}

export function RecipientFormItem({ name, channel, fetcher, mapper }: any) {
  return (
    <Form.Item
      rules={rules}
      name={name}
      className='form-item-label-with-icon'
      label={
        <React.Fragment>
          {getChannelIcon(name)} {channel} Recipient
        </React.Fragment>
      }
    >
      <AsyncSelect
        labelInValue
        size='large'
        mode='multiple'
        fetcher={fetcher}
        handleSearch={fetcher}
        handleLoadMore={fetcher}
        mapOptions={mapper}
        placeholder={`Select recipients to receive notifications via ${channel}`}
        getPopupContainer={getPopupContainer}
      />
    </Form.Item>
  )
}

export function WebhhookFormItem({
  name,
  label,
  placeholder,
  extraUrl,
  extraText,
}: any) {
  return (
    <Form.Item
      name={name}
      rules={rules}
      className='form-item-label-with-icon'
      label={
        <React.Fragment>
          {getChannelIcon(name)} {label}
        </React.Fragment>
      }
      extra={
        <span>
          <InfoCircleFilled />
          <a
            className='text-link text-underline text-placeholder'
            rel='noreferrer'
            target='_blank'
            href={extraUrl}
          >
            {extraText}
          </a>
        </span>
      }
    >
      <Input size='large' placeholder={placeholder} />
    </Form.Item>
  )
}
