import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { Button, Input } from 'antd'
import React, { useState } from 'react'

import { VoucherFilled } from 'components/Icons'
import { Voucher } from 'services/subscription'
import { notiError } from 'utils/notification'

const cardOptions = {
  style: {
    base: {
      iconColor: '#303030',
      color: '#303030',
      fontSize: '16px',
      lineHeight: '30px',
    },
    invalid: {
      iconColor: '#cc2020',
      color: '#cc2020',
    },
  },
}

interface Props {
  loading: boolean
  voucher?: Voucher
  onVoucherChange?: (code: string) => void
  onSubmit: (payload?: { paymentId: string; voucher?: Voucher }) => void
}

function CheckoutWithNewCard({
  loading,
  voucher,
  onVoucherChange,
  onSubmit,
}: Props) {
  const elements = useElements()
  const stripe = useStripe()

  const [cardLoading, setCardLoading] = useState(false)
  const [isValidCard, setIsValidCard] = useState(false)
  const [voucherCode, setVoucherCode] = useState(voucher?.code || '')

  const handleValidateCard = (event: StripeCardElementChangeEvent) => {
    setIsValidCard(event.complete && !event.error)
  }

  const handleSubmit = async () => {
    if (!stripe || !elements || !isValidCard) {
      return
    }

    try {
      setCardLoading(true)
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)!,
      })

      if (!paymentMethod || !paymentMethod.id) {
        // eslint-disable-next-line
        throw 'error'
      }

      await onSubmit({ paymentId: paymentMethod.id, voucher })
      setCardLoading(false)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      setCardLoading(false)
    }
  }

  const canSubmit = !loading && isValidCard && !cardLoading

  return (
    <div className='form-new-card'>
      <div className='form-group'>
        <label>Card Information</label>
        <CardElement
          className='card-element'
          onChange={handleValidateCard}
          options={cardOptions}
        />
      </div>

      <div className='form-group'>
        <label>Name on card</label>
        <Input size='large' placeholder='Enter name on the card' />
      </div>

      {!onVoucherChange && (
        <React.Fragment>
          <h3
            className='form-title'
            style={{ marginTop: '2.5rem', marginBottom: 8 }}
          >
            <VoucherFilled type='primary' /> Voucher
          </h3>
          <div className='form-group voucher'>
            <Input
              size='large'
              placeholder='Enter your voucher (optional)'
              value={voucherCode}
              onChange={e => setVoucherCode(e.target.value)}
            />
            {!voucher && (
              <Button
                size='large'
                className='ant-btn-secondary'
                disabled={loading}
                // onClick={() => voucherCode && onVoucherChange(voucherCode)}
              >
                Apply
              </Button>
            )}
            {!!voucher && (
              <Button
                className='ant-btn-secondary text-danger'
                disabled={loading}
                onClick={() => {
                  // onVoucherChange('')
                  setVoucherCode('')
                }}
              >
                Remove Voucher
              </Button>
            )}
          </div>
        </React.Fragment>
      )}

      <Button
        type='primary'
        size='large'
        block
        className='checkout-submit'
        disabled={!canSubmit}
        onClick={canSubmit ? handleSubmit : undefined}
      >
        Confirm
      </Button>
    </div>
  )
}

export default CheckoutWithNewCard
