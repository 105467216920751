import React, { createContext, useContext, useReducer } from 'react'

interface State {
  loading: boolean
  devices: any[]
  totalPage: number
  currentPage: number
  search: string
  deviceType?: string
  locationId?: number
  liveUpdate: boolean
}

interface Action {
  type: string
  payload?: any
}

const initState: State = {
  loading: false,
  devices: [],
  totalPage: 1,
  currentPage: 1,
  search: '',
  deviceType: undefined,
  locationId: undefined,
  liveUpdate: true,
}

const DeviceContext = createContext<any>(null)

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      }
    case 'SET_DEVICES':
      return {
        ...state,
        loading: false,
        devices: payload.data,
        totalPage: payload.meta.last_page,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: payload,
        currentPage: 1,
      }
    case 'SET_LIVE_UPDATE':
      return {
        ...state,
        liveUpdate: payload,
      }
    case 'SET_FILTER':
      return {
        ...state,
        deviceType: payload.deviceType,
        locationId: payload.locationId,
        currentPage: 1,
      }
    default:
      return state
  }
}

const DeviceContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  return (
    <DeviceContext.Provider value={{ ...state, dispatch }}>
      {children}
    </DeviceContext.Provider>
  )
}

const useDeviceContext = () => {
  return useContext(DeviceContext)
}

export { DeviceContextProvider as default, useDeviceContext }
