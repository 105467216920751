import { Account as IAccount } from './accounts'
import axios from './axios'

interface Account extends IAccount {
  brand_logo_url: string | null
  access: {
    can_access_vault: boolean
    can_see_counters: boolean
    can_use_audit_trails: boolean
    can_use_brand_logo: boolean
    can_use_third_party_integrations: boolean
    can_use_user_mgmt: boolean
    is_on_free_plan: boolean
    is_on_trial: boolean
    is_subscribed: boolean
  }
}

export interface CurrentUser {
  account: Account
  account_id: number
  avatar: string | null
  avatar_url: string | null
  created_at: string
  email: string
  email_verified_at: string
  enable_promotional_notifications: number
  enable_push_notifications: boolean
  enabled_2fa: boolean
  id: number
  is_admin: number
  is_super: number
  name: string
  updated_at: string
}

export const register = (credentials: {
  name: string
  email: string
  password: string
  password_confirmation: string
  planId?: number
}) => {
  return axios.post('/api/register', credentials)
}

export const login = async (credentials: {
  email: string
  password: string
}) => {
  await axios.get('/api/csrf-cookie')
  return await axios.post('/api/login', credentials)
}

export const logout = async () => {
  await axios.get('/api/csrf-cookie')
  await axios.post('/api/logout')
}

export const forgotPassword = (email: string) => {
  return axios.post('/api/forgot-password', { email })
}

export const resetPassword = (values: {
  email: string
  password: string
  password_confirmation: string
  token: string
}) => {
  return axios.post('/api/reset-password', values)
}

export const getCurrentUser = () => {
  return axios.get('/api/users/auth?include=account')
}

export const resendVerify = () => {
  return axios.post('/api/email/verification-notification')
}

export const verifyEmail = ({ id, hash }: { id: string; hash: string }) => {
  return axios.get(`/api/email/verify/${id}/${hash}`)
}

export const uploadUserAvatar = (img: File) => {
  const formData = new FormData()
  formData.append('avatar', img, img.name)
  formData.append('_method', 'PUT')
  return axios.post('/api/users/auth', formData)
}

export const updateUsername = (name: string) => {
  return axios.put('/api/users/auth', { name })
}

export const changeUserPassword = (password: string) => {
  return axios.put('/api/users/auth', { password })
}

export const togglePromoNoti = (isEnabled: number) => {
  return axios.put('/api/users/auth', {
    enable_promotional_notifications: isEnabled,
  })
}
