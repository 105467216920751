import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { Button, DatePicker, notification } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import AsyncSelect from 'components/AsyncSelect'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import { Avatar, Layout } from 'components/Shared'
import { MM_DD_YYYY, YYYY_MM_DD } from 'utils/dateTime'
import { notiError } from 'utils/notification'
import { getUsers, User } from 'services/users'

interface Props {
  userId?: number
  dateRange?: string[]
  onChange?: (filters: { userId?: number; dateRange?: string[] }) => void
  onCancel: () => void
}

function ModalFilterActivityLogs({
  userId,
  dateRange: range,
  onChange,
  onCancel,
}: Props) {
  const [selectedUserId, setSelectedUserId] = useState(userId)
  const [from, setFrom] = useState(() => (range ? moment(range[0]) : null))
  const [to, setTo] = useState(() => (range ? moment(range[1]) : null))

  const handleFilter = (isReset = false) => {
    if (isReset) {
      onChange?.({})
      return onCancel()
    }

    notification.destroy()
    const range: Moment[] = []
    if (!!from) range.push(from)
    if (!!to) range.push(to)
    if (
      range.length === 1 ||
      (range.length === 2 && range[1].diff(range[0]) < 0)
    ) {
      return notiError({ message: 'Error', description: 'Invalid duration' })
    }

    if (!onChange) return
    onChange({
      userId: selectedUserId,
      dateRange:
        range.length === 2
          ? [range[0].format(YYYY_MM_DD), range[1].format(YYYY_MM_DD)]
          : undefined,
    })
    onCancel()
  }

  const handleGetUsers = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getUsers({ currentPage: page, search })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapUsers = (users: User[]) =>
    users.map(({ name, id, avatar_url }: User) => ({
      key: id,
      value: id,
      label: (
        <React.Fragment>
          <Avatar
            key={id}
            size='small'
            fontSize={10}
            username={name}
            src={avatar_url || undefined}
          />
          {name}
        </React.Fragment>
      ),
    }))

  return (
    <BaseModal
      noPadding
      className='modal-filter-activity-logs'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          rounded
          bordered
          goBack
          onGoBack={onCancel}
          actionRight={
            <span
              className='text-primary text-500 hoverable'
              onClick={() => handleFilter(true)}
            >
              Reset Filter
            </span>
          }
        >
          Quick Filters
        </Layout.Header>
        <Layout.Body>
          <h4>User Made Action</h4>
          <AsyncSelect
            size='large'
            value={selectedUserId}
            placeholder='All Users'
            fetcher={handleGetUsers}
            handleSearch={handleGetUsers}
            handleLoadMore={handleGetUsers}
            mapOptions={mapUsers}
            onChange={v => setSelectedUserId(v)}
            getPopupContainer={getPopupContainer}
          />
          <hr className='divider' style={{ margin: '28px 0' }} />
          <h4>Duration</h4>
          <div className='date-picker-group'>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={from}
              onChange={v => setFrom(v)}
              placeholder='From'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
            <ArrowRightOutlined className='text-placeholder' />
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={to}
              onChange={v => setTo(v)}
              placeholder='To'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <Button
            style={{ marginTop: 34 }}
            block
            size='large'
            type='primary'
            onClick={() => handleFilter()}
          >
            Apply
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalFilterActivityLogs
