import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import { Fullscreen, RatioFilled, VideoCameraFilled } from 'components/Icons'
import ModalExportVideos from 'components/Modal/Livestream/ExportVideos'
import { useAppSelector } from 'store'
import { usePlaybackContext } from './PlaybackContext'

interface Props {
  layout: number
  onLayoutChange: (num: number) => void
  onTabFullscreen: () => void
}

function PlaybackOptions({ layout, onLayoutChange, onTabFullscreen }: Props) {
  const { push } = useHistory()
  const { stats } = useAppSelector(state => state.stats)
  const { timeRange, players } = usePlaybackContext()
  const [showExport, setShowExport] = useState(false)

  return (
    <div className='playback-options'>
      <div className='playback-option'>
        <div>
          <RatioFilled className='text-placeholder' />
          <span>Change Layout</span>
        </div>
        <div className='change-layout'>
          {[1, 2, 3, 4].map(num => (
            <Button
              key={num}
              size='large'
              onClick={() => onLayoutChange(num)}
              className={cx('btn-selection', {
                selected: num === layout,
              })}
            >
              {num}x{num}
            </Button>
          ))}
        </div>
      </div>
      <div className='playback-option' onClick={() => setShowExport(true)}>
        <div>
          <DownloadOutlined className='text-placeholder' />
          <span>Export</span>
        </div>
      </div>
      <div className='playback-option' onClick={onTabFullscreen}>
        <div>
          <Fullscreen style={{ transform: 'scale(.9)' }} />
          Enter Fullscreen Mode
        </div>
      </div>
      <div className='playback-option'>
        <div>
          <VideoCameraFilled color='#808080' />
          Camera licenses:{' '}
          <span className='text-500'>
            {stats?.camera_licenses.used}/{stats?.camera_licenses.total}
          </span>
        </div>
        <div
          className='text-500 text-underline text-placeholder'
          onClick={() => push('/settings/payment-settings/plans')}
        >
          Upgrade
        </div>
      </div>
      <AnimatePresence>
        {showExport && (
          <ModalExportVideos
            selectedTimeRange={timeRange}
            selectedDevices={players.filter(p => !!p).map(p => p!.device)}
            onCancel={() => setShowExport(false)}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default PlaybackOptions
