import React, { useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'
import { DoubleRightOutlined } from '@ant-design/icons'

import {
  ExitFullscreen,
  PauseFilled,
  PlayFilled,
  ScreenshotFilled,
  SpeakerLouderFilled,
  SpeakerMutedFilled,
} from 'components/Icons'
import { useFullscreen } from 'hooks'
import { usePlaybackContext } from './PlaybackContext'
import { secondsToHms } from 'utils/dateTime'
import { MAX_VIDEO_LENGTH_SEC } from 'utils/device'
import { videoCapture } from 'utils/functions'

interface Props {
  selectedItemIdx: number
  setSelectedItemIdx: (idx: number | null) => void
  isReady: boolean
  onPlay: () => void
  onPause: (status?: string) => void
}

function FullscreenControls({
  selectedItemIdx,
  setSelectedItemIdx,
  isReady,
  onPlay,
  onPause,
}: Props) {
  const { exitFullscreen } = useFullscreen()
  const { status, players, currentTime, dispatch } = usePlaybackContext()
  const [widthPerSec, setWidthPerSec] = useState(0)

  const progressRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const progressWidth = progressRef.current?.clientWidth || 0
    setWidthPerSec(progressWidth / MAX_VIDEO_LENGTH_SEC)
  }, [])

  const handleSeek = (pageX: number) => {
    const { left, right } = progressRef.current!.getBoundingClientRect()
    dispatch({
      type: 'SET_PLAYBACK_TIME',
      payload:
        pageX >= right
          ? MAX_VIDEO_LENGTH_SEC
          : pageX <= left
          ? 0
          : (pageX - left) / widthPerSec,
    })
  }

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = event => {
    handleTouchMove(event.nativeEvent)
    window.addEventListener('touchmove', handleTouchMove)
    window.addEventListener('touchend', handleTouchEnd)
  }

  const handleTouchMove = (event: TouchEvent) => {
    handleSeek(event.changedTouches[0].pageX)
  }

  const handleTouchEnd = (event: TouchEvent) => {
    window.removeEventListener('touchmove', handleTouchMove)
    window.removeEventListener('touchend', handleTouchEnd)
    handleSeek(event.changedTouches[0].pageX)
  }

  const activePlayer = players[selectedItemIdx]

  const handleVideoCapture = () => {
    if (!activePlayer || !activePlayer.video) return
    videoCapture(activePlayer.video)
  }

  const handleToggleVolume = () => {
    if (!activePlayer) return
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: {
        index: selectedItemIdx,
        muted: !activePlayer.muted,
      },
    })
  }

  console.log(status, isReady)

  return (
    <React.Fragment>
      <motion.div
        initial={{ x: '-50%', y: '100vh' }}
        animate={{ x: '-50%', y: -60 }}
        transition={{ type: 'tween', duration: 0.2 }}
        exit={{ x: '-50%', y: '100vh' }}
        className='fullscreen-controls seekbar'
      >
        <div
          ref={progressRef}
          className='progress-bar'
          onTouchStart={handleTouchStart}
        >
          <div className='inner' style={{ width: widthPerSec * currentTime }} />
          <div
            className='scrubber'
            style={{
              left: `calc(${widthPerSec * currentTime}px - 5px)`,
            }}
          />
        </div>
        <div className='current-time'>
          <span>{secondsToHms(currentTime)}</span>
        </div>
      </motion.div>
      <motion.div
        initial={{ x: '-50%', y: '100vh' }}
        animate={{ x: '-50%', y: 0 }}
        transition={{ type: 'tween', duration: 0.2 }}
        exit={{ x: '-50%', y: '100vh' }}
        className='fullscreen-controls'
      >
        {status === 'playing' ? (
          <div
            className={cx({ disabled: !isReady })}
            onClick={isReady ? () => onPause() : undefined}
          >
            <PauseFilled color='#fff' />
          </div>
        ) : (
          <div
            className={cx({ disabled: !isReady })}
            onClick={isReady ? onPlay : undefined}
          >
            <PlayFilled color='#fff' />
          </div>
        )}
        <div
          className={cx({ disabled: !activePlayer })}
          onClick={handleVideoCapture}
        >
          <ScreenshotFilled color='#fff' />
        </div>
        <div
          className={cx({ disabled: !activePlayer })}
          onClick={handleToggleVolume}
        >
          {activePlayer?.muted ? (
            <SpeakerMutedFilled color='#fff' />
          ) : (
            <SpeakerLouderFilled color='#fff' />
          )}
        </div>
        <div className='divider' />
        <div onClick={() => exitFullscreen()}>
          <ExitFullscreen color='#fff' />
        </div>
        <div className='divider' />
        <div onClick={() => setSelectedItemIdx(null)}>
          <DoubleRightOutlined rotate={90} />
        </div>
      </motion.div>
    </React.Fragment>
  )
}

export default FullscreenControls
