import { VideoCameraFilled, PencilFilled, TrashFilled } from 'components/Icons'
import { Layout } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'

interface Props {
  onLivestream: () => void
  onEdit: () => void
  onDelete: () => void
  onCancel: () => void
}

function ModalOptions({ onLivestream, onEdit, onDelete, onCancel }: Props) {
  const handleClick = (fn: Function) => {
    fn()
    onCancel()
  }

  return (
    <BaseModal
      noPadding
      className='modal-device-more-options'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header rounded bordered>
          Device More Options
        </Layout.Header>
        <Layout.Body>
          <div className='option' onClick={() => handleClick(onLivestream)}>
            <VideoCameraFilled
              color='#808080'
              style={{ transform: 'scale(1.2)' }}
            />
            <span>View Livestream</span>
          </div>
          <div className='option' onClick={() => handleClick(onEdit)}>
            <PencilFilled color='#808080' />
            <span>Edit Device</span>
          </div>
          <hr className='divider' />
          <div
            className='option text-danger'
            onClick={() => handleClick(onDelete)}
          >
            <TrashFilled type='danger' />
            <span>Delete Device</span>
          </div>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalOptions
