import React from 'react'

import './index.scss'
import BaseModal from './BaseModal'

interface Props {
  img?: React.ReactNode
  title?: React.ReactNode | string
  content?: React.ReactNode | string
  onCancel: () => void
}

function ModalInfo({ img, title, content, onCancel }: Props) {
  return (
    <BaseModal className='modal-info' onCancel={onCancel}>
      {img && <div className='info-img'>{img}</div>}
      {title && <h4 className='info-title'>{title}</h4>}
      {content && <div className='info-content'>{content}</div>}
    </BaseModal>
  )
}

export default ModalInfo
