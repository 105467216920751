import { useState } from 'react'
import { omit } from 'lodash'
import { Button, Form, Input } from 'antd'

import { ChannelSelection, DateTimeSelection } from 'components/Shared'
import { notiError } from 'utils/notification'
import { days as DAYS } from 'utils/selectOptions'
import { createSchedule, updateSchedule } from 'services/schedules'

import {
  rules,
  FormValues,
  getRecipientsByType,
  mapValuesToOptions,
  getOptionsId,
  RecipientFormItem,
  WebhhookFormItem,
} from './FormHelpers'

interface Props {
  deviceId: number
  scheduleId?: number
  formValues: FormValues
  loading: boolean
  setLoading: (l: boolean) => void
  onSuccess: () => void
}

function ScheduleForm(props: Props) {
  const { deviceId, scheduleId, formValues, ...p } = props
  const [form] = Form.useForm()

  const [channels, setChannels] = useState(formValues.channels)
  const [dateTime, setDateTime] = useState(formValues.dateTime)

  const handleSubmit = async () => {
    const { is247, startTime, endTime, selectedDays } = dateTime

    const errors = []
    if (!is247 && (!startTime || !endTime || !selectedDays.length)) {
      errors.push({ name: 'dateTime', errors: ['This field is required'] })
    }
    if (!channels.length) {
      errors.push({ name: 'channels', errors: ['This field is required'] })
    }
    if (errors.length) {
      return form.setFields(errors)
    }

    const values = form.getFieldsValue()
    const schedule = {
      name: values.name,
      device_id: deviceId,
      is_24_7: is247,
      channels,
      start_time: startTime || undefined,
      end_time: endTime || undefined,
      days: (is247 ? DAYS : selectedDays).map(d => d.toUpperCase()),
      is_active: formValues.is_active,
      email_recipient_ids: getOptionsId(values.email_recipients || []),
      phone_recipient_ids: getOptionsId(values.phone_recipients || []),
      whatsapp_recipient_ids: getOptionsId(values.whatsapp_recipients || []),
      webhook: values.webhook,
      slack_webhook: values.slack_webhook,
      teams_webbhook: values.teams_webbhook,
    }

    try {
      p.setLoading(true)
      if (scheduleId) {
        await updateSchedule(scheduleId, schedule)
      } else {
        await createSchedule(schedule)
      }
      p.onSuccess()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      p.setLoading(false)
    }
  }

  const getRecipientsHasPhone = getRecipientsByType({ phone: 1 })
  const getRecipientsHasEmail = getRecipientsByType({ email: 1 })

  return (
    <Form
      form={form}
      initialValues={omit(formValues, ['dateTime', 'channels'])}
      layout='vertical'
      onFinish={handleSubmit}
    >
      <h4 className='form-title'>Notification schedule detail</h4>
      <Form.Item name='name' label='Schedule Name' rules={rules}>
        <Input size='large' placeholder='Name your schedule' />
      </Form.Item>
      <Form.Item
        required
        name='dateTime'
        label='Schedule Date & Time'
        className='schedule-datetime-field'
      >
        <DateTimeSelection
          {...dateTime}
          onChange={v => {
            form.setFields([{ name: 'dateTime', errors: undefined }])
            setDateTime(v)
          }}
          popupContainer='.m-modal-inner'
        />
      </Form.Item>
      <Form.Item required name='channels' label='Channel'>
        <ChannelSelection
          popupContainer={() => document.querySelector('.m-modal-inner')!}
          selectedChannels={channels}
          onChange={v => {
            form.setFields([{ name: 'channels', errors: undefined }])
            setChannels(v)
          }}
        />
      </Form.Item>

      <div className='divider' />
      <h4 className='form-title'>
        Recipients
        {!channels.length && (
          <div
            className='text-danger text-400'
            style={{ fontSize: '0.875rem', marginTop: 8 }}
          >
            Please select at least 1 channels above
          </div>
        )}
      </h4>
      {channels.includes('phone') && (
        <RecipientFormItem
          name='phone_recipients'
          channel='SMS'
          fetcher={getRecipientsHasPhone}
          mapper={mapValuesToOptions}
        />
      )}
      {channels.includes('whatsapp') && (
        <RecipientFormItem
          name='whatsapp_recipients'
          channel='WhatsApp'
          fetcher={getRecipientsHasPhone}
          mapper={mapValuesToOptions}
        />
      )}
      {channels.includes('email') && (
        <RecipientFormItem
          name='email_recipients'
          channel='Email'
          fetcher={getRecipientsHasEmail}
          mapper={mapValuesToOptions}
        />
      )}
      {channels.includes('slack') && (
        <WebhhookFormItem
          name='slack_webhook'
          label='Slack'
          placeholder='Enter Slack Webhook URL'
          extraUrl='https://api.slack.com/messaging/webhooks'
          extraText='See How to get Slack Webhook URL'
        />
      )}
      {channels.includes('webhook') && (
        <WebhhookFormItem
          name='webhook'
          label='Webhook'
          placeholder='Enter Webhook URL'
          extraUrl='https://developers.facebook.com/docs/messenger-platform/webhook'
          extraText='See How to get Webhook URL'
        />
      )}
      {channels.includes('microsoft_teams') && (
        <WebhhookFormItem
          name='teams_webbhook'
          label='Microsoft Teams Webhook'
          placeholder='Enter Mircosoft Team Webhook URL'
          extraUrl='https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook'
          extraText='See how to create a Mircosoft Teams Webhook'
        />
      )}
      <Button
        disabled={p.loading}
        size='large'
        block
        type='primary'
        htmlType='submit'
      >
        Save
      </Button>
    </Form>
  )
}

export default ScheduleForm
