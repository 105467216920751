import { Button } from 'antd'
import { useHistory } from 'react-router-dom'

import './index.scss'
import NotFoundImg from 'assets/not-found.png'
import { Layout } from 'components/Shared'

function PageNotFound() {
  const { push } = useHistory()

  return (
    <Layout className='page-not-found'>
      <Layout.Body>
        <div className='row'>
          <img src={NotFoundImg} alt='404' />
        </div>
        <div className='row'>
          <h1>Oops! Page not found</h1>
          <span className='text-placeholder'>
            We can’t seem to find the page you’re looking for.
          </span>
        </div>
        <div className='row'>
          <Button type='primary' size='large' onClick={() => push('/')}>
            Back to Dashboard
          </Button>
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default PageNotFound
