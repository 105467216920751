import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function TextFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='14'
        height='12'
        viewBox='0 0 14 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M5.14285 1.71429H6.85714V2.57143H8.57144V0H0V2.57143H1.71429V1.71429H3.42859V10.2857H1.71429V12H6.85629V10.2857H5.14285V1.71429Z'
          fill={c}
        />
        <path
          d='M6.85706 4.28574V6.85716H8.57135V6.00003H9.42848V10.2857H8.57135V12H11.9999V10.2857H11.1428V6H11.9999V6.85713H13.7142V4.28571H6.85706V4.28574Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
