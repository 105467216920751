import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as Noti from 'services/notifications'

export const getSystemNotis: any = createAsyncThunk(
  'notification/getSystemNotis',
  async (page: number) => {
    const response = await Noti.getSystemNotis(page)
    return response.data
  },
)

interface State {
  loading: boolean
  error: boolean
  newNotifications: Noti.SystemNoti[]
  notifications: Noti.SystemNoti[]
  currentPage: number
  totalPage: number
  unreadCount: number
}

const initialState: State = {
  loading: true,
  error: false,
  newNotifications: [],
  notifications: [],
  currentPage: 1,
  totalPage: 1,
  unreadCount: 0,
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    markAllAsRead: state => {
      state.notifications = [
        ...state.newNotifications,
        ...state.notifications,
      ].map(n => ({
        ...n,
        read_at: new Date().toString(),
      }))
      state.newNotifications = []
      state.unreadCount = 0
    },
    addNewNotif: (
      state,
      action: { payload: Noti.SystemNoti; type: string },
    ) => {
      state.newNotifications = [action.payload, ...state.newNotifications]
      state.unreadCount = state.unreadCount + 1
    },
  },
  extraReducers: {
    [getSystemNotis.pending.type]: state => {
      state.loading = true
      state.error = false
    },
    [getSystemNotis.rejected.type]: state => {
      state.loading = true
      state.error = true
    },
    [getSystemNotis.fulfilled.type]: (state, { payload }) => {
      state.notifications = [...state.notifications, ...payload.data]
      state.totalPage = payload.meta.last_page
      state.unreadCount = payload.meta.unread_count
      state.loading = false
      state.error = false
    },
  },
})

export const { updateCurrentPage, markAllAsRead, addNewNotif } =
  notificationSlice.actions
export default notificationSlice.reducer
