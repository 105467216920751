import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function FileTextFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='11'
        height='12'
        viewBox='0 0 11 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M0 0V12H5.62627V7.42873H10.1976V0H0ZM4.94639 8.13185H2.30536V7.42873H4.94639V8.13185ZM7.89989 6.10594H2.2977V5.40281H7.89989V6.10594ZM7.89989 4.05155H2.2977V3.34842H7.89989V4.05155Z'
          fill={c}
        />
        <path d='M6.32935 8.1319V11.7941L9.99153 8.1319H6.32935Z' fill={c} />
      </svg>
    </SVGUniqueID>
  )
}
