import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'

import {
  Fullscreen,
  ScreenshotFilled,
  SpeakerLouderFilled,
  SpeakerMutedFilled,
} from 'components/Icons'

import { useFullscreen } from 'hooks'
import { videoCapture } from 'utils/functions'
import { usePlaybackContext, createPlayer } from './PlaybackContext'
import ModalAddDevices from 'components/Modal/Livestream/AddDevices'
import { Device } from 'services/devices'

interface Props {
  maxPlayerCount: number
  activeIndex: number
}

function PlayerActions({ maxPlayerCount, activeIndex }: Props) {
  const { players, dispatch } = usePlaybackContext()
  const { requestFullscreen } = useFullscreen()
  const [showAddPlayer, setShowAddPlayer] = useState(false)

  const activePlayer = players[activeIndex]
  const disabled = !activePlayer || !activePlayer.video

  const handleVideoCapture = () => {
    if (!activePlayer || !activePlayer.video) return
    videoCapture(activePlayer.video)
  }

  const handleToggleVolume = () => {
    if (!activePlayer) return
    dispatch({
      type: 'UPDATE_PLAYER',
      payload: {
        index: activeIndex,
        muted: !activePlayer.muted,
      },
    })
  }

  const handleFullscreen = () => {
    if (!activePlayer) return
    const el = document.querySelector(
      `.player-wrapper#player-${activePlayer.index} .player`,
    )
    if (!el) return
    requestFullscreen(el, () => {
      dispatch({
        type: 'UPDATE_PLAYER',
        payload: {
          index: activeIndex,
          fullscreen: true,
        },
      })
    })
  }

  const handleAddPlayers = (devices: (Device | undefined)[]) => {
    dispatch({
      type: 'SET_PLAYERS',
      payload: devices.map(
        (d, idx) => players[idx] ?? (!!d ? createPlayer(idx, d) : undefined),
      ),
    })
  }

  const handleRemovePlayer = () => {
    if (!activePlayer) return
    dispatch({
      type: 'REMOVE_PLAYER',
      payload: activeIndex,
    })
  }

  return (
    <React.Fragment>
      <div className='player-controls'>
        <div
          className={cx('control-item', { disabled })}
          onClick={handleVideoCapture}
        >
          <ScreenshotFilled type='secondary' />
          <span className='control-label'>Screenshot</span>
        </div>
        <div
          className={cx('control-item', { disabled })}
          onClick={handleToggleVolume}
        >
          {activePlayer?.muted ? (
            <SpeakerMutedFilled type='secondary' />
          ) : (
            <SpeakerLouderFilled type='secondary' />
          )}
          <span className='control-label'>Volume</span>
        </div>
        <div
          className={cx('control-item', { disabled })}
          onClick={handleFullscreen}
        >
          <Fullscreen type='secondary' />
          <span className='control-label'>Full screen</span>
        </div>
        {!activePlayer && (
          <div className='control-item' onClick={() => setShowAddPlayer(true)}>
            <PlusCircleOutlined className='text-placeholder' />
            <span className='control-label'>Add</span>
          </div>
        )}
        {activePlayer && (
          <div className='control-item' onClick={handleRemovePlayer}>
            <MinusCircleOutlined className='text-placeholder' />
            <span className='control-label'>Remove</span>
          </div>
        )}
      </div>
      <AnimatePresence>
        {showAddPlayer && (
          <ModalAddDevices
            mode='playback'
            max={maxPlayerCount}
            index={activeIndex}
            devices={players.map(p => p?.device)}
            onChange={handleAddPlayers}
            onCancel={() => setShowAddPlayer(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default PlayerActions
