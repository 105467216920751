import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function PencilFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M0 9.49965V12H2.50034L9.87802 4.62229L7.37767 2.12195L0 9.49965Z'
          fill={c}
        />
        <path
          d='M11.805 1.7519L10.2481 0.195027C9.98809 -0.065009 9.56469 -0.065009 9.30466 0.195027L8.08449 1.4152L10.5848 3.91554L11.805 2.69537C12.0651 2.43534 12.0651 2.01193 11.805 1.7519Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
