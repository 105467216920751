import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import './index.scss'
import BrandLogo from 'assets/brand-logo.png'

import { Layout, Avatar } from 'components/Shared'
import { changeSMTPServer, updloadBrandLogo } from 'services/accounts'
import { notiError, notiSuccess } from 'utils/notification'
import {
  updateBrandLogo,
  updateUseCustomSMTPServer,
} from 'store/slice/authSlice'
import { useAppDispatch, useAppSelector } from 'store'

function General() {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()

  const canUpload = () => {
    return currentUser?.account.access.can_use_brand_logo
  }

  const handleUploadImage = async (img: File) => {
    try {
      const response = await updloadBrandLogo(currentUser!.id, img)
      dispatch(
        updateBrandLogo({
          logo: response.data.data.brand_logo,
          logoUrl: response.data.data.brand_logo_url,
        }),
      )
      notiSuccess({ message: 'Brand logo updated' })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const handleChangeSmtp = async () => {
    try {
      setLoading(true)
      const useCustomSMTP = currentUser!.account.use_custom_smtp === 0 ? 1 : 0
      await changeSMTPServer(currentUser!.id, useCustomSMTP)
      dispatch(updateUseCustomSMTPServer(useCustomSMTP))
      notiSuccess({ message: 'SMTP setting updated' })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout className='general-page'>
      <Layout.Header goBack bordered>
        General Settings
      </Layout.Header>
      <Layout.Body>
        <div className='row brand-logo'>
          <h3>Brand Logo</h3>
          <span>
            Brand Logo is used to sent along with notifications from your
            devices to the recipient
          </span>
          <div>
            {currentUser?.account.access.can_use_brand_logo ? (
              <Avatar
                size={130}
                src={currentUser?.account.brand_logo_url || BrandLogo}
                onChange={canUpload() ? handleUploadImage : undefined}
              />
            ) : (
              <React.Fragment>
                <span style={{ marginBottom: 12 }} className='text-success'>
                  Upgrade to a Professional plan to access this feature
                </span>
                <Link
                  to='/settings/payment-settings'
                  className='ant-btn ant-btn-primary ant-btn-lg ant-btn-block'
                >
                  Upgrade now
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className='divider' />
        <div className='row smtp'>
          <h3>SMTP Server </h3>
          <span>
            {!!currentUser?.account.use_custom_smtp
              ? 'You are using your own SMTP server.'
              : 'You are using Broadflow Streams as your SMTP server.'}
          </span>
          <div
            className={cx('change-smtp', { disabled: loading })}
            onClick={handleChangeSmtp}
          >
            {!!currentUser?.account.use_custom_smtp
              ? 'Use Broadflow Streams as your SMTP server.'
              : 'Use my own SMTP server'}
          </div>
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default General
