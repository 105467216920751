import React, { useEffect, useReducer } from 'react'
import moment, { Moment } from 'moment'
import { Button, DatePicker, Form, TimePicker } from 'antd'

import './index.scss'
import { CameraFilled, ClockFilled } from 'components/Icons'
import { Layout } from 'components/Shared'
import Modal, { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { MM_DD_YYYY, YYYY_MM_DD_HH_MM } from 'utils/dateTime'
import { notiError, notiSuccess } from 'utils/notification'
import { validateTimeRange } from 'utils/validation'
import { Device, getDevices } from 'services/devices'
import { searchRecord } from 'services/recordingDownloads'

import { OptionValue, mapValuesToOptions } from '../Schedule/FormHelpers'

interface State {
  device?: OptionValue
  fromDate?: Moment | null
  fromTime?: Moment | null
  toDate?: Moment | null
  toTime?: Moment | null
  loading: boolean
}

interface Props {
  device?: OptionValue
  from?: string
  to?: string
  onSuccess?: (search: {
    device?: OptionValue
    from?: string
    to?: string
  }) => void
  onCancel: () => void
}

function ModalSearchRecord({ onCancel, onSuccess, ...props }: Props) {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      device: props.device,
      fromDate: props.from ? moment(props.from) : undefined,
      fromTime: props.from ? moment(props.from) : undefined,
      toDate: props.to ? moment(props.to) : undefined,
      toTime: props.to ? moment(props.to) : undefined,
      loading: false
    }
  )

  const [form] = Form.useForm()
  const { device, fromDate, fromTime, toDate, toTime, loading } = state

  useEffect(() => {
    if (!!fromDate && !!fromTime) {
      setState({
        toDate: fromDate,
        toTime: moment(fromTime).hour(fromTime.hour() + 1)
      })
    }
  }, [fromDate, fromTime])

  useEffect(() => {
    if (!!device) {
      form.setFields([{ name: 'device', errors: [] }])
    }
    if (!!fromDate && !!fromTime && !!toDate && !!toTime) {
      form.setFields([{ name: 'duration', errors: [] }])
    }
  }, [device, fromDate, fromTime, toDate, toTime, form])

  const handleGetDevices = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getDevices({
        pageSize: 300,
        search,
        currentPage: page
      })
      return {
        data: response.data.data.filter(
          (d: Device) => d.type === 'Security Camera'
        ),
        totalPage: response.data.meta.last_page
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mergeMoments = (...moments: [Moment, Moment]) => {
    const [date, time] = moments
    return moment(date).hour(time.hour()).minute(time.minute()).second(0)
  }

  const handleSubmit = () => {
    const errors = []
    if (!device) {
      errors.push({ name: 'device', errors: ['Please select device'] })
    }
    if (!fromDate || !fromTime || !toDate || !toTime) {
      errors.push({ name: 'duration', errors: ['Please select duration'] })
    }
    if (!!errors.length) {
      return form.setFields(errors)
    }

    const start = mergeMoments(fromDate!, fromTime!)
    const end = mergeMoments(toDate!, toTime!)

    const isValidRange = validateTimeRange(start, end)
    if (!isValidRange.success) {
      return form.setFields([
        { name: 'duration', errors: [isValidRange.message] }
      ])
    }

    handleSearchRecord({
      device: device!,
      from: start.format(YYYY_MM_DD_HH_MM),
      to: end.format(YYYY_MM_DD_HH_MM)
    })
  }

  const handleSearchRecord = async (search: {
    device: OptionValue
    from: string
    to: string
  }) => {
    try {
      setState({ loading: true })
      await searchRecord({
        device_id: search.device.value,
        start_timestamp: search.from,
        end_timestamp: search.to
      })
      setState({ loading: false })
      onSuccess?.(search)
      onCancel()
      notiSuccess({ message: 'Download request submitted' })
    } catch (err: any) {
      setState({ loading: false })
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <Modal
      noPadding
      height="100vh"
      onCancel={onCancel}
      className="modal-search-record"
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          Video Search
        </Layout.Header>
        <Layout.Body>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={{ device }}
          >
            <Form.Item
              name="device"
              className="form-item-label-with-icon"
              label={
                <React.Fragment>
                  <CameraFilled type="primary" /> Select device
                </React.Fragment>
              }
            >
              <AsyncSelect
                labelInValue
                size="large"
                style={{ marginTop: 8, width: '100%' }}
                fetcher={handleGetDevices}
                handleSearch={handleGetDevices}
                handleLoadMore={handleGetDevices}
                mapOptions={mapValuesToOptions}
                placeholder="Select device to add, type to search"
                onChange={(_: any, v: any) => setState({ device: v })}
                getPopupContainer={getPopupContainer}
              />
            </Form.Item>
            <div className="divider" />
            <Form.Item
              name="duration"
              className="form-item-label-with-icon"
              style={{ margin: 0 }}
              label={
                <React.Fragment>
                  <ClockFilled type="primary" /> Select duration
                </React.Fragment>
              }
            >
              <React.Fragment>
                <div
                  className="text-success"
                  style={{ marginLeft: '1.75rem', marginBottom: '1rem' }}
                >
                  Maximum duration is 4 hours
                </div>
                <div className="date-picker-wrapper">
                  <span className="text-500">From</span>
                  <DatePicker
                    allowClear={false}
                    size="large"
                    inputReadOnly
                    showToday={false}
                    format={MM_DD_YYYY}
                    placeholder="Date"
                    style={{ width: '100%' }}
                    value={fromDate}
                    onChange={v => setState({ fromDate: v })}
                    getPopupContainer={getPopupContainer}
                  />
                  <TimePicker
                    allowClear={false}
                    size="large"
                    inputReadOnly
                    format="HH:mm"
                    placeholder="Time"
                    style={{ width: '7.5rem' }}
                    value={fromTime}
                    onChange={v => setState({ fromTime: v })}
                    getPopupContainer={getPopupContainer}
                  />
                </div>
                <hr className="divider" style={{ margin: '1rem 0' }} />
                <div className="date-picker-wrapper">
                  <span className="text-500">To</span>
                  <DatePicker
                    allowClear={false}
                    size="large"
                    inputReadOnly
                    showToday={false}
                    format={MM_DD_YYYY}
                    placeholder="Date"
                    style={{ width: '100%' }}
                    value={toDate}
                    onChange={v => setState({ toDate: v })}
                    getPopupContainer={getPopupContainer}
                  />
                  <TimePicker
                    allowClear={false}
                    size="large"
                    inputReadOnly
                    format="HH:mm"
                    placeholder="Time"
                    style={{ width: '7.5rem' }}
                    value={toTime}
                    onChange={v => setState({ toTime: v })}
                    getPopupContainer={getPopupContainer}
                  />
                </div>
              </React.Fragment>
            </Form.Item>
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              style={{ marginTop: '2rem' }}
              disabled={loading}
            >
              Search
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </Modal>
  )
}

export default ModalSearchRecord
