import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function RatioFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='20'
        height='17'
        viewBox='0 0 20 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.75 1H1.25V16H18.75V1ZM14.5454 5.77276H11.8182V3.95456H16.3636V8.50003H14.5454V5.77276ZM3.63634 8.50003H5.45453V11.2273H8.18181V13.0454H3.63634V8.50003Z'
          fill={c}
        />
        <path
          d='M1.81816 0.318207H18.1818C19.1864 0.318207 20 1.13183 20 2.13636V14.8637C20 15.8682 19.1864 16.6819 18.1818 16.6819H1.81816C0.813625 16.6819 -4.19617e-05 15.8682 -4.19617e-05 14.8637V2.13636C1.90735e-06 1.13183 0.813625 0.318207 1.81816 0.318207ZM1.81816 14.8773H18.1818V2.12273H1.81816V14.8773Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
