import { useState } from 'react'

import '../index.scss'
import { Layout } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { notiError, notiSuccess } from 'utils/notification'
import { createTag } from 'services/tags'
import { useAppSelector } from 'store'

import TagDetails from './TagDetails'
import AffectedDevices from './AffectedDevices'
import Recipients from './Recipients'
import { steps, FormValues, formValues, getOptionsId } from './FormHelpers'

interface Props {
  onSuccess?: () => void
  onCancel: () => void
}

function ModalCreateNotifTag({ onSuccess, onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const [values, setValues] = useState(formValues)

  const { currentUser } = useAppSelector(state => state.auth)
  const currentStep = steps[stepIndex]

  const handleGoBack = () => {
    if (stepIndex === 0) return onCancel()
    setStepIndex(prev => prev - 1)
  }

  const handleStepChange = (num: number) => {
    setStepIndex(prev => prev + num)
  }

  const handleSkip = () => {
    const key = currentStep.key as keyof FormValues
    handleSetValues(key, formValues[key])
    handleStepChange(1)
  }

  const handleSetValues = <T extends keyof FormValues>(
    k: T,
    v: FormValues[T],
  ) => {
    setValues(prev => ({ ...prev, [k]: v }))
  }

  const handleCreateTag = async (recipients: FormValues['recipients']) => {
    try {
      setLoading(true)
      handleSetValues('recipients', recipients)
      await createTag({
        name: values.tagDetails.name,
        is_active: true,
        is_blocked: recipients.is_blocked,
        has_attachments: values.tagDetails.has_attachments,
        apply_to_all: values.affectedDevices.apply_to_all,
        search_in: values.tagDetails.search_in,
        subject_keywords: values.tagDetails.subject_keywords,
        body_keywords: values.tagDetails.body_keywords,
        account_id: currentUser!.id,
        channels: recipients.channels,
        webhook: recipients.webhook,
        slack_webhook: recipients.slack_webhook,
        teams_webbhook: recipients.teams_webbhook,
        email_recipient_ids: getOptionsId(recipients.email_recipients),
        phone_recipient_ids: getOptionsId(recipients.phone_recipients),
        whatsapp_recipient_ids: getOptionsId(recipients.whatsapp_recipients),
        device_ids: getOptionsId(values.affectedDevices.devices),
        stroke_color: values.tagDetails.stroke_color,
        text_color: values.tagDetails.text_color,
        color: values.tagDetails.color,
      })
      setLoading(false)
      onCancel()
      notiSuccess({ message: 'Tag created' })
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const renderFormStep = () => {
    switch (stepIndex) {
      case 0:
        return (
          <TagDetails
            initValue={values.tagDetails}
            onChange={handleSetValues}
            onStepChange={handleStepChange}
          />
        )
      case 1:
        return (
          <AffectedDevices
            initValue={values.affectedDevices}
            onChange={handleSetValues}
            onStepChange={handleStepChange}
          />
        )
      default:
        return (
          <Recipients
            loading={loading}
            initValue={values.recipients}
            onCreate={handleCreateTag}
          />
        )
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-create-notif-tag'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : handleGoBack}
          actionRight={
            !loading &&
            currentStep.canSkip && (
              <span
                className='text-500 hoverable text-primary'
                onClick={handleSkip}
              >
                Skip
              </span>
            )
          }
        >
          <h4>Create Tag</h4>
          <span className='text-400 text-placeholder'>
            Step {stepIndex + 1}/{steps.length}: {currentStep.title}
          </span>
        </Layout.Header>
        <Layout.Body>{renderFormStep()}</Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalCreateNotifTag
