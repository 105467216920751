import { useEffect, useState } from 'react'

import { ClockFilled, CloudFilled } from 'components/Icons'
import { Spinner } from 'components/Shared'
import StatisticsCard from 'components/Cards/StatisticsCard'
import { notiError } from 'utils/notification'
import {
  CloudStorage,
  Device,
  DeviceSMTP,
  getCloudStorage,
  getDeviceSMTP,
} from 'services/devices'

import SmtpStats from './SmtpStats'
import CameraStats from './CameraStats'
import Details from './DeviceDetails'

interface Props {
  device: Device
  onRefresh: () => void
}

function DeviceDetails({ device, onRefresh }: Props) {
  const [loading, setLoading] = useState(true)
  const [cloudStorage, setCloudStorage] = useState<CloudStorage | null>(null)
  const [smtp, setSmtp] = useState<DeviceSMTP | null>(null)

  useEffect(() => {
    handleGetDeviceStats()
    // eslint-disable-next-line
  }, [device.id])

  const handleGetDeviceStats = async () => {
    try {
      const [storageRes, smtpRes] = await Promise.all([
        getCloudStorage(device.id),
        getDeviceSMTP(device.id),
      ])
      setCloudStorage(storageRes.data.data)
      setSmtp(smtpRes.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner height='50vh' />
  }

  return (
    <div className='tab-device-details'>
      {!!cloudStorage && (
        <div className='cloud-stats'>
          <StatisticsCard
            icon={<CloudFilled type='primary' />}
            title='Cloud Storage'
            content={`${cloudStorage.cloud_storage_gb.used}GB/${cloudStorage.cloud_storage_gb.total}GB`}
          />
          <StatisticsCard
            icon={<ClockFilled type='primary' />}
            title='Est. Retention Time'
            content={cloudStorage.estimated_retention_time || '---'}
          />
        </div>
      )}
      {!!smtp && <SmtpStats smtp={smtp} />}
      <CameraStats camera={device.deviceLiveStream} onRefresh={onRefresh} />
      <Details device={device} onRefresh={onRefresh} />
    </div>
  )
}

export default DeviceDetails
