import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function WarningFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M9 3.75L12 2.25L14.25 3L23.25 18L22.5 21L20.25 22.5H4.5L1.5 21L0.75 18L9 3.75Z'
          fill='url(#warning_filled0_linear)'
        />
        <path
          d='M11.9997 16.9175C11.3287 16.9175 10.7663 17.4799 10.7663 18.1509C10.7663 18.8218 11.3287 19.3843 11.9997 19.3843C12.646 19.3843 13.2331 18.8218 13.2035 18.1805C13.2331 17.475 12.6756 16.9175 11.9997 16.9175Z'
          fill='white'
        />
        <path
          d='M23.416 21.2886C24.1906 19.9516 24.1955 18.358 23.4259 17.0259L15.6999 3.6461C14.9352 2.29924 13.5538 1.5 12.0046 1.5C10.4555 1.5 9.07409 2.30417 8.30939 3.64117L0.573551 17.0358C-0.196086 18.3827 -0.191152 19.9861 0.588352 21.3231C1.35799 22.6453 2.73446 23.4396 4.27373 23.4396H19.7059C21.2501 23.4396 22.6365 22.6354 23.416 21.2886ZM21.7386 20.3216C21.3094 21.0616 20.5496 21.5007 19.701 21.5007H4.2688C3.43009 21.5007 2.67525 21.0715 2.2559 20.3462C1.83161 19.6111 1.82668 18.733 2.25097 17.9929L9.98681 4.60322C10.4062 3.86811 11.1561 3.43396 12.0046 3.43396C12.8483 3.43396 13.6031 3.87305 14.0225 4.60815L21.7534 17.9979C22.1678 18.7182 22.1629 19.5865 21.7386 20.3216Z'
          fill='url(#warning_filled1_linear)'
        />
        <path
          d='M11.6939 8.25894C11.1068 8.42668 10.7417 8.95951 10.7417 9.60581C10.7713 9.99556 10.796 10.3902 10.8256 10.78C10.9094 12.265 10.9933 13.7204 11.0772 15.2054C11.1068 15.7086 11.4965 16.0737 11.9998 16.0737C12.503 16.0737 12.8977 15.684 12.9223 15.1758C12.9223 14.8699 12.9223 14.5887 12.9519 14.2779C13.0062 13.3257 13.0654 12.3735 13.1197 11.4214C13.1493 10.8047 13.2036 10.188 13.2332 9.57127C13.2332 9.34926 13.2036 9.15192 13.1197 8.95458C12.8681 8.40201 12.281 8.1208 11.6939 8.25894Z'
          fill='white'
        />
        <defs>
          <linearGradient
            id='warning_filled0_linear'
            x1='12'
            y1='2.25'
            x2='12'
            y2='22.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='warning_filled1_linear'
            x1='12'
            y1='1.5'
            x2='12'
            y2='23.4396'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
