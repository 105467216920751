import { Account } from './accounts'
import axios from './axios'

export interface Location {
  id: number
  account_id: number
  created_at: string
  name: string
  updated_at: string
  zipcode: string | null
  devices_count: number
  account: Account
}

export const getLocations = ({
  sort,
  sortDirection,
  pageSize,
  search,
  currentPage,
}: {
  sort: string
  sortDirection: string
  pageSize: number
  search: string
  currentPage: number
}) => {
  return axios.get(
    `/api/locations?include=account&order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&filters[search]=${search}&page=${currentPage}`,
  )
}

export const createLocation = async (location: {
  name: string
  zipcode?: string
  account_id: number
}) => {
  await axios.get('/api/csrf-cookie')
  return axios.post('/api/locations', location)
}

export const updateLocation = async (
  id: number,
  location: {
    name: string
    zipcode?: string
    account_id: number
  },
) => {
  await axios.get('/api/csrf-cookie')
  return axios.put(`/api/locations/${id}`, location)
}

export const deleteLocation = (id: number) => {
  return axios.delete(`/api/locations/${id}`)
}
