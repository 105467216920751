import React, { useEffect, useRef, useState } from 'react'
import { render } from 'react-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { PlusOutlined } from '@ant-design/icons'

import { useFullscreen } from 'hooks'
import ModalSelectDuration from 'components/Modal/Livestream/SelectDuration'
import { lockScreen, unlockScreen } from 'utils/functions'
import { formatTime, MM_DD_YYYY_HH_MM_P } from 'utils/dateTime'
import { MAX_VIDEO_LENGTH_SEC } from 'utils/device'

import { usePlaybackContext } from './PlaybackContext'
import PlayerActions from './PlayerActions'
import PlaybackControls from './PlaybackControls'
import Player from './PlaybackPlayer'
import PlaybackOptions from './PlaybackOptions'
import FullscreenControls from './FullscreenControls'

interface Props {
  tab: { key: number; name: string; layout: number }
  onEditTab: (updatedTab: { key: number; name: string; layout: number }) => void
  isTabActive: boolean
}

const PLAYBACK_INTERVAL = 250

function PlaybackTab({ tab, onEditTab, isTabActive }: Props) {
  const { isFullscreen, requestFullscreen } = useFullscreen()
  const { timeRange, players, currentTime, status, dispatch } =
    usePlaybackContext()

  const playerTabRef = useRef<HTMLDivElement>(null)
  const intervalRef = useRef<NodeJS.Timer>()

  const [isReady, setIsReady] = useState(false)
  const [tabFullscreen, setTabFullscreen] = useState(false)
  const [openSelectTime, setOpenSelectTime] = useState(false)
  const [selectedItemIdx, setSelectedItemIdx] = useState<number | null>(null)

  const { layout } = tab

  useEffect(() => {
    if (!isTabActive) return
    render(renderDuration(), document.getElementById('timerange'))
    // eslint-disable-next-line
  }, [isTabActive, timeRange])

  useEffect(() => {
    if (isFullscreen) {
      lockScreen('landscape')
    } else {
      setTabFullscreen(false)
      lockScreen('portrait', unlockScreen)
    }
  }, [isFullscreen])

  useEffect(() => {
    setIsReady(!!players.length && players.every(p => p?.status !== 'loading'))
  }, [players])

  useEffect(() => {
    if (currentTime === 0) {
      handlePause('idle')
    }
    if (currentTime >= MAX_VIDEO_LENGTH_SEC) {
      handlePause()
    }
    // eslint-disable-next-line
  }, [currentTime])

  useEffect(() => {
    if (!isReady && status === 'playing') {
      handlePause('waiting')
    }
    if (isReady && status === 'waiting') {
      handlePlay()
    }
    // eslint-disable-next-line
  }, [isReady, status])

  const renderDuration = () => (
    <div onClick={() => setOpenSelectTime(true)}>
      <span>
        {timeRange.length === 2
          ? `${formatTime(timeRange[0], MM_DD_YYYY_HH_MM_P)} | ${formatTime(
              timeRange[1],
              MM_DD_YYYY_HH_MM_P,
            )}`
          : 'Please select duration'}
      </span>
      <span className='text-600 text-primary'>
        {timeRange.length === 2 ? ' Change' : ' Select'}
      </span>
    </div>
  )

  const renderTabItem = (idx: number) => {
    if (players[idx]) return <Player key={idx} player={players[idx]!} />
    return (
      <div className='flex-centered' key={idx}>
        <PlusOutlined className='text-placeholder' style={{ fontSize: 20 }} />
      </div>
    )
  }

  const handleTabItemActive = (tabIdx: number) => {
    setSelectedItemIdx(prev => (prev === tabIdx ? null : tabIdx))
  }

  const handleLayoutChange = (num: number) => {
    dispatch({ type: 'RESET_PLAYBACK' })
    setSelectedItemIdx(null)
    onEditTab({ ...tab, layout: num })
  }

  const handleTabFullscreen = () => {
    if (!playerTabRef.current) return
    requestFullscreen(playerTabRef.current.querySelector('.tab-layout')!, () =>
      setTabFullscreen(true),
    )
  }

  const handlePlay = () => {
    console.log('play')

    if (currentTime >= MAX_VIDEO_LENGTH_SEC) {
      dispatch({ type: 'SET_PLAYBACK_TIME', payload: 0 })
    }

    dispatch({ type: 'SET_PLAYBACK_STATUS', payload: 'playing' })
    for (const player of players) {
      player?.video?.play()
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = setInterval(() => {
      dispatch({ type: 'UPDATE_CURRENT_TIME', payload: 0.25 })
    }, PLAYBACK_INTERVAL)
  }

  const handlePause = (status = 'paused') => {
    console.log('paused', status)
    dispatch({ type: 'SET_PLAYBACK_STATUS', payload: status })
    for (const player of players) {
      player?.video?.pause()
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
  }

  return (
    <React.Fragment>
      <div ref={playerTabRef} className='player-tab-wrapper'>
        <div
          className='tab-layout'
          style={{ gridTemplateColumns: `repeat(${layout}, 1fr)` }}
        >
          {Array.from({ length: layout * layout }).map((_, idx) => (
            <div
              key={idx}
              id={`player-${idx}`}
              onClick={() => handleTabItemActive(idx)}
              className={cx('player-wrapper', {
                active: selectedItemIdx === idx,
              })}
            >
              {renderTabItem(idx)}
            </div>
          ))}
          <AnimatePresence>
            {tabFullscreen && selectedItemIdx !== null && (
              <FullscreenControls
                isReady={isReady}
                onPlay={handlePlay}
                onPause={handlePause}
                selectedItemIdx={selectedItemIdx}
                setSelectedItemIdx={setSelectedItemIdx}
              />
            )}
          </AnimatePresence>
        </div>
      </div>

      <PlaybackControls
        isReady={isReady}
        onPlay={handlePlay}
        onPause={handlePause}
      />

      {selectedItemIdx !== null ? (
        <PlayerActions
          maxPlayerCount={layout * layout}
          activeIndex={selectedItemIdx}
        />
      ) : (
        <div className='player-controls'>
          <div className='control-message'>Tap any box above to interact</div>
        </div>
      )}

      <PlaybackOptions
        layout={layout}
        onLayoutChange={handleLayoutChange}
        onTabFullscreen={handleTabFullscreen}
      />

      <AnimatePresence>
        {openSelectTime && (
          <ModalSelectDuration
            dateRange={timeRange}
            deviceIds={players.filter(p => !!p).map(p => p!.device.id)}
            onChange={v => dispatch({ type: 'SET_TIME_RANGE', payload: v })}
            onCancel={() => setOpenSelectTime(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default PlaybackTab
