import axios from './axios'

export interface AccountStats {
  camera_licenses: {
    available?: number
    total: number
    used: number
  }
  devices: {
    available?: number
    total: number
    used: number
  }
  email: {
    available: number
    total: number
    used: number
  }
  sms: {
    available: number
    total: number
    used: number
  }
  whatsapp: {
    available: number
    enabled: boolean
    total: number
    used: number
  }
  vault: {
    available?: number
    total: number
    used: number
  }
  cloud_storage: {
    available?: number
    used: number
    total: number
  }
  is_unlimited: boolean
  slack_enabled: boolean
  teams_enabled: boolean
  webhook_enabled: boolean
}

export const getAccountStats = () => {
  return axios.get('/api/cards/accountStats')
}
