import moment from 'moment'

const result = (success: boolean, message: string) => ({
  success,
  message
})

export function validatePassword(password: string) {
  if (password.length < 7) {
    return result(false, 'Password must be longer than 7 characters')
  }
  if (!password.match(/\d/)) {
    return result(false, 'Needs at least 1 number')
  }
  if (!password.match(/[A-Z]/)) {
    return result(false, 'Needs at least 1 upper case character')
  }
  if (!password.match(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/)) {
    return result(false, 'Needs at least 1 special character')
  }
  return result(true, 'passed')
}

export function matchPasswords(password: string, rePassword: string) {
  if (password.trim() !== rePassword.trim()) {
    return result(false, "Password doesn't match")
  }
  return result(true, 'passed')
}

export function validateIpAddress(ipAddress: string) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipAddress
    )
  ) {
    return result(true, 'passed')
  }
  return result(false, 'Invalid IP address')
}

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (!re.test(email.toLowerCase())) {
    return result(false, 'Invalid email')
  }
  return result(true, 'passed')
}

export const validateTimeRange = (start: moment.Moment, end: moment.Moment) => {
  if (start.valueOf() > end.valueOf() || start.valueOf() === end.valueOf()) {
    return result(false, 'End time must be after start time')
  }
  if (end.diff(start, 'hours') > 4) {
    return result(false, 'Maximum duration is 4 hours')
  }
  return result(true, 'passed')
}
