import axios from './axios'

export interface AccountCredit {
  account_id: number
  cloud_storage: number
  created_at: string
  email_count: number
  id: number
  sms_count: number
  updated_at: string
  vault_storage: number
  whatsapp_count: number
}

export interface Customer {
  balance: number
}

export interface UpcomingInvoice {
  amount: number
  date: string
}

export interface Plan {
  created_at: string
  description: string
  device_count: number
  email_count: number
  handler: string
  has_audit_trails: boolean
  has_user_mgmt: boolean
  has_vault: boolean
  id: number
  inbound_log_days: number
  interval: string
  interval_count: number
  label: string
  name: string
  payment_type: string
  price: number
  recording_count: number
  show_counters: number
  sms_count: number
  stripe_price_id: string
  stripe_product_id: string
  third_party_integrations: boolean
  type: string
  updated_at: string
  use_brand_logo: number
  whatsapp_count: number
}

export interface Subscription {
  account_id: 39
  billing_end: string
  billing_end_date: string
  billing_start: string
  billing_start_date: string
  camera_licenses: number
  created_at: string
  deleted_at: string | null
  ends_at: string | null
  handler: string
  id: number
  is_active: number
  is_on_trial: boolean
  payment_mode: string
  plan: Plan | undefined
  plan_id: number
  status: string
  subscription_id: string
  updated_at: string
  used_camera_licenses: number
}

export interface CreditCard {
  brand: string
  last_4: string
}

export interface SubscriptionDetails {
  account_credit: AccountCredit | undefined
  billing_id: string | null
  brand_logo: null
  card: CreditCard | null
  created_at: string
  customer: Customer | undefined
  email: string
  enabled_notifications: number
  id: number
  is_active: number
  is_new_account: boolean
  is_unlimited: number
  name: string
  owner_id: number
  subscription: Subscription | null
  upcoming_invoice: UpcomingInvoice | undefined
  updated_at: string
  use_custom_smtp: number
}

export interface Voucher {
  amount_off: number | null
  camera_licenses: number | null
  code: string | null
  message: string | null
  percent_off: number | null
  plan_id: number | null
}

export const getPlans = () => {
  return axios.get('/api/plans?sort=asc')
}

export const getSubscriptionDetails = () => {
  return axios.get('/api/billing/subscription')
}

export const getVoucherInfo = (coupon: string) => {
  return axios.get(`/api/billing/coupons/${coupon}`)
}

export const getDefaultCreditCard = () => {
  return axios.get('/api/billing/card')
}

export const updateDefaultCreditCard = (token: string) => {
  return axios.post('/api/billing/card', { payment_token: token })
}

export const createSubscription = (data: {
  payment_token: string
  plan_id: number
  camera_licenses: number
  with_trial?: boolean
  coupon?: string
}) => {
  return axios.post('/api/billing/subscription', data)
}

export const updateSubscription = (data: {
  plan_id: number
  camera_licenses: number
}) => {
  return axios.put('/api/billing/subscription', data)
}
