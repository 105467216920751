import { Link } from 'react-router-dom'
import { Button, Form, Input, Select } from 'antd'
import { RightOutlined } from '@ant-design/icons'

import AsyncSelect from 'components/AsyncSelect'
import { getPopupContainer } from 'components/Modal/BaseModal'
import { deviceTypes, manufacturers, timezones } from 'utils/selectOptions'
import { validateIpAddress } from 'utils/validation'
import { getLocations } from 'services/locations'

import { FormValues, rules, mapValuesToOptions } from './FormHelpers'

interface Props {
  initValue: FormValues['deviceDetails']
  onChange: (k: 'deviceDetails', v: FormValues['deviceDetails']) => void
  onStepChange: (num: number) => void
}

function DeviceDetails({ initValue, onChange, onStepChange }: Props) {
  const [form] = Form.useForm()

  const handleGetLocations = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getLocations({
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        search,
        currentPage: page,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleSubmit = (values: FormValues['deviceDetails']) => {
    if (values.ip_address) {
      const validateIpAddressResult = validateIpAddress(values.ip_address)
      if (!validateIpAddressResult.success) {
        return form.setFields([
          { name: 'ip_address', errors: [validateIpAddressResult.message] },
        ])
      }
    }
    onChange('deviceDetails', values)
    onStepChange(1)
  }

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={initValue}
      style={{ padding: '1rem 8px' }}
      onFinish={handleSubmit}
    >
      <Form.Item name='name' label='Device Name' rules={rules}>
        <Input size='large' placeholder='Name your device' />
      </Form.Item>
      <Form.Item name='type' label='Device Type' rules={rules}>
        <Select
          size='large'
          placeholder='What type is it?'
          getPopupContainer={getPopupContainer}
        >
          {deviceTypes.map(type => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item rules={rules} name='timezone' label='Device Timezone'>
        <Select
          size='large'
          placeholder='Choose device timezone'
          getPopupContainer={getPopupContainer}
        >
          {timezones.map(timezone => (
            <Select.Option key={timezone} value={timezone}>
              {timezone}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='manufacturer' label='Manufacturer'>
        <Select
          size='large'
          placeholder='Select manufacturer (optional)'
          getPopupContainer={getPopupContainer}
        >
          {manufacturers.map(manufacturer => (
            <Select.Option key={manufacturer} value={manufacturer}>
              {manufacturer}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='ip_address' label='Device IP Address'>
        <Input size='large' placeholder='Enter device IP address (optional)' />
      </Form.Item>
      <Form.Item
        label='Device Location'
        name='location'
        colon={false}
        extra={
          <span>
            Need to add a location?{' '}
            <Link
              to='/devices?tab=device-location'
              className='text-link text-primary'
            >
              Create new
            </Link>
          </span>
        }
      >
        <AsyncSelect
          size='large'
          labelInValue
          getPopupContainer={getPopupContainer}
          fetcher={handleGetLocations}
          handleSearch={handleGetLocations}
          handleLoadMore={handleGetLocations}
          mapOptions={mapValuesToOptions}
          placeholder='Select device location (optional)'
        />
      </Form.Item>
      <Button size='large' block type='primary' htmlType='submit'>
        Next <RightOutlined />
      </Button>
    </Form>
  )
}

export default DeviceDetails
