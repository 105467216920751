import axios from './axios'
import { Location } from './locations'

export interface Meta {
  codec: null | string
  displaySize: null | string
  frameRate: null | string
  frameSize: null | string
  level: null | string
  profile: null | string
}

export interface DeviceLiveStream {
  audio_codec: null | string
  bitrate: null | number
  created_at: string
  device_id: number
  id: number
  is_recording: boolean
  is_streaming: boolean
  meta: null | Meta
  name: string
  playlist_url: null | string
  playlist_url_expiry_ms: number
  recording_start_timestamp: null | string
  recording_stop_timestamp: null | string
  stream_id: null | string
  streaming_start_timestamp: null | string
  streaming_stop_timestamp: null | string
  updated_at: null | string
  video_codec: null | string
}

export interface Device {
  account_id: number
  bridge_status: any
  code: string
  created_at: string
  deviceLiveStream: null | DeviceLiveStream
  email: string
  enable_recording: number
  id: number
  ip_address: string | null
  is_licensed: number
  location: null | Location
  location_id: number | null
  manufacturer: string | null
  name: string
  password: string | null
  stream_url: null | string
  thumbnail_url: null | string
  timezone: string
  type: string
  username: string | null
}

export interface DeviceSMTP {
  host: null | string
  password: null | string
  port: null | string
  sender_email: null | string
  to_email: null | string
  use_custom: number
  username: null | string
}

export interface CloudStorage {
  cloud_storage_gb: {
    total: null | number
    used: null | number
  }
  estimated_retention_time: string | null
}

export const getDevices = ({
  pageSize = 10,
  sort = 'id',
  sortDirection = 'desc',
  search = '',
  currentPage = 1,
  deviceType = '',
  location = '',
}: {
  pageSize?: number
  sort?: string
  sortDirection?: string
  search?: string
  currentPage?: number
  deviceType?: string
  location?: string
}) => {
  const qr = `/api/devices?include=deviceLiveStream,location&limit=${pageSize}&order_by=${sort}&sort=${sortDirection}&filters[search]=${search}&filters[type]=${deviceType}&filters[location_id]=${location}&page=${currentPage}`
  return axios.get(qr)
}

export const getDeviceDetails = (deviceId: number) => {
  return axios.get(`api/devices/${deviceId}?include=deviceLiveStream,location`)
}

export const getCloudStorage = (deviceId: number) => {
  return axios.get(`api/cards/devices/${deviceId}/cloudStorage`)
}

export const getDeviceSMTP = (deviceId: number) => {
  return axios.get(`/api/cards/devices/${deviceId}/smtpCard`)
}

export const checkStreamUrl = (streamUrl: string) => {
  return axios.post('/api/checkStream', { stream_url: streamUrl })
}

export const deviceCreateWizard = async (data: {
  device: any
  deviceSchedules: any
}) => {
  await axios.get('/api/csrf-cookie')
  return axios.post('/api/deviceCreateWizard', data)
}

export const updateDevice = async (
  deviceId: number,
  device: {
    name: string
    type: string
    manufacturer?: string
    timezone?: string
    location_id?: number
    ip_address?: string
    account_id: number
    stream_url?: string
    is_licensed?: boolean
  },
) => {
  await axios.get('/api/csrf-cookie')
  return axios.put(`api/devices/${deviceId}`, device)
}

export const updateDeviceLicense = async (
  deviceId: number,
  isLicensed: number,
) => {
  await axios.get('/api/csrf-cookie')
  return axios.put(`api/devices/${deviceId}`, {
    is_licensed: isLicensed,
  })
}

export const toggleDeviceRecording = async (
  deviceId: number,
  isRecord: number,
) => {
  await axios.get('/api/csrf-cookie')
  return axios.put(`api/devices/${deviceId}`, {
    enable_recording: isRecord,
  })
}

export const deleteDevice = (deviceId: number) => {
  return axios.delete(`api/devices/${deviceId}`)
}
