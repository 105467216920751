import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Collapse } from 'antd'
import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons'

import {
  BellFilled,
  StorageFilled,
  SupportFilled,
  UserFilled,
  VideoCameraFilled,
} from 'components/Icons'
import { CHARGE_PER_CAM } from 'utils/device'
import { useAppSelector } from 'store'

const defaultActiveKeys = ['video-streaming']

const additionalFeatures = {
  'Live Streaming into Your Website': [
    'Plus',
    'Professional',
    'Enterprise FULL',
  ],
  Timelapse: ['Plus', 'Professional', 'Enterprise FULL'],
  'Custom Recording Schedules': ['Plus', 'Professional', 'Enterprise FULL'],
  'Long Term Video Archival': ['Professional', 'Enterprise FULL'],
  'Phone Support': ['Enterprise FULL'],
  WhatsApp: ['Plus', 'Professional', 'Enterprise FULL'],
}

function PlanFeatures() {
  const [activeKeys, setActiveKeys] = useState<string[]>(defaultActiveKeys)
  const { subscription: sub } = useAppSelector(state => state.subscription)

  const isSupported = (feature: boolean, linkViewUpgrade: boolean = false) => {
    return feature ? (
      <CheckCircleOutlined className='text-success' />
    ) : linkViewUpgrade ? (
      <Link
        to='/settings/payment-settings/plans'
        className='text-500 text-primary'
      >
        Upgrade
      </Link>
    ) : (
      <span className='text-500 text-primary'>Upgrade to use</span>
    )
  }

  const isSupportedWhatsapp = () => {
    if (!sub || !sub.subscription || !sub.subscription.plan) {
      return null
    }
    const planName = sub!.subscription!.plan!.label
    // @ts-ignore
    const isSupported = !!additionalFeatures['WhatsApp']?.includes(planName)

    if (!isSupported) {
      return (
        <Link
          to='/settings/payment-settings/plans'
          className='text-500 text-primary'
        >
          Upgrade
        </Link>
      )
    }
    if (isSupported) {
      return <span>{sub.subscription?.plan?.whatsapp_count || 0}</span>
    }
    return <span className='text-placeholder'>Coming soon</span>
  }

  if (!sub) {
    return null
  }

  const basePlanPrice = (sub.subscription?.plan?.price || 0) / 100
  const totalCamPrice =
    (sub.subscription?.camera_licenses || 0) * CHARGE_PER_CAM
  const totalPrice = basePlanPrice + totalCamPrice

  return (
    <div className='plan-features'>
      <div className='plan-includes'>
        <h3>YOUR PLAN INCLUDES</h3>
        <div>
          <span className='text-placeholder'>Base Plan</span>
          <span>${basePlanPrice}/month</span>
        </div>
        <div>
          <span className='text-placeholder'>IP Camera Licenses</span>
          <span>
            {sub.subscription?.camera_licenses || 0} licenses x $
            {CHARGE_PER_CAM} = ${totalCamPrice}/month
          </span>
        </div>
        <div>
          <span className='text-placeholder'>Total</span>
          <span>${totalPrice}/month</span>
        </div>
      </div>
      <Collapse
        activeKey={activeKeys}
        onChange={keys => setActiveKeys(keys as string[])}
        expandIconPosition='right'
        expandIcon={({ isActive }) => (
          <DownOutlined rotate={isActive ? -180 : 0} />
        )}
      >
        <Collapse.Panel
          key='video-streaming'
          header={
            <span className='feature-header'>
              <VideoCameraFilled type='primary' /> Video Streaming
            </span>
          }
        >
          <div className='feature'>
            <span>IP Cameras Licenses</span>
            <span>{sub.subscription?.camera_licenses || 0}</span>
          </div>
          <div className='feature'>
            <span>Devices</span>
            <span>{sub.subscription?.plan?.device_count || 0}</span>
          </div>
          <div className='feature'>
            <span>Livestream</span>
            {isSupported(!!sub.subscription?.plan?.device_count)}
          </div>
          <div className='feature'>
            <span>Video Sharing</span>
            {isSupported(!!sub.subscription?.plan?.has_vault, true)}
          </div>
          <div className='feature'>
            <span>Concierge Device Provisioning</span>
            <a
              className='text-500 text-primary'
              href='https://broadflow.co/contact'
              target='_blank'
              rel='noreferrer'
            >
              Contact us
            </a>
          </div>
          <div className='feature'>
            <span>Live Streaming into Your Website</span>
            <span className='text-placeholder'>Coming soon</span>
          </div>
          <div className='feature'>
            <span>Timelapse</span>
            <span className='text-placeholder'>Coming soon</span>
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key='notification'
          header={
            <span className='feature-header'>
              <BellFilled type='primary' fillId={['bell1', 'bell2']} />
              Notification
            </span>
          }
        >
          <div className='feature'>
            <span>Notification Retention Days</span>
            <span>{sub.subscription?.plan?.inbound_log_days || 0}</span>
          </div>
          <div className='feature'>
            <span>Email Notifications</span>
            <span>{sub.subscription?.plan?.email_count || 0}</span>
          </div>
          <div className='feature'>
            <span>SMS Notifications</span>
            <span>{sub.subscription?.plan?.sms_count || 0}</span>
          </div>
          <div className='feature'>
            <span>WhatsApp Notifications</span>
            {isSupportedWhatsapp()}
          </div>
          <div className='feature'>
            <span>Multiple Schedules</span>
            {isSupported(true)}
          </div>
          <div className='feature'>
            <span>Webhook Notifications</span>
            {isSupported(
              !!sub.subscription?.plan?.third_party_integrations,
              true,
            )}
          </div>
          <div className='feature'>
            <span>Slack Notifications</span>
            {isSupported(
              !!sub.subscription?.plan?.third_party_integrations,
              true,
            )}
          </div>
          <div className='feature'>
            <span>Microsoft Teams Notifications</span>
            {isSupported(
              !!sub.subscription?.plan?.third_party_integrations,
              true,
            )}
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key='video-storage'
          header={
            <span className='feature-header'>
              <StorageFilled type='primary' /> Video Storage
            </span>
          }
        >
          <div className='feature'>
            <span>Inbound Log Days</span>
            <span>{sub.subscription?.plan?.inbound_log_days || 0}</span>
          </div>
          <div className='feature'>
            <span>Cloud Recording</span>
            {isSupported(!!sub.subscription?.camera_licenses, true)}
          </div>
          <div className='feature'>
            <span>Video Vault</span>
            {isSupported(!!sub.subscription?.plan?.has_vault, true)}
          </div>
          <div className='feature'>
            <span>Custom Recording Schedules</span>
            <span className='text-placeholder'>Coming soon</span>
          </div>
          <div className='feature'>
            <span>Long Term Video Archival</span>
            <span className='text-placeholder'>Coming soon</span>
          </div>
          <div className='feature'>
            <span>Cloud Storage</span>
            <span>
              {sub.subscription?.camera_licenses
                ? `${sub.subscription.camera_licenses * 100}GB`
                : 0}
            </span>
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key='management'
          header={
            <span className='feature-header'>
              <UserFilled type='primary' /> Management
            </span>
          }
        >
          <div className='feature'>
            <span>User Management</span>
            {isSupported(!!sub.subscription?.plan?.has_user_mgmt, true)}
          </div>
          <div className='feature'>
            <span>Activity Log</span>
            {isSupported(!!sub.subscription?.plan?.has_audit_trails, true)}
          </div>
          <div className='feature'>
            <span>Custom Branding</span>
            {isSupported(!!sub.subscription?.plan?.use_brand_logo, true)}
          </div>
        </Collapse.Panel>
        <Collapse.Panel
          key='support'
          header={
            <span className='feature-header'>
              <SupportFilled type='primary' /> Support
            </span>
          }
        >
          <div className='feature'>
            <span>Email Support</span>
            {isSupported(true)}
          </div>
          <div className='feature'>
            <span>Live Chat Support</span>
            {isSupported(true)}
          </div>
          <div className='feature'>
            <span>Phone Support</span>
            <a
              className='text-500 text-primary'
              href='https://broadflow.co/contact'
              target='_blank'
              rel='noreferrer'
            >
              Contact us
            </a>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

export default PlanFeatures
