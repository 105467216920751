import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import { Video } from 'components/Icons'

interface Props {
  src?: string
  alt?: string
  className?: string
  rounded?: boolean
}

export function Thumbnail({ src, alt, className, rounded }: Props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const imageRef = useRef<HTMLImageElement | null>(null)

  useEffect(() => {
    if (!src) {
      return
    }
    const img = document.createElement('img')
    img.src = src
    img.onload = onImageLoad
    img.onerror = onImageError
    // eslint-disable-next-line
  }, [src])

  const onImageLoad = () => {
    if (imageRef.current && src) {
      imageRef.current.src = src
      setError(false)
      setLoading(false)
    }
  }

  const onImageError = () => {
    setError(true)
    setLoading(false)
  }

  return (
    <div className={cx('thumbnail-wrapper', { rounded }, className)}>
      {!!src && !error && (
        <img ref={imageRef} alt={alt || 'image'} className={cx({ loading })} />
      )}
      {(!src || error) && (
        <div className='placeholder'>
          <Video />
        </div>
      )}
    </div>
  )
}
