const tagColors = [
  {
    strokeColor: 'linear-gradient(180deg, #A49FFF 0%, #9F96FF 100%)',
    textColor: '#16104F',
    color: 'linear-gradient(180deg, #DFE0FF 0.01%, #C5BBFF 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #FFB1F7 0%, #FFACFC 100%)',
    textColor: '#34072F',
    color: 'linear-gradient(180deg, #FFEDFD 0.01%, #FFCBFA 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #FFABAB 0%, #FF9B9B 100%)',
    textColor: '#350303',
    color: 'linear-gradient(180deg, #FFCDCD 0%, #FFDFDF 0.01%, #FFC4C4 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #ECC699 0%, #E4A962 100%)',
    textColor: '#402608',
    color: 'linear-gradient(180deg, #FFECD6 0%, #FFC178 100%, #FFCD93 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #FFDE98 0%, #E9BA55 100%)',
    textColor: '#482E06',
    color: 'linear-gradient(180deg, #FFFACE 0%, #FFF598 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #94E4FF 0%, #55D5FF 100%)',
    textColor: '#074154',
    color: 'linear-gradient(180deg, #DEF7FF 0%, #C2F0FF 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #88FFF8 0%, #4EE0C6 100%)',
    textColor: '#402608',
    color: 'linear-gradient(180deg, #E8FFF7 0%, #A0FFE8 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #8AF88F 0%, #7FEA76 100%)',
    textColor: '#0E3D0A',
    color: 'linear-gradient(180deg, #E6FFE6 0.01%, #CCFFBB 100%, #C2FFAE 100%)',
  },
  {
    strokeColor: 'linear-gradient(180deg, #DCDCDC 0%, #B5B5B5 100%)',
    textColor: '#303030',
    color: 'linear-gradient(180deg, #FBFBFB 0.01%, #E2E2E2 100%)',
  },
]

export const DEFAULT_TAG_COLOR = tagColors[0]

export default tagColors
