import * as AntdIcons from '@ant-design/icons'
import * as Icons from 'components/Icons'

export interface MenuItem {
  key: string
  label: string
  navLink?: string
  externalLink?: string
  Icon: (props: Icons.IconProps) => JSX.Element | any
  type?: string
}

export const menu: MenuItem[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    navLink: '/',
    Icon: Icons.Dashboard,
  },
  {
    key: 'livestream',
    label: 'Livestream',
    navLink: '/livestream',
    Icon: Icons.Livestream,
  },
  {
    key: 'playback',
    label: 'Playback',
    navLink: '/playback',
    Icon: Icons.Clock,
  },
  {
    key: 'notifications',
    label: 'Notifications',
    navLink: '/system-notifications',
    Icon: Icons.Alert,
  },
  {
    key: 'menu',
    label: 'Menu',
    navLink: '/menu',
    Icon: Icons.Burger,
  },
]

export const fullPageMenu: { title: string; children: MenuItem[] }[] = [
  {
    title: 'Device Management',
    children: [
      {
        key: 'all-devices',
        label: 'All Devices',
        navLink: '/devices?tab=device-list',
        Icon: Icons.VideoCameraFilled,
        type: 'primary',
      },
      {
        key: 'device-location',
        label: 'Device Location',
        navLink: '/devices?tab=device-location',
        Icon: Icons.Location,
        type: 'primary',
      },
    ],
  },
  {
    title: 'Notification Management',
    children: [
      {
        key: 'device-notifications',
        label: 'Device Notifications',
        navLink: '/notifications?tab=all-device-notification',
        Icon: Icons.BellFilled,
        type: 'primary',
      },
      {
        key: 'notification-tags',
        label: 'Notification Tags',
        navLink: '/notifications?tab=notification-tag',
        Icon: Icons.TagFilled,
        type: 'primary',
      },
    ],
  },
  {
    title: 'Cloud Storage',
    children: [
      {
        key: 'video-search',
        label: 'Video Search',
        navLink: '/cloud-storage?tab=video-search',
        Icon: AntdIcons.SearchOutlined,
        type: 'primary',
      },
      {
        key: 'my-vault',
        label: 'My Vault',
        navLink: '/cloud-storage?tab=my-vault',
        Icon: Icons.StorageFilled,
        type: 'primary',
      },
    ],
  },
  {
    title: 'Users & Recipients',
    children: [
      {
        key: 'all-users',
        label: 'All Users',
        navLink: '/users-recipients?tab=user-management',
        Icon: Icons.UserFilled,
        type: 'primary',
      },
      {
        key: 'all-recipients',
        label: 'All Recipients',
        navLink: '/users-recipients?tab=recipient-management',
        Icon: Icons.UserFilled,
        type: 'primary',
      },
      {
        key: 'activity-log',
        label: 'Activity Log',
        navLink: '/activity-logs',
        Icon: Icons.UnorderedList,
        type: 'primary',
      },
    ],
  },
  {
    title: 'Settings',
    children: [
      {
        key: 'general',
        label: 'General',
        navLink: '/settings/general',
        Icon: Icons.SettingsFilled,
        type: 'primary',
      },
      {
        key: 'security-login',
        label: 'Security & Login',
        navLink: '/settings/security-login',
        Icon: Icons.ShieldFilled,
        type: 'primary',
      },
      {
        key: 'payment-settings',
        label: 'Payment Settings',
        navLink: '/settings/payment-settings',
        Icon: Icons.CreditCardFilled,
        type: 'primary',
      },
      {
        key: 'notifications',
        label: 'Notifications',
        navLink: '/settings/notifications',
        Icon: Icons.BellFilled,
        type: 'primary',
      },
    ],
  },
  {
    title: 'Other',
    children: [
      {
        key: 'transaction-history',
        label: 'Transaction History',
        navLink: '/transaction-history',
        Icon: Icons.CreditCardFilled,
        type: 'secondary',
      },
      {
        key: 'contact-us',
        label: 'Contact Us',
        externalLink: 'https://broadflow.co/contact',
        Icon: Icons.EmailFilled,
        type: 'secondary',
      },
      {
        key: 'help-center',
        label: 'Help Center',
        externalLink: 'https://broadflow.co/support',
        Icon: Icons.Help,
        type: 'secondary',
      },
    ],
  },
]
