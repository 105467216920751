import { useEffect, useState } from 'react'
import screenfull, { Screenfull } from 'screenfull'

export const isEnabled = screenfull.isEnabled
const m = screenfull as Screenfull

export function useFullscreen() {
  const [isFullscreen, setIsFullscreen] = useState(m.isFullscreen)

  useEffect(() => {
    if (isEnabled) m.on('change', updater)
    return () => {
      isEnabled && m.off('change', updater)
    }
    // eslint-disable-next-line
  }, [])

  const updater = () => {
    setIsFullscreen(m.isFullscreen)
  }

  const toggleFullscreen = (el?: Element, callback?: () => void) => {
    if (isEnabled) {
      m.toggle(el).then(callback)
    } else {
      console.log('Not supported')
    }
  }

  const requestFullscreen = (el?: Element, callback?: () => void) => {
    if (isEnabled) {
      m.request(el).then(callback).catch(console.error)
    } else {
      console.log('Not supported')
    }
  }

  const exitFullscreen = (callback?: () => void) => {
    m.exit()
    callback?.()
  }

  return {
    isEnabled,
    isFullscreen,
    requestFullscreen,
    exitFullscreen,
    toggleFullscreen,
  }
}
