import React from 'react'
import { Button, Form, Input } from 'antd'

import { ChatCircle, InfoCircle } from 'components/Icons'
import PlanInfoCard from 'components/Cards/PlanInfoCard'
import { useAppSelector } from 'store'

import { FormValues } from './FormHelpers'

interface Props {
  loading: boolean
  initValue: FormValues['cloudSurveillance']
  onSubmit: (v?: FormValues['cloudSurveillance']) => void
}

function CloudSurveillance({ loading, initValue, onSubmit }: Props) {
  const [form] = Form.useForm()

  const { camera_licenses } = useAppSelector(state => state.stats.stats!)
  const remaining = (camera_licenses.total || 0) - (camera_licenses.used || 0)

  return (
    <React.Fragment>
      <PlanInfoCard showUpgrade>
        {!remaining ? (
          <React.Fragment>
            You do not currently have any{' '}
            <span className='text-bold'>cloud surveillance licenses</span>. A
            license is required in order to record and livestream your camera.
          </React.Fragment>
        ) : (
          <React.Fragment>
            You have{' '}
            <span className='text-bold'>
              {remaining} cloud surveillance licenses
            </span>{' '}
            remaining
          </React.Fragment>
        )}
      </PlanInfoCard>
      <Form
        form={form}
        layout='vertical'
        initialValues={initValue}
        style={{ padding: '1rem 8px' }}
        onFinish={v => onSubmit(v)}
      >
        {!!remaining && (
          <React.Fragment>
            <Form.Item name='stream_url' label='RTSP Link'>
              <Input
                size='large'
                placeholder='Enter device RTSP link then click check'
              />
            </Form.Item>
            <div className='help-info'>
              <InfoCircle color='#808080' />
              <span>
                RTSP alllow this device record & livestream.{' '}
                <a
                  className='text-placeholder text-500 text-underline'
                  href='/'
                >
                  See how to get device RTSP Link
                </a>
              </span>
            </div>
            <div className='help-info' style={{ marginBottom: '1.5rem' }}>
              <ChatCircle style={{ transform: 'scale(1.1)' }} type='primary' />
              <span className='text-primary text-500 hoverable'>
                Chat with technical support
              </span>
            </div>
          </React.Fragment>
        )}
        {!!remaining && (
          <Button
            disabled={loading}
            onClick={loading ? undefined : form.submit}
            style={{ marginBottom: '.75rem' }}
            type='primary'
            block
            size='large'
          >
            Save
          </Button>
        )}
        <Button
          disabled={loading}
          onClick={loading ? undefined : form.submit}
          block
          size='large'
          className='ant-btn-secondary'
        >
          Skip & Save
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default CloudSurveillance
