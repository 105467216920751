import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function TicketFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='13'
        viewBox='0 0 16 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M16 4.89999V1.69997C16 0.815983 15.284 0.0999584 14.4 0.0999584H1.60001C0.716024 0.0999584 0.00802499 0.815945 0.00802499 1.69997V4.89995C0.888037 4.90397 1.60001 5.61995 1.60001 6.49997C1.60001 7.38395 0.884024 8.09998 0.0040125 8.09998L0 11.3C0 12.184 0.715987 12.9 1.60001 12.9H14.4C15.284 12.9 16 12.184 16 11.3V8.09998C15.116 8.09998 14.4 7.38399 14.4 6.49997C14.4 5.61594 15.116 4.89999 16 4.89999ZM8.80001 10.9H7.19999V9.29998H8.80001V10.9ZM8.80001 7.29999H7.19999V5.69998H8.80001V7.29999ZM8.80001 3.69999H7.19999V2.09998H8.80001V3.69999Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
