import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button } from 'antd'
import { CopyOutlined, SyncOutlined } from '@ant-design/icons'

import Authy from 'assets/brand-logos/authy.svg'
import GoogleAuthenticator from 'assets/brand-logos/google-authenticator.svg'
import MicrosoftAuthenticator from 'assets/brand-logos/microsoft-authenticator.svg'

import {
  getQrCode,
  getRecoveryCodes,
  QRCode,
  RecoveryCodes,
  regenRecoveryCodes,
} from 'services/2fa'
import { Spinner } from 'components/Shared'
import ModalVerifyPassword from 'components/Modal/VerifyPassword'
import { copyToClipBoard } from 'utils/functions'
import { notiSuccess } from 'utils/notification'

function RecoveryCodeInfo() {
  const [loading, setLoading] = useState(true)
  const [regenLoading, setRegenLoading] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const [recoveryCodes, setRecoveryCodes] = useState<RecoveryCodes>([])
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)

  useEffect(() => {
    getQrAndCodes()
    // eslint-disable-next-line
  }, [])

  const getQrAndCodes = async () => {
    try {
      setLoading(true)
      const [qr, codes] = await Promise.all([getQrCode(), getRecoveryCodes()])
      setQrCode((qr.data as QRCode).svg)
      setRecoveryCodes(codes.data as RecoveryCodes)
    } catch (err: any) {
      if (err.status === 423) {
        setShowVerifyPassword(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleCopyCode = (str?: string) => {
    copyToClipBoard(
      str || recoveryCodes.reduce((acc, cur) => (acc += cur + '\n'), ''),
    )
    notiSuccess({ message: 'Copied to clipboard' })
  }

  const handleRegenCodes = async () => {
    try {
      setRegenLoading(true)
      await regenRecoveryCodes()
      const response = await getRecoveryCodes()
      setRecoveryCodes(response.data as RecoveryCodes)
    } catch (err: any) {
      if (err.status === 423) {
        setShowVerifyPassword(true)
      }
    } finally {
      setRegenLoading(false)
    }
  }

  if (loading) {
    return (
      <div className='recovery-code-info'>
        <Spinner />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className='recovery-code-info'>
        <div className='qr-code'>
          <h3>
            Scan the following QR code using your phone's authenticator
            application.
          </h3>
          <div
            className='auth-qr-code'
            dangerouslySetInnerHTML={{ __html: qrCode }}
          />
        </div>
        <hr className='divider' style={{ margin: '2rem 0' }} />
        <div className='auth-apps'>
          <h3>Don't have an authenticator app?</h3>
          <span className='text-placeholder'>
            The following authenticator apps are available for both iOS and
            Android. Download one and use it to scan the QR code above.
          </span>
          <div className='auth-app-list'>
            <div>
              <img src={GoogleAuthenticator} alt='Google Authenticator' />
              Google Authenticator
            </div>
            <div>
              <img src={MicrosoftAuthenticator} alt='Microsoft Authenticator' />
              Microsoft Authenticator
            </div>
            <div>
              <img src={Authy} alt='Authy' /> Authy
            </div>
          </div>
          <div className='apps-note text-success'>
            Note: other authenticator also work as well
          </div>
        </div>
        <hr className='divider' style={{ margin: '2rem 0' }} />
        <div className='recovery-codes'>
          <h3>
            Store these recovery codes in a safe place or secure password
            manager.
          </h3>
          <span className='text-placeholder'>
            They can be used to recover access to your account if your two
            factor authentication device is lost.
          </span>
          <div className='recovery-code-actions'>
            <Button
              size='large'
              className='ant-btn-secondary'
              onClick={() => handleCopyCode()}
            >
              <CopyOutlined /> Copy All
            </Button>
            <Button
              size='large'
              disabled={regenLoading}
              className='ant-btn-secondary'
              onClick={handleRegenCodes}
            >
              <SyncOutlined /> Regenerate Recovery Code
            </Button>
          </div>
          <div className='recovery-code-list'>
            {recoveryCodes.map(code => (
              <div key={code}>
                {code}{' '}
                <span
                  className='text-primary text-500'
                  onClick={() => handleCopyCode(code)}
                >
                  Copy
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showVerifyPassword && (
          <ModalVerifyPassword onCancel={() => setShowVerifyPassword(false)} />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default RecoveryCodeInfo
