import { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Button } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import './index.scss'
import history from 'configs/history'
import { Spinner } from 'components/Shared'
import { verifyRecipientEmail } from 'services/recipients'

type RouteParams = {
  id: string
  hash: string
}

function VerifyRecipientEmail() {
  const params: RouteParams = useParams()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    handleVerifyRecipientEmail()
    // eslint-disable-next-line
  }, [])

  const handleVerifyRecipientEmail = async () => {
    try {
      await verifyRecipientEmail({ id: params.id, hash: params.hash + search })
      setSuccess(true)
    } catch (e) {
      setSuccess(false)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner height='100vh' />
  }

  return (
    <div className='verify-recipient-email'>
      <div className='icon'>
        {success ? (
          <CheckCircleOutlined className='success' />
        ) : (
          <CloseCircleOutlined className='failed' />
        )}
      </div>
      <h1 className='title'>
        {success ? 'Congratulation!' : 'Failed to Verify'}
      </h1>
      <span className='subtitle text-placeholder'>
        {success
          ? "Recipient's email was successfully verified"
          : 'Please re-check your verification link'}
      </span>
      {!success && (
        <Button
          block
          type='primary'
          size='large'
          onClick={() => history.push('/')}
        >
          Back to Dashboard
        </Button>
      )}
    </div>
  )
}

export default VerifyRecipientEmail
