import React from 'react'

import { CameraFilled, InfoCircle } from 'components/Icons'
import Table from 'components/Table'
import { Tag } from 'services/tags'
import { DeviceInfo } from 'components/Shared'

interface Props {
  tag: Tag
}

function AffectedDevices({ tag }: Props) {
  if (tag.apply_to_all) {
    return (
      <div style={{ padding: '1rem 8px' }} className='detail-title'>
        <InfoCircle color='#808080' />
        <h3
          style={{ fontSize: '0.875rem' }}
          className='text-placeholder text-400'
        >
          This tag is currently applied to all devices
        </h3>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div style={{ margin: '1rem 8px 8px 8px' }} className='detail-title'>
        <CameraFilled type='primary' />
        <h3>Device affected by this tag</h3>
      </div>
      <Table
        className='table-affected-devices'
        empty={!(tag.devices || []).length}
      >
        {(tag.devices || []).map(t => (
          <Table.Row key={t.id} rowKey={t.id}>
            <Table.Col>
              <DeviceInfo status='default' device={t} />
              <div className='device-type'>
                <span>Device type</span>
                <span>{t.type}</span>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
    </React.Fragment>
  )
}

export default AffectedDevices
