import { useState } from 'react'
import cx from 'classnames'
import { Input } from 'antd'
import { SearchOutlined, CheckOutlined } from '@ant-design/icons'

import './index.scss'
import Modal from 'components/Modal/BaseModal'
import { Layout } from 'components/Shared'

interface Props {
  current: number
  total: number
  onPageChange: (page: number) => void
  onCancel: () => void
}

function SwitchPage({ current, total, onPageChange, onCancel }: Props) {
  const [page, setPage] = useState('')
  const pages = Array.from({ length: total }).map((_, idx) => idx + 1)

  const handlePageChange = (newPage?: number) => {
    const p = newPage || +page
    if (pages.includes(p) && p !== current) {
      onPageChange(p)
      onCancel()
    }
  }

  return (
    <Modal
      noPadding
      height='100vh'
      className='modal-switch-page'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header sticky bordered goBack onGoBack={onCancel}>
          Switch Page
        </Layout.Header>
        <Layout.Body>
          <Input
            type='number'
            size='large'
            placeholder='Enter page number to go'
            suffix={<SearchOutlined />}
            value={page}
            onChange={e => setPage(e.target.value)}
            onKeyDown={e =>
              e.key === 'Enter' ? handlePageChange() : undefined
            }
          />
          <ul className='page-list'>
            {pages.map(p => (
              <li
                key={p}
                className={cx({ active: p === current })}
                onClick={() => handlePageChange(p)}
              >
                <span>Page {p}</span>
                {p === current && <CheckOutlined />}
              </li>
            ))}
          </ul>
        </Layout.Body>
      </Layout>
    </Modal>
  )
}

export default SwitchPage
