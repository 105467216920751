import React from 'react'
import moment from 'moment'
import { CheckCircleFilled } from '@ant-design/icons'

import Note from 'assets/images/note.svg'
import { Quote } from 'components/Icons'
import { Avatar } from 'components/Shared'
import { MM_DD_YYYY_HH_MM_P } from 'utils/dateTime'
import { Note as INote } from 'services/notes'

interface Props {
  previewMode: boolean
  notifNotes: INote[]
}

function NotifNote({ previewMode, notifNotes }: Props) {
  return (
    <React.Fragment>
      <div className='title-group'>
        <div>
          <img src={Note} alt='note' />
          <span className='text-500'>Note</span>
        </div>
      </div>
      {!previewMode &&
        notifNotes.map(({ id, created_by, body, notify, created_at }) => (
          <div key={id} className='notif-note'>
            <Avatar
              src={created_by.avatar_url || undefined}
              username={created_by.name}
            />
            <div className='note-content'>
              <div>
                <div className='username'>{created_by.name}</div>
                <div className='body'>{body}</div>
              </div>
              <div className='text-placeholder'>
                {!!notify && (
                  <CheckCircleFilled
                    style={{ fontSize: 14 }}
                    className='text-success'
                  />
                )}
                {moment(created_at).format(MM_DD_YYYY_HH_MM_P)}
              </div>
            </div>
          </div>
        ))}
      {previewMode &&
        notifNotes.map(({ id, body, created_at, created_by }) => (
          <div key={id} className='preview-notif-note'>
            <div className='text-placeholder'>
              <span className='text-500'>{created_by.name}</span> added a note
              at{' '}
              <span className='text-500'>
                {moment(created_at).format(MM_DD_YYYY_HH_MM_P)}
              </span>
            </div>
            <div className='body'>
              <Quote style={{ marginRight: 4 }} /> {body}
            </div>
          </div>
        ))}
    </React.Fragment>
  )
}

export default NotifNote
