import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { CurrentUser } from 'services/auth'
import { useAppSelector } from 'store'

interface RestrictedRoute {
  [key: string]: {
    when: string
    title: string
    desc: string
  }
}

export const restrictedRoutes: RestrictedRoute = {
  '/activity-logs': {
    when: 'can_use_activity_log',
    title: 'Upgrade your plan to access your Activity Log',
    desc: 'The Activity Log is used to monitor all system and user actions',
  },
  '/cloud-storage?tab=video-search': {
    when: 'can_access_vault',
    title: 'Upgrade your plan to access Cloud Storage',
    desc: '100GB of Cloud Storage is included with every camera license',
  },
  '/cloud-storage?tab=my-vault': {
    when: 'can_access_vault',
    title: 'Upgrade your plan to access the Vault',
    desc: 'Use the Vault to permanently store video clips for future use',
  },
  '/users-recipients?tab=user-management': {
    when: 'can_use_user_mgmt',
    title: 'Upgrade your plan to access User Management',
    desc: 'Create separate users to keep track of changes and protect sensitive settings',
  },
}

function findRestrictedRoute(
  routePath: string,
  access?: CurrentUser['account']['access'],
) {
  const restrictedRoute = restrictedRoutes[routePath]
  if (!restrictedRoute || isEmpty(access) || !access) {
    return null
  }
  const when = restrictedRoute.when as keyof CurrentUser['account']['access']
  if (!access[when]) {
    return restrictedRoute
  }
  return null
}

export function useRestrictRoute() {
  const { pathname, search } = useLocation()
  const { currentUser } = useAppSelector(state => state.auth)

  const restrictedRoute = findRestrictedRoute(
    pathname + search,
    currentUser?.account.access,
  )

  return {
    canAccess: !restrictedRoute,
    message: !!restrictedRoute
      ? {
          title: restrictedRoute.title,
          desc: restrictedRoute.desc,
        }
      : undefined,
  }
}
