import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function VideoCameraFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='15'
        viewBox='0 0 24 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M15.0106 0.5H1.61458C0.723199 0.501051 0.00105116 1.2232 0 2.11458V12.7397C0.00105116 13.6311 0.723199 14.3533 1.61458 14.3543H15.0106C15.9018 14.3533 16.6241 13.6311 16.6252 12.7397V2.11458C16.6241 1.2232 15.9018 0.501051 15.0106 0.5Z'
          fill='url(#video_camera_filled0_linear)'
        />
        <path
          d='M17.7015 9.63861L23.25 12.6678V2.22119L17.7015 5.25043V9.63861Z'
          fill='url(#video_camera_filled1_linear)'
        />
        <defs>
          <linearGradient
            id='video_camera_filled0_linear'
            x1='0'
            y1='0.5'
            x2='0'
            y2='14.3543'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='video_camera_filled1_linear'
            x1='17.7015'
            y1='2.22119'
            x2='17.7015'
            y2='12.6678'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
