import { EyeFilled, FileTextFilled } from 'components/Icons'

import './index.scss'
import { Layout } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'

interface Props {
  onPreview: () => void
  onAddNote: () => void
  onCancel: () => void
}

function ModalNotifMoreOptions({ onPreview, onAddNote, onCancel }: Props) {
  const handleClick = (fn: Function) => () => {
    fn()
    onCancel()
  }

  return (
    <BaseModal
      noPadding
      className='modal-notif-more-options'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header bordered rounded>
          More Options
        </Layout.Header>
        <Layout.Body>
          <div className='option' onClick={handleClick(onPreview)}>
            <EyeFilled style={{ transform: 'scale(1.2)' }} />
            <span>Preview</span>
          </div>
          <div className='option' onClick={handleClick(onAddNote)}>
            <FileTextFilled />
            <span>Add Note</span>
          </div>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalNotifMoreOptions
