import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button } from 'antd'
import { DeleteFilled } from '@ant-design/icons'

import { Location as IconLocation } from 'components/Icons'
import Table from 'components/Table'
import ModalLocationDetail from 'components/Modal/Location/LocationDetail'
import ModalCreateUpdateLocation from 'components/Modal/Location/CreateUpdateLocation'
import ModalConfirm from 'components/Modal/ModalConfirm'
import { notiError } from 'utils/notification'
import { Location, getLocations, deleteLocation } from 'services/locations'

import { useLocationContext } from './DeviceLocationContext'

interface Props {
  addLocation: boolean
  setAddLocation: (state: boolean) => void
}

function TableList({ addLocation, setAddLocation }: Props) {
  const { loading, locations, currentPage, search, dispatch } =
    useLocationContext()

  const [viewLocation, setViewLocation] = useState<Location | null>(null)
  const [updateLocation, setUpdateLocation] = useState<Location | null>(null)
  const [deleteLocationId, setDeleteLocationId] = useState<number | null>(null)

  useEffect(() => {
    handleGetLocations()
    // eslint-disable-next-line
  }, [search, currentPage])

  const handleGetLocations = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const response = await getLocations({
        currentPage,
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        search,
      })
      dispatch({
        type: 'SET_LOCATIONS',
        payload: response.data,
      })
    } catch {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleDeleteLocation = async () => {
    if (!deleteLocationId) return
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      await deleteLocation(deleteLocationId)
      if (locations.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetLocations()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = (location: Location) => (
    <React.Fragment>
      <Button
        className='ant-btn-ternary'
        onClick={() => setViewLocation(location)}
      >
        View
      </Button>
      <Button
        className='ant-btn-ternary'
        onClick={() => setUpdateLocation(location)}
      >
        Edit
      </Button>
      <Button
        className='ant-btn-ternary text-danger'
        onClick={() => setDeleteLocationId(location.id)}
      >
        Delete
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        loading={loading}
        empty={!locations.length}
        className='table-location'
      >
        {locations.map((l: Location) => (
          <Table.Row key={l.id} rowKey={l.id} rowActions={renderRowActions(l)}>
            <Table.Col>
              <div className='location-name'>
                <IconLocation />
                <span className='text-500'>{l.name}</span>
              </div>
              <div className='zipcode'>
                <span>Zip Code</span>
                <span>{l.zipcode || '---'}</span>
              </div>
              <div className='device-count'>
                <span>No. of devices in this location</span>
                <span>{l.devices_count}</span>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {viewLocation && (
          <ModalLocationDetail
            location={viewLocation}
            onCancel={() => setViewLocation(null)}
          />
        )}
        {deleteLocationId && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this location?'
            description='All devices belonging to this location will be move to “Unknown”'
            onConfirm={handleDeleteLocation}
            onCancel={() => setDeleteLocationId(null)}
          />
        )}
        {(addLocation || !!updateLocation) && (
          <ModalCreateUpdateLocation
            location={updateLocation || undefined}
            onSuccess={handleGetLocations}
            onCancel={() => {
              setUpdateLocation(null)
              setAddLocation(false)
            }}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
