import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Badge, Button } from 'antd'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'

import Pagination from 'components/Pagination'
import ModalSearch from 'components/Modal/ModalSearch'
import ModalFilterActivityLogs from 'components/Modal/QuickFilters/FilterDeviceActivityLogs'
import { useLogContext } from './LogContext'

function TableListHeader() {
  const { loading, currentPage, totalPage, dateRange, search, dispatch } =
    useLogContext()

  const [showSearch, setShowSearch] = useState(false)
  const [showFilter, setShowFilter] = useState(false)

  const handlePageChange = (newPage: number) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: newPage })
  }

  const handleFilter = (filters: { dateRange: string[] }) => {
    dispatch({ type: 'SET_FILTER', payload: filters.dateRange })
  }

  const handleSearch = (value: string) => {
    if (value !== search) {
      dispatch({ type: 'SET_SEARCH', payload: value })
    }
  }

  return (
    <React.Fragment>
      <div
        className='table-list-header sticky'
        style={{ borderBottomWidth: 1 }}
      >
        <div className='left'>
          <Pagination
            disabled={loading}
            current={currentPage}
            total={totalPage}
            onPageChange={handlePageChange}
          />
        </div>
        <div className='right'>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowFilter(true)}
          >
            <Badge dot={!!dateRange.length}>
              <FilterOutlined />
            </Badge>
          </Button>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowSearch(true)}
          >
            <Badge dot={!!search}>
              <SearchOutlined />
            </Badge>
          </Button>
        </div>
      </div>
      <AnimatePresence>
        {showFilter && (
          <ModalFilterActivityLogs
            dateRange={dateRange}
            onChange={handleFilter}
            onCancel={() => setShowFilter(false)}
          />
        )}
        {showSearch && (
          <ModalSearch
            title='Search Activities'
            placeholder='Search activities'
            value={search}
            onSearch={handleSearch}
            onCancel={() => setShowSearch(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableListHeader
