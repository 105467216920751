import { useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Button } from 'antd'

import './index.scss'
import EmailNotif from 'assets/images/email-notif.svg'
import { Layout } from 'components/Shared'
import { resendVerify } from 'services/auth'
import { useAppSelector } from 'store'

import LinkExpired from './LinkExpired'
import { notiError, notiSuccess } from 'utils/notification'

function ResendVerifyLink() {
  const { currentUser } = useAppSelector(state => state.auth)

  if (!currentUser) {
    return <Redirect to='/login' />
  }
  if (currentUser!.email_verified_at) {
    return <LinkExpired />
  }

  return <ResendLink />
}

function ResendLink() {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAppSelector(state => state.auth)

  const handleResend = async () => {
    try {
      setLoading(true)
      await resendVerify()
      notiSuccess({ message: 'Verify email link sent' })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout className='resend-verify-page verify-message'>
      <img src={EmailNotif} alt='email notif' />
      <h4>Please verify your email</h4>
      <span>
        We have sent verification link to your email <br />
        {currentUser!.email}
      </span>
      <Button
        block
        type='primary'
        size='large'
        disabled={loading}
        onClick={loading ? undefined : handleResend}
      >
        Resend verification link
      </Button>
    </Layout>
  )
}

export default ResendVerifyLink
