import { useRouteMatch, Redirect } from 'react-router-dom'

import './index.scss'
import EmbedPlayer from './EmbedPlayer'

function EmbedWrapper() {
  const params = useRouteMatch().params as { url: string }

  const getDecodedUrl = () => {
    try {
      if (params.url) {
        return decodeURIComponent(atob(params.url))
      }
      return undefined
    } catch {
      return undefined
    }
  }

  const decodedUrl = getDecodedUrl()

  if (!decodedUrl) {
    return <Redirect to='/404' />
  }

  return <EmbedPlayer url={decodedUrl} />
}

export default EmbedWrapper
