import { useState } from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import './index.scss'
import { Layout } from 'components/Shared'
import { useLocalStorage } from 'hooks'
import BaseModal from './BaseModal'

interface Props {
  title?: string
  placeholder?: string
  value?: string
  onSearch?: (v: string) => void
  onCancel: () => void
}

function ModalSearch({ title, placeholder, value, onSearch, onCancel }: Props) {
  const [input, setInput] = useState(value || '')
  const [recentSearch, setRecentSearch] = useLocalStorage<string[]>(
    'APP/RECENT_SEARCH',
    [],
  )

  const handleSearch = (v?: string) => {
    const search = v || input
    if (!recentSearch.includes(search) && search !== '') {
      setRecentSearch(prev => [search, ...prev])
    }
    onSearch && onSearch(search)
    setTimeout(onCancel, 0)
  }

  return (
    <BaseModal
      noPadding
      className='modal-search'
      onCancel={onCancel}
      height='100vh'
    >
      <Layout>
        <Layout.Header
          bordered
          sticky
          goBack
          onGoBack={onCancel}
          actionRight={
            <span
              className='text-500 text-primary hoverable'
              onClick={() => handleSearch()}
            >
              Search
            </span>
          }
        >
          {title || 'Search'}
        </Layout.Header>
        <Layout.Body>
          <Input
            allowClear
            size='large'
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={e => (e.key === 'Enter' ? handleSearch() : undefined)}
            placeholder={placeholder || 'Search'}
            suffix={<SearchOutlined />}
          />
          {!recentSearch.length && (
            <div style={{ marginTop: '1rem' }} className='text-placeholder'>
              You have no search at recent
            </div>
          )}
          {!!recentSearch.length && (
            <div className='recent-search'>
              <div className='recent-search-title'>
                <h3>Recent Search</h3>
                <span
                  className='text-primary hoverable'
                  onClick={() => setRecentSearch([])}
                >
                  clear
                </span>
              </div>
              <ul className='recent-search-list'>
                {recentSearch.map(s => (
                  <li
                    key={s}
                    className='hoverable'
                    onClick={() => handleSearch(s)}
                  >
                    {s}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalSearch
