import { useState } from 'react'
import moment, { Moment } from 'moment'
import { Button, DatePicker, notification } from 'antd'

import { Layout } from 'components/Shared'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import { MM_DD_YYYY, YYYY_MM_DD } from 'utils/dateTime'
import { notiError } from 'utils/notification'

interface Props {
  dateRange: string[]
  onChange?: (filters: { dateRange: string[] }) => void
  onCancel: () => void
}

function ModalFilterActivityLogs({ dateRange, onChange, onCancel }: Props) {
  const [from, setFrom] = useState(() =>
    dateRange[0] ? moment(dateRange[0]) : null,
  )
  const [to, setTo] = useState(() =>
    dateRange[1] ? moment(dateRange[0]) : null,
  )

  const handleFilter = (isReset = false) => {
    notification.destroy()
    const range: Moment[] = []
    if (!!from) range.push(from)
    if (!!to) range.push(to)
    if (
      range.length === 1 ||
      (range.length === 2 && range[1].diff(range[0]) < 0)
    ) {
      return notiError({ message: 'Error', description: 'Invalid duration' })
    }

    onChange &&
      onChange({
        dateRange: isReset
          ? []
          : range.length === 2
          ? [range[0].format(YYYY_MM_DD), range[1].format(YYYY_MM_DD)]
          : [],
      })
    onCancel()
  }

  return (
    <BaseModal
      noPadding
      className='modal-filter-device-activity-logs'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          rounded
          bordered
          goBack
          onGoBack={onCancel}
          actionRight={
            <span
              className='text-primary text-500 hoverable'
              onClick={() => handleFilter(true)}
            >
              Reset Filter
            </span>
          }
        >
          Quick Filters
        </Layout.Header>
        <Layout.Body>
          <h3>Duration</h3>
          <div className='date-picker-group'>
            <span className='text-500'>From</span>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={from}
              onChange={v => setFrom(v)}
              placeholder='From'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <hr className='divider' style={{ margin: '1rem 0' }} />
          <div className='date-picker-group'>
            <label className='text-500'>To</label>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={to}
              onChange={v => setTo(v)}
              placeholder='To'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <Button
            block
            size='large'
            type='primary'
            onClick={() => handleFilter()}
          >
            Apply
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalFilterActivityLogs
