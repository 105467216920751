import { Layout } from 'components/Shared'

import TableListHeader from './TableListHeader'
import TableList from './TableList'

function VideoSearch() {
  return (
    <Layout className='video-search-records'>
      <Layout.Header bordered goBack>
        Video Search
      </Layout.Header>
      <Layout.Body>
        <TableListHeader />
        <TableList />
      </Layout.Body>
    </Layout>
  )
}

export default VideoSearch
