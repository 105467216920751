import { useState } from 'react'
import { Collapse } from 'antd'
import { CheckCircleOutlined, DownOutlined } from '@ant-design/icons'

import { Plan } from 'services/subscription'

interface Props {
  free: Plan
  basic: Plan
  plus: Plan
  pro: Plan
  enterprise: Plan
}

const defaultActiveKeys = ['video-streaming', 'notification']

function Features({ free, basic, plus, pro, enterprise }: Props) {
  const [activeKeys, setActiveKeys] = useState<string[]>(defaultActiveKeys)

  const isSupported = (feature: boolean) => {
    return feature ? (
      <CheckCircleOutlined style={{ fontSize: 18 }} className='text-success' />
    ) : (
      '---'
    )
  }

  return (
    <div className='feature-list'>
      <h3 className='title'>Compare features</h3>
      <div style={{ overflowY: 'auto' }}>
        <div className='feature-plans'>
          <div>FREE</div>
          <div>BASIC</div>
          <div>PLUS</div>
          <div>PROFESSIONAL</div>
          <div>CUSTOM</div>
        </div>
        <Collapse
          activeKey={activeKeys}
          onChange={keys => setActiveKeys(keys as string[])}
          expandIconPosition='right'
          expandIcon={({ isActive }) => (
            <DownOutlined rotate={isActive ? -180 : 0} />
          )}
        >
          <Collapse.Panel
            key='video-streaming'
            header={<span className='feature-header'>Video Streaming</span>}
          >
            <div className='feature'>IP Camera Licences</div>
            <div className='support-list'>
              <span>---</span>
              <span>---</span>
              <span>---</span>
              <span>---</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Device</div>
            <div className='support-list'>
              <span>{free.device_count}</span>
              <span>{basic.device_count}</span>
              <span>{plus.device_count}</span>
              <span>{pro.device_count}</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Livestream</div>
            <div className='support-list'>
              <span>---</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Video Sharing</div>
            <div className='support-list'>
              <span>{isSupported(free.has_vault)}</span>
              <span>{isSupported(basic.has_vault)}</span>
              <span>{isSupported(plus.has_vault)}</span>
              <span>{isSupported(pro.has_vault)}</span>
              <span>{isSupported(enterprise.has_vault)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Concierge Device Provisioning</div>
            <div className='support-list'>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(true)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Live Streaming into Your Website</div>
            <div className='support-list'>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Timelapse</div>
            <div className='support-list'>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key='notification'
            header={<span className='feature-header'>Notification</span>}
          >
            <div className='feature'>Notification Retention Days</div>
            <div className='support-list'>
              <span>{free.inbound_log_days}</span>
              <span>{basic.inbound_log_days}</span>
              <span>{plus.inbound_log_days}</span>
              <span>{pro.inbound_log_days}</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Email Notifications</div>
            <div className='support-list'>
              <span>{free.email_count}</span>
              <span>{basic.email_count}</span>
              <span>{plus.email_count}</span>
              <span>{pro.email_count}</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>SMS Notifications</div>
            <div className='support-list'>
              <span>{free.sms_count}</span>
              <span>{basic.sms_count}</span>
              <span>{plus.sms_count}</span>
              <span>{pro.sms_count}</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>WhatsApp Notifications</div>
            <div className='support-list'>
              <span>{free.whatsapp_count}</span>
              <span>{basic.whatsapp_count}</span>
              <span>{plus.whatsapp_count}</span>
              <span>{pro.whatsapp_count}</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Multiple Schedules</div>
            <div className='support-list'>
              <span>---</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Webhook Notifications</div>
            <div className='support-list'>
              <span>{isSupported(free.third_party_integrations)}</span>
              <span>{isSupported(basic.third_party_integrations)}</span>
              <span>{isSupported(plus.third_party_integrations)}</span>
              <span>{isSupported(pro.third_party_integrations)}</span>
              <span>{isSupported(enterprise.third_party_integrations)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Slack Notifications</div>
            <div className='support-list'>
              <span>{isSupported(free.third_party_integrations)}</span>
              <span>{isSupported(basic.third_party_integrations)}</span>
              <span>{isSupported(plus.third_party_integrations)}</span>
              <span>{isSupported(pro.third_party_integrations)}</span>
              <span>{isSupported(enterprise.third_party_integrations)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Microsoft Teams Notifications</div>
            <div className='support-list'>
              <span>{isSupported(free.third_party_integrations)}</span>
              <span>{isSupported(basic.third_party_integrations)}</span>
              <span>{isSupported(plus.third_party_integrations)}</span>
              <span>{isSupported(pro.third_party_integrations)}</span>
              <span>{isSupported(enterprise.third_party_integrations)}</span>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key='video-storage'
            header={<span className='feature-header'>Video Storage</span>}
          >
            <div className='feature'>Inbound Log Days</div>
            <div className='support-list'>
              <span>{free.inbound_log_days}</span>
              <span>{basic.inbound_log_days}</span>
              <span>{plus.inbound_log_days}</span>
              <span>{pro.inbound_log_days}</span>
              <span>Customizable</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Cloud Recording</div>
            <div className='support-list'>
              <span>{isSupported(false)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Video Vault</div>
            <div className='support-list'>
              <span>{isSupported(free.has_vault)}</span>
              <span>{isSupported(basic.has_vault)}</span>
              <span>{isSupported(plus.has_vault)}</span>
              <span>{isSupported(pro.has_vault)}</span>
              <span>{isSupported(enterprise.has_vault)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Custom Recording Schedules</div>
            <div className='support-list'>
              <span>---</span>
              <span>---</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Long Term Video Archival</div>
            <div className='support-list'>
              <span>---</span>
              <span>---</span>
              <span>---</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Cloud Storage</div>
            <div className='support-list'>
              <span>---</span>
              <span>---</span>
              <span>---</span>
              <span>Coming soon</span>
              <span>Coming soon</span>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key='management'
            header={<span className='feature-header'>Management</span>}
          >
            <div className='feature'>User Management</div>
            <div className='support-list'>
              <span>{isSupported(free.has_user_mgmt)}</span>
              <span>{isSupported(basic.has_user_mgmt)}</span>
              <span>{isSupported(plus.has_user_mgmt)}</span>
              <span>{isSupported(pro.has_user_mgmt)}</span>
              <span>{isSupported(enterprise.has_user_mgmt)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Activity Log</div>
            <div className='support-list'>
              <span>{isSupported(free.has_audit_trails)}</span>
              <span>{isSupported(basic.has_audit_trails)}</span>
              <span>{isSupported(plus.has_audit_trails)}</span>
              <span>{isSupported(pro.has_audit_trails)}</span>
              <span>{isSupported(enterprise.has_audit_trails)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Custom Brand Logo</div>
            <div className='support-list'>
              <span>{isSupported(!!free.use_brand_logo)}</span>
              <span>{isSupported(!!basic.use_brand_logo)}</span>
              <span>{isSupported(!!plus.use_brand_logo)}</span>
              <span>{isSupported(!!pro.use_brand_logo)}</span>
              <span>{isSupported(!!enterprise.use_brand_logo)}</span>
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            key='support'
            header={<span className='feature-header'>Support</span>}
          >
            <div className='feature'>Email/Chat Support</div>
            <div className='support-list'>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
              <span>{isSupported(true)}</span>
            </div>
            <hr className='divider' style={{ margin: '8px 0' }} />
            <div className='feature'>Phone Support</div>
            <div className='support-list'>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(false)}</span>
              <span>{isSupported(true)}</span>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  )
}

export default Features
