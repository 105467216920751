import { useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import EmailChecked from 'assets/email-checked.png'
import { Layout, Spinner } from 'components/Shared'
import { useAppSelector } from 'store'
import { verifyEmail } from 'services/auth'

import LinkExpired from './LinkExpired'

type RouteParams = {
  id: string
  hash: string
}

type SelectedPlan = {
  planId?: string
  count?: string
}

function VerifyEmail() {
  const { id, hash } = useParams<RouteParams>()
  const { search } = useLocation()
  const [loading, setLoading] = useState(true)
  const [verifySuccess, setVerifySuccess] = useState(false)

  useEffect(() => {
    handleVerifyEmail()
    // eslint-disable-next-line
  }, [])

  const handleVerifyEmail = async () => {
    try {
      await verifyEmail({ id, hash: hash + search })
      setVerifySuccess(true)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner height='100vh' />
  }

  if (!loading && verifySuccess) {
    const { planId, count }: SelectedPlan = JSON.parse(
      localStorage.getItem('APP/SELECTED_PLAN') || '{}',
    )
    localStorage.removeItem('APP/SELECTED_PLAN')
    localStorage.removeItem('LAST_PATH')
    return (
      <Layout className='verify-message'>
        <img src={EmailChecked} alt='email notif' />
        <h4>Your account is verified</h4>
        <span>
          Thank you for using Broadflow. We hope you have a great experience
          with our service
        </span>
        {!!planId && !!count ? (
          <Link
            className='ant-btn ant-btn-primary ant-btn-lg ant-btn-block'
            to={{
              pathname: '/settings/payment-settings/checkout',
              state: { camCount: +count, planId: +planId },
            }}
          >
            Continue to Checkout
          </Link>
        ) : (
          <Link
            to='/'
            className='ant-btn ant-btn-primary ant-btn-lg ant-btn-block'
          >
            Back to Dashboard
          </Link>
        )}
      </Layout>
    )
  }

  return <LinkExpired />
}

function VerifyEmailWrapper() {
  const { currentUser } = useAppSelector(state => state.auth)
  if (currentUser!.email_verified_at) return <LinkExpired />
  return <VerifyEmail />
}

export default VerifyEmailWrapper
