export function PasswordRequirements() {
  return (
    <div className='password-requirements'>
      <h4>Please provide your email address and set your password</h4>
      <ul>
        <li>
          <span>●</span>
          <span>Is longer than 7 characters</span>
        </li>
        <li>
          <span>●</span>
          <span>Contains at least 1 number (0 1 2 3 ....)</span>
        </li>
        <li>
          <span>●</span>
          <span>Contains at least 1 upper case character (A B C ...)</span>
        </li>
        <li>
          <span>●</span>
          <span>Contains at least 1 special character (! @ # $...)</span>
        </li>
      </ul>
    </div>
  )
}
