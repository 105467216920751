import React, { useRef } from 'react'

import { Union } from 'components/Icons'
import { secondsToHms } from 'utils/dateTime'
import { MAX_VIDEO_LENGTH, MAX_VIDEO_LENGTH_SEC } from 'utils/device'

import PlaybackTimeGraph from './TimeGraph'
import { usePlaybackContext } from '../PlaybackContext'

const TIMELINE_WIDTH = 4250

function PlaybackTimeline() {
  const { currentTime, dispatch } = usePlaybackContext()
  const timelineRef = useRef<HTMLDivElement>(null)

  const widthPerSec = (TIMELINE_WIDTH - 16) / MAX_VIDEO_LENGTH_SEC
  const indicatorPos = widthPerSec * currentTime

  const calcSegment = () => {
    const seg = Math.round(MAX_VIDEO_LENGTH / 10)
    const widthPerSeg = (TIMELINE_WIDTH - 16) / seg
    return { seg, widthPerSeg }
  }

  const renderLines = () => {
    const { seg, widthPerSeg } = calcSegment()
    const lines: JSX.Element[] = []

    for (let i = 1; seg >= i; i++) {
      const totalLine = 10
      const widthPerLine = widthPerSeg / totalLine
      lines.push(
        <div style={{ width: widthPerSeg, display: 'inline-flex' }} key={i}>
          {Array.from({ length: totalLine }).map((_, idx) => (
            <div
              key={idx}
              className='segment'
              style={{
                width: widthPerLine,
                justifyContent:
                  i === 1 && idx === 0 ? 'space-between' : 'flex-end',
              }}
            >
              {i === 1 && idx === 0 && (
                <div className='line' style={{ height: 12 }} />
              )}
              <div
                className='line'
                style={{ height: idx + 1 === totalLine ? 12 : 5 }}
              />
            </div>
          ))}
        </div>,
      )
    }
    return lines
  }

  const renderTimes = () => {
    const { seg, widthPerSeg } = calcSegment()
    const times: JSX.Element[] = []

    for (let i = 1; seg >= i; i++) {
      times.push(
        <div
          key={i}
          style={{
            width: widthPerSeg,
            display: 'inline-flex',
            justifyContent: i === 1 ? 'space-between' : 'flex-end',
          }}
        >
          {i === 1 && <span>00:00:00</span>}
          <span>{secondsToHms(i * 60 * (MAX_VIDEO_LENGTH / seg))}</span>
        </div>,
      )
    }

    return times
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLDivElement
    const playerList = document.querySelector(
      '.ant-tabs-tabpane-active .player-list',
    )
    playerList!.scrollTop = target.scrollTop
    timelineRef.current!.scrollLeft = target.scrollLeft
  }

  const handleSeek = (pageX: number) => {
    r.current.lastPageX = pageX
    const { minPageX, maxPageX, scrollX, widthPerSec } = r.current
    if (pageX > maxPageX) {
      pageX = maxPageX
    } else if (pageX < minPageX) {
      pageX = minPageX
    }
    pageX += scrollX
    dispatch({
      type: 'SET_PLAYBACK_TIME',
      payload: (pageX - minPageX) / widthPerSec,
    })
  }

  const handleTimelineMouseDown: React.MouseEventHandler<HTMLDivElement> =
    e => {
      const hasSpecialKey = e.ctrlKey || e.altKey || e.shiftKey || e.metaKey
      if (hasSpecialKey) {
        return
      }
      r.current.lastPageX = -1
      r.current.widthPerSec = widthPerSec // need to cache?
      const d = e.target as HTMLDivElement
      const bcr = d.getBoundingClientRect()
      r.current.minPageX = bcr.left + 8 // padding
      r.current.maxPageX = bcr.right - 8 // padding
      r.current.scrollX = d.scrollLeft || 0
      onTimelineMouseUp(e.nativeEvent)
      window.addEventListener('mousemove', r.current.onTimelineMouseMove)
      window.addEventListener('mouseup', r.current.onTimelineMouseUp)
      window.addEventListener('touchmove', r.current.onTimelineMouseMove)
      window.addEventListener('touchend', r.current.onTimelineMouseUp)
    }

  const onTimelineMouseMove = (e: MouseEvent | TouchEvent) => {
    const pageX = e instanceof MouseEvent ? e.pageX : e.changedTouches[0].pageX
    handleSeek(pageX)
  }

  const onTimelineMouseUp = (e: MouseEvent | TouchEvent) => {
    window.removeEventListener('mousemove', r.current.onTimelineMouseMove)
    window.removeEventListener('mouseup', r.current.onTimelineMouseUp)
    window.removeEventListener('touchmove', r.current.onTimelineMouseMove)
    window.removeEventListener('touchend', r.current.onTimelineMouseUp)
    const pageX = e instanceof MouseEvent ? e.pageX : e.changedTouches[0].pageX
    if (pageX !== r.current.lastPageX) {
      handleSeek(pageX)
    }
  }

  const r = useRef({
    onTimelineMouseMove,
    onTimelineMouseUp,
    lastPageX: 0,
    widthPerSec: 0,
    minPageX: 0,
    maxPageX: 0,
    scrollX: 0,
  })

  return (
    <React.Fragment>
      <div
        ref={timelineRef}
        className='playback-timeline'
        onTouchStart={(e: any) => handleTimelineMouseDown(e)}
        onMouseDown={handleTimelineMouseDown}
      >
        <div className='current-time'>
          <div>{secondsToHms(currentTime)}</div>
        </div>
        <div className='timeline-lines' style={{ width: TIMELINE_WIDTH }}>
          {renderLines()}
        </div>
        <div className='timeline-times' style={{ width: TIMELINE_WIDTH }}>
          {renderTimes()}
        </div>
        <div className='indicator' style={{ width: TIMELINE_WIDTH }}>
          <div
            className='indicator-inner'
            style={{ width: TIMELINE_WIDTH - 16 }}
          >
            <div className='line' style={{ left: indicatorPos }} />
          </div>
          <Union className='handle' style={{ left: indicatorPos + 1 }} />
        </div>
      </div>
      <PlaybackTimeGraph
        graphWidth={TIMELINE_WIDTH}
        widthPerSec={widthPerSec}
        indicatorPosition={indicatorPos}
        onScroll={handleScroll}
      />
    </React.Fragment>
  )
}

export default PlaybackTimeline
