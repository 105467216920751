import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'

import './index.scss'
import { InfoCircle } from 'components/Icons'

interface Props {
  showUpgrade?: boolean
  rounded?: boolean
}

const PlanInfoCard: React.FC<Props> = ({ showUpgrade, rounded, children }) => {
  return (
    <div className={cx('plan-info-card-wrapper', { rounded })}>
      <InfoCircle type='primary' />
      <div>
        <span className='plan-message'>{children}</span>
        {showUpgrade !== undefined && showUpgrade && (
          <Link
            to='/settings/payment-settings/plans'
            className='text-link text-underline text-500'
          >
            Upgrade your plan
          </Link>
        )}
      </div>
    </div>
  )
}

export default PlanInfoCard
