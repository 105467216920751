import React from 'react'

import * as FormHelpers from 'components/Modal/Schedule/FormHelpers'
export * from 'components/Modal/Schedule/FormHelpers'

export const steps = [
  {
    key: 'deviceDetails',
    title: 'Device Details',
    canSkip: false,
  },
  {
    key: 'notifications',
    title: 'Notifications',
    canSkip: true,
    toolTip: (
      <React.Fragment>
        <h3 style={{ color: '#fff', fontSize: '1rem', marginBottom: '1rem' }}>
          What is a Notification?
        </h3>
        <p style={{ marginBottom: '.75rem' }}>
          A Notification is defined as one or more schedules, recipients and
          channels which combine to form a workflow for to alert teams when a
          particular event has been triggered.
        </p>
        <p style={{ marginBottom: '0' }}>
          Notification schedules provide the flexibility to define who should
          receive a given notification for a given time period.
        </p>
      </React.Fragment>
    ),
  },
  {
    key: 'cloudSurveillance',
    title: 'Cloud Surveillance',
    canSkip: false,
    toolTip: (
      <React.Fragment>
        <h3 style={{ color: '#fff', fontSize: '1rem', marginBottom: '1rem' }}>
          What is Cloud Surveillance?
        </h3>
        <p style={{ marginBottom: '.75rem' }}>
          Cloud Surveillance provides users with an easy way to manage and
          monitor security cameras without the need for any local recorders.
        </p>
        <p style={{ marginBottom: '0' }}>
          Camera licenses include support for recording and livestreaming your
          cameras securely in the AWS cloud.
        </p>
      </React.Fragment>
    ),
  },
]

export interface FormValues {
  deviceDetails: {
    name: string
    type?: string
    timezone?: string
    manufacturer?: string
    ip_address?: string
    location?: FormHelpers.OptionValue // location_id
  }
  notifications: {
    name: string
    dateTime: {
      is247: boolean
      selectedDays: string[]
      startTime: string
      endTime: string
    }
    is_active: boolean
    channels: string[]
    phone_recipients: FormHelpers.OptionValue[]
    email_recipients: FormHelpers.OptionValue[]
    whatsapp_recipients: FormHelpers.OptionValue[]
    webhook: string
    slack_webhook: string
    teams_webbhook: string
  }
  cloudSurveillance: {
    stream_url?: string
  }
}

export const formValues: FormValues = {
  deviceDetails: {
    name: '',
    type: undefined,
    timezone: undefined,
    manufacturer: undefined,
    ip_address: '',
    location: undefined,
  },
  notifications: {
    name: '',
    dateTime: {
      is247: false,
      selectedDays: [],
      startTime: '',
      endTime: '',
    },
    is_active: true,
    channels: [],
    phone_recipients: [],
    email_recipients: [],
    whatsapp_recipients: [],
    webhook: '',
    slack_webhook: '',
    teams_webbhook: '',
  },
  cloudSurveillance: {
    stream_url: '',
  },
}
