import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ClockFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M18 0.5C8.35011 0.5 0.5 8.35011 0.5 18C0.5 27.6499 8.35011 35.5 18 35.5C27.6499 35.5 35.5 27.6499 35.5 18C35.5 8.35011 27.6499 0.5 18 0.5ZM26.3228 27.0517C26.0384 27.3361 25.6651 27.4793 25.2918 27.4793C24.9184 27.4793 24.5449 27.3361 24.2608 27.0517L16.969 19.7603C16.6948 19.4876 16.5418 19.117 16.5418 18.7293V9.25C16.5418 8.44357 17.1949 7.79176 18 7.79176C18.8051 7.79176 19.4582 8.44357 19.4582 9.25V18.1255L26.3228 24.9897C26.8929 25.5601 26.8929 26.4816 26.3228 27.0517Z'
          fill='url(#clock_linear)'
        />
        <defs>
          <linearGradient
            id='clock_linear'
            x1='0.5'
            y1='0.5'
            x2='0.5'
            y2='35.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
