import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ChatCircle({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M5.92922 0.75C5.92641 0.75 5.92078 0.75 5.91234 0.75C3.06532 0.75 0.75 3.06531 0.75 5.91233C0.75 7.0742 1.14667 8.20232 1.87249 9.111L1.12135 10.858C1.05946 11.0015 1.12698 11.1675 1.26764 11.2266C1.31828 11.2491 1.37454 11.2547 1.428 11.2463L4.18218 10.7624C4.73358 10.9621 5.31311 11.0634 5.89827 11.0606C8.7453 11.0606 11.0606 8.74528 11.0606 5.89826C11.0662 3.05968 8.7678 0.752813 5.92922 0.75ZM4.16249 4.87423H5.68165C5.83638 4.87423 5.96298 5.00083 5.96298 5.15556C5.96298 5.31029 5.83638 5.43689 5.68165 5.43689H4.16249C4.00776 5.43689 3.88116 5.31029 3.88116 5.15556C3.88116 5.00083 4.00776 4.87423 4.16249 4.87423ZM7.65094 6.84351H4.16249C4.00776 6.84351 3.88116 6.71692 3.88116 6.56219C3.88116 6.40746 4.00776 6.28086 4.16249 6.28086H7.65094C7.80567 6.28086 7.93226 6.40746 7.93226 6.56219C7.93226 6.71692 7.80567 6.84351 7.65094 6.84351Z'
          fill='url(#chat_circle0_linear)'
        />
        <defs>
          <linearGradient
            id='chat_circle0_linear'
            x1='0.75'
            y1='0.75'
            x2='0.75'
            y2='11.25'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
