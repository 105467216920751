import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Button } from 'antd'

import './index.scss'
import { Layout } from 'components/Shared'
import ModalVerifyPassword from 'components/Modal/VerifyPassword'
import {
  checkConfirmPassword,
  ConfirmPasswordStatus,
  disable2FA,
  enable2FA,
} from 'services/2fa'
import { update2FA } from 'store/slice/authSlice'
import { useAppDispatch, useAppSelector } from 'store'

import RecoveryCodeInfo from './RecoveryCodeInfo'
import BrowserSessions from './BrowserSessions'
import MobileTokens from './MobileTokens'

function SecurityLogin() {
  const [loading, setLoading] = useState(false)
  const [show2FAInfo, setShow2FAInfo] = useState(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)

  const { currentUser } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()

  const handleToggle2FA = async () => {
    try {
      setLoading(true)
      const isEnabled = currentUser!.enabled_2fa
      const promise = isEnabled ? disable2FA : enable2FA
      await promise()
      setShow2FAInfo(!isEnabled)
      dispatch(update2FA(!isEnabled))
    } catch (error: any) {
      console.error(error)
      if (error.status === 423) {
        setShowVerifyPassword(true)
      }
    } finally {
      setLoading(false)
    }
  }

  const handleToggle2FAInfo = async () => {
    try {
      setLoading(true)
      if (show2FAInfo) {
        return setShow2FAInfo(false)
      }
      const response = await checkConfirmPassword()
      const data = response.data as ConfirmPasswordStatus
      if (data.confirmed) {
        return setShow2FAInfo(true)
      }
      setShowVerifyPassword(true)
    } finally {
      setLoading(false)
    }
  }

  if (!currentUser) {
    return null
  }

  const { enabled_2fa } = currentUser

  return (
    <React.Fragment>
      <Layout className='security-login-page'>
        <Layout.Header goBack bordered>
          Security & Login Settings
        </Layout.Header>
        <Layout.Body>
          <div className='row'>
            <h3>
              Two-Factor Authentication is{' '}
              <b className={cx({ 'text-success': enabled_2fa })}>
                {enabled_2fa ? 'On' : 'Off'}
              </b>
            </h3>
            <span className='text-placeholder'>
              {enabled_2fa
                ? 'We will request an authentication code if we notice a login attempt from an unrecognized device or browser.'
                : 'We will NOT request an authentication code if we notice a login attempt from an unrecognized device or browser.'}
            </span>
            <div className='enabled-2fa'>
              <Button
                size='large'
                disabled={loading}
                className='ant-btn-secondary'
                onClick={handleToggle2FA}
              >
                Turn {enabled_2fa ? 'off' : 'on'}
              </Button>
              {enabled_2fa && (
                <Button
                  size='large'
                  disabled={loading}
                  className='ant-btn-secondary'
                  onClick={handleToggle2FAInfo}
                >
                  {show2FAInfo ? 'Hide' : 'Show'} Recovery Code
                </Button>
              )}
            </div>
            {enabled_2fa && show2FAInfo && <RecoveryCodeInfo />}
          </div>
          <div className='divider' />
          <BrowserSessions />
          <div className='divider' />
          <MobileTokens />
        </Layout.Body>
      </Layout>
      <AnimatePresence>
        {showVerifyPassword && (
          <ModalVerifyPassword onCancel={() => setShowVerifyPassword(false)} />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default SecurityLogin
