import BrokenLink from 'assets/images/broken-link.svg'
import { Layout } from 'components/Shared'

function LinkExpired() {
  return (
    <Layout className='verify-message'>
      <img src={BrokenLink} alt='link-expired' />
      <h4>This link is expired</h4>
      <span>The link you’re trying to access is now expired</span>
    </Layout>
  )
}

export default LinkExpired
