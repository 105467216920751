import moment from 'moment'

export const HH_MM_A = 'HH:mm A'

export const YYYY_MM_DD = 'YYYY-MM-DD'
export const YYYY_MM_DD_HH_MM = 'YYYY-MM-DD HH:mm'
export const YYYY_MM_DD_HH_MM_P = 'YYYY-MM-DD HH:mm A'

export const MM_DD_YYYY = 'MM-DD-YYYY'
export const MM_DD_YYYY_HH_MM = 'MM-DD-YYYY HH:mm'
export const MM_DD_YYYY_HH_MM_P = 'MM-DD-YYYY HH:mm A'

export const MMM_DD_YYYY = 'MMM DD, YYYY'

const leadZero = (num: string | number) => {
  return (num + '').padStart(2, '0')
}

export const secondsToHms = (secs: number) => {
  const hours = Math.floor(secs / 3600)
  const minutes = Math.floor((secs - hours * 3600) / 60)
  const seconds = Math.floor(secs - hours * 3600 - minutes * 60)
  return `${leadZero(hours)}:${leadZero(minutes)}:${leadZero(seconds)}`
}

export const hmsToSeconds = (hms: string) => {
  const [h, m, s] = hms.split(':')
  const hSecs = +h * 60 * 60
  const mSecs = +m * 60
  return hSecs + mSecs + +s
}

export const formatTime = (time: moment.Moment, format: string) => {
  return moment(time).format(format)
}

export const getFirstAndLastDateInMonth = (
  date?: string | moment.Moment,
  format?: string
) => {
  const firstDate = moment(date).startOf('month')
  const lastDate = moment(date).endOf('month')
  return {
    firstDate: format ? firstDate.format(format) : firstDate,
    lastDate: format ? lastDate.format(format) : lastDate
  }
}
