import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function MonitorFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M15.8862 2.5H0.98877C0.443628 2.5 0 2.94363 0 3.48877V13.3105C0 13.8557 0.443628 14.2993 0.98877 14.2993H15.8862C16.4314 14.2993 16.875 13.8557 16.875 13.3105V3.48877C16.875 2.94363 16.4314 2.5 15.8862 2.5Z'
          fill={c}
        />
        <path
          d='M13.7109 16.7383H11.0413V15.2881H5.83374V16.7383H3.16406C2.89116 16.7383 2.66968 16.9598 2.66968 17.2327C2.66968 17.5056 2.89116 17.7271 3.16406 17.7271H13.7109C13.9838 17.7271 14.2053 17.5056 14.2053 17.2327C14.2053 16.9598 13.9838 16.7383 13.7109 16.7383Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
