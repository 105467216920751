import cx from 'classnames'
import { DownOutlined } from '@ant-design/icons'

import { TableRowHeadProps } from './TableTypes'
import { useTableContext } from './TableContext'

const TableRowHead: React.FC<TableRowHeadProps> = ({
  className,
  groupKey,
  expanded,
  children,
}) => {
  const { expandable, expandedKeys, onExpand } = useTableContext()

  const toggleExpand = () => {
    onExpand!(
      expanded
        ? expandedKeys!.filter(k => k !== groupKey)
        : [...expandedKeys!, groupKey!],
    )
  }

  return (
    <div
      onClick={toggleExpand}
      className={cx(
        'm-table-row-head',
        expandable && 'hoverable expandable',
        className,
      )}
    >
      <div>{children}</div>
      {expandable && (
        <div className='expand-icon'>
          <DownOutlined className='text-color' rotate={expanded ? 0 : 180} />
        </div>
      )}
    </div>
  )
}

export default TableRowHead
