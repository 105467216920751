const ICONS_SOLIDS = {
  primary: '#5156D3',
  secondary: '#808080',
  success: '#0B9830',
  warning: '#F98F14',
  danger: '#CC2020',
  info: '#1DADCD',
}

const ICON_GRADIENTS = {
  primary: ['#6065EA', '#3A3EA6'],
  secondary: ['#DDDDDD', '#B5B5B5'],
  success: ['#46D86C', '#0B9830'],
  warning: ['#FFC96A', '#F98F14'],
  danger: ['#F96E6E', '#CC2020'],
  info: ['#55F5FF', '#1DADCD'],
}

export { ICON_GRADIENTS, ICONS_SOLIDS }
