import React, { createContext, useContext, useReducer } from 'react'

import { Tag } from 'services/tags'

interface State {
  loading: boolean
  tags: Tag[]
  totalPage: number
  currentPage: number
  search: string
}

interface Action {
  type: string
  payload?: any
}

const initState: State = {
  loading: false,
  tags: [],
  totalPage: 1,
  currentPage: 1,
  search: '',
}

const TagsContext = createContext<any>(null)

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      }
    case 'SET_TAGS':
      return {
        ...state,
        loading: false,
        tags: payload.data,
        totalPage: payload.meta.last_page,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: payload,
        currentPage: 1,
      }
    default:
      return state
  }
}

const TagsContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <TagsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </TagsContext.Provider>
  )
}

const useTagsContext = () => {
  return useContext(TagsContext)
}

export { TagsContextProvider as default, useTagsContext }
