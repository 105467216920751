import MicrosoftTeam from 'assets/brand-logos/microsoft-team.svg'
import Slack from 'assets/brand-logos/slack.svg'
import Webhook from 'assets/brand-logos/webhook.svg'
import WhatsApp from 'assets/brand-logos/whatsapp.svg'

import { EmailFilled, SmsFilled } from 'components/Icons'
import { ChannelSelection } from 'components/Shared'
import { formatPhoneNumber } from 'utils/functions'
import { Tag } from 'services/tags'

interface Props {
  tag: Tag
}

function Recipients({ tag }: Props) {
  if (tag.is_blocked) {
    return (
      <div style={{ margin: '1rem 8px' }}>
        All notifications match this tag will NOT be forwarded
      </div>
    )
  }

  return (
    <div style={{ padding: '1rem 8px' }}>
      <div className='detail-title'>
        <h3>Notification Channel</h3>
      </div>
      <ChannelSelection
        readOnly
        popupContainer={() => document.querySelector('.m-modal-inner')!}
        selectedChannels={tag.channels || []}
      />
      <div className='divider' />
      <div className='detail-title'>
        <h3>Recipients</h3>
      </div>
      {tag.channels?.includes('phone') && (
        <div className='recipient-detail'>
          <h4>
            <SmsFilled type='primary' />
            <span>SMS Recipient</span>
          </h4>
          <div className='recipients'>
            {tag.phone_recipients?.map(r => (
              <span key={r.id}>{formatPhoneNumber(r.phone)}</span>
            ))}
          </div>
        </div>
      )}
      {tag.channels?.includes('whatsapp') && (
        <div className='recipient-detail'>
          <h4>
            <img src={WhatsApp} alt='WhatsApp' />
            <span>WhatsApp Recipient</span>
          </h4>
          <div className='recipients'>
            {tag.whatsapp_recipients?.map(r => (
              <span key={r.id}>{formatPhoneNumber(r.phone)}</span>
            ))}
          </div>
        </div>
      )}
      {tag.channels?.includes('email') && (
        <div className='recipient-detail'>
          <h4>
            <EmailFilled type='primary' />
            <span>Email Recipient</span>
          </h4>
          <div className='recipients'>
            {tag.email_recipients?.map(r => (
              <span key={r.id}>{r.email}</span>
            ))}
          </div>
        </div>
      )}
      {tag.channels?.includes('slack') && (
        <div className='recipient-detail'>
          <h4>
            <img src={Slack} alt='Slack' />
            <span>Slack Webhook</span>
          </h4>
          <div className='recipients'>
            <span>{tag.slack_webhook}</span>
          </div>
        </div>
      )}
      {tag.channels?.includes('webhook') && (
        <div className='recipient-detail'>
          <h4>
            <img src={Webhook} alt='Webhook' />
            <span>Webhook</span>
          </h4>
          <div className='recipients'>
            <span>{tag.webhook}</span>
          </div>
        </div>
      )}
      {tag.channels?.includes('microsoft_teams') && (
        <div className='recipient-detail'>
          <h4>
            <img src={MicrosoftTeam} alt='MicrosoftTeam' />
            <span>Microsoft Team Webhook</span>
          </h4>
          <div className='recipients'>
            <span>{tag.teams_webbhook}</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default Recipients
