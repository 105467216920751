import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function TagFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='15'
        height='18'
        viewBox='0 0 15 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M13.5923 17.7823C13.4021 17.7823 13.2167 17.6997 13.0892 17.5485L7.37502 10.7736L1.66088 17.5485C1.48276 17.7598 1.19158 17.837 0.93214 17.7424C0.672645 17.6475 0.5 17.4007 0.5 17.1244V0.907947C0.5 0.544602 0.794602 0.25 1.15795 0.25H13.5921C13.9554 0.25 14.25 0.544602 14.25 0.907947V17.1243C14.25 17.4006 14.0774 17.6474 13.8179 17.7423C13.7441 17.7692 13.6678 17.7823 13.5923 17.7823ZM7.37502 9.09507C7.56893 9.09507 7.75295 9.18061 7.87796 9.32883L12.9342 15.3237V1.56589H1.81589V15.3237L6.87209 9.32883C6.9971 9.18061 7.18111 9.09507 7.37502 9.09507Z'
          fill='url(#paint0_linear)'
        />
        <path
          d='M1.34848 0.797852H13.4019V17.2344L7.37521 10.1119L1.34848 16.6865V0.797852Z'
          fill='url(#paint1_linear)'
        />
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='0.5'
            y1='0.25'
            x2='0.5'
            y2='17.7823'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='paint1_linear'
            x1='1.34848'
            y1='0.797852'
            x2='1.34848'
            y2='17.2344'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
