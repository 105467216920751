import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as auth from 'services/auth'

export const getCurrentUserInfo: any = createAsyncThunk(
  'auth/getCurrentUserInfo',
  async () => {
    const response = await auth.getCurrentUser()
    return response
  },
)

export const logoutUser: any = createAsyncThunk('auth/logout', async () => {
  return await auth.logout()
})

interface State {
  loading: boolean
  error: boolean
  currentUser: auth.CurrentUser | null
}

const initialState: State = {
  loading: true,
  error: false,
  currentUser: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    updateUserAvatar: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.avatar = action.payload.avatar
      state.currentUser.avatar_url = action.payload.avatar_url
    },
    updateUsername: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.name = action.payload
    },
    updateUseCustomSMTPServer: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.account.use_custom_smtp = action.payload
    },
    updateBrandLogo: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.account.brand_logo = action.payload.logo
      state.currentUser.account.brand_logo_url = action.payload.logoUrl
    },
    update2FA: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.enabled_2fa = action.payload
    },
    updateDeviceNoti: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.account.enabled_notifications = action.payload
    },
    updatePromoNoti: (state, action) => {
      if (!state.currentUser) {
        return
      }
      state.currentUser.enable_promotional_notifications = action.payload
    },
  },
  extraReducers: {
    [getCurrentUserInfo.pending.type]: state => {
      state.loading = true
      state.error = false
    },
    [getCurrentUserInfo.rejected.type]: state => {
      state.loading = false
      state.error = true
      state.currentUser = null
    },
    [getCurrentUserInfo.fulfilled.type]: (state, { payload }) => {
      state.currentUser = payload.data.data
      state.loading = false
    },
    [logoutUser.pending.type]: state => {
      state.error = false
      state.loading = true
    },
    [logoutUser.rejected.type]: state => {
      state.error = true
      state.loading = false
    },
    [logoutUser.fulfilled.type]: state => {
      state.currentUser = null
    },
  },
})

export const {
  updateCurrentUser,
  updateUserAvatar,
  updateUsername,
  updateBrandLogo,
  updateUseCustomSMTPServer,
  update2FA,
  updateDeviceNoti,
  updatePromoNoti,
} = authSlice.actions
export default authSlice.reducer
