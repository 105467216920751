import React, { createContext, useContext, useReducer } from 'react'

interface State {
  loading: boolean
  logs: []
  totalPage: number
  currentPage: number
  dateRange: string[]
  search: string
}

interface Action {
  type: string
  payload?: any
}

const initState: State = {
  loading: false,
  logs: [],
  totalPage: 1,
  currentPage: 1,
  dateRange: [],
  search: '',
}

const LogContext = createContext<any>(null)

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      }
    case 'SET_LOGS':
      return {
        ...state,
        loading: false,
        logs: payload.data,
        totalPage: payload.meta.last_page,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload,
      }
    case 'SET_FILTER':
      return {
        ...state,
        dateRange: payload,
        currentPage: 1,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: payload,
        currentPage: 1,
      }
    default:
      return state
  }
}

const LogContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  return (
    <LogContext.Provider value={{ ...state, dispatch }}>
      {children}
    </LogContext.Provider>
  )
}

const useLogContext = () => {
  return useContext(LogContext)
}

export { LogContextProvider as default, useLogContext }
