import axios from './axios'

export interface User {
  account_id: number
  avatar: string | null
  avatar_url: string | null
  created_at: string
  email: string
  email_verified_at: string | null
  id: number
  is_admin: number
  name: string
  enabled_2fa: boolean
  two_factor_recovery_codes: string | null
  two_factor_secret: string | null
  updated_at: string
}

export const getUsers = ({
  search = '',
  sort = 'id',
  sortDirection = 'desc',
  pageSize = 10,
  currentPage = 1,
}: {
  search?: string
  sort?: string
  sortDirection?: string
  pageSize?: number
  currentPage?: number
}) => {
  return axios.get(
    `/api/users?filters[search]=${search}&order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`,
  )
}

export const getUserProfile = (userId: number) => {
  return axios.get(`/api/users/${userId}`)
}

export const createUser = (data: {
  name: string
  email: string
  password: string
  is_admin: boolean
  is_super_admin: boolean
  account_id: number
}) => {
  return axios.post('/api/users/', data)
}

export const updateUserProfile = (
  userId: number,
  {
    name,
    is_admin,
    avatar,
  }: { name?: string; is_admin?: number; avatar?: File },
) => {
  const formData = new FormData()
  if (name) {
    formData.append('name', name)
  }
  if (is_admin !== undefined) {
    formData.append('is_admin', is_admin + '')
  }
  if (avatar) {
    formData.append('avatar', avatar, avatar.name)
  }

  formData.append('_method', 'PUT')
  return axios.post(`/api/users/${userId}`, formData)
}

export const deleteUser = (userId: number) => {
  return axios.delete(`/api/users/${userId}`)
}
