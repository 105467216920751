import { FC, ReactElement, Children, cloneElement } from 'react'
import cx from 'classnames'

import { TableRowGroupProps } from './TableTypes'
import { useTableContext } from './TableContext'
import TableRowHead from './TableRowHead'
import TableRow from './TableRow'

const TableRowGroup: FC<TableRowGroupProps> = ({
  className,
  children,
  groupKey,
}) => {
  const { expandable, expandedKeys } = useTableContext()
  const expanded = expandable && expandedKeys!.includes(groupKey)

  const renderChild = (child: any) => {
    if (!expandable) return child
    const el = child as ReactElement
    if (el.type === TableRowHead)
      return cloneElement(el, { groupKey, expanded })
    if (el.type === TableRow && expanded) return el
  }

  return (
    <div className={cx('m-table-row-group', className)}>
      {Children.map(children, renderChild)}
    </div>
  )
}

export default TableRowGroup
