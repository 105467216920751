import { Checkbox } from 'antd'

import { CameraFilled } from 'components/Icons'
import { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { getDevices } from 'services/devices'

import { OptionValue, mapValuesToOptions } from './FormHelpers'

interface Props {
  applyAll: boolean
  devices: OptionValue[]
  onApplyAllChange: (applyAll: boolean) => void
  onDeviceChange: (devices: OptionValue[]) => void
}

function AffectedDevices({
  applyAll,
  devices,
  onApplyAllChange,
  onDeviceChange,
}: Props) {
  const handleGetDevices = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getDevices({
        search,
        currentPage: page,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  return (
    <div style={{ padding: '1rem 8px' }}>
      <div className='form-group-title'>
        <CameraFilled type='primary' />
        <h3>Choose Devices to Apply this Tag to</h3>
      </div>
      <div>
        <Checkbox
          className='checkbox-large'
          checked={applyAll}
          onChange={e => onApplyAllChange(e.target.checked)}
        >
          Apply to all devices
        </Checkbox>
      </div>
      {!applyAll && (
        <AsyncSelect
          labelInValue
          size='large'
          mode='multiple'
          value={devices}
          style={{ marginTop: '1rem', width: '100%' }}
          fetcher={handleGetDevices}
          handleSearch={handleGetDevices}
          handleLoadMore={handleGetDevices}
          mapOptions={mapValuesToOptions}
          placeholder='Select device to add, type to search'
          onChange={(_: any, v: any) => onDeviceChange(v)}
          getPopupContainer={getPopupContainer}
        />
      )}
    </div>
  )
}

export default AffectedDevices
