import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Tabs } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

import { useLocalStorage } from 'hooks'
import { PlusFilled } from 'components/Icons'
import { Layout } from 'components/Shared'
import ModalTabOptions from 'components/Modal/Livestream/TabOptions'
import ModalEditTabName from 'components/Modal/Livestream/EditTabName'

import PlaybackContextProvider from './PlaybackContext'
import PlaybackTab from './PlaybackTab'

const defaultTab = {
  key: 1,
  name: 'Page 1',
  layout: 2,
}

type Tab = typeof defaultTab

function Playback() {
  const [tabs, setTabs] = useLocalStorage<Tab[]>('APP/PLAYBACK', [defaultTab])

  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key)
  const [editTab, setEditTab] = useState(false)
  const [showTabOptions, setShowTabOptions] = useState(false)

  const handleAdd = () => {
    const lastTab = tabs[tabs.length - 1]
    const newKey = lastTab.key + 1
    setTabs(prev => [
      ...prev,
      { key: newKey, name: `Page ${lastTab.key + 1}`, layout: 2 },
    ])
    setActiveTabKey(newKey)
  }

  const handleEdit = (updateTab: Tab) => {
    setTabs(prev => prev.map(t => (t.key === updateTab.key ? updateTab : t)))
  }

  const handleRemove = (key: number) => {
    const newTabs = tabs.filter(tab => tab.key !== +key)
    setTabs(newTabs)
    setActiveTabKey(
      activeTabKey === +key ? newTabs[newTabs.length - 1].key : activeTabKey,
    )
  }

  const renderTabExtraLeft = () => (
    <div className='tab-extra-content-left'>
      <button onClick={handleAdd}>
        <PlusFilled color='#303030' />
        <span>New</span>
      </button>
      <div className='divider' />
    </div>
  )

  const renderTab = () => {
    return tabs.map(tab => (
      <Tabs.TabPane
        key={tab.key}
        closable={tabs.length > 1}
        tab={
          <div title={tab.name}>
            <span>{tab.name}</span>
            {tab.key === activeTabKey && (
              <MoreOutlined
                rotate={90}
                onClick={() => setShowTabOptions(true)}
              />
            )}
          </div>
        }
      >
        <PlaybackContextProvider>
          <PlaybackTab
            tab={tab}
            onEditTab={handleEdit}
            isTabActive={activeTabKey === tab.key}
          />
        </PlaybackContextProvider>
      </Tabs.TabPane>
    ))
  }

  return (
    <React.Fragment>
      <Layout className='page-playback'>
        <Layout.Header>
          <h4>Playback</h4>
          <div id='timerange'>Please select time range</div>
        </Layout.Header>
        <Layout.Body>
          <Tabs
            type='card'
            activeKey={activeTabKey + ''}
            onChange={key => setActiveTabKey(+key)}
            tabBarExtraContent={{
              left: renderTabExtraLeft(),
            }}
          >
            {renderTab()}
          </Tabs>
        </Layout.Body>
      </Layout>
      <AnimatePresence>
        {showTabOptions && (
          <ModalTabOptions
            canDelete={tabs.length > 1}
            onEdit={() => setEditTab(true)}
            onDelete={() => handleRemove(activeTabKey)}
            onCancel={() => setShowTabOptions(false)}
          />
        )}
        {editTab && (
          <ModalEditTabName
            tab={tabs.find(t => t.key === activeTabKey)!}
            onChange={handleEdit}
            onCancel={() => setEditTab(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default Playback
