import { useState } from 'react'
import { InfoCircle } from 'components/Icons'

import { DeviceSMTP } from 'services/devices'

interface Props {
  smtp: DeviceSMTP
}

function SmtpStats({ smtp }: Props) {
  const [showPassowrd, setShowPassword] = useState(false)

  return (
    <div className='smtp-stats'>
      <div className='stat-title'>
        <h3>SMTP Configuration</h3>
      </div>
      <div className='stat-message'>
        <InfoCircle color='#ffffff' />
        <span>
          Enter the following credentials in the SMTP settings for your device
        </span>
      </div>
      <div className='stat-item'>
        <span>Host</span>
        <span>{smtp.host}</span>
      </div>
      <div className='stat-item'>
        <span>Port</span>
        <span>{smtp.port}</span>
      </div>
      <div className='stat-item'>
        <span>Username</span>
        <span>{smtp.username}</span>
      </div>
      <div className='stat-item'>
        <span>Password</span>
        <span>
          {showPassowrd ? smtp.password : '******'}
          <p
            className='text-underline hoverable'
            onClick={() => setShowPassword(prev => !prev)}
          >
            {showPassowrd ? 'Hide' : 'Show'}
          </p>
        </span>
      </div>
      <div className='stat-item'>
        <span>From Address</span>
        <span>{smtp.sender_email}</span>
      </div>
      <div className='stat-item'>
        <span>To Address</span>
        <span>{smtp.to_email}</span>
      </div>
    </div>
  )
}

export default SmtpStats
