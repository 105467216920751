import cx from 'classnames'
import { useEffect, useRef, useState } from 'react'

import { CameraFilled } from 'components/Icons'
import colors from 'configs/avatarColors'

interface Props {
  alt?: string
  className?: string
  username?: string
  src?: string
  size?: 'small' | 'default' | 'large' | number
  fontSize?: string | number
  onChange?: (image: File) => void
}

export function Avatar({
  alt,
  className,
  username,
  src,
  size = 'default',
  fontSize,
  onChange,
  ...props
}: Props) {
  const [srcValue, setSrcValue] = useState(src)
  const [letters, setLetters] = useState('')

  const inputPickerRef = useRef<HTMLInputElement | null>(null)
  const avatarColor = colors[(username || 'a').charCodeAt(0) % colors.length]

  useEffect(() => {
    if (srcValue?.startsWith('blob:')) {
      setTimeout(() => {
        URL.revokeObjectURL(srcValue)
      }, 1000)
    }
  }, [srcValue])

  useEffect(() => {
    if (!src && username) {
      const nameArr = username.split(' ')
      const [first, last] = [nameArr[0], nameArr[nameArr.length - 1]]
      setLetters(() => {
        if (nameArr.length === 1) return first[0].toUpperCase()
        return `${first[0].toUpperCase()}${last[0].toUpperCase()}`
      })
    }
  }, [src, username])

  const handleOpenFileDialog = () => {
    if (inputPickerRef.current) {
      inputPickerRef.current.click()
    }
  }

  const handlePickerChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { files } = e.target
    if (!files || !files.length) {
      return
    }
    const newSrc = URL.createObjectURL(files[0])
    setSrcValue(newSrc)
    onChange && onChange(files[0])
  }

  return (
    <div
      {...props}
      style={{
        background: `${avatarColor.stroke}`,
        width: typeof size === 'number' ? size : undefined,
        height: typeof size === 'number' ? size : undefined,
      }}
      className={cx(
        'avatar',
        typeof size === 'string' && `size-${size}`,
        className,
        { 'default-avatar': !srcValue },
      )}
    >
      {srcValue ? (
        <img src={srcValue} alt={alt || 'avatar'} className='avatar-img' />
      ) : (
        <span
          style={{ background: `${avatarColor.bg}`, fontSize }}
          className='avatar-string'
        >
          {letters}
        </span>
      )}
      {onChange && (
        <div className='avatar-picker' onClick={handleOpenFileDialog}>
          <input
            type='file'
            accept='image/*'
            ref={inputPickerRef}
            onChange={handlePickerChange}
          />
          <CameraFilled color='#ffffff' />
        </div>
      )}
    </div>
  )
}
