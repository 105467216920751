import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button } from 'antd'

import { Monitor } from 'components/Icons'
import { Spinner } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import {
  BrowserSession,
  checkConfirmPassword,
  getBrowserSessions,
  logOutOtherBrowserSessions,
} from 'services/2fa'
import ModalVerifyPassword from 'components/Modal/VerifyPassword'

function BrowserSessions() {
  const [loading, setLoading] = useState(true)
  const [sessions, setSessions] = useState<BrowserSession[]>([])
  const [showMore, setShowMore] = useState(false)
  const [showVerifyPassword, setShowVerifyPassword] = useState(false)

  useEffect(() => {
    handleGetBrowserSessions()
    // eslint-disable-next-line
  }, [])

  const handleGetBrowserSessions = async () => {
    try {
      setLoading(true)
      const response = await getBrowserSessions()
      const data = response.data as BrowserSession[]
      setSessions(data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const renderSessions = () => {
    return sessions
      .slice(0, showMore ? sessions.length : 5)
      .map((s, idx, self) => (
        <React.Fragment key={idx}>
          <div className='browser-session'>
            <Monitor color='#bdbdbd' />
            <div className='session-info'>
              <div className='text-500'>
                {s.platform} - {s.browser}
              </div>
              <div className='text-placeholder'>
                <span>{s.ip_address}</span>
                <span>&#8231;</span>
                {s.is_current_device ? (
                  <span className='text-success'>Active now</span>
                ) : (
                  <span>Last active {s.last_active}</span>
                )}
              </div>
            </div>
          </div>
          {idx + 1 < self.length && (
            <hr
              className='divider'
              style={{ borderStyle: 'solid', margin: '12px 0' }}
            />
          )}
        </React.Fragment>
      ))
  }

  const handleToggleShowMore = () => {
    setShowMore(prev => !prev)
  }

  const handleLogOutSessions = async () => {
    try {
      setLoading(true)
      const response = await checkConfirmPassword()
      if (!response.data.confirmed) {
        throw new Error('unconfirmed')
      }

      await logOutOtherBrowserSessions()
      await handleGetBrowserSessions()
      notiSuccess({ message: 'Successfully logged out ' })
    } catch (err: any) {
      const { message, errors } = err
      if (message === 'unconfirmed') {
        return setShowVerifyPassword(true)
      }
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const disabledButton = loading || !sessions.length

  return (
    <React.Fragment>
      <div className='row browser-sessions' style={{ paddingTop: 8 }}>
        <h3>Where You're Logged In</h3>
        <span className='text-placeholder'>
          We'll ask for a code if we notice an attempted login from an
          unrecognized device or browser.
        </span>
        <div className='browser-sessions-actions'>
          <Button
            size='large'
            disabled={disabledButton}
            className='ant-btn-secondary'
            onClick={handleLogOutSessions}
          >
            Log Out Of All Sessions
          </Button>
          <Button
            size='large'
            disabled={disabledButton}
            className='ant-btn-secondary'
            onClick={disabledButton ? undefined : handleToggleShowMore}
          >
            Show {showMore ? 'Less' : 'All'}
          </Button>
        </div>
        {loading && !sessions.length && <Spinner />}
        {!!sessions.length && (
          <div className='browser-sessions-list'>{renderSessions()}</div>
        )}
      </div>
      <AnimatePresence>
        {showVerifyPassword && (
          <ModalVerifyPassword onCancel={() => setShowVerifyPassword(false)} />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default BrowserSessions
