import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Radio, RadioGroupProps } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

import './index.scss'
import ModalSwitchPage from './SwitchPage'

interface Props extends RadioGroupProps {
  disabled?: boolean
  current: number
  total: number
  onPageChange: (page: number) => void
}

function Pagination({
  className,
  current,
  total,
  onPageChange,
  ...props
}: Props) {
  const [showModal, setShowModal] = useState(false)

  const prevDisabled = props.disabled || current === 1
  const nextDisbled = props.disabled || current === total

  return (
    <React.Fragment>
      <Radio.Group
        {...props}
        value=''
        size={props.size || 'large'}
        className={cx('m-pagination', className)}
      >
        <Radio.Button
          disabled={prevDisabled}
          onClick={prevDisabled ? undefined : () => onPageChange(current - 1)}
        >
          <LeftOutlined />
        </Radio.Button>
        <Radio.Button onClick={() => setShowModal(true)}>
          Page: {current}
        </Radio.Button>
        <Radio.Button
          disabled={nextDisbled}
          onClick={nextDisbled ? undefined : () => onPageChange(current + 1)}
        >
          <RightOutlined />
        </Radio.Button>
      </Radio.Group>
      <AnimatePresence>
        {showModal && (
          <ModalSwitchPage
            current={current}
            total={total}
            onPageChange={onPageChange}
            onCancel={() => setShowModal(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default Pagination
