import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Help({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM17.46 7.62L14.68 8.77C14.17 7.41 13.1 6.33 11.73 5.83L12.88 3.05C14.98 3.85 16.65 5.52 17.46 7.62ZM10 13.5C8.34 13.5 7 12.16 7 10.5C7 8.84 8.34 7.5 10 7.5C11.66 7.5 13 8.84 13 10.5C13 12.16 11.66 13.5 10 13.5ZM7.13 3.04L8.3 5.82C6.92 6.32 5.83 7.41 5.32 8.79L2.54 7.63C3.35 5.52 5.02 3.85 7.13 3.04ZM2.54 13.37L5.32 12.22C5.83 13.6 6.91 14.68 8.29 15.18L7.12 17.96C5.02 17.15 3.35 15.48 2.54 13.37ZM12.88 17.96L11.73 15.18C13.1 14.67 14.18 13.59 14.68 12.21L17.46 13.38C16.65 15.48 14.98 17.15 12.88 17.96Z'
            fill={c}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect
              width='20'
              height='20'
              fill='white'
              transform='translate(0 0.5)'
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
