import React, { useEffect, useMemo, useState } from 'react'
import { Select, SelectProps } from 'antd'
import { debounce } from 'lodash'
import cx from 'classnames'

import './index.scss'

interface Props extends SelectProps<any> {
  fetcher: any
  handleSearch?: ({
    search,
    page,
  }: {
    search: string
    page: number
  }) => Promise<any>
  mapOptions: any
  handleLoadMore?: any
}

function AsyncSelect({
  fetcher,
  handleSearch,
  handleLoadMore,
  mapOptions,
  ...props
}: Props) {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [options, setOptions] = useState<any[]>([])

  useEffect(() => {
    setLoading(true)
    fetcher().then(({ data, totalPage = 1 }: any) => {
      setOptions(data)
      setTotalPage(totalPage)
      setLoading(false)
    })
    // eslint-disable-next-line
  }, [])

  const searchDebounce = useMemo(
    () =>
      debounce((search: string) => {
        if (handleSearch) {
          setLoading(true)
          handleSearch({ search, page: 1 }).then(({ data, totalPage }: any) => {
            setOptions(data)
            setPage(1)
            setTotalPage(totalPage)
            setLoading(false)
          })
        }
      }, 500),
    // eslint-disable-next-line
    [],
  )

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, offsetHeight, scrollTop } =
      event.target as HTMLDivElement
    if (scrollHeight === offsetHeight + scrollTop && !loading) {
      setLoading(true)
      const newPage = page + 1
      handleLoadMore({ page: newPage }).then(({ data, totalPage }: any) => {
        setOptions(prev => [...prev, ...data])
        setPage(newPage)
        setTotalPage(totalPage)
        setLoading(false)
      })
    }
  }

  return (
    <Select
      {...props}
      value={props.value || undefined}
      filterOption={false}
      showSearch={!!handleSearch}
      onSearch={handleSearch ? searchDebounce : undefined}
      dropdownClassName={cx('async-select-dropdown', { loading })}
      onPopupScroll={
        handleLoadMore && totalPage > page ? handleScroll : undefined
      }
      options={mapOptions(options)}
    />
  )
}

export default AsyncSelect
