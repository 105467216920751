import { Redirect, useHistory } from 'react-router'

import './index.scss'
import MyVault from './MyVault'
import VaultContextProvider from './MyVault/VaultContext'
import VideoSearch from './VideoSearch'
import VideoSearchContextProvider from './VideoSearch/VideoSearchContext'

function CloudStorage() {
  const { location } = useHistory()

  if (location.search.endsWith('video-search')) {
    return (
      <VideoSearchContextProvider>
        <VideoSearch />
      </VideoSearchContextProvider>
    )
  }

  if (location.search.endsWith('my-vault')) {
    return (
      <VaultContextProvider>
        <MyVault />
      </VaultContextProvider>
    )
  }

  return <Redirect to={`${location.pathname}?tab=video-search`} />
}

export default CloudStorage
