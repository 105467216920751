import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import 'moment-timezone'
import 'antd/dist/antd.less'

import './styles/index.scss'
import history from 'configs/history'
import App from './App'
import store from './store'

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
)
