import { useState } from 'react'
import { Button, Checkbox } from 'antd'
import { RightOutlined } from '@ant-design/icons'

import { CameraFilled } from 'components/Icons'
import { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { getDevices } from 'services/devices'

import { FormValues, mapValuesToOptions } from './FormHelpers'

interface Props {
  initValue: FormValues['affectedDevices']
  onChange: (k: 'affectedDevices', v: FormValues['affectedDevices']) => void
  onStepChange: (num: number) => void
}

function AffectedDevices({ initValue, onChange, onStepChange }: Props) {
  const [applyAll, setApplyAll] = useState(initValue.apply_to_all)
  const [devices, setDevices] = useState(initValue.devices)

  const handleGetDevices = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getDevices({
        search,
        currentPage: page,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleNext = () => {
    onChange('affectedDevices', { apply_to_all: applyAll, devices })
    onStepChange(1)
  }

  return (
    <div style={{ padding: '1rem 8px' }}>
      <div className='form-group-title'>
        <CameraFilled type='primary' />
        <h3>Choose Devices to Apply this Tag to</h3>
      </div>
      <div>
        <Checkbox
          className='checkbox-large'
          checked={applyAll}
          onChange={e => setApplyAll(e.target.checked)}
        >
          Apply to all devices
        </Checkbox>
      </div>
      {!applyAll && (
        <AsyncSelect
          labelInValue
          size='large'
          mode='multiple'
          value={devices}
          style={{ marginTop: '1rem', width: '100%' }}
          fetcher={handleGetDevices}
          handleSearch={handleGetDevices}
          handleLoadMore={handleGetDevices}
          mapOptions={mapValuesToOptions}
          placeholder='Select device to add, type to search'
          onChange={(_: any, v: any) => setDevices(v)}
          getPopupContainer={getPopupContainer}
        />
      )}
      <Button
        style={{ marginTop: 24 }}
        size='large'
        block
        type='primary'
        onClick={handleNext}
      >
        Next <RightOutlined />
      </Button>
    </div>
  )
}

export default AffectedDevices
