import { useState } from 'react'
import { Link } from 'react-router-dom'

import './index.scss'
import Logo from 'assets/logo.png'
import { login } from 'services/auth'
import { notiError } from 'utils/notification'

import LoginForm from './LoginForm'
import Verify2FACode from './Verify2FACode'

function Login() {
  const [loading, setLoading] = useState(false)
  const [twoFa, setTwoFA] = useState(false)

  const handleSubmit = async (values: { email: string; password: string }) => {
    setLoading(true)
    try {
      const response = await login({
        email: values.email!,
        password: values.password!,
      })
      setLoading(false)
      if (response.data.two_factor) {
        return setTwoFA(true)
      }
      onSuccess()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const onSuccess = () => {
    const lastPath = localStorage.getItem('LAST_PATH')
    localStorage.removeItem('LAST_PATH')
    window.location.href = lastPath || window.location.origin
  }

  const onFail = () => {
    setTwoFA(false)
    notiError({
      message: 'Error',
      description: 'Your provided code is not valid',
    })
  }

  return (
    <div className='login-page'>
      <img className='logo' alt='logo' src={Logo} />
      {twoFa ? (
        <Verify2FACode onSuccess={onSuccess} onFail={onFail} />
      ) : (
        <LoginForm disabled={loading} onSubmit={handleSubmit} />
      )}
      <div className='page-footer'>
        {twoFa ? (
          <div />
        ) : (
          <div className='text-placeholder'>
            Don’t have an account?{' '}
            <Link className='text-underline text-500' to='/register'>
              Register
            </Link>
          </div>
        )}
        <div>
          <a
            className='text-500 text-link'
            target='_blank'
            rel='noreferrer'
            href='https://broadflow.co/terms-of-use'
          >
            Terms
          </a>
          <a
            className='text-500 text-link'
            target='_blank'
            rel='noreferrer'
            href='https://broadflow.co/privacy-policy'
          >
            Privacy
          </a>
          <a
            className='text-500 text-link'
            target='_blank'
            rel='noreferrer'
            href='https://broadflow.co/about'
          >
            About
          </a>
        </div>
      </div>
    </div>
  )
}

export default Login
