import React from 'react'
import cx from 'classnames'
import { LeftOutlined } from '@ant-design/icons'

import { History } from 'history'
import history from 'configs/history'

type HeaderComp = React.FC<{
  className?: string
  bordered?: boolean
  rounded?: boolean
  goBack?: boolean
  onGoBack?: (h: History) => void
  sticky?: boolean
  actionLeft?: React.ReactNode
  actionRight?: React.ReactNode
}>

type BodyComp = React.FC<{ className?: string; fullHeight?: boolean }>

type LayoutComp = React.FC<{ className?: string }> & {
  Header: HeaderComp
  Body: BodyComp
}

const Header: HeaderComp = ({
  children,
  className,
  bordered,
  rounded,
  goBack,
  onGoBack,
  sticky,
  actionLeft,
  actionRight,
}) => {
  const handleGoBack = () => {
    if (onGoBack) return onGoBack(history)
    history.goBack()
  }

  return (
    <div
      className={cx(
        'm-layout-header',
        { bordered, rounded, sticky },
        className,
      )}
    >
      <div className='left'>
        {actionLeft}
        {!actionLeft && goBack && (
          <LeftOutlined className='go-back' onClick={handleGoBack} />
        )}
      </div>
      <div className='center'>{children}</div>
      <div className='right'>{actionRight}</div>
    </div>
  )
}

const Body: BodyComp = ({ children, className, fullHeight }) => {
  return (
    <div
      className={cx('m-layout-body', { 'full-height': fullHeight }, className)}
    >
      {children}
    </div>
  )
}

const Layout: LayoutComp = ({ children, className }) => {
  return <div className={cx('m-layout', className)}>{children}</div>
}

Layout.Header = Header
Layout.Body = Body
export { Layout }
