import { useState } from 'react'
import cx from 'classnames'
import { Checkbox, Input } from 'antd'

import '../index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { addNewNote } from 'services/notes'

interface Props {
  notifId: number
  onSuccess?: () => void
  onCancel: () => void
}

function ModalAddNote({ notifId, onSuccess, onCancel }: Props) {
  const [value, setValue] = useState('')
  const [sendAll, setSendAll] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    if (!value) return
    try {
      setLoading(true)
      await addNewNote(notifId!, { body: value, notify: sendAll })
      notiSuccess({ message: sendAll ? 'Note was added & sent' : 'Note added' })
      setLoading(false)
      onCancel()
      onSuccess && onSuccess()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      setLoading(false)
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-add-note'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          bordered
          sticky
          goBack
          onGoBack={loading ? () => {} : onCancel}
          actionRight={
            <span
              style={{ opacity: loading ? 0.7 : 1 }}
              onClick={!loading ? handleSave : undefined}
              className={cx('text-primary text-500', { hoverable: !loading })}
            >
              {sendAll ? 'Save & Send' : 'Save'}
            </span>
          }
        >
          Add Note
        </Layout.Header>
        <Layout.Body>
          <Input.TextArea
            rows={8}
            value={value}
            onChange={e => setValue(e.target.value)}
            placeholder='Write your note'
          />
          <Checkbox
            checked={sendAll}
            onChange={e => setSendAll(e.target.checked)}
          >
            Send this note to all recipients of this device
          </Checkbox>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalAddNote
