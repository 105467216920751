import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button } from 'antd'
import { DeleteFilled } from '@ant-design/icons'

import Table from 'components/Table'
import { DeviceInfo } from 'components/Shared'
import ModalPlayback from 'components/Modal/Player/Playback'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalShareFile from 'components/Modal/RecordFile/ShareFile'
import { downloadFile } from 'utils/functions'
import { notiError } from 'utils/notification'
import { deleteRecord, getVaultFiles, Vault } from 'services/vault'

import { useVaultContext } from './VaultContext'

function TableList() {
  const { loading, vault, currentPage, search, dispatch } = useVaultContext()

  const [viewVaultUrl, setViewVaultUrl] = useState<string | null>(null)
  const [deleteVaultId, setDeleteVaultId] = useState<number | null>(null)
  const [shareVaultId, setShareVaultId] = useState<number | null>(null)

  useEffect(() => {
    handleGetVaultFiles()
    // eslint-disable-next-line
  }, [search, currentPage])

  const handleGetVaultFiles = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const response = await getVaultFiles({
        pageSize: 10,
        sort: 'id',
        sortDirection: 'desc',
        search,
        currentPage,
      })
      dispatch({ type: 'SET_VAULT', payload: response.data })
    } catch {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleDelete = async () => {
    if (!deleteVaultId) return
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await deleteRecord(deleteVaultId)
      if (vault.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetVaultFiles()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = ({ id, file_url }: Vault) => (
    <React.Fragment>
      <Button
        className='ant-btn-ternary'
        onClick={() => setViewVaultUrl(file_url)}
      >
        View
      </Button>
      <Button className='ant-btn-ternary' onClick={() => setShareVaultId(id)}>
        Share
      </Button>
      <Button
        className='ant-btn-ternary'
        onClick={() => downloadFile(file_url)}
      >
        Download
      </Button>
      <Button
        className='ant-btn-ternary text-danger'
        onClick={() => setDeleteVaultId(id)}
      >
        Delete
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        className='table-video-vault'
        loading={loading}
        empty={!vault.length}
      >
        {vault.map((v: Vault) => (
          <Table.Row key={v.id} rowKey={v.id} rowActions={renderRowActions(v)}>
            <Table.Col>
              <div className='file-name text-500'>{v.file_name}</div>
              <div className='device-info'>
                <span>Device</span>
                {!!v.device ? (
                  <DeviceInfo
                    status='default'
                    device={v.device}
                    to={`/devices/${v.device.id}?tab=device-details`}
                  />
                ) : (
                  '---'
                )}
              </div>
              <div className='file-type'>
                <span>File Type</span>
                <span>{v.file_type}</span>
              </div>
              <div className='file-size'>
                <span>File Size</span>
                <span>{v.file_size_mb} MB</span>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {viewVaultUrl && (
          <ModalPlayback
            fileUrl={viewVaultUrl}
            onCancel={() => setViewVaultUrl(null)}
          />
        )}
        {shareVaultId && (
          <ModalShareFile
            fileId={shareVaultId}
            onCancel={() => setShareVaultId(null)}
          />
        )}
        {deleteVaultId && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this record?'
            description='Action can’t be undone'
            onConfirm={handleDelete}
            onCancel={() => setDeleteVaultId(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
