import { createPortal } from 'react-dom'
import { useEffect } from 'react'
import { motion } from 'framer-motion'
import cx from 'classnames'

import './index.scss'

const modalRoot = document.querySelector('#modal-root')

interface Props {
  className?: string
  height?: string | number
  noPadding?: boolean
  onCancel: () => void
  onAnimationEnd?: () => void
}

const Portal: React.FC = ({ children }) => {
  if (!modalRoot) return null
  return createPortal(children, modalRoot)
}

const BaseModal: React.FC<Props> = ({
  className,
  onCancel,
  height,
  noPadding,
  children,
  onAnimationEnd,
}) => {
  useEffect(() => {
    const isNestedModal = document.querySelectorAll('.m-modal').length > 1
    if (!isNestedModal) {
      document.body.style.width = `calc(100% - ${
        window.innerWidth - document.body.clientWidth
      }px)`
      document.body.className = 'no-scroll'
    }
    return () => {
      if (!isNestedModal) {
        document.body.style.removeProperty('width')
        document.body.classList.remove('no-scroll')
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', () => {
      onCancel()
    })
    // eslint-disable-next-line
  }, [])

  const handleClose: React.MouseEventHandler<HTMLDivElement> = event => {
    const el = event.target as HTMLDivElement
    if (el.classList.contains('m-modal')) {
      onCancel()
    }
  }

  return (
    <Portal>
      <div className={cx('m-modal-wrap', className)}>
        <motion.div
          initial={{ top: '100vh', overflowY: 'hidden' }}
          animate={{ top: 0, transitionEnd: { overflowY: 'auto' } }}
          exit={{ top: '100vh' }}
          transition={{ duration: 0.2, delay: 0 }}
          className='m-modal hoverable'
          onClick={handleClose}
          onAnimationComplete={onAnimationEnd}
        >
          <div
            className={cx('m-modal-inner', { 'no-padding': noPadding })}
            style={{ minHeight: height || '5rem' }}
          >
            {children}
          </div>
        </motion.div>
      </div>
    </Portal>
  )
}

export const getPopupContainer = (): HTMLElement => {
  return document.querySelector('.m-modal-inner')!
}

export default BaseModal
