import { useEffect, useState } from 'react'
import { Tabs } from 'antd'

import '../index.scss'
import { Layout, Spinner } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { notiError } from 'utils/notification'
import { getInboundNotiDetails, InboundNoti } from 'services/notifications'
import { getInboundNotiNotes, Note } from 'services/notes'

import DetailAndNote from './DetailAndNote'
import OutboundNotification from './OutboundNotification'

interface Props {
  notifId: number
  onCancel: () => void
}

function ModalInboundNotifDetail({ notifId, onCancel }: Props) {
  const [loading, setLoading] = useState(true)
  const [notifDetail, setNotifDetail] = useState<InboundNoti | null>(null)
  const [notifNotes, setNotifNotes] = useState<Note[]>([])
  const [previewMode, setPreviewMode] = useState(false)

  useEffect(() => {
    handleGetNofiDetail()
    // eslint-disable-next-line
  }, [])

  const handleGetNofiDetail = async () => {
    try {
      const [detail, notes] = await Promise.all([
        getInboundNotiDetails(notifId),
        getInboundNotiNotes(notifId),
      ])
      setNotifDetail(detail.data.data)
      setNotifNotes(notes.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-inbound-notif-detail'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header sticky goBack onGoBack={onCancel}>
          Notification Detail
        </Layout.Header>
        {loading && (
          <Layout.Body fullHeight>
            <Spinner />
          </Layout.Body>
        )}
        {!loading && !!notifDetail && (
          <Layout.Body>
            <Tabs type='card'>
              <Tabs.TabPane tab='Detail & Note' key='notif-detail'>
                <DetailAndNote
                  previewMode={previewMode}
                  setPreviewMode={setPreviewMode}
                  notifDetail={notifDetail}
                  notifNotes={notifNotes}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab='Outbound Notification' key='outbount-notif'>
                <OutboundNotification notifId={notifId} />
              </Tabs.TabPane>
            </Tabs>
          </Layout.Body>
        )}
      </Layout>
    </BaseModal>
  )
}

export default ModalInboundNotifDetail
