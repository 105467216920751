import { Redirect, useHistory } from 'react-router'

import './index.scss'
import DeviceList from './DeviceList'
import DeviceLocation from './DeviceLocation'
import DeviceListProvider from './DeviceList/DeviceListContext'
import DeviceLocationContextProvider from './DeviceLocation/DeviceLocationContext'

function DeviceManagement() {
  const { location } = useHistory()

  if (location.search.endsWith('device-list')) {
    return (
      <DeviceListProvider>
        <DeviceList />
      </DeviceListProvider>
    )
  }
  if (location.search.endsWith('device-location')) {
    return (
      <DeviceLocationContextProvider>
        <DeviceLocation />
      </DeviceLocationContextProvider>
    )
  }
  return <Redirect to={`${location.pathname}?tab=device-list`} />
}

export default DeviceManagement
