import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Switch, Button } from 'antd'
import {
  CheckCircleOutlined,
  StopOutlined,
  DeleteFilled,
} from '@ant-design/icons'

import Table from 'components/Table'
import { Tag } from 'components/Shared'
import ModalViewTagDetail from 'components/Modal/NotificationTag/ViewTagDetail'
import { notiError } from 'utils/notification'
import { deleteTag, getTags, Tag as ITag, updateTag } from 'services/tags'

import { useTagsContext } from './TagContext'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalCreateNotifTag from 'components/Modal/NotificationTag/CreateTag'
import ModalUpdateNotifTag from 'components/Modal/NotificationTag/UpdateTag'

interface Props {
  addTag: boolean
  setAddTag: (v: boolean) => void
}

function TableList({ addTag, setAddTag }: Props) {
  const { loading, tags, search, currentPage, dispatch } = useTagsContext()

  const [viewTagId, setViewTagId] = useState<number | null>(null)
  const [updateTagId, setUpdateTagId] = useState<number | null>(null)
  const [deleteTagId, setDeleteTagId] = useState<number | null>(null)

  useEffect(() => {
    handleGetTags()
    // eslint-disable-next-line
  }, [search, currentPage])

  const handleGetTags = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const response = await getTags({
        pageSize: 10,
        sort: 'id',
        sortDirection: 'desc',
        search,
        currentPage,
      })
      dispatch({ type: 'SET_TAGS', payload: response.data })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleToggleStatus = (id: number) => async (checked: boolean) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      await updateTag(id, { is_active: checked })
      handleGetTags()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleDeleteTag = async () => {
    if (!deleteTagId) return
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      await deleteTag(deleteTagId)
      if (tags.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetTags()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = ({ id }: ITag) => (
    <React.Fragment>
      <Button className='ant-btn-ternary' onClick={() => setViewTagId(id)}>
        View
      </Button>
      <Button className='ant-btn-ternary' onClick={() => setUpdateTagId(id)}>
        Edit
      </Button>
      <Button
        className='ant-btn-ternary text-danger'
        onClick={() => setDeleteTagId(id)}
      >
        Delete
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table loading={loading} empty={!tags.length}>
        {tags.map((tag: ITag) => (
          <Table.Row
            key={tag.id}
            rowKey={tag.id}
            rowActions={renderRowActions(tag)}
          >
            <Table.Col>
              <div className='tag-info'>
                <Tag
                  strokeColor={tag.stroke_color}
                  textColor={tag.text_color}
                  color={tag.color}
                >
                  {tag.name}
                </Tag>
                <div className='active-status'>
                  <Switch
                    defaultChecked={!!tag.is_active}
                    onChange={handleToggleStatus(tag.id)}
                  />
                  <span
                    className={cx(
                      tag.is_active ? 'text-primary' : 'text-placeholder',
                    )}
                  >
                    {tag.is_active ? 'Active' : 'Inactive'}
                  </span>
                </div>
              </div>
              <div style={{ marginTop: 0 }} className='title-match'>
                <span>Title match</span>
                <span>{tag.subject_keywords}</span>
              </div>
              <div className='content-match'>
                <span>Content match</span>
                <span>{tag.body_keywords}</span>
              </div>
              <div className='status'>
                <span>Forward if matches</span>
                <span
                  className={cx(
                    !!tag.is_blocked ? 'text-danger' : 'text-success',
                  )}
                >
                  {!!tag.is_blocked ? (
                    <StopOutlined />
                  ) : (
                    <CheckCircleOutlined />
                  )}
                  {!!tag.is_blocked ? 'No' : 'Yes'}
                </span>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {viewTagId && (
          <ModalViewTagDetail
            tagId={viewTagId}
            onCancel={() => setViewTagId(null)}
          />
        )}
        {addTag && (
          <ModalCreateNotifTag
            onSuccess={handleGetTags}
            onCancel={() => setAddTag(false)}
          />
        )}
        {updateTagId && (
          <ModalUpdateNotifTag
            tagId={updateTagId}
            onSuccess={handleGetTags}
            onCancel={() => setUpdateTagId(null)}
          />
        )}
        {deleteTagId && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this tag?'
            description='Action can’t be undone'
            onConfirm={handleDeleteTag}
            onCancel={() => setDeleteTagId(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
