import axios from './axios'

export interface Config {
  pusher_app_cluster: string
  pusher_app_key: string
  stripe_key: string
}

export const getConfig = () => {
  return axios.get('/api/config')
}
