import React, { useEffect, useReducer, useRef } from 'react'
import Hls, { ErrorData, Events } from 'hls.js'

import { Live } from 'components/Icons'
import { DeviceInfo, Spinner } from 'components/Shared'
import { Device, getDeviceDetails } from 'services/devices'

import { Player as IPlayer } from './Types'
import moment from 'moment'

interface Props {
  player: IPlayer
  layout: number
  tabFullscreen: boolean
}

interface State {
  slideUp: boolean
  loading: boolean
  error: string
  device: Device | null
  showControls: boolean
  videoLoading: boolean
  playing: boolean
  currentTime: number
  retryCount: number
}

const initState: State = {
  slideUp: true,
  loading: true,
  error: '',
  device: null,
  showControls: true,
  videoLoading: false,
  playing: false,
  currentTime: 0,
  retryCount: 0,
}

function Player({ player, layout, tabFullscreen }: Props) {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  const playerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const hls = useRef<Hls | null>(null)

  const { loading, error, device, ...s } = state
  const tz = device?.timezone || 'America/New_York'
  const url = device?.deviceLiveStream?.playlist_url

  useEffect(() => {
    handleGetDeviceInfo()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!url) return
    setTimeout(init, 0)
    return () => cleanUp()
    // eslint-disable-next-line
  }, [url])

  const handleGetDeviceInfo = async () => {
    try {
      setState({ loading: true })
      const response = await getDeviceDetails(player.device.id)
      const data: Device = response.data.data

      if (!data.deviceLiveStream || !data.deviceLiveStream.playlist_url) {
        return setState({
          error: 'This device does not support live streaming.',
        })
      }
      if (data.deviceLiveStream.meta?.codec === 'H265') {
        return setState({
          error: 'H265 streaming is not supported by browsers.',
        })
      }
      setState({ device: data, error: '' })
    } catch (err: any) {
      setState({
        error:
          err?.status === 404
            ? `Device with id: ${player.device.id} does not exist.`
            : 'Something went wrong, please try again.',
      })
    } finally {
      setState({ loading: false })
    }
  }

  const init = () => {
    const videoEl = videoRef.current
    if (!videoEl || !url) return

    setState({ videoLoading: true })
    if (!Hls.isSupported()) {
      videoEl.src = url
    } else {
      const newHls = new Hls()
      newHls.loadSource(url)
      newHls.attachMedia(videoEl)
      newHls.on(Events.ERROR, handleRetry)
      hls.current = newHls
    }
  }

  const cleanUp = () => {
    hls.current?.stopLoad()
    hls.current?.detachMedia()
    hls.current?.destroy()
    hls.current = null
  }

  const handleRetry = (_: any, errData: ErrorData) => {
    if (!errData.fatal) return
    cleanUp()

    const newRetryCount = s.retryCount + 1
    if (newRetryCount > 3) {
      return setState({
        loading: false,
        videoLoading: false,
        error: 'Cannot load this livestream',
      })
    }
    setState({
      ...initState,
      slideUp: false,
      retryCount: newRetryCount,
      error: 'Reconnecting...',
    })
    handleGetDeviceInfo()
  }

  const handleReady = () => {
    videoRef.current!.play().catch(console.error)
    setState({ videoLoading: false })
  }

  const handleTimeUpdate = () => {
    setState({ currentTime: videoRef.current?.currentTime || 0 })
  }

  return (
    <div ref={playerRef} className='player'>
      {(layout < 4 || tabFullscreen || player.fullscreen) && (
        <React.Fragment>
          <DeviceInfo device={player.device} />
          <div className='live-icon'>
            <Live type='danger' />
          </div>
        </React.Fragment>
      )}

      {(loading || s.videoLoading) && <Spinner />}
      {!!error && <div className='player-message'>{error}</div>}

      <video
        ref={videoRef}
        muted={player.muted}
        onLoadedMetadata={handleReady}
        onTimeUpdate={handleTimeUpdate}
        onPlaying={() => setState({ playing: true })}
        onPause={() => setState({ playing: false })}
        onCanPlay={() => setState({ videoLoading: false })}
        onWaiting={() => setState({ videoLoading: true })}
      />

      {layout < 4 && (
        <div className='current-datetime'>
          <span className='date' style={{ marginRight: 4 }}>
            {moment().tz(tz).format('MM/DD/YYYY')}
          </span>
          <span className='time'>{moment().tz(tz).format('HH:mm:ss')}</span>
        </div>
      )}
    </div>
  )
}

export default Player
