import { hmsToSeconds } from 'utils/dateTime'
import { MAX_VIDEO_LENGTH_SEC } from 'utils/device'
import { usePlaybackContext, Player } from '../PlaybackContext'

interface Props {
  graphWidth: number
  widthPerSec: number
  indicatorPosition: number
  onScroll: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void
}

function TimeGraph(props: Props) {
  const { graphWidth, indicatorPosition, widthPerSec, onScroll } = props
  const { players } = usePlaybackContext()

  const getEndTime = (endTime: string) => {
    const end = hmsToSeconds(endTime)
    return end > MAX_VIDEO_LENGTH_SEC ? MAX_VIDEO_LENGTH_SEC : end
  }

  const renderGraph = (player: Player | undefined) => {
    if (!player) return null
    return (
      <div key={player.index} className='graph' style={{ width: graphWidth }}>
        <div className='indicator' style={{ width: graphWidth }}>
          <div className='indicator-inner' style={{ width: graphWidth - 16 }}>
            <div
              className='indicator-line'
              style={{ left: indicatorPosition }}
            />
          </div>
        </div>
        {player.segments.map(({ starttime, endtime }, idx) => {
          const start = widthPerSec * hmsToSeconds(starttime) + 8
          const end = widthPerSec * getEndTime(endtime) + 8
          return (
            <div
              key={idx}
              className='segment'
              style={{ left: start, width: end - start }}
            />
          )
        })}
        {player.notifs.map(({ id, tags, starttime }) => (
          <div
            key={id}
            className='notification'
            style={{ left: widthPerSec * hmsToSeconds(starttime) + 8 }}
          >
            {tags.map(tag => (
              <span key={tag.id} style={{ background: tag.color }} />
            ))}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className='playback-timegraph' onScroll={onScroll}>
      <div
        style={{
          width: graphWidth,
          height: 1,
          position: 'absolute',
          pointerEvents: 'none',
        }}
      />
      {players.map(renderGraph)}
    </div>
  )
}

export default TimeGraph
