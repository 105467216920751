import { useState, useEffect } from 'react'
import { Tabs, Form, notification } from 'antd'

import '../index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout, Spinner } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { Tag, getTagDetails, updateTag } from 'services/tags'
import { useAppSelector } from 'store'

import TagDetails from './TagDetails'
import AffectedDevices from './AffectedDevices'
import Recipients from './Recipients'
import {
  FormValues,
  formValues,
  mapValuesToOptions,
  getOptionsId,
} from './FormHelpers'

interface Props {
  tagId: number
  onSuccess?: () => void
  onCancel: () => void
}

function ModalUpdateNotifTag({ tagId, onSuccess, onCancel }: Props) {
  const [initLoading, setInitLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<FormValues>(formValues)

  const [isActive, setIsActive] = useState(false)
  const { currentUser } = useAppSelector(state => state.auth)
  const [form] = Form.useForm()

  useEffect(() => {
    handleGetTagDetails()
    // eslint-disable-next-line
  }, [])

  const handleGetTagDetails = async () => {
    try {
      const response = await getTagDetails(tagId)
      const tag = response.data.data as Tag
      setValues({
        tagDetails: {
          name: tag.name,
          stroke_color: tag.stroke_color!,
          text_color: tag.text_color!,
          color: tag.color!,
          subject_keywords: tag.subject_keywords,
          body_keywords: tag.body_keywords,
          search_in: tag.search_in,
          has_attachments: !!tag.has_attachments,
        },
        affectedDevices: {
          apply_to_all: !!tag.apply_to_all,
          devices: mapValuesToOptions(tag.devices),
        },
        recipients: {
          is_blocked: !!tag.is_blocked,
          channels: tag.channels || [],
          phone_recipients: mapValuesToOptions(tag.phone_recipients),
          whatsapp_recipients: mapValuesToOptions(tag.whatsapp_recipients),
          email_recipients: mapValuesToOptions(tag.email_recipients),
          slack_webhook: tag.slack_webhook || '',
          webhook: tag.webhook || '',
          teams_webbhook: tag.teams_webbhook || '',
        },
      })
      setIsActive(!!tag.is_active)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setInitLoading(false)
    }
  }

  const handleTagColorChange = (c: {
    strokeColor: string
    textColor: string
    color: string
  }) => {
    setValues(prev => ({
      ...prev,
      tagDetails: {
        ...prev.tagDetails,
        stroke_color: c.strokeColor,
        text_color: c.textColor,
        color: c.color,
      },
    }))
  }

  const handleFormFailed = () => {
    notification.destroy()
    notiError({
      message: 'Invalid form',
      description: 'The given data was invalid',
    })
  }

  const handleSubmit = () => {
    const v = form.getFieldsValue()
    const { search_in, subject_keywords, body_keywords } = v
    const errors = []

    if (search_in === 'subject_and_body') {
      if (!subject_keywords) {
        errors.push({
          name: 'subject_keywords',
          errors: ['This field is required'],
        })
      }
      if (!body_keywords) {
        errors.push({
          name: 'body_keywords',
          errors: ['This field is required'],
        })
      }
    }
    if (search_in === 'subject_or_body') {
      if (!body_keywords && !subject_keywords) {
        errors.push(
          {
            name: 'body_keywords',
            errors: ['Please choose at least one criteria'],
          },
          {
            name: 'subject_keywords',
            errors: ['Please choose at least one criteria'],
          },
        )
      }
    }
    if (!values.recipients.channels.length && !values.recipients.is_blocked) {
      errors.push({ name: 'channels', errors: ['This field is required'] })
    }
    if (!!errors.length) {
      handleFormFailed()
      return form.setFields(errors)
    }
    return handleUpdateTag()
  }

  const handleUpdateTag = async () => {
    try {
      setLoading(true)
      const v = form.getFieldsValue()
      await updateTag(tagId, {
        name: v.name,
        is_active: isActive,
        is_blocked: values.recipients.is_blocked,
        has_attachments: v.has_attachments,
        apply_to_all: values.affectedDevices.apply_to_all,
        search_in: v.search_in,
        subject_keywords: v.subject_keywords,
        body_keywords: v.body_keywords,
        account_id: currentUser!.id,
        channels: values.recipients.channels,
        webhook: v.webhook,
        slack_webhook: v.slack_webhook,
        teams_webbhook: v.teams_webbhook,
        email_recipient_ids: getOptionsId(v.email_recipients),
        phone_recipient_ids: getOptionsId(v.phone_recipients),
        whatsapp_recipient_ids: getOptionsId(v.whatsapp_recipients),
        device_ids: getOptionsId(values.affectedDevices.devices),
        stroke_color: values.tagDetails.stroke_color,
        text_color: values.tagDetails.text_color,
        color: values.tagDetails.color,
      })
      setLoading(false)
      onCancel()
      notiSuccess({ message: 'Tag updated' })
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const { tagDetails, affectedDevices, recipients } = values

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-update-notif-tag'
      onCancel={onCancel}
    >
      {initLoading && (
        <Layout>
          <Layout.Header sticky goBack onGoBack={onCancel}>
            Update Tag
          </Layout.Header>
          <Layout.Body fullHeight>
            <Spinner />
          </Layout.Body>
        </Layout>
      )}
      {!initLoading && tagDetails.name && (
        <Form
          form={form}
          layout='vertical'
          initialValues={{ ...tagDetails, ...affectedDevices, ...recipients }}
          onFinish={handleSubmit}
          onFinishFailed={handleFormFailed}
        >
          <Layout>
            <Layout.Header
              goBack
              onGoBack={loading ? () => {} : onCancel}
              actionRight={
                <button
                  type='submit'
                  className='base-btn text-primary hoverable text-500'
                  disabled={loading}
                >
                  Save
                </button>
              }
            >
              Update Tag
            </Layout.Header>
            <Layout.Body>
              <Tabs type='card'>
                <Tabs.TabPane forceRender key='1' tab='Tag Details'>
                  <TagDetails
                    form={form}
                    stroke_color={tagDetails.stroke_color}
                    text_color={tagDetails.text_color}
                    color={tagDetails.color}
                    onTagColorChange={handleTagColorChange}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane forceRender key='2' tab='Affected Devices'>
                  <AffectedDevices
                    applyAll={affectedDevices.apply_to_all}
                    devices={affectedDevices.devices}
                    onApplyAllChange={v => {
                      setValues(prev => ({
                        ...prev,
                        affectedDevices: {
                          ...prev.affectedDevices,
                          apply_to_all: v,
                        },
                      }))
                    }}
                    onDeviceChange={v => {
                      setValues(prev => ({
                        ...prev,
                        affectedDevices: {
                          ...prev.affectedDevices,
                          devices: v,
                        },
                      }))
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane forceRender key='3' tab='Recipients'>
                  <Recipients
                    isBlock={recipients.is_blocked}
                    channels={recipients.channels}
                    onBlockChange={v => {
                      setValues(prev => ({
                        ...prev,
                        recipients: {
                          ...prev.recipients,
                          is_blocked: v,
                        },
                      }))
                    }}
                    onChannelChange={v => {
                      setValues(prev => ({
                        ...prev,
                        recipients: {
                          ...prev.recipients,
                          channels: v,
                        },
                      }))
                      form.setFields([{ name: 'channels', errors: [] }])
                    }}
                  />
                </Tabs.TabPane>
              </Tabs>
            </Layout.Body>
          </Layout>
        </Form>
      )}
    </BaseModal>
  )
}

export default ModalUpdateNotifTag
