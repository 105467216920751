import { Link } from 'react-router-dom'

import '../index.scss'
import MSTeam from 'assets/brand-logos/microsoft-team.svg'
import Slack from 'assets/brand-logos/slack.svg'
import Webhook from 'assets/brand-logos/webhook.svg'
import WhatsApp from 'assets/brand-logos/whatsapp.svg'

import { EmailFilled, SmsFilled } from 'components/Icons'
import StatisticsCard from 'components/Cards/StatisticsCard'
import { useAppSelector } from 'store'

import NotifContextProvider from './NotifContext'
import TableListHeader from './TableListHeader'
import TableList from './TableList'

type Resource = {
  available?: number
  enabled?: boolean
  used: number | null
  total: number | null
}

interface Props {
  deviceId: number
}

function InboundNotifications({ deviceId }: Props) {
  const { stats } = useAppSelector(state => state.stats)

  const upgradeLink = () => (
    <Link to='/settings/payment-settings/plans'>Upgrade</Link>
  )

  const renderStats = (key: 'email' | 'sms' | 'whatsapp') => {
    if (!stats) return upgradeLink()
    const stat = stats[key] as Resource
    const { total, used, available, enabled } = stat
    if (enabled !== undefined && !enabled) return upgradeLink()
    return `${available || used}/${total}`
  }

  return (
    <div className='tab-inbound-notifications'>
      <div className='device-stats'>
        <StatisticsCard
          icon={<EmailFilled type='primary' />}
          title='Email'
          content={renderStats('email')}
        />
        <StatisticsCard
          icon={<SmsFilled type='primary' />}
          title='SMS'
          content={renderStats('sms')}
        />
        <StatisticsCard
          imgSrc={WhatsApp}
          title='WhatsApp'
          content={renderStats('whatsapp')}
        />
        <StatisticsCard
          imgSrc={Webhook}
          title='Webhook'
          content={stats!.webhook_enabled ? 'Unlimited' : upgradeLink()}
        />
        <StatisticsCard
          imgSrc={Slack}
          title='Slack'
          content={stats!.slack_enabled ? 'Unlimited' : upgradeLink()}
        />
        <StatisticsCard
          imgSrc={MSTeam}
          title='Microsoft Teams'
          content={stats!.teams_enabled ? 'Unlimited' : upgradeLink()}
        />
      </div>
      <NotifContextProvider>
        <TableListHeader />
        <TableList deviceId={deviceId} />
      </NotifContextProvider>
    </div>
  )
}

export default InboundNotifications
