import { Link, useLocation } from 'react-router-dom'
import { Badge } from 'antd'
import cx from 'classnames'

import './index.scss'
import { menu } from 'configs/navigation'
import { useAppSelector } from 'store'

function Menu() {
  const { pathname } = useLocation()

  const { unreadCount } = useAppSelector(state => state.notification)

  return (
    <div className='menu'>
      {menu.map(({ key, label, navLink, Icon }) => {
        const active = pathname === navLink
        const I = <Icon type={active ? 'primary' : 'secondary'} />
        return (
          <div key={key} className={cx('menu-item', { active })}>
            <Link to={navLink || '/'}>
              {key === 'notifications' && !!unreadCount ? (
                <Badge count={unreadCount} overflowCount={9}>
                  {I}
                </Badge>
              ) : (
                I
              )}
              <span>{label}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default Menu
