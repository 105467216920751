import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function UnorderedList({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='19'
        height='19'
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M16.626 2.96771H4.7552C4.09959 2.96771 3.56812 3.49919 3.56812 4.1548C3.56812 4.8104 4.09959 5.34188 4.7552 5.34188H16.626C17.2816 5.34188 17.8131 4.8104 17.8131 4.1548C17.8131 3.49919 17.2816 2.96771 16.626 2.96771Z'
            fill={c}
          />
          <path
            d='M2.38081 4.15474C2.37948 3.84042 2.25354 3.53946 2.03063 3.31785C1.56763 2.85759 0.819838 2.85759 0.356839 3.31785C0.13389 3.53946 0.00794789 3.84042 0.00664952 4.15474C-0.00221651 4.23164 -0.00221651 4.30932 0.00664952 4.38622C0.0200784 4.46357 0.0419652 4.53921 0.0719391 4.61177C0.103805 4.68206 0.141532 4.74958 0.184712 4.81357C0.227336 4.88012 0.277082 4.94181 0.333097 4.99757C0.387109 5.05136 0.446797 5.09914 0.51116 5.14002C0.573667 5.18554 0.641442 5.22341 0.712964 5.25279C0.791645 5.2936 0.87552 5.32357 0.962251 5.34182C1.03915 5.35043 1.11683 5.35043 1.19373 5.34182C1.5072 5.34208 1.80805 5.21833 2.03063 4.99757C2.08664 4.94181 2.13639 4.88012 2.17901 4.81357C2.22219 4.74958 2.25992 4.68206 2.29178 4.61177C2.32966 4.5401 2.35952 4.46446 2.38081 4.38622C2.38968 4.30932 2.38968 4.23164 2.38081 4.15474Z'
            fill={c}
          />
          <path
            d='M2.38076 9.49666C2.38959 9.41976 2.38959 9.34208 2.38076 9.26518C2.36024 9.18857 2.33034 9.11483 2.29173 9.04557C2.26116 8.97275 2.22336 8.90315 2.17895 8.83783C2.13781 8.77202 2.08788 8.71211 2.03057 8.65977C1.56757 8.19951 0.819781 8.19951 0.356781 8.65977C0.133832 8.88138 0.00789017 9.18234 0.0065918 9.49666C0.00889177 9.65309 0.0390511 9.80782 0.095623 9.95369C0.125374 10.0239 0.161135 10.0914 0.20246 10.1555C0.247607 10.22 0.299319 10.2797 0.356781 10.3336C0.409235 10.3908 0.469109 10.4407 0.534844 10.4819C0.597351 10.5275 0.665089 10.5654 0.736648 10.5947C0.809023 10.6252 0.884736 10.6471 0.962193 10.66C1.03813 10.677 1.11585 10.685 1.19367 10.6837C1.27058 10.6926 1.34825 10.6926 1.42516 10.6837C1.50068 10.6708 1.57443 10.6489 1.64477 10.6185C1.71825 10.5893 1.78799 10.5514 1.85251 10.5057C1.91824 10.4644 1.97811 10.4145 2.03057 10.3573C2.08777 10.3048 2.1377 10.245 2.17895 10.1792C2.22458 10.1168 2.26246 10.049 2.29173 9.97743C2.33227 9.89863 2.36221 9.8148 2.38076 9.72814C2.38988 9.65124 2.38988 9.57356 2.38076 9.49666Z'
            fill={c}
          />
          <path
            d='M2.38083 14.8385C2.38962 14.7616 2.38962 14.6839 2.38083 14.607C2.36032 14.5285 2.33042 14.4528 2.2918 14.3815C2.25993 14.3112 2.22221 14.2437 2.17903 14.1797C2.13777 14.114 2.08784 14.0541 2.03064 14.0016C1.56764 13.5414 0.819853 13.5414 0.356854 14.0016C0.299651 14.0541 0.24972 14.114 0.208469 14.1797C0.165288 14.2437 0.127562 14.3112 0.0956958 14.3815C0.0649058 14.4538 0.0429819 14.5295 0.0304062 14.607C0.0136758 14.683 0.00573716 14.7607 0.00666456 14.8385C0.00800003 15.1528 0.133942 15.4538 0.356854 15.6754C0.409308 15.7326 0.469182 15.7826 0.534916 15.8238C0.597424 15.8694 0.665162 15.9072 0.73672 15.9366C0.809095 15.967 0.884809 15.989 0.962266 16.0019C1.0382 16.0189 1.11592 16.0269 1.19375 16.0256C1.27065 16.0345 1.34833 16.0345 1.42523 16.0256C1.50076 16.0127 1.5745 15.9907 1.64484 15.9603C1.71833 15.9312 1.78807 15.8933 1.85258 15.8475C1.91831 15.8063 1.97819 15.7564 2.03064 15.6992C2.08784 15.6467 2.13777 15.5868 2.17903 15.5211C2.22469 15.4587 2.26257 15.3909 2.2918 15.3193C2.33231 15.2405 2.36224 15.1567 2.38083 15.07C2.38996 14.9931 2.38996 14.9154 2.38083 14.8385Z'
            fill={c}
          />
          <path
            d='M17.8131 8.30957H4.7552C4.09959 8.30957 3.56812 8.84105 3.56812 9.49665C3.56812 10.1523 4.09959 10.6837 4.7552 10.6837H17.8131C18.4687 10.6837 19.0002 10.1523 19.0002 9.49665C19.0002 8.84105 18.4687 8.30957 17.8131 8.30957Z'
            fill={c}
          />
          <path
            d='M12.4712 13.6514H4.7552C4.09959 13.6514 3.56812 14.1829 3.56812 14.8385C3.56812 15.4941 4.09959 16.0256 4.7552 16.0256H12.4712C13.1268 16.0256 13.6583 15.4941 13.6583 14.8385C13.6583 14.1829 13.1268 13.6514 12.4712 13.6514Z'
            fill={c}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect width='19' height='19' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
