import React from 'react'
import moment from 'moment'
import { Button } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

import { DeviceLiveStream } from 'services/devices'

interface Props {
  camera: DeviceLiveStream | null
  onRefresh: () => void
}

function CameraStats({ camera, onRefresh }: Props) {
  const getFormatDistanceToNow = (dateStr: string | null) => {
    if (!dateStr) {
      return 'down'
    }
    return 'up for about ' + moment(dateStr).fromNow()
  }

  const getFrameRate = () => {
    const frameRate = camera?.meta?.frameRate
    return frameRate === 'NaN' ? 'Unavailable' : frameRate || '0.00'
  }

  return (
    <div className='camera-stats'>
      <div className='stat-title'>
        <h3>Camera Stats</h3>
        <Button className='ant-btn-secondary lighter' onClick={onRefresh}>
          <SyncOutlined />
          Refresh
        </Button>
      </div>
      {!camera && (
        <div className='text-placeholder'>
          This device is not using a camera license.
        </div>
      )}
      {camera && (
        <React.Fragment>
          <div className='stat-item'>
            <span>Streaming</span>
            <span>
              {camera.is_streaming
                ? getFormatDistanceToNow(camera.streaming_start_timestamp)
                : getFormatDistanceToNow(camera.streaming_stop_timestamp)}
            </span>
          </div>
          <div className='stat-item'>
            <span>Recording</span>
            <span>
              {camera.is_recording
                ? getFormatDistanceToNow(camera.recording_start_timestamp)
                : getFormatDistanceToNow(camera.recording_stop_timestamp)}
            </span>
          </div>
          <div className='stat-item'>
            <span>Bit Rate</span>
            <span>{camera.bitrate} Mbits / sec</span>
          </div>
          <div className='stat-item'>
            <span>Video codec</span>
            <span>{camera.video_codec}</span>
          </div>
          <div className='stat-item'>
            <span>Profile</span>
            <span>{camera.meta?.profile}</span>
          </div>
          <div className='stat-item'>
            <span>Resolution</span>
            <span>{camera.meta?.displaySize}</span>
          </div>
          <div className='stat-item'>
            <span>Frame Rate</span>
            <span>{getFrameRate()}</span>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default CameraStats
