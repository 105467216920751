import axios from './axios'

export interface Transaction {
  amount: number
  card_last_four: number
  date: string
  id: number
  receipt_url: string
  transaction_id: string
}

export const getTransactionHistory = ({
  pageSize,
  sort,
  sortDirection,
  currentPage,
}: {
  pageSize: number
  sort: string
  sortDirection: string
  currentPage: number
}) => {
  return axios.get(
    `/api/transactions?page=${currentPage}&limit=${pageSize}&sort=${sortDirection}&order_by=${sort}`,
  )
}
