import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ExitFullscreen({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g clipPath='url(#clip0)'>
          <path
            d='M5.5372 6.4657C6.19979 6.4657 6.74023 5.92526 6.74023 5.26266L6.74023 -0.00107956L4.33787 -0.00107956L4.33787 4.06333L0.277158 4.06333L0.277158 6.4657L5.5372 6.4657Z'
            fill={c}
          />
          <path
            d='M15.0131 6.46313L20.2769 6.46313V4.06077H16.2161V5.8651e-05H13.8138V5.2601C13.8138 5.92269 14.3505 6.46313 15.0131 6.46313Z'
            fill={c}
          />
          <path
            d='M16.2198 20V15.9393H20.2805V13.5369H15.0168C14.3542 13.5369 13.8137 14.0774 13.8137 14.74V20H16.2198Z'
            fill={c}
          />
          <path
            d='M4.34128 15.9432V20.0039H6.74365L6.74365 14.7402C6.74365 14.0776 6.20321 13.5371 5.54062 13.5371H0.276875V15.9395H4.34128V15.9432Z'
            fill={c}
          />
        </g>
        <defs>
          <clipPath id='clip0'>
            <rect
              width='20'
              height='20'
              fill='white'
              transform='translate(0.276855)'
            />
          </clipPath>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
