import { useState } from 'react'
import { Alert, Button, Form, Input } from 'antd'

import './index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { createRecipient } from 'services/recipients'
import { useAppSelector } from 'store'

const rules = [{ required: true, message: 'This field is required' }]

interface FormValues {
  name: string
  email: string
  phone: string
}

interface Props {
  onSuccess?: () => void
  onCancel: () => void
}

function ModalCreateRecipient({ onSuccess, onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const { currentUser } = useAppSelector(state => state.auth)

  const handleSubmit = async (values: FormValues) => {
    try {
      setLoading(true)
      await createRecipient({
        ...values,
        phone: values.phone || undefined,
        wants_email: true,
        wants_sms: !!values.phone,
        wants_whatsapp: !!values.phone,
        account_id: currentUser!.account_id,
      })
      setLoading(false)
      notiSuccess({ message: 'Recipient created' })
      onCancel()
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      onCancel={onCancel}
      className='modal-create-recipient'
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          Create Recipient
        </Layout.Header>
        <Layout.Body>
          <Alert
            type='success'
            message='An email will be sent to the recipient to verify their address'
          />
          <Form
            layout='vertical'
            initialValues={{ name: '', email: '', phone: '' }}
            onFinish={handleSubmit}
          >
            <Form.Item name='name' label='Recipient Name' rules={rules}>
              <Input
                size='large'
                placeholder="Enter the recipient's full name"
              />
            </Form.Item>
            <Form.Item
              name='email'
              label='Email'
              rules={[
                ...rules,
                { type: 'email', message: 'Invalid email address' },
              ]}
            >
              <Input
                size='large'
                placeholder='Enter the recipient’s email address'
              />
            </Form.Item>
            <Form.Item name='phone' label='Phone Number'>
              <Input
                size='large'
                placeholder='Enter the recipient’s phone number'
              />
            </Form.Item>
            <Button
              disabled={loading}
              htmlType='submit'
              type='primary'
              block
              size='large'
            >
              Create Recipient
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalCreateRecipient
