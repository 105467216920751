import { Redirect, useHistory } from 'react-router'

import './index.scss'
import AllDeviceNotifications from './AllDeviceNotifications'
import NotifContextProvider from './AllDeviceNotifications/NotifContext'
import NotificationTags from './NotificationTags'
import TagContextProvider from './NotificationTags/TagContext'

function DeviceManagement() {
  const { location } = useHistory()
  if (location.search.endsWith('all-device-notification')) {
    return (
      <NotifContextProvider>
        <AllDeviceNotifications />
      </NotifContextProvider>
    )
  }
  if (location.search.endsWith('notification-tag')) {
    return (
      <TagContextProvider>
        <NotificationTags />
      </TagContextProvider>
    )
  }
  return <Redirect to={`${location.pathname}?tab=all-device-notification`} />
}

export default DeviceManagement
