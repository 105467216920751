import React, { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import moment from 'moment'

import {
  FileTextFilled,
  LoginFilled,
  PencilFilled,
  PlusFilled,
  RecordStart,
  RecordStop,
  TrashFilled,
  WifiOffline,
  WifiOnline,
} from 'components/Icons'
import Table from 'components/Table'
import { MM_DD_YYYY_HH_MM_P } from 'utils/dateTime'
import { upperFirstLetter } from 'utils/functions'
import { ActivityLog, getAllLogs } from 'services/activityLogs'

import { useLogContext } from './LogContext'

function TableList() {
  const history = useHistory()
  const location = useLocation()

  const { loading, logs, search, currentPage, userId, dateRange, dispatch } =
    useLogContext()

  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(location.search))
    const userId = queryParams['user-id']
    if (!!userId && !isNaN(+userId) && +userId > 0) {
      dispatch({ type: 'SET_FILTER', payload: { userId: +userId } })
      history.replace(location.pathname)
    } else {
      handleGetActivityLogs()
    }
    // eslint-disable-next-line
  }, [currentPage, search, userId, dateRange])

  const handleGetActivityLogs = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      const response = await getAllLogs({
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        currentPage,
        search,
        userId,
        dateRange: dateRange ? dateRange.join(',') : undefined,
      })
      dispatch({ type: 'SET_LOGS', payload: response.data })
    } catch {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderLogDesc = ({ description: desc }: ActivityLog) => {
    let Icon = FileTextFilled
    if (desc.endsWith('started')) {
      Icon = RecordStart
    }
    if (desc.endsWith('stopped')) {
      Icon = RecordStop
    }
    if (desc.endsWith('online')) {
      Icon = WifiOnline
    }
    if (desc.endsWith('offline')) {
      Icon = WifiOffline
    }
    if (desc.startsWith('deleted')) {
      Icon = TrashFilled
    }
    if (desc.startsWith('created')) {
      Icon = PlusFilled
    }
    if (desc.startsWith('updated')) {
      Icon = PencilFilled
    }
    if (desc.startsWith('Login')) {
      Icon = LoginFilled
    }
    return (
      <React.Fragment>
        <Icon type='secondary' />
        {upperFirstLetter(desc)}
      </React.Fragment>
    )
  }

  return (
    <Table loading={loading} empty={!logs.length}>
      {logs.map((log: ActivityLog) => (
        <Table.Row key={log.id} rowKey={log.id}>
          <Table.Col>
            <div className='log-description'>{renderLogDesc(log)}</div>
            <div className='happened-at text-placeholder'>
              Happened at: {moment(log.created_at).format(MM_DD_YYYY_HH_MM_P)}
            </div>
            <div className='initiated-by text-placeholder'>
              Initiated by: {(log.causer_type || 'System').replace('App\\', '')}
            </div>
            <div className='ip-address text-placeholder'>
              IP address: {log.properties?.ip || '---'}
            </div>
          </Table.Col>
        </Table.Row>
      ))}
    </Table>
  )
}

export default TableList
