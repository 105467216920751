import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function CloudFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='10'
        viewBox='0 0 16 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M13.5916 4.09591C13.3815 2.751 12.2155 1.71875 10.8125 1.71875C10.2847 1.71875 9.7775 1.86478 9.33759 2.13716C8.66834 1.01241 7.4635 0.3125 6.125 0.3125C4.05728 0.3125 2.375 1.99478 2.375 4.0625C2.375 4.07531 2.375 4.08859 2.37547 4.10141C1.04656 4.32525 0 5.48384 0 6.875C0 8.42591 1.29284 9.6875 2.84375 9.6875H13.1562C14.7072 9.6875 16 8.42591 16 6.875C16 5.47197 14.9365 4.30603 13.5916 4.09591Z'
          fill='url(#cloud_linear)'
        />
        <defs>
          <linearGradient
            id='cloud_linear'
            x1='0'
            y1='0.3125'
            x2='0'
            y2='9.6875'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
