import { useParams, useLocation, Redirect } from 'react-router-dom'

import ViewSharedFile from './ViewSharedFile'

type RouteQuery = {
  email: string
  signature: string
}

function SharedFile() {
  const { id } = useParams() as { id: string }
  const { search } = useLocation()

  const query = Object.fromEntries(new URLSearchParams(search)) as RouteQuery
  const isValidUrl = id && !isNaN(+id) && !!query.email && !!query.signature

  if (!isValidUrl) {
    return <Redirect to='/404' />
  }

  return <ViewSharedFile id={+id} {...query} />
}

export default SharedFile
