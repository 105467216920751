import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Button } from 'antd'
import { ClockCircleFilled, StopFilled } from '@ant-design/icons'

import { DeviceInfo, Layout, Thumbnail } from 'components/Shared'
import { PlayFilled } from 'components/Icons'
import BaseModal from 'components/Modal/BaseModal'
import Table from 'components/Table'
import { Device } from 'services/devices'

interface Props {
  type: string
  devices: Device[]
  onCancel: () => void
}

function ModalManageDevices({ type, devices, onCancel }: Props) {
  const [groupedDevices, setGroupedDevices] = useState<any[]>([])
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])

  useEffect(() => {
    const temp: any = {}
    devices.forEach((device: Device) => {
      const locationName = device?.location?.name || 'Unknown'
      const location = temp[locationName] || {
        id: locationName,
        __location: locationName,
        children: [],
      }
      location.children = [...location.children, device]
      temp[locationName] = location
    })

    // setGroupedDevices(Object.values(temp))
    setGroupedDevices(Object.values(temp))
    setExpandedKeys(Object.keys(temp))
  }, [devices])

  const renderRecording = (device: Device) => {
    const { enable_recording, is_licensed } = device
    const isRecording = !is_licensed || !enable_recording ? 'OFF' : 'ON'
    const textClass = `text-${isRecording ? 'success' : 'placeholder'}`
    return (
      <span className={cx('status text-bold', textClass)}>
        <ClockCircleFilled className={textClass} /> {isRecording ? 'ON' : 'OFF'}
      </span>
    )
  }

  const renderLiveStream = (device: Device) => {
    if (!!device.is_licensed && !!device.deviceLiveStream?.is_streaming) {
      return (
        <Button className='ant-btn-secondary'>
          <PlayFilled /> Play
        </Button>
      )
    }
    return (
      <span className='status text-bold text-placeholder'>
        <StopFilled className='text-placeholder' /> OFF
      </span>
    )
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-manage-devices'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header bordered sticky goBack onGoBack={onCancel}>
          {type === 'online' ? 'Online' : 'Offline'} Devices List
        </Layout.Header>
        <Layout.Body>
          <Table
            empty={!devices.length}
            expandable={{
              expandedKeys: expandedKeys,
              onExpand: newKeys => setExpandedKeys(newKeys as string[]),
            }}
          >
            {groupedDevices.map(g => (
              <Table.RowGroup key={g.id} groupKey={g.id}>
                <Table.RowHead>
                  {g.__location} ({g.children.length})
                </Table.RowHead>
                {g.children.map((d: Device) => (
                  <Table.Row key={d.id} rowKey={d.id}>
                    <Table.Col width='5rem' className='col-device-thumbnail'>
                      <Thumbnail rounded src={d.thumbnail_url || undefined} />
                    </Table.Col>
                    <Table.Col className='col-device-info'>
                      <DeviceInfo
                        showLicense
                        device={d}
                        to={`/devices/${d.id}?tab=device-details`}
                      />
                      <div>
                        <div>Recording</div>
                        <div>{renderRecording(d)}</div>
                      </div>
                      <div>
                        <div>Livestream</div>
                        <div>{renderLiveStream(d)}</div>
                      </div>
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.RowGroup>
            ))}
          </Table>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalManageDevices
