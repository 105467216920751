import { Redirect, useHistory } from 'react-router'

import './index.scss'

import UserManagement from './UserManagement'
import UserContextProvider from './UserManagement/UserContext'
import RecipientManagement from './RecipientManagement'
import RecipientContextProvider from './RecipientManagement/RecipientContext'

function UsersRecipients() {
  const { location } = useHistory()

  if (location.search.endsWith('user-management')) {
    return (
      <UserContextProvider>
        <UserManagement />
      </UserContextProvider>
    )
  }
  if (location.search.endsWith('recipient-management')) {
    return (
      <RecipientContextProvider>
        <RecipientManagement />
      </RecipientContextProvider>
    )
  }

  return <Redirect to={`${location.pathname}?tab=user-management`} />
}

export default UsersRecipients
