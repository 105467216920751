import React, { useEffect, useReducer } from 'react'
import { Button, DatePicker, TimePicker, Collapse, Checkbox } from 'antd'
import moment, { Moment } from 'moment'

import { ClockFilled, VideoCameraFilled } from 'components/Icons'
import { DeviceInfo, Layout, Spinner } from 'components/Shared'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import { MM_DD_YYYY, YYYY_MM_DD_HH_MM } from 'utils/dateTime'
import { notiError, notiSuccess } from 'utils/notification'
import { validateTimeRange } from 'utils/validation'
import { Device, getDevices } from 'services/devices'
import { searchRecord } from 'services/recordingDownloads'

interface Props {
  selectedTimeRange: Moment[]
  selectedDevices: Device[]
  onCancel: () => void
}

interface State {
  loading: boolean
  exporting: boolean
  fromDate: Moment | null
  fromTime: Moment | null
  toDate: Moment | null
  toTime: Moment | null
  devices: Device[]
  groupedDevices: { id: string; __location: string; children: Device[] }[]
  expandedKeys: string[]
  selectedDevices: Device[]
}

function ModalExportVideos({
  selectedTimeRange,
  selectedDevices: propSelectedDevices,
  onCancel,
}: Props) {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    {
      loading: true,
      exporting: false,
      fromDate: selectedTimeRange[0],
      fromTime: selectedTimeRange[0],
      toDate: selectedTimeRange[1],
      toTime: selectedTimeRange[1],
      devices: [],
      groupedDevices: [],
      expandedKeys: [],
      selectedDevices: propSelectedDevices,
    },
  )

  useEffect(() => {
    setState({ loading: true })
    getDevices({ pageSize: 1000 })
      .then(response => {
        setState({ devices: response.data.data, loading: false })
      })
      .catch((err: any) => {
        const { message, errors } = err
        notiError({ message, description: errors })
        setState({ loading: false })
      })
  }, [])

  useEffect(() => {
    const temp: any = {}
    state.devices.forEach((device: Device) => {
      const locationName = device?.location?.name || 'Unknown'
      const location = temp[locationName] || {
        id: locationName,
        __location: locationName,
        children: [],
      }
      location.children = [...location.children, device]
      temp[locationName] = location
    })
    setState({
      groupedDevices: Object.values(temp),
      expandedKeys: Object.keys(temp),
    })
  }, [state.devices])

  const handleSelectDevice = (device: Device) => {
    const selected = !!state.selectedDevices.find(d => d.id === device.id)
    setState({
      selectedDevices: selected
        ? state.selectedDevices.filter(d => d.id !== device.id)
        : [...state.selectedDevices, device],
    })
  }

  const renderDevice = (d: Device, idx: number, self: Device[]) => (
    <React.Fragment key={d.id}>
      <div className='device'>
        <Checkbox
          className='checkbox-large'
          checked={!!state.selectedDevices.find(i => i.id === d.id)}
          onChange={() => handleSelectDevice(d)}
        >
          <DeviceInfo device={d}>
            {d.name}
            {!!propSelectedDevices.find(i => i.id === d.id) && (
              <span className='viewing'>(Viewing)</span>
            )}
          </DeviceInfo>
        </Checkbox>
      </div>
      {idx + 1 < self.length && (
        <hr className='divider' style={{ margin: '.75rem 0' }} />
      )}
    </React.Fragment>
  )

  const mergeDateTime = (date: Moment, time: Moment) => {
    return moment(date).hours(time.hours()).minutes(time.minutes()).seconds(0)
  }

  const handleSubmit = () => {
    const { fromDate, fromTime, toDate, toTime, selectedDevices } = state
    if (!fromDate || !fromTime || !toDate || !toTime) {
      return notiError({
        message: 'Error',
        description: 'Please select time range',
      })
    }

    const newFromDate = mergeDateTime(fromDate, fromTime)
    const newToDate = mergeDateTime(toDate, toTime)
    const validateResult = validateTimeRange(newFromDate, newToDate)

    if (!validateResult.success) {
      return notiError({
        message: 'Error',
        description: validateResult.message,
      })
    }

    if (!selectedDevices.length) {
      return notiError({
        message: 'Error',
        description: 'Please select at least 1 device',
      })
    }

    handleExport([newFromDate, newToDate], selectedDevices)
  }

  const handleExport = async (timeRange: Moment[], devices: Device[]) => {
    setState({ exporting: true })
    const from = timeRange[0].format(YYYY_MM_DD_HH_MM)
    const to = timeRange[1].format(YYYY_MM_DD_HH_MM)
    const statuses: boolean[] = []

    await Promise.all(
      devices.map(d =>
        searchRecord({
          device_id: d.id,
          start_timestamp: from,
          end_timestamp: to,
        })
          .then(() => statuses.push(true))
          .catch(() => statuses.push(false)),
      ),
    )

    setState({ exporting: false })
    const isAllFailed = statuses.every(s => !s)
    if (isAllFailed) {
      notiError({
        message: 'Opps! Your record(s) were failed to process',
        description:
          "We're so sorry for this inconvenient. You can try with a different time range",
        duration: 10,
      })
    } else {
      onCancel()
      notiSuccess({
        message: 'Your record(s) are being prepared',
        description: "We will let you know right after it's completed",
        duration: 10,
      })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      onCancel={onCancel}
      className='modal-export-videos'
    >
      <Layout>
        <Layout.Header sticky bordered goBack onGoBack={onCancel}>
          Export Videos
        </Layout.Header>
        {state.loading && (
          <Layout.Body>
            <Spinner height='80vh' />
          </Layout.Body>
        )}
        {!state.loading && !!state.groupedDevices.length && (
          <Layout.Body>
            <div className='select-time-range'>
              <h4 className='title'>
                <ClockFilled type='primary' />
                <span>Select Time Range</span>
              </h4>
              <span className='subtitle text-success'>
                Maximum time range is 4 hours
              </span>
              <div className='date-group'>
                <label className='text-500'>From</label>
                <DatePicker
                  size='large'
                  inputReadOnly
                  showToday={false}
                  value={state.fromDate}
                  format={MM_DD_YYYY}
                  className='date-picker'
                  onChange={v => setState({ fromDate: v })}
                  getPopupContainer={getPopupContainer}
                />
                <TimePicker
                  size='large'
                  inputReadOnly
                  format='HH:mm'
                  value={state.fromTime}
                  showSecond={false}
                  className='time-picker'
                  onChange={v => setState({ fromTime: v })}
                  getPopupContainer={getPopupContainer}
                />
              </div>
              <hr className='divider' style={{ margin: '1rem 0' }} />
              <div className='date-group'>
                <label className='text-500'>To</label>
                <DatePicker
                  size='large'
                  inputReadOnly
                  showToday={false}
                  value={state.toDate}
                  format={MM_DD_YYYY}
                  className='date-picker'
                  onChange={v => setState({ toDate: v })}
                  getPopupContainer={getPopupContainer}
                />
                <TimePicker
                  size='large'
                  inputReadOnly
                  format='HH:mm'
                  value={state.toTime}
                  showSecond={false}
                  className='time-picker'
                  onChange={v => setState({ toTime: v })}
                  getPopupContainer={getPopupContainer}
                />
              </div>
            </div>
            <div className='select-devices'>
              <h4 className='title'>
                <VideoCameraFilled
                  type='primary'
                  style={{ transform: 'scale(1.1)' }}
                />
                <span>Select devices to export video</span>
              </h4>
              <span className='subtitle text-success'>
                You can select multiple devices
              </span>
              <Collapse
                ghost
                expandIconPosition='right'
                defaultActiveKey={state.expandedKeys}
              >
                {state.groupedDevices.map(({ id, children, __location }) => (
                  <Collapse.Panel key={id} header={__location}>
                    {children.map(renderDevice)}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
            <Button
              block
              size='large'
              type='primary'
              onClick={handleSubmit}
              disabled={state.exporting}
            >
              Export
            </Button>
          </Layout.Body>
        )}
      </Layout>
    </BaseModal>
  )
}

export default ModalExportVideos
