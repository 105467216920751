import React from 'react'
import { Link } from 'react-router-dom'

import {
  CloudNetwork,
  Dvr,
  ElectronicDevice,
  MonitorFilled,
  VideoCameraFilled,
} from 'components/Icons'
import tagColors from 'configs/tagColors'
import { Tag } from './Tag'
import { getDeviceStatus, statusColors } from 'utils/device'
import { Device } from 'services/devices'

const LICENSED_COLOR = tagColors[4]

interface Props {
  device: Device
  status?: 'online' | 'offline' | 'default'
  to?: string
  onLinkClick?: React.MouseEventHandler<HTMLAnchorElement>
  showLicense?: boolean
  textColored?: boolean
}

export const DeviceInfo: React.FC<Props> = ({
  device,
  status,
  to,
  onLinkClick,
  showLicense,
  textColored,
  children,
}) => {
  const getDeviceIcon = () => {
    switch (device.type) {
      case 'Network Devices':
      case 'Network Video Recorder':
      case 'Digital Video Recorder':
        return MonitorFilled
      case 'NVR/DVR':
        return Dvr
      case 'Camera':
      case 'Security Camera':
        return VideoCameraFilled
      case 'IOT Devices':
      case 'IoT Gateway':
        return CloudNetwork
      default:
        return ElectronicDevice
    }
  }

  const renderDeviceIcon = () => {
    const Icon = getDeviceIcon()
    return (
      <Icon
        className='device-icon'
        color={statusColors[status || getDeviceStatus(device)]}
      />
    )
  }

  const handleLinkClick: React.MouseEventHandler<HTMLAnchorElement> = event => {
    event.preventDefault()
    onLinkClick && onLinkClick(event)
  }

  return (
    <div className='device-info-wrapper'>
      {renderDeviceIcon()}
      {!!children ? (
        children
      ) : (
        <React.Fragment>
          {to && (
            <Link
              className='device-name'
              to={to}
              onClick={onLinkClick ? handleLinkClick : undefined}
            >
              {device.name}
            </Link>
          )}
          {!to && (
            <span
              className='device-name'
              style={{
                color: textColored
                  ? statusColors[status || getDeviceStatus(device)]
                  : undefined,
              }}
            >
              {device.name}
            </span>
          )}
          {showLicense && !!device.is_licensed && (
            <Tag {...LICENSED_COLOR}>Licensed</Tag>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
