import cx from 'classnames'
import { DeleteFilled } from '@ant-design/icons'

import './index.scss'
import { PencilFilled } from 'components/Icons'
import { Layout } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'

interface Props {
  canDelete: boolean
  onEdit: () => void
  onDelete: () => void
  onCancel: () => void
}

function ModalTabOptions({ canDelete, onCancel, onEdit, onDelete }: Props) {
  const handleClick = (fn: Function) => {
    fn()
    onCancel()
  }

  return (
    <BaseModal className='modal-tab-options' noPadding onCancel={onCancel}>
      <Layout>
        <Layout.Header bordered rounded>
          Page Options
        </Layout.Header>
        <Layout.Body>
          <div className='option' onClick={() => handleClick(onEdit)}>
            <PencilFilled type='secondary' />
            <span>Edit page name</span>
          </div>
          <hr className='divider' />
          <div
            className={cx('option', { disabled: !canDelete })}
            onClick={canDelete ? () => handleClick(onDelete) : undefined}
          >
            <DeleteFilled />
            <span>Delete page</span>
          </div>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalTabOptions
