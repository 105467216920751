import { useState } from 'react'
import { Form, notification, Tabs } from 'antd'
import { omit, pick } from 'lodash'

import '../index.scss'
import { Layout } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { validateIpAddress } from 'utils/validation'
import { checkStreamUrl, Device, updateDevice } from 'services/devices'
import { useAppSelector } from 'store'

import BaseModal from '../../BaseModal'
import DeviceDetail from './DeviceDetail'
import CloudSurveillance from './CloudSurveillance'

interface Props {
  device: Device
  onSuccess?: (usedLicense: boolean) => void
  onCancel: () => void
}

interface FormValues {
  name: string
  type: string
  timezone: string
  manufacturer: string | null
  ip_address: string | null
  stream_url: string | null
  location: { label: string; value: number } | null
}

function ModalUpdateDevice({ device, onSuccess, onCancel }: Props) {
  const [form] = Form.useForm()
  const { camera_licenses } = useAppSelector(state => state.stats.stats!)

  const [loading, setLoading] = useState(false)
  const [isLicensed, setIsLicensed] = useState(!!device.is_licensed)
  const [remaining, setRemaining] = useState(
    () => (camera_licenses.total || 0) - (camera_licenses.used || 0),
  )

  const formValues: FormValues = {
    ...pick(device, [
      'name',
      'type',
      'timezone',
      'manufacturer',
      'ip_address',
      'stream_url',
    ]),
    location: device.location
      ? { value: device.location.id, label: device.location.name }
      : null,
  }

  const handleAssignLicense = () => {
    setIsLicensed(true)
    setRemaining(prev => prev - 1)
  }

  const handleSubmit = async (values: FormValues) => {
    notification.destroy()

    if (values.ip_address) {
      const validateIpAddressResult = validateIpAddress(values.ip_address)
      if (!validateIpAddressResult.success) {
        handleFormFailed()
        return form.setFields([
          { name: 'ip_address', errors: [validateIpAddressResult.message] },
        ])
      }
    }

    setLoading(true)
    try {
      if (!!values.stream_url && values.stream_url !== formValues.stream_url) {
        await checkStreamUrl(values.stream_url)
      }
    } catch {
      setLoading(false)
      return notiError({
        message: 'Error',
        description: 'RTSP url is not valid',
      })
    }

    try {
      await updateDevice(device.id, {
        ...(omit(values, ['location']) as any),
        account_id: device.account_id,
        stream_url: values.stream_url || null,
        is_licensed: isLicensed,
        location_id: values.location?.value,
      })
      setLoading(false)
      notiSuccess({ message: 'Device updated' })
      onCancel()
      onSuccess?.(!device.is_licensed && isLicensed)
    } catch (err: any) {
      const { message, errors } = err
      setLoading(false)
      notiError({ message, description: errors })
    }
  }

  const handleFormFailed = () => {
    notification.destroy()
    notiError({
      message: 'Invalid form',
      description: 'The given data was invalid',
    })
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-update-device'
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout='vertical'
        initialValues={formValues}
        onFinish={handleSubmit}
        onFinishFailed={handleFormFailed}
      >
        <Layout>
          <Layout.Header
            goBack
            sticky
            onGoBack={loading ? () => {} : onCancel}
            actionRight={
              <button
                type='submit'
                className='base-btn text-primary hoverable text-500'
                disabled={loading}
              >
                Save
              </button>
            }
          >
            Update Device
          </Layout.Header>
          <Layout.Body>
            <Tabs type='card'>
              <Tabs.TabPane forceRender key='1' tab='Device Detail'>
                <DeviceDetail />
              </Tabs.TabPane>
              <Tabs.TabPane forceRender key='2' tab='Cloud Surveillance'>
                <CloudSurveillance
                  isLicensed={isLicensed}
                  remaining={remaining}
                  onAssignLicense={handleAssignLicense}
                />
              </Tabs.TabPane>
            </Tabs>
          </Layout.Body>
        </Layout>
      </Form>
    </BaseModal>
  )
}

export default ModalUpdateDevice
