import { useState } from 'react'
import { Form, Input, Button } from 'antd'

import './index.scss'
import { Layout, PasswordRequirements } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { matchPasswords, validatePassword } from 'utils/validation'
import { notiError, notiSuccess } from 'utils/notification'
import { confirmPassword } from 'services/2fa'
import { changeUserPassword } from 'services/auth'

type FormValues = {
  currentPassword: string
  newPassword: string
  rePassword: string
}

interface Props {
  onCancel: () => void
}

const rules = [{ required: true, message: 'This field is required' }]

function ModalChangePassword({ onCancel }: Props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const handleSubmit = (values: FormValues) => {
    const { currentPassword, newPassword, rePassword } = values

    const currentPasswordResult = validatePassword(currentPassword)
    if (!currentPasswordResult.success) {
      return form.setFields([
        { name: 'currentPassword', errors: [currentPasswordResult.message] },
      ])
    }
    const matchPasswordsResult = matchPasswords(newPassword, rePassword)
    if (!matchPasswordsResult.success) {
      return form.setFields([
        { name: 'newPassword', errors: [matchPasswordsResult.message] },
        { name: 'rePassword', errors: [matchPasswordsResult.message] },
      ])
    }
    const diffPasswordsResult = matchPasswords(currentPassword, newPassword)
    if (diffPasswordsResult.success) {
      return form.setFields([
        {
          name: 'currentPassword',
          errors: ['Current password and new password are the same'],
        },
        {
          name: 'newPassword',
          errors: ['Current password and new password are the same'],
        },
      ])
    }

    const newPasswordResult = validatePassword(newPassword)
    if (!newPasswordResult.success) {
      return form.setFields([
        { name: 'newPassword', errors: [newPasswordResult.message] },
      ])
    }
    const rePasswordResult = validatePassword(rePassword)
    if (!rePasswordResult.success) {
      return form.setFields([
        { name: 'rePassword', errors: [rePasswordResult.message] },
      ])
    }

    handleChangePassword(values)
  }

  const handleChangePassword = async (values: FormValues) => {
    try {
      setLoading(true)
      await confirmPassword(values.currentPassword)
      await changeUserPassword(values.newPassword)
      setLoading(false)
      notiSuccess({ message: 'Password updated' })
      onCancel()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-change-password'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          Change Password
        </Layout.Header>
        <Layout.Body>
          <Form form={form} onFinish={handleSubmit} layout='vertical'>
            <PasswordRequirements />
            <Form.Item
              name='currentPassword'
              label='Current password'
              rules={rules}
            >
              <Input.Password
                size='large'
                placeholder='Enter your current password'
              />
            </Form.Item>
            <Form.Item name='newPassword' label='New password' rules={rules}>
              <Input.Password
                size='large'
                placeholder='Enter your new password'
              />
            </Form.Item>
            <Form.Item
              name='rePassword'
              label='Re-enter Password'
              rules={rules}
            >
              <Input.Password
                size='large'
                placeholder='Re-enter your new password'
              />
            </Form.Item>
            <Button
              disabled={loading}
              htmlType='submit'
              block
              type='primary'
              size='large'
            >
              Change password
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalChangePassword
