import React from 'react'
import cx from 'classnames'
import { Button } from 'antd'

import './index.scss'
import {
  BackwardFilled,
  ForwardFilled,
  PauseFilled,
  PlayFilled,
} from 'components/Icons'
import { DeviceInfo } from 'components/Shared'

import PlaybackTimeline from './Timeline'
import { usePlaybackContext, Player } from '../PlaybackContext'
import { MAX_VIDEO_LENGTH_SEC } from 'utils/device'

interface Props {
  isReady: boolean
  onPlay: () => void
  onPause: (status?: string) => void
}

function PlaybackControls({ isReady, onPlay, onPause }: Props) {
  const { players, status, currentTime, dispatch } = usePlaybackContext()

  const renderPlayerList = (player: Player | undefined, idx: number) => {
    if (!player) return null
    return (
      <div className='player-list-item' key={idx}>
        <DeviceInfo
          textColored
          device={{ ...player.device, name: `#${idx + 1}` }}
        />
      </div>
    )
  }

  const handleForwardBackward = (sec: number) => () => {
    const newCurrentTime = currentTime + sec
    dispatch({
      type: 'SET_PLAYBACK_TIME',
      payload:
        newCurrentTime < 0
          ? 0
          : newCurrentTime > MAX_VIDEO_LENGTH_SEC
          ? MAX_VIDEO_LENGTH_SEC
          : newCurrentTime,
    })
  }

  return (
    <React.Fragment>
      <div className='playback-controls'>
        <div className='left'>
          <div className='spacer' />
          <div className='spacer' />
          <div className='player-list'>{players.map(renderPlayerList)}</div>
        </div>
        <div className='right'>
          <PlaybackTimeline />
        </div>
      </div>
      <div className='controls-btns'>
        <Button
          size='large'
          className={cx('ant-btn-secondary', { disabled: !isReady })}
          onClick={
            isReady && currentTime > 0 ? handleForwardBackward(-10) : undefined
          }
        >
          <BackwardFilled />
        </Button>
        {status === 'playing' ? (
          <Button
            size='large'
            type='primary'
            className={cx({ disabled: !isReady })}
            onClick={isReady ? () => onPause() : undefined}
          >
            <PauseFilled color='#fff' />
          </Button>
        ) : (
          <Button
            size='large'
            type='primary'
            className={cx({ disabled: !isReady })}
            onClick={isReady ? onPlay : undefined}
          >
            <PlayFilled color='#fff' />
          </Button>
        )}

        <Button
          size='large'
          className={cx('ant-btn-secondary', { disabled: !isReady })}
          onClick={
            isReady && currentTime < MAX_VIDEO_LENGTH_SEC
              ? handleForwardBackward(10)
              : undefined
          }
        >
          <ForwardFilled />
        </Button>
      </div>
    </React.Fragment>
  )
}

export default PlaybackControls
