import axios from './axios'
import { Subscription } from './subscription'

export interface Account {
  billing_id: string | number | null
  brand_logo: string | null
  created_at: string
  email: string | null
  enabled_notifications: number
  id: number
  is_active: number
  is_unlimited: number
  name: string
  owner_id: number
  subscription: Subscription
  updated_at: string
  use_custom_smtp: number
}

export const getAccounts = ({
  search,
  page,
}: {
  search: string
  page: number
}) => {
  return axios.get(`/api/accounts?filters[search]=${search}&page=${page}`)
}

export const updloadBrandLogo = (id: number, img: File) => {
  const formData = new FormData()
  formData.append('brand_logo', img, img.name)
  formData.append('_method', 'PUT')
  return axios.post(`/api/accounts/${id}`, formData)
}

export const changeSMTPServer = (id: number, useCustomSmtp: number) => {
  return axios.put(`/api/accounts/${id}`, { use_custom_smtp: useCustomSmtp })
}

export const toggleDeviceNoti = (id: number, isEnabled: number) => {
  return axios.put(`/api/accounts/${id}`, { enabled_notifications: isEnabled })
}
