import { useEffect, useState } from 'react'
import { Button, Checkbox, Form, Input } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'

import './index.scss'
import history from 'configs/history'
import { Layout, PasswordRequirements } from 'components/Shared'
import { notiError } from 'utils/notification'
import { matchPasswords, validatePassword } from 'utils/validation'
import { login, register } from 'services/auth'
import { useLocalStorage } from 'hooks'

type RouteParams = {
  planId?: string
  count?: string
}

type FormValues = {
  name: string
  email: string
  password: string
  rePassword: string
  acceptTerms: boolean
}

const rules = [{ required: true, message: 'This field is required' }]

function Register() {
  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm()
  const [selectedPlan, setSelectedPlan] = useLocalStorage<RouteParams>(
    'APP/SELECTED_PLAN',
    {},
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search)
    const params = Object.fromEntries(searchParams.entries()) as RouteParams
    setSelectedPlan(params)
    // eslint-disable-next-line
  }, [])

  const handleSubmit = (values: FormValues) => {
    const { acceptTerms, password, rePassword } = values

    const comparePasswordsResult = matchPasswords(password, rePassword)
    if (!comparePasswordsResult.success) {
      return form.setFields([
        { name: 'password', errors: [comparePasswordsResult.message] },
        { name: 'rePassword', errors: [comparePasswordsResult.message] },
      ])
    }
    const passwordResult = validatePassword(password)
    if (!passwordResult.success) {
      return form.setFields([
        { name: 'password', errors: [passwordResult.message] },
      ])
    }
    const rePasswordResult = validatePassword(rePassword)
    if (!rePasswordResult.success) {
      return form.setFields([
        { name: 'rePassword', errors: [rePasswordResult.message] },
      ])
    }

    if (!acceptTerms) {
      return form.setFields([
        {
          name: 'acceptTerms',
          errors: [
            'Please agree to the above terms to proceed with registration',
          ],
        },
      ])
    }

    handleRegister(values)
  }

  const handleRegister = async (values: FormValues) => {
    setLoading(true)
    const { name, email, password, rePassword } = values

    try {
      await register({
        name,
        email,
        password,
        password_confirmation: rePassword,
        planId: Number(selectedPlan.planId) || undefined,
      })
      await login({ email, password })
      setLoading(false)
      window.location.href = window.location.origin
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const handleCheckboxChange = (event: CheckboxChangeEvent) => {
    const checked = event.target.checked
    return form.setFields([
      {
        name: 'acceptTerms',
        errors: checked ? [] : ['Please accept our terms'],
      },
    ])
  }

  return (
    <Layout className='register-page'>
      <Layout.Header bordered goBack>
        Sign Up
      </Layout.Header>
      <Layout.Body fullHeight>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout='vertical'
          className='form-register'
        >
          <PasswordRequirements />
          <Form.Item name='name' label='Your name' rules={rules}>
            <Input size='large' placeholder='Enter your name' />
          </Form.Item>
          <Form.Item
            name='email'
            label='Email Address'
            rules={[
              ...rules,
              { type: 'email', message: 'Invalid email address' },
            ]}
          >
            <Input size='large' placeholder='Enter user email address' />
          </Form.Item>
          <Form.Item name='password' label='Password' rules={rules}>
            <Input.Password size='large' placeholder='Enter your password' />
          </Form.Item>
          <Form.Item name='rePassword' label='Re-enter Password' rules={rules}>
            <Input.Password size='large' placeholder='Re-enter your password' />
          </Form.Item>
          <Form.Item
            valuePropName='checked'
            name='acceptTerms'
            wrapperCol={{ span: 24 }}
          >
            <Checkbox onChange={handleCheckboxChange}>
              I agree to the Broadflow{' '}
              <a
                className='text-500 text-underline'
                target='_blank'
                rel='noreferrer'
                href='https://broadflow.co/terms-of-use'
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                className='text-500 text-underline'
                target='_blank'
                rel='noreferrer'
                href='https://broadflow.co/privacy-policy'
              >
                Privacy Policy
              </a>
            </Checkbox>
          </Form.Item>
          <Button
            disabled={loading}
            type='primary'
            block
            size='large'
            htmlType='submit'
          >
            Register
          </Button>
        </Form>
      </Layout.Body>
    </Layout>
  )
}

export default Register
