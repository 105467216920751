import { Link } from 'react-router-dom'
import { Select, Form, Input } from 'antd'

import { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { deviceTypes, manufacturers, timezones } from 'utils/selectOptions'
import { Location, getLocations } from 'services/locations'

const rules = [{ required: true, message: 'This field is required' }]

function DeviceDetail() {
  const handleGetLocations = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getLocations({
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        search,
        currentPage: page,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapLocations = (locations: Location[]) =>
    locations.map(({ name, id }: Location) => ({ value: id, label: name }))

  return (
    <div style={{ padding: '1rem 8px' }}>
      <Form.Item rules={rules} name='name' label='Device Name'>
        <Input size='large' placeholder='Name your device' />
      </Form.Item>
      <Form.Item rules={rules} name='type' label='Device Type'>
        <Select
          size='large'
          placeholder='What type is it?'
          getPopupContainer={getPopupContainer}
        >
          {deviceTypes.map(type => (
            <Select.Option key={type} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item rules={rules} name='timezone' label='Device Timezone'>
        <Select
          size='large'
          placeholder='Choose device timezone'
          getPopupContainer={getPopupContainer}
        >
          {timezones.map(timezone => (
            <Select.Option key={timezone} value={timezone}>
              {timezone}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='manufacturer' label='Manufacturer'>
        <Select
          size='large'
          placeholder='Select manufacturer (optional)'
          getPopupContainer={getPopupContainer}
        >
          {manufacturers.map(manufacturer => (
            <Select.Option key={manufacturer} value={manufacturer}>
              {manufacturer}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='ip_address' label='Device IP Address'>
        <Input size='large' placeholder='Enter device IP address (optional)' />
      </Form.Item>
      <Form.Item
        label='Device Location'
        name='location'
        colon={false}
        extra={
          <span>
            Need to add a location?{' '}
            <Link
              to='/devices?tab=device-location'
              className='text-link text-primary'
            >
              Create new
            </Link>
          </span>
        }
      >
        <AsyncSelect
          size='large'
          labelInValue
          getPopupContainer={getPopupContainer}
          fetcher={handleGetLocations}
          handleSearch={handleGetLocations}
          handleLoadMore={handleGetLocations}
          mapOptions={mapLocations}
          placeholder='Select device location (optional)'
        />
      </Form.Item>
    </div>
  )
}

export default DeviceDetail
