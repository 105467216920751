import moment from 'moment'

import WhatsApp from 'assets/brand-logos/whatsapp.svg'
import { ClockFilled, EmailFilled, SmsFilled } from 'components/Icons'

import { Avatar, Layout } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { formatPhoneNumber } from 'utils/functions'
import { MM_DD_YYYY } from 'utils/dateTime'
import { Recipient } from 'services/recipients'

interface Props {
  recipient: Recipient
  onCancel: () => void
}

function ModalViewRecipientProfile({ recipient, onCancel }: Props) {
  return (
    <BaseModal
      noPadding
      height='100vh'
      onCancel={onCancel}
      className='modal-view-recipient-profile'
    >
      <Layout className='user-profile'>
        <Layout.Header sticky bordered goBack onGoBack={onCancel}>
          {recipient.name}
        </Layout.Header>
        <div className='profile-cover' />
        <Layout.Body>
          <Avatar fontSize='2rem' username={recipient.name} />
          <div className='user-info'>
            <div className='info'>
              <div>
                <EmailFilled type='primary' />
                <span className='text-500'>Email</span>
              </div>
              <div>{recipient.email}</div>
            </div>
            <hr className='divider' />
            <div className='info'>
              <div>
                <SmsFilled type='primary' />
                <span className='text-500'>Phone Number</span>
              </div>
              <div>{formatPhoneNumber(recipient.phone)}</div>
            </div>
            <hr className='divider' />
            <div className='info'>
              <div>
                <img src={WhatsApp} alt='whatsapp' />
                <span className='text-500'>WhatsApp</span>
              </div>
              <div>{formatPhoneNumber(recipient.phone)}</div>
            </div>
            <hr className='divider' />
            <div className='info'>
              <div>
                <ClockFilled type='primary' />
                <span className='text-500'>Created At</span>
              </div>
              <div>{moment(recipient.created_at).format(MM_DD_YYYY)}</div>
            </div>
          </div>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalViewRecipientProfile
