import { useState } from 'react'
import { Button, Form, Input } from 'antd'

import './index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout, Avatar } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import {
  updateUsername as updateAuthUsername,
  uploadUserAvatar,
} from 'services/auth'
import { updateUserAvatar, updateUsername } from 'store/slice/authSlice'
import { useAppDispatch, useAppSelector } from 'store'

interface Props {
  onCancel: () => void
}

function ModalEditProfile({ onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState<File | null>(null)

  const { currentUser } = useAppSelector(state => state.auth)
  const dispatch = useAppDispatch()

  const handleSubmit = async ({ name }: { name: string }) => {
    try {
      setLoading(true)
      if (!!avatar) {
        const { data } = await uploadUserAvatar(avatar)
        await dispatch(
          updateUserAvatar({
            avatar: data.data.avatar,
            avatar_url: data.data.avatar_url,
          }),
        )
      }
      if (!!name && name !== currentUser!.name) {
        await updateAuthUsername(name)
        await dispatch(updateUsername(name))
      }
      setLoading(false)
      onCancel()
      notiSuccess({ message: 'Profile updated' })
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-edit-profile'
      onCancel={onCancel}
    >
      {currentUser && (
        <Layout>
          <Layout.Header
            sticky
            bordered
            goBack
            onGoBack={loading ? () => {} : onCancel}
          >
            Edit Profile
          </Layout.Header>
          <div
            className='profile-cover'
            style={{ backgroundImage: `url(${currentUser.avatar_url})` }}
          />
          <Layout.Body>
            <Avatar
              src={currentUser.avatar_url || undefined}
              username={currentUser.name}
              onChange={setAvatar}
              fontSize='2rem'
            />
            <Form
              layout='vertical'
              initialValues={{ name: currentUser.name }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name='name'
                label='Name'
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <Input size='large' placeholder='Enter your name' />
              </Form.Item>
              <Form.Item label='Email'>
                <Input
                  size='large'
                  readOnly
                  disabled
                  value={currentUser.email}
                />
              </Form.Item>
              <Form.Item label='User Role'>
                <Input
                  size='large'
                  readOnly
                  disabled
                  value={!!currentUser.is_admin ? 'Admin' : 'Standard User'}
                />
              </Form.Item>
              <Button
                htmlType='submit'
                type='primary'
                block
                size='large'
                disabled={loading}
              >
                Save
              </Button>
            </Form>
          </Layout.Body>
        </Layout>
      )}
    </BaseModal>
  )
}

export default ModalEditProfile
