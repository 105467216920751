import { Link } from 'react-router-dom'
import { isNumber } from 'lodash'
import cx from 'classnames'

import MSTeam from 'assets/brand-logos/microsoft-team.svg'
import Slack from 'assets/brand-logos/slack.svg'
import Webhook from 'assets/brand-logos/webhook.svg'
import WhatsApp from 'assets/brand-logos/whatsapp.svg'
import Checked from 'assets/images/checked.svg'

import {
  CloudFilled,
  EmailFilled,
  SmsFilled,
  StorageFilled,
} from 'components/Icons'
import StatisticsCard from 'components/Cards/StatisticsCard'
import WarningCard from 'components/Cards/WarningCard'
import { isPlanExpired } from 'utils/plan'
import { useAppSelector } from 'store'

import ManageDevices from './ManageDevices'

type Resource = {
  available?: number
  enabled?: boolean
  used: number | null
  total: number | null
}

type ResourceKey =
  | 'devices'
  | 'cloud_storage'
  | 'vault'
  | 'camera_licenses'
  | 'email'
  | 'sms'
  | 'whatsapp'

function ResourceSummary() {
  const { currentUser } = useAppSelector(state => state.auth)
  const { stats } = useAppSelector(state => state.stats)
  const { subscription: sub } = useAppSelector(state => state.subscription)

  const upgradeLink = () => (
    <Link to='/settings/payment-settings/plans'>Upgrade</Link>
  )

  const renderStats = (key: ResourceKey, suffix = '') => {
    if (!stats) return upgradeLink()
    const stat = stats[key] as Resource
    const { total, used, available, enabled } = stat
    if (enabled !== undefined && !enabled) return upgradeLink()

    const textDanger = isNumber(available)
      ? (total || 0) <= 0
      : (used || 0) >= (total || 0)

    return (
      <span className={cx({ 'text-danger': textDanger })}>
        {available || used}/{total}
        {suffix}
      </span>
    )
  }

  const isOutOfResource = ({ available, total, used }: Resource) => {
    if (isNumber(available) && (total || 0) <= 0) return true
    if (!total || !used) return false
    return used >= total
  }

  const renderOutOfResourceMessage = () => {
    const messages: string[] = []
    if (isPlanExpired(sub)) {
      messages.push('expired')
    }

    if (
      stats &&
      sub &&
      sub.subscription &&
      sub.subscription.plan_id !== 1 &&
      sub.subscription.plan_id !== 3
    ) {
      if (isOutOfResource(stats.devices)) {
        messages.push('Devices')
      }
      if (isOutOfResource(stats.cloud_storage)) {
        messages.push('Cloud Storage')
      }
      if (isOutOfResource(stats.vault)) {
        messages.push('Vault')
      }
      if (isOutOfResource(stats.camera_licenses)) {
        messages.push('Camera Licenses')
      }
      if (isOutOfResource(stats.email)) {
        messages.push('Email')
      }
      if (isOutOfResource(stats.sms)) {
        messages.push('SMS Notif')
      }
      if (stats.whatsapp.enabled && isOutOfResource(stats.whatsapp)) {
        messages.push('WhatsApp')
      }
    }

    if (!!messages.length) {
      return (
        <WarningCard
          action={
            <Link
              to='/settings/payment-settings/plans'
              className='ant-btn ant-btn-primary'
            >
              Update Subscription Plan
            </Link>
          }
        >
          {messages.map(m => (
            <div key={m} className={cx(m === 'expired' && 'text-500')}>
              {m === 'expired'
                ? 'Your plan has been expired'
                : 'You’re out of ' + m}
            </div>
          ))}
        </WarningCard>
      )
    }

    return null
  }

  if (!stats) {
    return null
  }

  return (
    <div className='resource-summary'>
      <h3>Resource Summary</h3>
      <Link
        to='/settings/payment-settings'
        className='ant-btn ant-btn-lg ant-btn-block ant-btn-secondary lighter'
      >
        View My Plan
      </Link>
      {renderOutOfResourceMessage()}
      <ManageDevices stats={renderStats('devices')} />
      <StatisticsCard
        icon={<CloudFilled type='primary' />}
        title='Cloud Storage'
        content={renderStats('cloud_storage', 'GB')}
        action={{
          content: !!currentUser?.account.access.can_access_vault ? (
            <Link to='/cloud-storage?tab=video-search'>View Cloud Storage</Link>
          ) : (
            upgradeLink()
          ),
        }}
      />
      <StatisticsCard
        icon={<StorageFilled type='primary' />}
        title='Vault Storage'
        content={renderStats('vault', 'GB')}
        action={{
          content: !!currentUser?.account.access.can_access_vault ? (
            <Link to='/cloud-storage?tab=my-vault'>View Vault Storage</Link>
          ) : (
            upgradeLink()
          ),
        }}
      />
      <StatisticsCard
        imgSrc={Checked}
        title='Camera Licenses'
        content={renderStats('camera_licenses')}
        action={{
          content: (
            <Link to='/settings/payment-settings/plans'>Add / Remove</Link>
          ),
        }}
      />
      <StatisticsCard
        icon={<EmailFilled type='primary' />}
        title='Email'
        content={renderStats('email')}
      />
      <StatisticsCard
        icon={<SmsFilled type='primary' />}
        title='SMS'
        content={renderStats('sms')}
      />
      <StatisticsCard
        imgSrc={WhatsApp}
        title='WhatsApp'
        content={renderStats('whatsapp')}
      />
      <StatisticsCard
        imgSrc={Webhook}
        title='Webhook'
        content={stats.webhook_enabled ? 'Unlimited' : upgradeLink()}
      />
      <StatisticsCard
        imgSrc={Slack}
        title='Slack'
        content={stats.slack_enabled ? 'Unlimited' : upgradeLink()}
      />
      <StatisticsCard
        imgSrc={MSTeam}
        title='Microsoft Teams'
        content={stats.teams_enabled ? 'Unlimited' : upgradeLink()}
      />
    </div>
  )
}

export default ResourceSummary
