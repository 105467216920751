import React, { createContext, useContext, useReducer } from 'react'

import { Tag } from 'services/tags'

interface State {
  loading: boolean
  notifications: any[]
  totalPage: number
  currentPage: number
  search: string
  device?: { value: number; label: string }
  dateRange?: string[]
  tags?: Tag[]
}

interface Action {
  type: string
  payload?: any
}

const initState: State = {
  loading: false,
  notifications: [],
  totalPage: 1,
  currentPage: 1,
  search: '',
  device: undefined,
  dateRange: undefined,
  tags: undefined,
}

const NotiContext = createContext<any>(null)

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      }
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        loading: false,
        notifications: payload.data,
        totalPage: payload.meta.last_page,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: payload,
        currentPage: 1,
      }
    case 'SET_FILTER':
      return {
        ...state,
        device: payload.device,
        tags: payload.tags,
        dateRange: payload.dateRange,
        currentPage: 1,
      }
    default:
      return state
  }
}

const NotifContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <NotiContext.Provider value={{ ...state, dispatch }}>
      {children}
    </NotiContext.Provider>
  )
}

const useNotifContext = () => {
  return useContext(NotiContext)
}

export { NotifContextProvider as default, useNotifContext }
