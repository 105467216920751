import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ShieldFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='19'
        height='20'
        viewBox='0 0 19 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M17.8643 2.7991C17.8284 2.54124 17.6428 2.32912 17.392 2.2594L9.35364 0.0239217C9.23904 -0.00797391 9.11799 -0.00797391 9.00331 0.0239217L0.964912 2.2594C0.714104 2.32912 0.528569 2.54115 0.492665 2.7991C0.446041 3.13436 -0.617582 11.0556 2.11054 14.9962C4.83544 18.9321 8.85525 19.9407 9.02501 19.9818C9.07547 19.994 9.12688 20 9.17847 20C9.23006 20 9.28148 19.9939 9.33194 19.9818C9.50179 19.9407 13.5216 18.9321 16.2464 14.9962C18.9745 11.0557 17.9109 3.13444 17.8643 2.7991ZM14.3622 7.42362L8.87921 12.9066C8.75163 13.0342 8.58431 13.0981 8.41707 13.0981C8.24984 13.0981 8.08252 13.0343 7.95494 12.9066L4.56485 9.51653C4.44223 9.394 4.37339 9.22773 4.37339 9.05439C4.37339 8.88106 4.44232 8.71478 4.56485 8.59226L5.23797 7.91914C5.49322 7.66397 5.90708 7.66388 6.16224 7.91914L8.41707 10.174L12.7648 5.82614C12.8873 5.70352 13.0536 5.63468 13.227 5.63468C13.4003 5.63468 13.5666 5.70352 13.6891 5.82614L14.3622 6.49926C14.6175 6.75451 14.6175 7.16837 14.3622 7.42362Z'
          fill='url(#shield_linear)'
        />
        <defs>
          <linearGradient
            id='shield_linear'
            x1='0.25'
            y1='0'
            x2='0.25'
            y2='20'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
