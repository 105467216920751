import { useState } from 'react'
import cx from 'classnames'
import { PlusOutlined } from '@ant-design/icons'

import { AccessDenied, Layout } from 'components/Shared'
import { useRestrictRoute } from 'hooks'

import { useUserContext } from './UserContext'
import TableListHeader from './TableListHeader'
import TableList from './TableList'

function UserManagement() {
  const [showAdd, setShowAdd] = useState(false)

  const { canAccess, message } = useRestrictRoute()
  const { loading } = useUserContext()

  return (
    <Layout>
      <Layout.Header
        bordered
        goBack
        actionRight={
          canAccess && (
            <PlusOutlined
              disabled={loading}
              className={cx({ hoverable: !loading })}
              onClick={!loading ? () => setShowAdd(true) : undefined}
            />
          )
        }
      >
        All Users
      </Layout.Header>
      {!canAccess && !!message && (
        <Layout.Body>
          <AccessDenied {...message} />
        </Layout.Body>
      )}
      {canAccess && (
        <Layout.Body>
          <TableListHeader />
          <TableList showAdd={showAdd} setShowAdd={setShowAdd} />
        </Layout.Body>
      )}
    </Layout>
  )
}

export default UserManagement
