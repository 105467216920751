import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ArrowFall({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M9.0002 12L5.56054 8.56054L12 2.12107L9.87893 -1.8543e-07L3.43946 6.43946L7.86822e-07 2.9998L0 12L9.0002 12Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
