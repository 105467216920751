import { useHistory } from 'react-router-dom'

import './index.scss'
import FormResetPassword from './FormResetPassword'
import FormSendResetLink from './FormSendResetLink'

function ResetPassword() {
  const { location } = useHistory()

  const token = location.search.includes('?token=')
    ? location.search.replace('?token=', '')
    : ''

  return token ? <FormResetPassword token={token} /> : <FormSendResetLink />
}

export default ResetPassword
