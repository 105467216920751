import './index.scss'

import cx from 'classnames'
import React, { CSSProperties } from 'react'

interface Props {
  className?: string
  style?: CSSProperties
  imgSrc?: string
  icon?: React.ReactNode
  title: string | React.ReactNode
  content: string | React.ReactNode
  extra?: React.ReactNode
  action?: {
    appearOnHover?: boolean
    content: React.ReactNode
  }
}

function StatisticsCard({
  className,
  style,
  imgSrc,
  icon,
  title,
  content,
  extra,
  action,
}: Props) {
  return (
    <div
      style={style}
      className={cx('statistics-card-wrapper', className, {
        'appear-on-hover': action?.appearOnHover,
      })}
    >
      <div className='card-inner'>
        <div className='icon-wrapper'>
          {icon ? icon : <img src={imgSrc} alt='icon' />}
        </div>
        <div className='content'>
          <h1 className='text-placeholder'>{title}</h1>
          <span className='text-500'>{content}</span>
        </div>
        {action && <div className='action'>{action.content}</div>}
      </div>
      {extra && extra}
    </div>
  )
}

function StatisticsCardMinimalized({
  className,
  style,
  imgSrc,
  title,
  content,
  action,
  icon,
}: Props) {
  return (
    <div
      style={style}
      className={cx('statistics-card-minimalized-wrapper', className, {
        'appear-on-hover': action?.appearOnHover,
      })}
    >
      <div className='card-inner'>
        <div className='icon-wrapper'>
          {icon ? icon : <img src={imgSrc} alt='icon' />}
        </div>
        <div className='content'>
          <span className='text-500'>{title}: </span>
          <span className='text-bold'>{content}</span>
        </div>
        {action && <div className='action text-500'>{action.content}</div>}
      </div>
    </div>
  )
}

StatisticsCard.Minimalized = StatisticsCardMinimalized
export default StatisticsCard
