import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function RecordStop({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M11.5214 0H0.481274C0.216957 0 -6.10352e-05 0.205328 -6.10352e-05 0.471291V11.526C-6.10352e-05 11.7864 0.215566 12 0.481274 12H11.5214C11.7857 12 11.9999 11.7878 11.9999 11.526V0.471291C11.9985 0.206706 11.7857 0 11.5214 0Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
