import { useState } from 'react'
import { Button, Form, Input, Radio } from 'antd'

import './index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout, Avatar } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { updateUserProfile, User } from 'services/users'

interface Props {
  user: User
  onSuccess?: () => void
  onCancel: () => void
}

function ModalUpdateUser({ user, onSuccess, onCancel }: Props) {
  const [form] = Form.useForm()

  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState<File | undefined>(undefined)
  const [role, setRole] = useState(!!user.is_admin ? 'admin' : 'standard')

  const handleSubmit = async (values: { name: string }) => {
    try {
      setLoading(true)
      await updateUserProfile(user.id, {
        avatar,
        is_admin: role === 'admin' ? 1 : 0,
        name: values.name,
      })
      setLoading(false)
      notiSuccess({ message: 'User updated' })
      onCancel()
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-update-user'
      onCancel={onCancel}
    >
      <Layout className='user-profile'>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          {user.name}
        </Layout.Header>
        <div
          className='profile-cover'
          style={{ backgroundImage: `url(${user.avatar_url})` }}
        />
        <Layout.Body>
          <Avatar
            fontSize='2rem'
            src={user.avatar_url || undefined}
            username={user.name}
            onChange={setAvatar}
          />
          <Form
            form={form}
            layout='vertical'
            initialValues={{ name: user.name }}
            onFinish={handleSubmit}
          >
            <Form.Item
              name='name'
              label='Name'
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <Input size='large' placeholder='Enter your name' />
            </Form.Item>
            <Form.Item label='Email'>
              <Input
                disabled
                readOnly
                size='large'
                value={user.email}
                placeholder='Enter your name'
              />
            </Form.Item>
            <Form.Item label='User Role'>
              <Radio.Group value={role} onChange={e => setRole(e.target.value)}>
                <Radio value='standard'>Standard User</Radio>
                <Radio value='admin'>Admin</Radio>
              </Radio.Group>
            </Form.Item>
            <Button
              disabled={loading}
              htmlType='submit'
              block
              size='large'
              type='primary'
            >
              Save
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalUpdateUser
