import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import cx from 'classnames'
import { Badge, Button } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import Pagination from 'components/Pagination'
import Table from 'components/Table'
import { notiError } from 'utils/notification'
import { MM_DD_YYYY_HH_MM_P } from 'utils/dateTime'
import { getOutboundNotis, OutboundNoti } from 'services/notifications'
import ModalSearch from 'components/Modal/ModalSearch'

interface Props {
  notifId: number
}

function OutboundNotification({ notifId }: Props) {
  const [loading, setLoading] = useState(true)
  const [search, setSearch] = useState('')
  const [outboundNotifs, setOutboundNotifs] = useState<OutboundNoti[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [showSearch, setShowSearch] = useState(false)

  useEffect(() => {
    handleGetOutboundNotis()
    // eslint-disable-next-line
  }, [search, currentPage])

  const handleGetOutboundNotis = async () => {
    try {
      setLoading(true)
      const response = await getOutboundNotis(notifId, {
        search,
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        currentPage,
      })
      setOutboundNotifs(response.data.data)
      setTotalPage(response.data.meta.last_page)
      setLoading(false)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      setLoading(false)
    }
  }

  const handleSearch = (s: string) => {
    setSearch(s)
    setCurrentPage(1)
  }

  return (
    <React.Fragment>
      <div className='table-list-header'>
        <div className='left'>
          <Pagination
            disabled={loading}
            current={currentPage}
            total={totalPage}
            onPageChange={p => setCurrentPage(p)}
          />
        </div>
        <div className='right'>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowSearch(true)}
          >
            <Badge dot={!!search}>
              <SearchOutlined />
            </Badge>
          </Button>
        </div>
      </div>
      <Table
        className='table-outbound-notification'
        loading={loading}
        empty={!outboundNotifs.length}
      >
        {outboundNotifs.map(({ id, via, to, status, created_at }) => (
          <Table.Row key={id} rowKey={id}>
            <Table.Col>
              <div className='sent-to text-500'>To: {to}</div>
              <div className='channel'>
                <span>Channel</span>
                <span>{via}</span>
              </div>
              <div className='status'>
                <span>Status</span>
                <span
                  className={cx({
                    'text-success': status === 'success',
                    'text-warning': status === 'initiated',
                    'text-danger': status === 'failed',
                  })}
                >
                  {status === 'success' && <CheckCircleOutlined />}
                  {status === 'initiated' && <ClockCircleOutlined />}
                  {status === 'failed' && <ExclamationCircleOutlined />}
                  &nbsp;
                  {status === 'success'
                    ? 'Sent'
                    : status === 'initiated'
                    ? 'Pending'
                    : 'Failed'}
                </span>
              </div>
              <div className='timestamp'>
                <span>Timestamp</span>
                <span>{moment(created_at).format(MM_DD_YYYY_HH_MM_P)}</span>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {showSearch && (
          <ModalSearch
            value={search}
            onSearch={handleSearch}
            onCancel={() => setShowSearch(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default OutboundNotification
