import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

import authSlice from './slice/authSlice'
import configSlice from './slice/configSlice'
import notificationSlice from './slice/notificationSlice'
import plansSlice from './slice/plansSlice'
import statsSlice from './slice/statsSlice'
import subscriptionSlice from './slice/subscriptionSlice'

const store = configureStore({
  reducer: {
    auth: authSlice,
    stats: statsSlice,
    appConfig: configSlice,
    subscription: subscriptionSlice,
    plans: plansSlice,
    notification: notificationSlice
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  }),
  devTools: process.env.NODE_ENV !== 'production'
})

type RootState = ReturnType<typeof store.getState>
type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
