import { useState } from 'react'
import cx from 'classnames'
import { PlusOutlined } from '@ant-design/icons'

import { Layout } from 'components/Shared'
import { useRecipientContext } from './RecipientContext'
import TableListHeader from './TableListHeader'
import TableList from './TableList'

function RecipientManagement() {
  const { loading } = useRecipientContext()
  const [showAdd, setShowAdd] = useState(false)

  return (
    <Layout>
      <Layout.Header
        bordered
        goBack
        actionRight={
          <PlusOutlined
            disabled={loading}
            className={cx({ hoverable: !loading })}
            onClick={!loading ? () => setShowAdd(true) : undefined}
          />
        }
      >
        All Recipients
      </Layout.Header>
      <Layout.Body>
        <TableListHeader />
        <TableList showAdd={showAdd} setShowAdd={setShowAdd} />
      </Layout.Body>
    </Layout>
  )
}

export default RecipientManagement
