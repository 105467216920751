const avatarColors = [
  {
    stroke: 'linear-gradient(180deg, #A49FFF 0%, #9F96FF 100%)',
    bg: 'linear-gradient(180deg, #DFE0FF 0.01%, #C5BBFF 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #FFB1F7 0%, #FFACFC 100%)',
    bg: 'linear-gradient(180deg, #FFEDFD 0.01%, #FFCBFA 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #FFABAB 0%, #FF9B9B 100%)',
    bg: 'linear-gradient(180deg, #FFCDCD 0%, #FFDFDF 0.01%, #FFC4C4 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #ECC699 0%, #E4A962 100%)',
    bg: 'linear-gradient(180deg, #FFECD6 0%, #FFC178 100%, #FFCD93 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #FFDE98 0%, #E9BA55 100%)',
    bg: 'linear-gradient(180deg, #FFFACE 0%, #FFF598 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #94E4FF 0%, #55D5FF 100%)',
    bg: 'linear-gradient(180deg, #DEF7FF 0%, #C2F0FF 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #88FFF8 0%, #4EE0C6 100%)',
    bg: 'linear-gradient(180deg, #E8FFF7 0%, #A0FFE8 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #8AF88F 0%, #7FEA76 100%)',
    bg: 'linear-gradient(180deg, #E6FFE6 0.01%, #CCFFBB 100%, #C2FFAE 100%)',
  },
  {
    stroke: 'linear-gradient(180deg, #DCDCDC 0%, #B5B5B5 100%)',
    bg: 'linear-gradient(180deg, #FBFBFB 0.01%, #E2E2E2 100%)',
  },
]

export default avatarColors
