import axios from 'axios'
import { notification } from 'antd'

import history from 'configs/history'
import store from 'store'
import { updateCurrentUser } from 'store/slice/authSlice'

const BASE_URL = process.env.REACT_APP_BASE_URL
const USER_TOKEN = '3|n7486GxEIDlqXQ3v6QVAPE8if8RR2XDPKOMQdBv9'

export interface ResponseError {
  status: number
  message?: string
  errors?: { [key: string]: string[] }
}

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

const authInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

authInstance.interceptors.response.use(
  response => response,
  ({ response: { status, data } }) => {
    if (status === 401 || status === 419) {
      store.dispatch(updateCurrentUser(null))
      notification.destroy()
      history.push('/login')
    }
    return Promise.reject<ResponseError>({
      status,
      message: data.message || data.error,
      errors: data.errors
    })
  }
)

if (process.env.NODE_ENV === 'development') {
  instance.defaults.headers.common['Authorization'] = 'Bearer ' + USER_TOKEN
  authInstance.defaults.headers.common['Authorization'] = 'Bearer ' + USER_TOKEN
}

export { authInstance as default, instance }
