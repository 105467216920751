import { SVGUniqueID } from 'react-svg-unique-id'

interface Props {
  [key: string]: any
}

export function Union(props: Props) {
  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='21'
        viewBox='0 0 16 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <g filter='url(#filter0_d)'>
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6 1C3.79086 1 2 2.79086 2 5V11.0614L8.1087 17.5294L14.2174 11.0614V5C14.2174 2.79086 12.4265 1 10.2174 1H6Z'
            fill='url(#paint0_linear)'
          />
          <path
            d='M2 11.0614H1.5V11.2602L1.63649 11.4047L2 11.0614ZM8.1087 17.5294L7.74519 17.8727L8.1087 18.2576L8.4722 17.8727L8.1087 17.5294ZM14.2174 11.0614L14.5809 11.4047L14.7174 11.2602V11.0614H14.2174ZM2.5 5C2.5 3.067 4.067 1.5 6 1.5V0.5C3.51472 0.5 1.5 2.51472 1.5 5H2.5ZM2.5 11.0614V5H1.5V11.0614H2.5ZM8.4722 17.1861L2.36351 10.7181L1.63649 11.4047L7.74519 17.8727L8.4722 17.1861ZM13.8539 10.7181L7.74519 17.1861L8.4722 17.8727L14.5809 11.4047L13.8539 10.7181ZM13.7174 5V11.0614H14.7174V5H13.7174ZM10.2174 1.5C12.1504 1.5 13.7174 3.067 13.7174 5H14.7174C14.7174 2.51472 12.7027 0.5 10.2174 0.5V1.5ZM6 1.5H10.2174V0.5H6V1.5Z'
            fill='#A9ACFF'
          />
        </g>
        <defs>
          <filter
            id='filter0_d'
            x='0.5'
            y='0.5'
            width='15.2174'
            height='19.7576'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='0.5' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0.270588 0 0 0 0 0.286275 0 0 0 0 0.72549 0 0 0 0.5 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow'
              result='shape'
            />
          </filter>
          <linearGradient
            id='paint0_linear'
            x1='2'
            y1='1'
            x2='2'
            y2='17.5294'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#6065EA' />
            <stop offset='1' stopColor='#3A3EA6' />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
