import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function WifiOnline({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='12'
        viewBox='0 0 16 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M4.60809 9.04803C3.73609 8.18403 3.20009 6.98403 3.20009 5.65603C3.20009 4.32803 3.73609 3.12803 4.60809 2.26403L5.74409 3.40003C5.16009 3.97603 4.80009 4.77603 4.80009 5.65603C4.80009 6.53603 5.16009 7.33603 5.73609 7.92003L4.60809 9.04803ZM11.3921 9.04803C12.2641 8.18403 12.8001 6.98403 12.8001 5.65603C12.8001 4.32803 12.2641 3.12803 11.3921 2.26403L10.2561 3.40003C10.8401 3.97603 11.2001 4.77603 11.2001 5.65603C11.2001 6.53603 10.8401 7.33603 10.2641 7.92003L11.3921 9.04803ZM8.00009 4.05603C7.12009 4.05603 6.40009 4.77603 6.40009 5.65603C6.40009 6.53603 7.12009 7.25603 8.00009 7.25603C8.88009 7.25603 9.60009 6.53603 9.60009 5.65603C9.60009 4.77603 8.88009 4.05603 8.00009 4.05603ZM14.4001 5.65603C14.4001 7.42403 13.6801 9.02403 12.5201 10.176L13.6561 11.312C15.1041 9.86403 16.0001 7.86403 16.0001 5.65603C16.0001 3.44803 15.1041 1.44803 13.6561 3.05176e-05L12.5201 1.13603C13.6801 2.28803 14.4001 3.88803 14.4001 5.65603ZM3.48009 1.13603L2.34409 3.05176e-05C0.896091 1.44803 9.15527e-05 3.44803 9.15527e-05 5.65603C9.15527e-05 7.86403 0.896091 9.86403 2.34409 11.312L3.48009 10.176C2.32009 9.02403 1.60009 7.42403 1.60009 5.65603C1.60009 3.88803 2.32009 2.28803 3.48009 1.13603Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
