import React, { useState } from 'react'
import { Button, Checkbox, Form } from 'antd'

import PlanInfoCard from 'components/Cards/PlanInfoCard'
import { ChannelSelection } from 'components/Shared'
import { useAppSelector } from 'store'

import {
  FormValues,
  getRecipientsByType,
  mapValuesToOptions,
  RecipientFormItem,
  totalMsg,
  WebhhookFormItem,
} from './FormHelpers'

interface Props {
  loading: boolean
  initValue: FormValues['recipients']
  onCreate: (v: FormValues['recipients']) => void
}

function Recipients({ loading, initValue, onCreate }: Props) {
  const [isBlock, setIsBlock] = useState(false)
  const [channels, setChannels] = useState<string[]>([])

  const [form] = Form.useForm()
  const { stats } = useAppSelector(state => state.stats)

  const handleSubmit = () => {
    if (isBlock) return onCreate({ ...initValue, is_blocked: true })
    return form.submit()
  }

  const handleFormFinish = (v: FormValues['recipients']) => {
    if (!channels.length) {
      return form.setFields([
        { name: 'channels', errors: ['This field is required'] },
      ])
    }
    return onCreate({
      is_blocked: false,
      channels,
      email_recipients: v.email_recipients || [],
      phone_recipients: v.phone_recipients || [],
      whatsapp_recipients: v.whatsapp_recipients || [],
      webhook: v.webhook || '',
      slack_webhook: v.slack_webhook || '',
      teams_webbhook: v.teams_webbhook || '',
    })
  }

  const getRecipientsHasPhone = getRecipientsByType({ phone: 1 })
  const getRecipientsHasEmail = getRecipientsByType({ email: 1 })

  return (
    <React.Fragment>
      <PlanInfoCard showUpgrade>
        {totalMsg(
          stats?.sms.total || 0,
          stats?.email.total || 0,
          stats?.whatsapp.total || 0,
        )}
      </PlanInfoCard>
      <div style={{ margin: '1rem 8px 0 8px' }}>
        <Checkbox
          className='checkbox-large text-danger'
          checked={isBlock}
          onChange={e => setIsBlock(e.target.checked)}
        >
          Do NOT forward any notifications matching this tag
        </Checkbox>
      </div>
      {!isBlock && (
        <React.Fragment>
          <hr className='divider' style={{ margin: '1.5rem 8px' }} />
          <Form
            form={form}
            initialValues={initValue}
            layout='vertical'
            style={{ padding: '0 8px' }}
            onFinish={handleFormFinish}
          >
            <div className='form-group-title' style={{ marginBottom: 8 }}>
              <h3>Notification Channel</h3>
            </div>
            <Form.Item name='channels'>
              <ChannelSelection
                popupContainer={() => document.querySelector('.m-modal-inner')!}
                selectedChannels={channels}
                onChange={v => {
                  form.setFields([{ name: 'channels', errors: undefined }])
                  setChannels(v)
                }}
              />
            </Form.Item>
            <div className='divider' />
            <div className='form-group-title'>
              <h3>Recipients</h3>
              {!channels.length && (
                <div
                  className='text-danger text-400'
                  style={{ width: '100%', fontSize: '0.875rem' }}
                >
                  Please select at least 1 channels above
                </div>
              )}
            </div>
            {channels.includes('phone') && (
              <RecipientFormItem
                name='phone_recipients'
                channel='SMS'
                fetcher={getRecipientsHasPhone}
                mapper={mapValuesToOptions}
              />
            )}
            {channels.includes('whatsapp') && (
              <RecipientFormItem
                name='whatsapp_recipients'
                channel='WhatsApp'
                fetcher={getRecipientsHasPhone}
                mapper={mapValuesToOptions}
              />
            )}
            {channels.includes('email') && (
              <RecipientFormItem
                name='email_recipients'
                channel='Email'
                fetcher={getRecipientsHasEmail}
                mapper={mapValuesToOptions}
              />
            )}
            {channels.includes('slack') && (
              <WebhhookFormItem
                name='slack_webhook'
                label='Slack'
                placeholder='Enter Slack Webhook URL'
                extraUrl='https://api.slack.com/messaging/webhooks'
                extraText='See How to get Slack Webhook URL'
              />
            )}
            {channels.includes('webhook') && (
              <WebhhookFormItem
                name='webhook'
                label='Webhook'
                placeholder='Enter Webhook URL'
                extraUrl='https://developers.facebook.com/docs/messenger-platform/webhook'
                extraText='See How to get Webhook URL'
              />
            )}
            {channels.includes('microsoft_teams') && (
              <WebhhookFormItem
                name='teams_webbhook'
                label='Microsoft Teams Webhook'
                placeholder='Enter Mircosoft Team Webhook URL'
                extraUrl='https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook'
                extraText='See how to create a Mircosoft Teams Webhook'
              />
            )}
          </Form>
        </React.Fragment>
      )}
      <div style={{ margin: '1.5rem 8px 1rem 8px' }}>
        <Button
          disabled={loading}
          type='primary'
          size='large'
          block
          onClick={loading ? undefined : handleSubmit}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  )
}

export default Recipients
