import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import moment from 'moment'
import { Button } from 'antd'
import { EditFilled } from '@ant-design/icons'

import './index.scss'
import {
  ClockFilled,
  EmailFilled,
  ShieldFilled,
  UserFilled,
} from 'components/Icons'
import { Layout, Avatar } from 'components/Shared'
import ModalEditProfile from 'components/Modal/UserProfile/EditProfile'
import { MM_DD_YYYY } from 'utils/dateTime'
import { useAppSelector } from 'store'
import ModalChangePassword from 'components/Modal/UserProfile/ChangePassword'

function UserProfile() {
  const [showEdit, setShowEdit] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)

  const { currentUser } = useAppSelector(state => state.auth)

  if (!currentUser) {
    return null
  }

  return (
    <React.Fragment>
      <Layout className='user-profile'>
        <Layout.Header goBack bordered>
          {currentUser.name}
        </Layout.Header>
        <div
          className='profile-cover'
          style={{ backgroundImage: `url(${currentUser.avatar_url})` }}
        />
        <Layout.Body>
          <Avatar
            src={currentUser.avatar_url || undefined}
            username={currentUser.name}
            fontSize='2rem'
          />
          <div className='user-info'>
            <div className='info'>
              <div>
                <EmailFilled color='#BABBBF' />
                <span className='text-500'>Email</span>
              </div>
              <div>{currentUser.email}</div>
            </div>
            <hr className='divider' />
            <div className='info'>
              <div>
                <UserFilled color='#BABBBF' />
                <span className='text-500'>User Role</span>
              </div>
              <div>{!!currentUser.is_admin ? 'Admin' : 'Standard User'}</div>
            </div>
            <hr className='divider' />
            <div className='info'>
              <div>
                <ClockFilled color='#BABBBF' />
                <span className='text-500'>Created At</span>
              </div>
              <div>{moment(currentUser.created_at).format(MM_DD_YYYY)}</div>
            </div>
          </div>
          <div className='actions'>
            <hr className='divider' style={{ margin: '12px 0' }} />
            <div>
              <Button
                size='large'
                block
                className='ant-btn-secondary'
                onClick={() => setShowEdit(true)}
              >
                <EditFilled /> Edit
              </Button>
              <Button
                size='large'
                block
                className='ant-btn-secondary'
                onClick={() => setShowChangePassword(true)}
              >
                <ShieldFilled
                  style={{
                    width: '1rem',
                    height: '1rem',
                    transform: 'translateY(2px)',
                  }}
                />{' '}
                Change Password
              </Button>
            </div>
          </div>
        </Layout.Body>
      </Layout>
      <AnimatePresence>
        {showEdit && <ModalEditProfile onCancel={() => setShowEdit(false)} />}
        {showChangePassword && (
          <ModalChangePassword onCancel={() => setShowChangePassword(false)} />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default UserProfile
