import { createContext, FC, useContext, useReducer } from 'react'
import { Moment } from 'moment'

import { Device } from 'services/devices'
import { PlaybackNoti, PlaybackSegment } from 'services/recordingPlayback'

export interface Player {
  index: number
  device: Device
  status: 'idle' | 'loading' | 'ready'
  muted: boolean
  fullscreen: boolean
  segments: PlaybackSegment[]
  notifs: PlaybackNoti[]
  video?: HTMLVideoElement
}

interface State {
  status: string
  timeRange: Moment[]
  currentTime: number
  seekTime: number
  players: (Player | undefined)[]
  dispatch: React.Dispatch<Action>
}

interface Action {
  type: string
  payload?: any
}

export const createPlayer = (index: number, device: Device): Player => ({
  index,
  device,
  status: 'idle',
  muted: true,
  fullscreen: false,
  segments: [],
  notifs: [],
  video: undefined,
})

const PlaybackContext = createContext<State | undefined>(undefined)

const initState: State = {
  status: 'idle',
  timeRange: [],
  currentTime: 0,
  seekTime: 0,
  players: [],
  dispatch: () => {},
}

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_TIME_RANGE':
      return {
        ...state,
        status: 'idle',
        timeRange: payload,
      }
    case 'SET_PLAYERS':
      return {
        ...state,
        status: 'idle',
        players: payload,
      }
    case 'UPDATE_PLAYER':
      return {
        ...state,
        players: state.players.map(p => {
          return p?.index === payload.index ? { ...p, ...payload } : p
        }),
      }
    case 'REMOVE_PLAYER':
      return {
        ...state,
        players: state.players.map(p => {
          return p?.index === payload ? undefined : p
        }),
      }
    case 'UPDATE_CURRENT_TIME':
      return {
        ...state,
        currentTime: state.currentTime + payload,
      }
    case 'SET_PLAYBACK_TIME': {
      return {
        ...state,
        seekTime: payload,
        currentTime: payload,
      }
    }
    case 'SET_PLAYBACK_STATUS':
      return {
        ...state,
        status: payload,
      }
    case 'RESET_PLAYBACK':
      return {
        ...initState,
        status: 'idle',
        timeRange: state.timeRange,
      }
    default:
      return state
  }
}

const PlaybackContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)
  return (
    <PlaybackContext.Provider value={{ ...state, dispatch }}>
      {children}
    </PlaybackContext.Provider>
  )
}

const usePlaybackContext = () => {
  const ctx = useContext(PlaybackContext)
  if (ctx === undefined) {
    throw Error('Invalid context call')
  }
  return ctx
}

export { PlaybackContextProvider as default, usePlaybackContext }
