import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Button } from 'antd'
import {
  CheckCircleOutlined,
  StopOutlined,
  ClockCircleOutlined,
  DeleteFilled,
} from '@ant-design/icons'

import Crown from 'assets/images/crown.svg'
import { Avatar } from 'components/Shared'
import Table from 'components/Table'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalCreateUser from 'components/Modal/UserRecipient/CreateUser'
import ModalUpdateUser from 'components/Modal/UserRecipient/UpdateUser'
import ModalViewUserProfile from 'components/Modal/UserRecipient/ViewUserProfile'
import { User, getUsers, deleteUser } from 'services/users'
import { useAppSelector } from 'store'

import { useUserContext } from './UserContext'
import { notiError } from 'utils/notification'

interface Props {
  showAdd: boolean
  setShowAdd: (v: boolean) => void
}

function TableList({ showAdd, setShowAdd }: Props) {
  const { loading, users, currentPage, search, dispatch } = useUserContext()
  const { currentUser } = useAppSelector(state => state.auth)

  const [viewUser, setViewUser] = useState<User | null>(null)
  const [editUser, setEditUser] = useState<User | null>(null)
  const [deleteUserId, setDeleteUserId] = useState<number | null>(null)

  useEffect(() => {
    handleGetUsers()
    // eslint-disable-next-line
  }, [search, currentPage])

  const handleGetUsers = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      const response = await getUsers({
        currentPage,
        pageSize: 10,
        search,
        sort: 'id',
        sortDirection: 'desc',
      })
      dispatch({ type: 'SET_USERS', payload: response.data })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleDeleteUser = async () => {
    if (!deleteUserId) return
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await deleteUser(deleteUserId)
      if (users.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetUsers()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = (user: User) => (
    <React.Fragment>
      <Button className='ant-btn-ternary' onClick={() => setViewUser(user)}>
        View
      </Button>
      {currentUser && (!!currentUser.is_super || !!currentUser.is_admin) && (
        <Button className='ant-btn-ternary' onClick={() => setEditUser(user)}>
          Edit
        </Button>
      )}
      <Link
        to={`/activity-logs?user-id=${user.id}`}
        className='ant-btn ant-btn-ternary'
      >
        Activity Log
      </Link>
      <Button
        className='ant-btn-ternary text-danger'
        onClick={() => setDeleteUserId(user.id)}
      >
        Delete
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        loading={loading}
        empty={!users.length}
        className='table-user-management'
      >
        {users.map((u: User) => (
          <Table.Row key={u.id} rowKey={u.id} rowActions={renderRowActions(u)}>
            <Table.Col>
              <div className='user-info-wrapper'>
                <Avatar
                  size='large'
                  username={u.name}
                  src={u.avatar_url || undefined}
                />
                <div className='user-info'>
                  <div className='name'>{u.name}</div>
                  <div className='email'>
                    <span>Email</span>
                    <span>{u.email}</span>
                  </div>
                  <div className='role'>
                    <span>User Role</span>
                    <span>
                      {u.is_admin ? (
                        <React.Fragment>
                          <img src={Crown} alt='Admin' />
                          Admin
                        </React.Fragment>
                      ) : (
                        'Standard User'
                      )}
                    </span>
                  </div>
                  <div className='two-fa'>
                    <span>2 Factor Auth</span>
                    <span
                      className={cx(
                        u.enabled_2fa ? 'text-success' : 'text-placeholder',
                      )}
                    >
                      {u.enabled_2fa ? (
                        <CheckCircleOutlined />
                      ) : (
                        <StopOutlined />
                      )}
                      {u.enabled_2fa ? 'On' : 'Off'}
                    </span>
                  </div>
                  <div className='status'>
                    <span>Status</span>
                    <span
                      className={cx(
                        !!u.email_verified_at ? 'text-success' : 'text-warning',
                      )}
                    >
                      {!!u.email_verified_at ? (
                        <CheckCircleOutlined />
                      ) : (
                        <ClockCircleOutlined />
                      )}
                      {!!u.email_verified_at
                        ? 'Verified'
                        : 'Verification pending'}
                    </span>
                  </div>
                </div>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {!!showAdd && (
          <ModalCreateUser
            onSuccess={handleGetUsers}
            onCancel={() => setShowAdd(false)}
          />
        )}
        {!!editUser && (
          <ModalUpdateUser
            user={editUser}
            onSuccess={handleGetUsers}
            onCancel={() => setEditUser(null)}
          />
        )}
        {!!viewUser && (
          <ModalViewUserProfile
            user={viewUser}
            onCancel={() => setViewUser(null)}
          />
        )}
        {deleteUserId && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this user?'
            description='Invitation link will be expired after this user is deleted'
            onConfirm={handleDeleteUser}
            onCancel={() => setDeleteUserId(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
