import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'

import { Spinner } from 'components/Shared'
import { instance } from 'services/axios'
import { useAppDispatch, useAppSelector } from 'store'
import { updateCurrentUser } from 'store/slice/authSlice'

export const BlankLayoutWithAuth: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true)

  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)

  useEffect(() => {
    if (currentUser) {
      return setLoading(false)
    }
    handleGetCurrentUser()
    // eslint-disable-next-line
  }, [])

  const handleGetCurrentUser = async () => {
    try {
      setLoading(true)
      const response = await instance.get('/api/users/auth?include=account')
      await dispatch(updateCurrentUser(response.data.data))
    } catch {
      dispatch(updateCurrentUser(null))
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner height='100vh' />
  }

  if (!currentUser) {
    const lastPath = window.location.href
    if (
      lastPath.search(/\/verify-email\/([a-zA-Z0-9]+)\/([a-zA-Z0-9]+)/) > -1
    ) {
      localStorage.setItem('LAST_PATH', lastPath)
    }
    return <Redirect to='/login' />
  }

  return <React.Fragment>{children}</React.Fragment>
}

export default BlankLayoutWithAuth
