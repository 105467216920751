import { useState } from 'react'
import { Form, Input, Button } from 'antd'

import history from 'configs/history'
import { Layout, PasswordRequirements } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { matchPasswords, validatePassword } from 'utils/validation'
import { resetPassword } from 'services/auth'

interface Props {
  token: string
}

type FormValues = {
  email: string
  password: string
  rePassword: string
}

const rules = [{ required: true, message: 'This field is required' }]

function FormResetPassword({ token }: Props) {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const handleSubmit = ({ email, password, rePassword }: FormValues) => {
    const comparePasswordsResult = matchPasswords(password, rePassword)
    if (!comparePasswordsResult.success) {
      return form.setFields([
        { name: 'password', errors: [comparePasswordsResult.message] },
        { name: 'rePassword', errors: [comparePasswordsResult.message] },
      ])
    }
    const passwordResult = validatePassword(password)
    if (!passwordResult.success) {
      return form.setFields([
        { name: 'password', errors: [passwordResult.message] },
      ])
    }
    const rePasswordResult = validatePassword(rePassword)
    if (!rePasswordResult.success) {
      return form.setFields([
        { name: 'rePassword', errors: [rePasswordResult.message] },
      ])
    }
    handleResetPassword({ email, password, rePassword })
  }

  const handleResetPassword = async (values: FormValues) => {
    setLoading(true)
    try {
      await resetPassword({
        email: values.email,
        password: values.password,
        password_confirmation: values.rePassword,
        token,
      })
      setLoading(false)
      notiSuccess({ message: 'Password updated' })
      history.push('/login')
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <Layout className='reset-password-page'>
      <Layout.Header bordered goBack>
        Reset Password
      </Layout.Header>
      <Layout.Body fullHeight>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout='vertical'
          className='form-reset-password'
          requiredMark={false}
        >
          <PasswordRequirements />
          <Form.Item
            label='Email'
            name='email'
            rules={[
              ...rules,
              { type: 'email', message: 'Invalid email address' },
            ]}
          >
            <Input size='large' placeholder='Enter your email' />
          </Form.Item>
          <Form.Item label='New password' name='password' rules={rules}>
            <Input.Password
              size='large'
              placeholder='Enter your new password'
            />
          </Form.Item>
          <Form.Item label='Re-enter Password' name='rePassword' rules={rules}>
            <Input.Password
              size='large'
              placeholder='Re-enter your new password'
            />
          </Form.Item>
          <Button
            disabled={loading}
            type='primary'
            block
            size='large'
            htmlType='submit'
          >
            Change password
          </Button>
        </Form>
      </Layout.Body>
    </Layout>
  )
}

export default FormResetPassword
