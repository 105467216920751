import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function UserFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M8 0C5.67378 0 3.78125 1.89253 3.78125 4.21875C3.78125 6.54497 5.67378 8.4375 8 8.4375C10.3262 8.4375 12.2188 6.54497 12.2188 4.21875C12.2188 1.89253 10.3262 0 8 0Z'
          fill='url(#user_filled0_linear)'
        />
        <path
          d='M13.2489 11.1936C12.0939 10.0208 10.5628 9.375 8.9375 9.375H7.0625C5.43725 9.375 3.90606 10.0208 2.75106 11.1936C1.60172 12.3606 0.96875 13.901 0.96875 15.5312C0.96875 15.7901 1.17862 16 1.4375 16H14.5625C14.8214 16 15.0312 15.7901 15.0312 15.5312C15.0312 13.901 14.3983 12.3606 13.2489 11.1936Z'
          fill='url(#user_filled1_linear)'
        />
        <defs>
          <linearGradient
            id='user_filled0_linear'
            x1='3.78125'
            y1='0'
            x2='3.78125'
            y2='8.4375'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='user_filled1_linear'
            x1='0.96875'
            y1='9.375'
            x2='0.96875'
            y2='16'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
