import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function LoginFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M13.7294 1.00876C13.7149 1.00758 13.7026 1 13.6875 1H7.41667C6.45184 1 5.66667 1.78517 5.66667 2.75V3.3333C5.66667 3.65533 5.92793 3.9167 6.24996 3.9167C6.572 3.9167 6.83326 3.65533 6.83326 3.3333V2.75C6.83326 2.4286 7.09516 2.1667 7.41667 2.1667H10.1344L9.95643 2.2262C9.484 2.38951 9.16667 2.83459 9.16667 3.3333V12.0833H7.41667C7.09516 12.0833 6.83326 11.8214 6.83326 11.5V10.3333C6.83326 10.0114 6.572 9.75 6.24996 9.75C5.92793 9.75 5.66667 10.0114 5.66667 10.3333V11.5C5.66667 12.4648 6.45184 13.25 7.41667 13.25H9.16667V13.8333C9.16667 14.4767 9.68983 15 10.3333 15C10.4581 15 10.5766 14.9819 10.7049 14.9422L14.2096 13.7738C14.6826 13.6105 15 13.1654 15 12.6667V2.1667C15 1.48653 14.4137 0.953323 13.7294 1.00876Z'
          fill={c}
        />
        <path
          d='M7.2457 6.42078L4.9124 4.08749C4.74556 3.92065 4.49477 3.87045 4.27655 3.96091C4.05898 4.05128 3.9167 4.26426 3.9167 4.49989V6.24989H1.5833C1.26137 6.24989 1 6.51125 1 6.83318C1 7.15522 1.26137 7.41659 1.5833 7.41659H3.9167V9.16659C3.9167 9.40221 4.05898 9.61509 4.27655 9.70556C4.49477 9.79592 4.74556 9.74583 4.9124 9.57899L7.2457 7.24558C7.47385 7.01754 7.47385 6.64893 7.2457 6.42078Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
