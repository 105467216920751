import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Monitor({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='30'
        height='25'
        viewBox='0 0 30 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M27.0002 0.666992H3.00016C1.5335 0.666992 0.333496 1.86699 0.333496 3.33366V19.3337C0.333496 20.8003 1.5335 22.0003 3.00016 22.0003H9.66683V24.667H20.3335V22.0003H27.0002C28.4668 22.0003 29.6535 20.8003 29.6535 19.3337L29.6668 3.33366C29.6668 1.86699 28.4668 0.666992 27.0002 0.666992ZM27.0002 19.3337H3.00016V3.33366H27.0002V19.3337Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
