import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'

import './index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout } from 'components/Shared'
import { confirmPassword } from 'services/2fa'
import { notiError } from 'utils/notification'

interface Props {
  onCancel: () => void
  onSuccess?: () => void
  onFail?: () => void
}

function ModalVerifyPassword({ onCancel, onSuccess, onFail }: Props) {
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  const handleVerifyPassword = async () => {
    if (!password.length) return
    try {
      setLoading(true)
      await confirmPassword(password)
      onSuccess?.()
      onCancel()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      setLoading(false)
      onFail?.()
    }
  }

  return (
    <BaseModal noPadding className='modal-verify-password' onCancel={onCancel}>
      <Layout>
        <Layout.Header bordered rounded>
          Enter password to continue
        </Layout.Header>
        <Layout.Body>
          <span className='verify-desc'>
            The action you are trying to do requires that you re-enter your
            password.
          </span>
          <Input.Password
            size='large'
            placeholder='Enter your password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => e.code === 'Enter' && handleVerifyPassword()}
          />
          <Link to='/reset-password' className='forgot-password text-500'>
            Forgot password?
          </Link>
          <Button
            disabled={loading}
            type='primary'
            size='large'
            block
            onClick={!loading ? handleVerifyPassword : undefined}
          >
            Confirm
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalVerifyPassword
