import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function ScreenshotFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M0 10.6667H1.77778V14.2222H5.33333V16H1.77778C0.8 16 0 15.2 0 14.2222V10.6667Z'
          fill={c}
        />
        <path
          d='M5.33333 1.77778H1.77778V5.33333H0V1.77778C0 0.8 0.8 0 1.77778 0H5.33333V1.77778Z'
          fill={c}
        />
        <path
          d='M10.6667 0H14.2222C15.2 0 16 0.8 16 1.77778V5.33333H14.2222V1.77778H10.6667V0Z'
          fill={c}
        />
        <path
          d='M10.6667 14.2222H14.2222V10.6667H16V14.2222C16 15.2 15.2 16 14.2222 16H10.6667V14.2222Z'
          fill={c}
        />
        <path
          d='M8 9.97315C8.94257 9.97315 9.70667 9.20905 9.70667 8.26648C9.70667 7.32392 8.94257 6.55981 8 6.55981C7.05744 6.55981 6.29333 7.32392 6.29333 8.26648C6.29333 9.20905 7.05744 9.97315 8 9.97315Z'
          fill={c}
        />
        <path
          d='M6.40002 2.93335L5.42402 4.00002H3.73335C3.14669 4.00002 2.66669 4.48002 2.66669 5.06668V11.4667C2.66669 12.0534 3.14669 12.5333 3.73335 12.5333H12.2667C12.8534 12.5333 13.3334 12.0534 13.3334 11.4667V5.06668C13.3334 4.48002 12.8534 4.00002 12.2667 4.00002H10.576L9.60002 2.93335H6.40002ZM8.00002 10.9333C6.52802 10.9333 5.33335 9.73868 5.33335 8.26668C5.33335 6.79468 6.52802 5.60002 8.00002 5.60002C9.47202 5.60002 10.6667 6.79468 10.6667 8.26668C10.6667 9.73868 9.47202 10.9333 8.00002 10.9333Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
