import * as FormHelpers from 'components/Modal/Schedule/FormHelpers'
import { DEFAULT_TAG_COLOR } from 'configs/tagColors'
export * from 'components/Modal/Schedule/FormHelpers'

export const steps = [
  { key: 'tagDetails', title: 'Tag Details', canSkip: false },
  { key: 'affectedDevices', title: 'Affected Devices', canSkip: true },
  { key: 'recipients', title: 'Recipients', canSkip: false },
]

export interface FormValues {
  tagDetails: {
    name: string
    stroke_color: string
    text_color: string
    color: string
    subject_keywords: string // in_title
    body_keywords: string // in_content
    search_in: string
    has_attachments: boolean
  }
  affectedDevices: {
    apply_to_all: boolean
    devices: FormHelpers.OptionValue[]
  }
  recipients: {
    is_blocked: boolean
    channels: string[]
    phone_recipients: FormHelpers.OptionValue[]
    whatsapp_recipients: FormHelpers.OptionValue[]
    email_recipients: FormHelpers.OptionValue[]
    slack_webhook: string
    webhook: string
    teams_webbhook: string
  }
}

export const formValues: FormValues = {
  tagDetails: {
    name: '',
    stroke_color: DEFAULT_TAG_COLOR.strokeColor,
    text_color: DEFAULT_TAG_COLOR.textColor,
    color: DEFAULT_TAG_COLOR.color,
    subject_keywords: '',
    body_keywords: '',
    search_in: 'subject_or_body',
    has_attachments: false,
  },
  affectedDevices: {
    apply_to_all: false,
    devices: [],
  },
  recipients: {
    is_blocked: false,
    channels: [],
    phone_recipients: [],
    whatsapp_recipients: [],
    email_recipients: [],
    slack_webhook: '',
    webhook: '',
    teams_webbhook: '',
  },
}

//
