import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function EmptyList({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='65'
        height='59'
        viewBox='0 0 65 59'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M52.7273 32.0033L44.1708 0.798751C44.0415 0.327428 43.6131 0 43.1233 0H9.64203C9.15279 0 8.72436 0.327428 8.59458 0.798751L0.0385529 32.0033C0.013032 32.0972 0 32.1933 0 32.2905V46.914C0 47.5135 0.486527 48 1.086 48H51.6794C52.2788 48 52.7654 47.5135 52.7654 46.914V32.2905C52.7654 32.1933 52.7523 32.0972 52.7273 32.0033ZM39.8887 4.53132L46.3672 27.8857H35.6321C35.0326 27.8857 34.5461 28.3722 34.5461 28.9717V39.3804H18.2192V28.9722C18.2192 28.3728 17.7327 27.8862 17.1332 27.8862H6.39815L12.8767 4.53187H39.8887V4.53132Z'
          fill='#DEDEDE'
        />
        <path
          d='M49 29C41.2804 29 35 35.2804 35 43C35 50.7196 41.2804 57 49 57C56.7196 57 63 50.7196 63 43C63 35.2804 56.7196 29 49 29Z'
          fill='url(#empty_list0_linear)'
        />
        <path
          d='M54.2498 38.0505L47.0501 45.2501L43.7503 41.9503C43.2534 41.4533 42.4475 41.4532 41.9504 41.9502C41.4534 42.4472 41.4534 43.253 41.9504 43.7501L46.1501 47.9499C46.3888 48.1886 46.7125 48.3227 47.05 48.3227C47.0501 48.3227 47.05 48.3227 47.0501 48.3227C47.3876 48.3227 47.7113 48.1886 47.95 47.95L56.0497 39.8504C56.5467 39.3534 56.5467 38.5476 56.0497 38.0506C55.5526 37.5535 54.7468 37.5534 54.2498 38.0505Z'
          fill='white'
        />
        <defs>
          <linearGradient
            id='empty_list0_linear'
            x1='49'
            y1='29'
            x2='49'
            y2='57'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
