import { Button, Form, Input } from 'antd'
import { Link } from 'react-router-dom'

interface Props {
  disabled: boolean
  onSubmit: (values: { email: string; password: string }) => void
}

const rules = [{ required: true, message: 'This field is required' }]

function LoginForm({ disabled, onSubmit }: Props) {
  return (
    <Form
      className='form-login'
      onFinish={onSubmit}
      layout='vertical'
      requiredMark={false}
    >
      <Form.Item
        name='email'
        label='Email'
        rules={[...rules, { type: 'email', message: 'Invalid email address' }]}
      >
        <Input size='large' placeholder='Enter username or email' />
      </Form.Item>
      <Form.Item
        className='field-password'
        name='password'
        label={
          <div>
            Password <Link to='/reset-password'>Forgot password?</Link>
          </div>
        }
        rules={rules}
      >
        <Input.Password size='large' placeholder='Enter your password' />
      </Form.Item>
      <Button
        disabled={disabled}
        htmlType='submit'
        type='primary'
        block
        size='large'
      >
        Sign In
      </Button>
    </Form>
  )
}

export default LoginForm
