import React, { createContext, useContext, useReducer } from 'react'

interface State {
  loading: boolean
  recipients: []
  totalPage: number
  currentPage: number
  search: string
}

interface Action {
  type: string
  payload?: any
}

const initState: State = {
  loading: false,
  recipients: [],
  totalPage: 1,
  currentPage: 1,
  search: '',
}

const RecipientContext = createContext<any>(null)

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: payload,
      }
    case 'SET_RECIPIENTS':
      return {
        ...state,
        loading: false,
        recipients: payload.data,
        totalPage: payload.meta.last_page,
      }
    case 'SET_CURRENT_PAGE':
      return {
        ...state,
        currentPage: payload,
      }
    case 'SET_SEARCH':
      return {
        ...state,
        search: payload,
        currentPage: 1,
      }
    default:
      return state
  }
}

const RecipientContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)

  return (
    <RecipientContext.Provider value={{ ...state, dispatch }}>
      {children}
    </RecipientContext.Provider>
  )
}

const useRecipientContext = () => {
  return useContext(RecipientContext)
}

export { RecipientContextProvider as default, useRecipientContext }
