import './index.scss'

import cx from 'classnames'
import React, { CSSProperties } from 'react'

import NoResultImg from 'assets/images/no-result.svg'

interface Props {
  icon?: React.ReactNode
  imgSrc?: string
  style?: CSSProperties
  className?: string
}

export const NoResult: React.FC<Props> = ({
  children,
  icon,
  imgSrc,
  className,
  style,
}) => {
  return (
    <div className={cx('no-result-wrapper', className)} style={style}>
      <div className='no-result-icon'>
        {icon ? icon : <img src={imgSrc || NoResultImg} alt='no-result-icon' />}
      </div>
      <div className='no-result-content'>{children}</div>
    </div>
  )
}
