import { Checkbox, Form, FormInstance, Input, Radio } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

import { FilterFilled, InfoCircle, TagFilled } from 'components/Icons'
import { Tag } from 'components/Shared'
import tagColors from 'configs/tagColors'

import { rules } from './FormHelpers'

interface Props {
  form: FormInstance
  stroke_color: string
  text_color: string
  color: string
  onTagColorChange: (c: {
    strokeColor: string
    textColor: string
    color: string
  }) => void
}

function TagDetails({
  form,
  stroke_color,
  text_color,
  color,
  onTagColorChange,
}: Props) {
  return (
    <div style={{ padding: '1rem 8px' }}>
      <div className='form-group-title'>
        <TagFilled type='primary' />
        <h3>Tag Info</h3>
      </div>
      <Form.Item
        name='name'
        label='Tag name'
        rules={rules}
        extra={
          <span>
            <InfoCircle color='#808080' />
            Keep the name short and meaningful for clean categorization
          </span>
        }
      >
        <Input size='large' placeholder='Name your tag' />
      </Form.Item>
      <Form.Item label='Tag Color'>
        <div className='tag-colors'>
          {tagColors.map(c => (
            <Tag
              key={c.color}
              strokeColor={c.strokeColor}
              textColor={c.textColor}
              color={c.color}
              onClick={() => onTagColorChange(c)}
            >
              {color === c.color &&
                stroke_color === c.strokeColor &&
                text_color === c.textColor && <CheckOutlined />}
            </Tag>
          ))}
        </div>
      </Form.Item>
      <div className='divider' />

      <div className='form-group-title'>
        <FilterFilled type='primary' />
        <h3>Matching Criteria</h3>
      </div>
      <Form.Item name='subject_keywords' label='In Title'>
        <Input.TextArea
          rows={3}
          size='large'
          placeholder='Enter a keyword to search for, e.g. motion, tamper, etc'
          onChange={() =>
            form.setFields([{ name: 'subject_keywords', errors: [] }])
          }
        />
      </Form.Item>
      <Form.Item name='search_in' label='Matching Method'>
        <Radio.Group
          optionType='button'
          buttonStyle='solid'
          options={[
            { label: 'Or', value: 'subject_or_body' },
            { label: 'And', value: 'subject_and_body' },
          ]}
        />
      </Form.Item>
      <Form.Item name='body_keywords' label='In Content'>
        <Input.TextArea
          rows={3}
          size='large'
          placeholder='Enter a keyword to search for, e.g. motion, tamper, etc'
          onChange={() =>
            form.setFields([{ name: 'body_keywords', errors: [] }])
          }
        />
      </Form.Item>
      <Form.Item
        style={{ margin: 0 }}
        valuePropName='checked'
        label='Other Criteria'
        name='has_attachments'
        colon={false}
      >
        <Checkbox className='checkbox-large'>Contains attachment</Checkbox>
      </Form.Item>
    </div>
  )
}

export default TagDetails
