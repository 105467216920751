import { useState } from 'react'

import TableList from './TableList'
import TableListHeader from './TableListHeader'
import ScheduleContext from './ScheduleContext'

interface Props {
  deviceId: number
}

function DeviceSchedules({ deviceId }: Props) {
  const [showCreateSchedule, setShowCreateSchedule] = useState(false)

  return (
    <div className='tab-device-schedules'>
      <ScheduleContext>
        <TableListHeader onCreateSchedule={() => setShowCreateSchedule(true)} />
        <TableList
          showCreateSchedule={showCreateSchedule}
          setShowCreateSchedule={setShowCreateSchedule}
          deviceId={deviceId}
        />
      </ScheduleContext>
    </div>
  )
}

export default DeviceSchedules
