import { useState } from 'react'
import { omit } from 'lodash'
import { Alert, Button, Form, Input, Radio } from 'antd'

import './index.scss'
import BaseModal from 'components/Modal/BaseModal'
import { Layout } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { matchPasswords, validatePassword } from 'utils/validation'
import { createUser } from 'services/users'
import { useAppSelector } from 'store'

const rules = [{ required: true, message: 'This field is required' }]

interface FormValues {
  name: string
  email: string
  password: string
  rePassword: string
}

interface Props {
  onSuccess?: () => void
  onCancel: () => void
}

function ModalCreateUser({ onSuccess, onCancel }: Props) {
  const [form] = Form.useForm()
  const { currentUser } = useAppSelector(state => state.auth)

  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState('standard')

  const handleSubmit = (values: FormValues) => {
    const { password, rePassword } = values
    const matchPasswordsResult = matchPasswords(password, rePassword)
    if (!matchPasswordsResult.success) {
      return form.setFields([
        { name: 'password', errors: [matchPasswordsResult.message] },
        { name: 'rePassword', errors: [matchPasswordsResult.message] },
      ])
    }
    const passwordResult = validatePassword(password)
    if (!passwordResult.success) {
      return form.setFields([
        { name: 'password', errors: [passwordResult.message] },
      ])
    }
    const rePasswordResult = validatePassword(rePassword)
    if (!rePasswordResult.success) {
      return form.setFields([
        { name: 'rePassword', errors: [rePasswordResult.message] },
      ])
    }
    return handleCreateUser(values)
  }

  const handleCreateUser = async (values: FormValues) => {
    try {
      setLoading(true)
      await createUser({
        ...omit(values, ['rePassword']),
        is_admin: role === 'admin',
        is_super_admin: false,
        account_id: currentUser!.account_id,
      })
      setLoading(false)
      notiSuccess({
        message: `${values.name} was created & pending for accept`,
      })
      onCancel()
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-create-user'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          Create User
        </Layout.Header>
        <Layout.Body>
          <Alert
            type='success'
            message='An email will be sent to user’s email to confirm'
          />
          <Form form={form} onFinish={handleSubmit} layout='vertical'>
            <Form.Item name='name' label='Full Name' rules={rules}>
              <Input size='large' placeholder='Enter user full name' />
            </Form.Item>
            <Form.Item
              name='email'
              label='Email Address'
              rules={[
                ...rules,
                { type: 'email', message: 'Invalid email address' },
              ]}
            >
              <Input size='large' placeholder='Enter user email address' />
            </Form.Item>
            <Form.Item name='password' label='Password' rules={rules}>
              <Input.Password size='large' placeholder='Enter user password' />
            </Form.Item>
            <Form.Item
              name='rePassword'
              label='Re-enter Password'
              rules={rules}
            >
              <Input.Password size='large' placeholder='Re-enter password' />
            </Form.Item>
            <Form.Item label='User Role'>
              <Radio.Group value={role} onChange={e => setRole(e.target.value)}>
                <Radio value='standard'>Standard User</Radio>
                <Radio value='admin'>Admin</Radio>
              </Radio.Group>
            </Form.Item>
            <Button
              disabled={loading}
              htmlType='submit'
              size='large'
              block
              type='primary'
            >
              Create User
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalCreateUser
