import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function EmailFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='14'
        height='11'
        viewBox='0 0 14 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M0.292169 0.851922C2.21361 2.47918 5.58515 5.34201 6.57609 6.23465C6.70912 6.35513 6.8518 6.41638 6.99992 6.41638C7.14774 6.41638 7.29017 6.3557 7.4229 6.2358C8.41468 5.34229 11.7862 2.47918 13.7077 0.851922C13.8273 0.750805 13.8456 0.57307 13.7487 0.449449C13.5248 0.163789 13.191 0 12.8332 0H1.16659C0.808856 0 0.475017 0.163789 0.251153 0.449477C0.154302 0.57307 0.17254 0.750805 0.292169 0.851922Z'
          fill='url(#email1_linear)'
        />
        <path
          d='M13.8308 1.73373C13.7274 1.68561 13.6058 1.7024 13.5198 1.7759C11.3889 3.58228 8.66939 5.89769 7.81375 6.6687C7.33351 7.10221 6.66701 7.10221 6.18565 6.66813C5.27362 5.8464 2.21966 3.25016 0.480211 1.77587C0.393613 1.70237 0.271715 1.68616 0.169176 1.73371C0.0660898 1.78159 0 1.8847 0 1.99834V9.33329C0 9.97675 0.523223 10.5 1.16668 10.5H12.8334C13.4768 10.5 14 9.97675 14 9.33329V1.99834C14 1.8847 13.9339 1.78131 13.8308 1.73373Z'
          fill='url(#email2_linear)'
        />
        <defs>
          <linearGradient
            id='email1_linear'
            x1='0.188965'
            y1='0'
            x2='0.188965'
            y2='6.41638'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='email2_linear'
            x1='0'
            y1='1.70654'
            x2='0'
            y2='10.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
