import { useEffect, useState } from 'react'
import { Tabs } from 'antd'

import '../index.scss'
import { Layout, Spinner } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { notiError } from 'utils/notification'
import { getTagDetails, Tag } from 'services/tags'

import TagDetails from './TagDetails'
import AffectedDevices from './AffectedDevices'
import Recipients from './Recipients'

interface Props {
  tagId: number
  onCancel: () => void
}

function ModalViewTagDetail({ tagId, onCancel }: Props) {
  const [loading, setLoading] = useState(true)
  const [tag, setTag] = useState<Tag | null>(null)

  useEffect(() => {
    handleGetTagDetails()
    // eslint-disable-next-line
  }, [])

  const handleGetTagDetails = async () => {
    try {
      const response = await getTagDetails(tagId)
      setTag(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-view-tag-detail'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header sticky goBack onGoBack={onCancel}>
          Tag Details
        </Layout.Header>
        {loading && (
          <Layout.Body fullHeight>
            <Spinner />
          </Layout.Body>
        )}
        {!loading && !!tag && (
          <Layout.Body>
            <Tabs type='card' defaultActiveKey='tag-details'>
              <Tabs.TabPane key='tag-details' tab='Tag Details'>
                <TagDetails tag={tag} />
              </Tabs.TabPane>
              <Tabs.TabPane key='affected-devices' tab='Affected Devices'>
                <AffectedDevices tag={tag} />
              </Tabs.TabPane>
              <Tabs.TabPane key='recipients' tab='Recipients'>
                <Recipients tag={tag} />
              </Tabs.TabPane>
            </Tabs>
          </Layout.Body>
        )}
      </Layout>
    </BaseModal>
  )
}

export default ModalViewTagDetail
