import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function MobilePhone({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='25'
        viewBox='0 0 16 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M12.2222 0.111328H3.33333C1.48889 0.111328 0 1.60022 0 3.44466V21.2224C0 23.0669 1.48889 24.5558 3.33333 24.5558H12.2222C14.0667 24.5558 15.5556 23.0669 15.5556 21.2224V3.44466C15.5556 1.60022 14.0667 0.111328 12.2222 0.111328ZM13.3333 19.0002H2.22222V3.44466H13.3333V19.0002ZM10 22.3335H5.55556V21.2224H10V22.3335Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
