import axios from './axios'
import { Device } from './devices'
import { Note } from './notes'
import { Tag } from './tags'

export interface InboundNoti {
  attachment_urls: string[]
  attachments: string[] | null
  code: string
  created_at: string
  device_id: number
  id: number
  message: string
  notes: Note[]
  recipients_count: number
  status: string
  subject: string
  tags: Tag[] | undefined
  updated_at: string
  device: Device | null
}

export interface OutboundNoti {
  batch_id: string
  created_at: string
  device_id: number
  device_inbound_notification_id: number
  id: number
  is_sent: number
  name: string
  recipient_id: number
  response: string
  status: string
  to: string
  updated_at: string
  via: string
}

export interface BroadcastNoti {
  created_at: {
    date: string
    timezone: string
    timezone_type: number
  }
  data: {
    level: 'info' | 'success' | 'warning'
    message: string
    target: string
    url: string | null
  }
  id: string
  read_at: string | null
  type: string
}

export interface SystemNoti {
  created_at: string
  data: {
    level: 'info' | 'success' | 'warning'
    message: string
    target: string
    url: string | null
  }
  read_at: string | null
}

export const getInboundNotis = async (qr?: {
  deviceId: number | string
  sort: string
  sortDirection: string
  pageSize: number
  search: string
  tagIds: string
  dateRange: string
  currentPage: number
}) => {
  if (!qr) {
    return axios.get('/api/deviceInboundNotifications?include=tags,device')
  }
  return axios.get(
    `/api/deviceInboundNotifications?filters[device_id]=${qr.deviceId}&filters[search]=${qr.search}&filters[tag_ids]=${qr.tagIds}&filters[date_range]=${qr.dateRange}&order_by=${qr.sort}&sort=${qr.sortDirection}&limit=${qr.pageSize}&include=tags,device,notes&page=${qr.currentPage}`,
  )
}

export const getInboundNotiDetails = (notiId: number) => {
  return axios.get(
    `/api/deviceInboundNotifications/${notiId}?include=tags,device,device.location`,
  )
}

export const getOutboundNotis = (
  inboundNotiId: number,
  {
    sort,
    sortDirection,
    pageSize,
    currentPage,
    search,
  }: {
    sort: string
    sortDirection: string
    pageSize: number
    currentPage: number
    search: string
  },
) => {
  return axios.get(
    `/api/deviceInboundNotifications/${inboundNotiId}/outboundNotificationLogs?filters[search]=${search}&page=${currentPage}&order_by=${sort}&sort=${sortDirection}&limit=${pageSize}`,
  )
}

export const getTodayInboundNotis = (deviceId?: number) => {
  return axios.get(
    `/api/cards/inboundNotifications${
      deviceId ? `?device_id=${deviceId}` : ''
    }`,
  )
}

export const getTodayOutboundNotis = (deviceId?: number) => {
  return axios.get(
    `/api/cards/outboundNotifications${
      deviceId ? `?device_id=${deviceId}` : ''
    }`,
  )
}

export const getSystemNotis = (currentPage: number) => {
  return axios.get(`/api/notifications?page=${currentPage}&limit=20`)
}

export const markAllNotifAsRead = () => {
  return axios.get('/api/notifications?mark_as_read=1')
}
