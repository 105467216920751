import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Live({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='24'
        height='13'
        viewBox='0 0 24 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <rect
          x='0.800537'
          y='1.3042'
          width='22.4176'
          height='9.60753'
          fill='white'
        />
        <path
          d='M22.1914 0H1.80884C0.809812 0 0 0.809812 0 1.80864V10.7041C0 11.7031 0.809812 12.5129 1.80884 12.5129H22.1912C23.1902 12.5129 24 11.7031 24 10.7041V1.80864C24 0.809812 23.1902 0 22.1914 0ZM5.9482 10.1921H3.0993C2.6724 10.1921 2.32623 9.84597 2.32623 9.41888V3.09402C2.32623 2.66712 2.6724 2.32076 3.0993 2.32076C3.52639 2.32076 3.87256 2.66693 3.87256 3.09402V8.64562H5.9482C6.37529 8.64562 6.72146 8.99179 6.72146 9.41888C6.72146 9.84597 6.37529 10.1921 5.9482 10.1921ZM9.11708 9.41888C9.11708 9.84597 8.77092 10.1921 8.34382 10.1921C7.91673 10.1921 7.57056 9.84597 7.57056 9.41888V3.09402C7.57056 2.66712 7.91673 2.32076 8.34382 2.32076C8.77092 2.32076 9.11708 2.66693 9.11708 3.09402V9.41888ZM15.6229 3.31763L13.7134 9.6423C13.7133 9.64289 13.7131 9.64347 13.7129 9.64406C13.5055 10.3518 12.4482 10.3344 12.2336 9.64406C12.2334 9.64347 12.2332 9.64289 12.233 9.6423L10.3235 3.31763C10.2002 2.90872 10.4314 2.47733 10.8403 2.35379C11.2488 2.23045 11.6806 2.46169 11.8039 2.8706L12.9732 6.74335L14.1425 2.8706C14.266 2.46169 14.6974 2.23026 15.1063 2.35379C15.5151 2.47733 15.7465 2.90872 15.6229 3.31763ZM20.38 5.08503C20.8071 5.08503 21.1532 5.4312 21.1532 5.85829C21.1532 6.28518 20.8071 6.63155 20.38 6.63155H18.1857V8.64562H20.7965C21.2236 8.64562 21.5698 8.99179 21.5698 9.41888C21.5698 9.84597 21.2236 10.1921 20.7965 10.1921H17.4124C16.9853 10.1921 16.6392 9.84597 16.6392 9.41888V3.09402C16.6392 2.66712 16.9853 2.32076 17.4124 2.32076H20.9007C21.3276 2.32076 21.674 2.66693 21.674 3.09402C21.674 3.52111 21.3276 3.86728 20.9007 3.86728H18.1855V5.08503H20.38Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
