import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router'

import './index.scss'
import Heart from 'assets/heart.png'

import { Layout, Spinner } from 'components/Shared'
import { getPlans } from 'store/slice/plansSlice'
import { useAppDispatch, useAppSelector } from 'store'

import UpdatePlan from './UpdatePlan'
import BuyNewPlan from './BuyNewPlan'

type RouteParams = {
  planId: number
  camCount: number
}

function Checkout() {
  const { subscription } = useAppSelector(state => state.subscription)
  const isUpdatePlan = !!subscription?.subscription?.plan_id

  return (
    <Layout className='checkout-page'>
      <Layout.Header goBack bordered>
        Checkout
      </Layout.Header>
      <Layout.Body>
        {!isUpdatePlan ? <UpdatePlan /> : <BuyNewPlan />}
      </Layout.Body>
    </Layout>
  )
}

function CheckoutWrapper() {
  const { location } = useHistory()
  const { plans } = useAppSelector(state => state.plans)
  const { config } = useAppSelector(state => state.appConfig)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!plans.length) {
      dispatch(getPlans())
    }
    // eslint-disable-next-line
  }, [])

  if (!location.state) {
    return <Redirect to='/settings/payment-settings' />
  }

  if (!plans.length || !config) {
    return <Spinner height='calc(90vh)' />
  }

  const routeParams = location.state as RouteParams
  const selectedPlan = plans.find(p => p.id === routeParams.planId)
  if (!selectedPlan) {
    return <Redirect to='/settings/payment-settings' />
  }

  return (
    <React.Fragment>
      <Checkout />
      <img src={Heart} alt='preload-img' className='preload-img' />
    </React.Fragment>
  )
}

export default CheckoutWrapper
