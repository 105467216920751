import { useEffect, useReducer, useRef } from 'react'
import Hls, { Events } from 'hls.js'
import moment from 'moment'
import cx from 'classnames'

import {
  ExitFullscreen,
  Fullscreen,
  Live,
  PauseFilled,
  PlayFilled,
  ScreenshotFilled,
  SpeakerLouderFilled,
  SpeakerMutedFilled,
} from 'components/Icons'
import { useFullscreen } from 'hooks'
import { Layout, Spinner } from 'components/Shared'
import { videoCapture } from 'utils/functions'
import { notiError } from 'utils/notification'
import { DoubleRightOutlined } from '@ant-design/icons'

interface State {
  loading: boolean
  isPlaying: boolean
  currentTime: number
  isMuted: boolean
  showControls: boolean
}

interface Props {
  url: string
}

const initState = {
  loading: true,
  isPlaying: false,
  currentTime: 0,
  isMuted: true,
  showControls: true,
}

function EmbedPlayer({ url }: Props) {
  const { isFullscreen, toggleFullscreen } = useFullscreen()
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  const videoRef = useRef<HTMLVideoElement>(null)
  const hlsRef = useRef<Hls | null>(null)

  useEffect(() => {
    setTimeout(init, 0)
    return () => {
      hlsRef.current && hlsRef.current.destroy()
    }
    // eslint-disable-next-line
  }, [])

  const init = () => {
    const videoEl = videoRef.current
    if (!videoEl) {
      return
    }

    if (!Hls.isSupported()) {
      videoEl.src = url
    } else {
      const newHls = new Hls()
      newHls.loadSource(url)
      newHls.attachMedia(videoEl)
      newHls.on(Events.ERROR, (_, { fatal }) => {
        if (!fatal) return
        setState({ loading: false })
        notiError({
          message: 'Error',
          description: 'The link you’re trying to access is expired',
        })
      })
      hlsRef.current = newHls
    }
  }

  const handleReady = () => {
    videoRef.current!.play().catch(console.error)
    setState({ loading: false })
  }

  const handleTimeUpdate = () => {
    setState({ currentTime: videoRef.current?.currentTime || 0 })
  }

  const handlePlay = () => {
    try {
      const videoEl = videoRef.current!
      videoEl.currentTime = videoEl.duration || 0
      videoEl.play()
      setState({ isPlaying: true })
    } catch (err) {
      console.error(err)
    }
  }

  const handlePause = () => {
    videoRef.current!.pause()
    setState({ isPlaying: false })
  }

  const { loading, isMuted, isPlaying, showControls } = state

  return (
    <Layout className='embed-player-wrapper modal-livestream'>
      <div className='live-icon'>
        <Live type='danger' />
      </div>

      {loading && <Spinner />}

      <video
        ref={videoRef}
        muted={isMuted}
        onLoadedMetadata={handleReady}
        onTimeUpdate={handleTimeUpdate}
        onPlaying={() => setState({ isPlaying: true })}
        onPause={() => setState({ isPlaying: false })}
        onSeeking={() => setState({ loading: true })}
        onSeeked={() => setState({ loading: false })}
        onClick={isPlaying && !loading ? handlePause : undefined}
      />

      <div className='current-datetime'>
        <span className='date' style={{ marginRight: 4 }}>
          {moment().tz('America/New_York').format('MM/DD/YYYY')}
        </span>
        <span className='time'>
          {moment().tz('America/New_York').format('HH:mm:ss')}
        </span>
      </div>

      <div className='controls-wrapper'>
        <div className={cx('controls', { hide: !showControls })}>
          <div onClick={isPlaying ? handlePause : handlePlay}>
            {isPlaying ? (
              <PauseFilled color='#ffffff' />
            ) : (
              <PlayFilled color='#ffffff' />
            )}
          </div>
          <div onClick={() => videoCapture(videoRef.current!)}>
            <ScreenshotFilled color='#ffffff' />
          </div>
          <div onClick={() => setState({ isMuted: !isMuted })}>
            {isMuted ? (
              <SpeakerMutedFilled color='#ffffff' />
            ) : (
              <SpeakerLouderFilled color='#ffffff' />
            )}
          </div>
          <div className='divider' />
          <div onClick={() => toggleFullscreen(videoRef.current!)}>
            {isFullscreen ? (
              <ExitFullscreen color='#ffffff' />
            ) : (
              <Fullscreen color='#ffffff' />
            )}
          </div>
          <div className='divider' />
          <div onClick={() => setState({ showControls: !showControls })}>
            <DoubleRightOutlined rotate={90} />
          </div>
        </div>
        <div className={cx('show-controls', { show: !showControls })}>
          <div onClick={() => setState({ showControls: !showControls })}>
            <DoubleRightOutlined rotate={-90} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EmbedPlayer
