import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button, Badge } from 'antd'
import { SearchOutlined, SyncOutlined } from '@ant-design/icons'

import Pagination from 'components/Pagination'
import ModalSearchRecord from 'components/Modal/RecordFile/SearchRecord'
import { OptionValue } from 'components/Modal/Schedule/FormHelpers'
import { notiError } from 'utils/notification'
import { getVideoRecords } from 'services/recordingDownloads'
import { useAppSelector } from 'store'

import { useVideoSearchContext } from './VideoSearchContext'

function TableListHeader() {
  const { newNotifications } = useAppSelector(state => state.notification)
  const { loading, totalPage, currentPage, device, from, to, dispatch } =
    useVideoSearchContext()

  const [showSearch, setShowSearch] = useState(false)
  const lastNotifUrlRef = useRef<string>('')

  useEffect(() => {
    if (newNotifications.length) {
      const notif = newNotifications.find(n =>
        n.data.message.endsWith('ready for download'),
      )
      if (!!notif && notif.data.url !== lastNotifUrlRef.current) {
        handleRefetch()
        lastNotifUrlRef.current = notif.data.url!
      }
    }
    // eslint-disable-next-line
  }, [newNotifications])

  const handlePageChange = (newPage: number) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: newPage })
  }

  const handleSearchSuccess = async (search: {
    device?: OptionValue
    from?: string
    to?: string
  }) => {
    dispatch({ type: 'SET_SEARCH', payload: search })
    handleRefetch()
  }

  const handleRefetch = async () => {
    try {
      if (currentPage !== 1) {
        return handlePageChange(1)
      }

      dispatch({ type: 'SET_LOADING', payload: true })
      const response = await getVideoRecords({
        sort: 'id',
        sortDirection: 'desc',
        currentPage: 1,
        pageSize: 10,
      })

      dispatch({ type: 'SET_VIDEOS', payload: response.data })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  return (
    <React.Fragment>
      <div className='table-list-header sticky'>
        <div className='left'>
          <Pagination
            disabled={loading}
            current={currentPage}
            total={totalPage}
            onPageChange={handlePageChange}
          />
        </div>
        <div className='right'>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowSearch(true)}
          >
            <Badge dot={!!device || !!from || !!to}>
              <SearchOutlined />
            </Badge>
          </Button>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={loading ? undefined : handleRefetch}
          >
            <Badge dot={false}>
              <SyncOutlined />
            </Badge>
          </Button>
        </div>
      </div>
      <AnimatePresence>
        {showSearch && (
          <ModalSearchRecord
            device={device}
            from={from}
            to={to}
            onSuccess={handleSearchSuccess}
            onCancel={() => setShowSearch(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableListHeader
