import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import cx from 'classnames'
import { Button, DatePicker, notification } from 'antd'

import { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { Layout, Spinner } from 'components/Shared'
import { MM_DD_YYYY, YYYY_MM_DD } from 'utils/dateTime'
import { notiError } from 'utils/notification'
import { Device, getDevices } from 'services/devices'
import { getTags, Tag } from 'services/tags'

import BaseModal from '../BaseModal'

interface OptionType {
  value: number
  label: string
}

interface Filters {
  device?: OptionType
  tags?: Tag[]
  dateRange?: [string, string]
}

interface Props {
  device?: OptionType
  tags?: Tag[]
  dateRange?: string[]
  onChange?: (filters: Filters) => void
  onCancel: () => void
}

function ModalFilterDeviceNotifs({
  device,
  dateRange,
  tags: pTags,
  onChange,
  onCancel,
}: Props) {
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState<Tag[]>([])

  const [selectedDevice, setSelectedDevice] = useState<OptionType | undefined>(
    device,
  )
  const [selectedTags, setSelectedTags] = useState<Tag[]>(pTags || [])
  const [from, setFrom] = useState(() =>
    dateRange && dateRange[0] ? moment(dateRange[0]) : null,
  )
  const [to, setTo] = useState(() =>
    dateRange && dateRange[1] ? moment(dateRange[0]) : null,
  )

  useEffect(() => {
    handleGetTags()
    // eslint-disable-next-line
  }, [])

  const handleGetTags = async () => {
    try {
      setLoading(true)
      const response = await getTags({ pageSize: 10000, currentPage: 1 })
      setTags(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = (isReset = false) => {
    if (isReset && !!onChange) {
      onChange({})
      return onCancel()
    }

    notification.destroy()
    const range: Moment[] = []
    if (!!from) range.push(from)
    if (!!to) range.push(to)
    if (
      range.length === 1 ||
      (range.length === 2 && range[1].diff(range[0]) < 0)
    ) {
      return notiError({ message: 'Error', description: 'Invalid duration' })
    }

    if (!onChange) return
    onChange({
      device: selectedDevice,
      dateRange:
        range.length === 2
          ? [range[0].format(YYYY_MM_DD), range[1].format(YYYY_MM_DD)]
          : undefined,
      tags: !selectedTags.length ? undefined : selectedTags,
    })
    onCancel()
  }

  const handleGetDevices = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getDevices({
        search,
        currentPage: page,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const handleTagSelect = (tag: Tag) => {
    setSelectedTags(prev =>
      !!prev.find(p => p.id === tag.id)
        ? prev.filter(p => p.id !== tag.id)
        : [...prev, tag],
    )
  }

  const renderTags = () => {
    return tags.map(t => (
      <Button
        key={t.id}
        size='large'
        onClick={() => handleTagSelect(t)}
        className={cx('btn-selection', {
          selected: !!selectedTags.find(pt => pt.id === t.id),
        })}
      >
        #{t.name}
      </Button>
    ))
  }

  const mapDevices = (device: Device[]) => {
    return device.map(d => ({ value: d.id, label: d.name }))
  }

  return (
    <BaseModal
      noPadding
      className='modal-filter-device-notifs'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          rounded
          bordered
          goBack
          onGoBack={onCancel}
          actionRight={
            <span
              className='text-primary text-500 hoverable'
              onClick={() => handleFilter(true)}
            >
              Reset Filter
            </span>
          }
        >
          Quick Filters
        </Layout.Header>
        <Layout.Body>
          <h3>Device</h3>
          <AsyncSelect
            size='large'
            labelInValue
            value={selectedDevice}
            allowClear={false}
            fetcher={handleGetDevices}
            handleSearch={handleGetDevices}
            handleLoadMore={handleGetDevices}
            mapOptions={mapDevices}
            onChange={(_, v: any) => setSelectedDevice(v)}
            placeholder='Choose devices to filter, type to search'
            getPopupContainer={getPopupContainer}
          />
          <div className='divider' />

          <h3>Duration</h3>
          <div className='date-picker-group'>
            <span className='text-500'>From</span>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={from}
              onChange={v => setFrom(v)}
              placeholder='From'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <hr className='divider' style={{ margin: '1rem 0' }} />
          <div className='date-picker-group'>
            <label className='text-500'>To</label>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={to}
              onChange={v => setTo(v)}
              placeholder='To'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <div className='divider' />

          <h3>Tags</h3>
          {loading && <Spinner />}
          {!loading && !!tags.length && (
            <div className='tag-list'>{renderTags()}</div>
          )}
          <Button
            block
            size='large'
            type='primary'
            onClick={() => handleFilter()}
          >
            Apply
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalFilterDeviceNotifs
