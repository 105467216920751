import { useEffect, useState } from 'react'

import { Layout, Spinner } from 'components/Shared'
import PlanInfoCard from 'components/Cards/PlanInfoCard'
import { notiError, notiSuccess } from 'utils/notification'
import { getScheduleDetails, Schedule } from 'services/schedules'
import { useAppSelector } from 'store'

import BaseModal from '../BaseModal'
import ScheduleForm from './ScheduleForm'
import { initValues, mapValuesToOptions, totalMsg } from './FormHelpers'

interface Props {
  deviceId: number
  scheduleId?: number
  onSuccess?: () => void
  onCancel: () => void
}

function ModalCreateUpdateSchedule(props: Props) {
  const { deviceId, scheduleId, onSuccess, onCancel } = props
  const { stats } = useAppSelector(state => state.stats)

  const [formValues, setFormValues] = useState(initValues)
  const [init, setInit] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    handleGetScheduleDetails()
    // eslint-disable-next-line
  }, [])

  const handleGetScheduleDetails = async () => {
    if (!scheduleId) return setInit(false)
    try {
      const response = await getScheduleDetails(scheduleId)
      const data = response.data.data as Schedule
      setFormValues({
        name: data.name,
        dateTime: {
          is247: !!data.is_24_7,
          selectedDays: data.days || [],
          startTime: data.start_time || '',
          endTime: data.end_time || '',
        },
        is_active: !!data.is_active,
        channels: data.channels || [],
        phone_recipients: mapValuesToOptions(data.phone_recipients),
        email_recipients: mapValuesToOptions(data.email_recipients),
        whatsapp_recipients: mapValuesToOptions(data.whatsapp_recipients),
        webhook: data.webhook || '',
        slack_webhook: data.slack_webhook || '',
        teams_webbhook: data.teams_webbhook || '',
      })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setInit(false)
    }
  }

  const handleSuccess = () => {
    setLoading(false)
    onSuccess && onSuccess()
    onCancel()
    notiSuccess({ message: `Schedule ${scheduleId ? 'updated' : 'created'}` })
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-create-update-schedule'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          goBack
          onGoBack={loading ? () => {} : onCancel}
          bordered
        >
          {scheduleId ? 'Update ' : 'Create '} Schedule
        </Layout.Header>
        {init && (
          <Layout.Body fullHeight>
            <Spinner />
          </Layout.Body>
        )}
        {!init && (
          <Layout.Body>
            <PlanInfoCard showUpgrade>
              {totalMsg(
                stats?.sms.total || 0,
                stats?.email.total || 0,
                stats?.whatsapp.total || 0,
              )}
            </PlanInfoCard>
            <ScheduleForm
              deviceId={deviceId}
              scheduleId={scheduleId}
              formValues={formValues}
              loading={loading}
              setLoading={setLoading}
              onSuccess={handleSuccess}
            />
          </Layout.Body>
        )}
      </Layout>
    </BaseModal>
  )
}

export default ModalCreateUpdateSchedule
