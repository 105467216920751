import { useState } from 'react'
import { uniqueId } from 'lodash'
import { Button, Form, Input, Select } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'

import './index.scss'
import { Layout } from 'components/Shared'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import AsyncSelect from 'components/AsyncSelect'
import { validateEmail } from 'utils/validation'
import { notiError, notiSuccess } from 'utils/notification'
import { getRecipients, Recipient } from 'services/recipients'
import { getUsers, User } from 'services/users'
import { shareVaultFile } from 'services/vault'

const rules = [{ required: true, message: 'This field is required' }]

interface Viewer {
  id: number | string
  email: string
  name: string
  type: 'user' | 'recipient' | 'email'
}

interface Props {
  fileId: number
  onCancel: () => void
}

function ModalShareFile({ fileId, onCancel }: Props) {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [viewers, setViewers] = useState<Viewer[]>([])
  const [expiryType, setExpiryType] = useState('hours')

  const getViewers = async ({ search = '', page = 1 } = {}) => {
    try {
      if (!search.includes('@')) {
        const recipientPromise = await getRecipients({
          search,
          currentPage: page,
        })
        const userPromise = await getUsers({
          search,
          currentPage: page,
        })
        const [recipients, users] = await Promise.all([
          recipientPromise,
          userPromise,
        ])

        const userViewers = mapViewers(users.data.data, 'user')
        const recipientViewers = mapViewers(recipients.data.data, 'recipient')
        const userPage = users.data.meta.last_page
        const recipientPage = recipients.data.meta.last_page

        return {
          data: [...userViewers, ...recipientViewers],
          totalPage: userPage > recipientPage ? userPage : recipientPage,
        }
      } else {
        const validateEmailResult = validateEmail(search)
        return {
          data: !validateEmailResult.success
            ? []
            : [
                {
                  id: uniqueId(),
                  label: search,
                  email: search,
                  name: search,
                  value: search,
                  type: 'email',
                },
              ],
          totalPage: 1,
        }
      }
    } catch {
      return { data: [], totalPage: 1 }
    }
  }

  const mapViewers = (
    arr: User[] | Recipient[],
    type: 'user' | 'recipient',
  ): Viewer[] => {
    return arr.map((a: User | Recipient) => ({
      id: a.id,
      label: a.name,
      email: a.email,
      name: a.name,
      value: uniqueId('viewer-'),
      type,
    }))
  }

  const handleViewersChange = (_: any, values: any) => {
    setViewers(values)
  }

  const handleExpiryTypeChange = (value: any) => {
    setExpiryType(value)
  }

  const handleSubmit = async ({ expiry_number, message }: any) => {
    if (isNaN(expiry_number) || +expiry_number < 1) {
      return form.setFields([
        {
          name: 'expiry_number',
          errors: ['Must be a number and greater than 0'],
        },
      ])
    }

    try {
      setLoading(true)
      await shareVaultFile(fileId, {
        expiry_number: +expiry_number,
        expiry_type: expiryType,
        message,
        viewers: viewers.map(v => ({
          email: v.email,
          user_id: v.type === 'user' ? +v.id : undefined,
          recipient_id: v.type === 'recipient' ? +v.id : undefined,
        })),
      })
      setLoading(false)
      notiSuccess({ message: 'Shared successfully' })
      onCancel()
    } catch (err: any) {
      const { message, errors } = err
      setLoading(false)
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-share-file'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          Share File
        </Layout.Header>
        <Layout.Body>
          <Form form={form} layout='vertical' onFinish={handleSubmit}>
            <Form.Item
              name='viewers'
              label='People you want to share'
              rules={rules}
            >
              <AsyncSelect
                size='large'
                mode='multiple'
                mapOptions={(v: Viewer[]) => v}
                fetcher={getViewers}
                handleSearch={getViewers}
                handleLoadMore={getViewers}
                placeholder='Add people by email'
                onChange={handleViewersChange}
                getPopupContainer={getPopupContainer}
              />
            </Form.Item>
            <Form.Item
              label='Expiration Time'
              name='expiry_number'
              rules={rules}
              extra={
                <span>
                  <InfoCircleFilled />
                  People won’t be able to access to this link after it expired
                </span>
              }
            >
              <Input
                className='expiry-time'
                size='large'
                placeholder='Please enter expiration time'
                addonAfter={
                  <Select
                    value={expiryType}
                    onChange={handleExpiryTypeChange}
                    getPopupContainer={getPopupContainer}
                  >
                    <Select.Option value='hours'>hour</Select.Option>
                    <Select.Option value='minutes'>minute</Select.Option>
                    <Select.Option value='days'>day</Select.Option>
                  </Select>
                }
              />
            </Form.Item>
            <Form.Item label='Message' name='message'>
              <Input.TextArea
                rows={6}
                placeholder='Include an optional message with your shared video'
              />
            </Form.Item>
            <Button
              size='large'
              block
              type='primary'
              htmlType='submit'
              disabled={loading}
            >
              Share
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalShareFile
