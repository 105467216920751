import React, { useEffect, useState } from 'react'
import { Button } from 'antd'

import { MobilePhone } from 'components/Icons'
import { Spinner } from 'components/Shared'
import {
  deleteMobileDeviceToken,
  getLoggedInMobileDevices,
  LoggedInMobileDevice,
} from 'services/2fa'

function MobileTokens() {
  const [loading, setLoading] = useState(true)
  const [devices, setDevices] = useState<LoggedInMobileDevice[]>([])
  const [showMore, setShowMore] = useState(false)

  useEffect(() => {
    handleGetLoggedInMobileDevices()
    // eslint-disable-next-line
  }, [])

  const handleGetLoggedInMobileDevices = async () => {
    try {
      setLoading(true)
      const response = await getLoggedInMobileDevices()
      const data = response.data as LoggedInMobileDevice[]
      setDevices(data)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const renderDevices = () => {
    return devices
      .slice(0, showMore ? devices.length : 5)
      .map((d, idx, self) => (
        <React.Fragment key={d.id}>
          <div className='mobile-token'>
            <MobilePhone color='#bdbdbd' />
            <div className='token-info'>
              <div className='text-500'>{d.name}</div>
              <div className='text-placeholder'>
                Last used at {d.last_used || 'unknown'}
              </div>
            </div>
            <Button
              danger
              type='link'
              className='delete-token'
              onClick={() => handleDeleteToken(d.id)}
            >
              Delete
            </Button>
          </div>
          {idx + 1 < self.length && (
            <hr className='divider' style={{ margin: '12px 0' }} />
          )}
        </React.Fragment>
      ))
  }

  const handleDeleteToken = (id: number) => {
    deleteMobileDeviceToken(id)
    setDevices(prev => prev.filter(d => d.id !== id))
  }

  const handleToggleShowMore = () => {
    setShowMore(prev => !prev)
  }

  const disabledButton = loading || !devices.length

  return (
    <div
      className='row mobile-tokens'
      style={{ paddingTop: 8, paddingBottom: 16 }}
    >
      <h3>Manage Logged In Mobile Devices</h3>
      <div className='browser-sessions-actions'>
        <Button
          size='large'
          disabled={disabledButton}
          className='ant-btn-secondary'
          onClick={disabledButton ? undefined : handleToggleShowMore}
        >
          Show {showMore ? 'Less' : 'All'}
        </Button>
      </div>
      {loading && !devices.length && <Spinner />}
      {!!devices.length && (
        <div className='mobile-tokens-list'>{renderDevices()}</div>
      )}
    </div>
  )
}

export default MobileTokens
