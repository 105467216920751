import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function PlayFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='12'
        height='14'
        viewBox='0 0 12 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M11 7.86602C11.6667 7.48112 11.6667 6.51888 11 6.13397L2 0.937821C1.33333 0.552921 0.5 1.03405 0.5 1.80385V12.1962C0.5 12.966 1.33333 13.4471 2 13.0622L11 7.86602Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
