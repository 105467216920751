import React, { useEffect } from 'react'
import moment from 'moment'
import cx from 'classnames'
import {
  LoadingOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, Tooltip } from 'antd'

import Table from 'components/Table'
import { DeviceInfo } from 'components/Shared'
import { MM_DD_YYYY_HH_MM_P } from 'utils/dateTime'
import { downloadFile, upperFirstLetter } from 'utils/functions'
import { notiError, notiSuccess } from 'utils/notification'
import { VideoRecord, getVideoRecords } from 'services/recordingDownloads'
import { addRecordToVault } from 'services/vault'

import { useVideoSearchContext } from './VideoSearchContext'

function TableList() {
  const { loading, videos, currentPage, dispatch } = useVideoSearchContext()

  useEffect(() => {
    handleGetVideoRecords()
    // eslint-disable-next-line
  }, [currentPage])

  const handleGetVideoRecords = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const response = await getVideoRecords({
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
        currentPage,
      })
      dispatch({ type: 'SET_VIDEOS', payload: response.data })
    } catch {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleAddToVault = async ({ id }: VideoRecord) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await addRecordToVault(id)
      await handleGetVideoRecords()
      notiSuccess({ message: 'Record added to My Vault' })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleDownload = ({ file_urls }: VideoRecord) => {
    if (!!file_urls && !!file_urls.length) {
      downloadFile(file_urls[0])
    }
  }

  const renderRowActions = (v: VideoRecord) => {
    return (
      v.status === 'ready' && (
        <React.Fragment>
          <Button
            className='ant-btn-ternary'
            onClick={() => handleAddToVault(v)}
          >
            Add to My Vault
          </Button>
          <Button className='ant-btn-ternary' onClick={() => handleDownload(v)}>
            Download
          </Button>
        </React.Fragment>
      )
    )
  }

  return (
    <Table loading={loading} empty={!videos.length}>
      {videos.map((v: VideoRecord) => (
        <Table.Row key={v.id} rowKey={v.id} rowActions={renderRowActions(v)}>
          <Table.Col>
            <div className={cx('record-date', 'text-500', v.status)}>
              {moment(v.start_timestamp).format(MM_DD_YYYY_HH_MM_P)} |{' '}
              {moment(v.end_timestamp).format(MM_DD_YYYY_HH_MM_P)}
              <Tooltip
                trigger={['hover', 'click']}
                title={!!v.status ? upperFirstLetter(v.status) : 'Exporting'}
              >
                {v.status === 'ready' ? (
                  <CheckCircleOutlined className='text-success' />
                ) : v.status === 'failed' ? (
                  <ExclamationCircleOutlined className='text-danger' />
                ) : (
                  <LoadingOutlined className='text-body' />
                )}
              </Tooltip>
            </div>
            <div className='device-info'>
              <span>Device</span>
              {v.device ? (
                <DeviceInfo
                  status='default'
                  device={v.device}
                  to={`/devices/${v.device.id}?tab=device-details`}
                />
              ) : (
                <span>---</span>
              )}
            </div>
            <div className='expired-date'>
              <span>Expired date</span>
              <span>{moment(v.expiry).format(MM_DD_YYYY_HH_MM_P)}</span>
            </div>
            <div className='file-size'>
              <span>File size</span>
              <span>{`${(v.files || [])[0]?.size || 0} MB`}</span>
            </div>
          </Table.Col>
        </Table.Row>
      ))}
    </Table>
  )
}

export default TableList
