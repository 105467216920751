import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { Button, Switch } from 'antd'
import { ClockCircleFilled, DeleteFilled, StopFilled } from '@ant-design/icons'

import { PlayFilled } from 'components/Icons'
import { Thumbnail, DeviceInfo } from 'components/Shared'
import Table from 'components/Table'
import ModalUpdateDevice from 'components/Modal/Device/UpdateDevice'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalLivestream from 'components/Modal/Player/Livestream'
import ModalDeviceWizard from 'components/Modal/Device/DeviceWizard'
import { notiError } from 'utils/notification'
import { groupDevicesByLocation } from 'utils/device'
import {
  Device,
  getDevices,
  toggleDeviceRecording,
  updateDeviceLicense,
  deleteDevice as deleteDeviceService
} from 'services/devices'
import {
  updateCamlicenseCount,
  updateDeviceCount
} from 'store/slice/statsSlice'
import { useAppSelector, useAppDispatch } from 'store'

import { useDeviceContext } from './DeviceListContext'

const LIVE_UPDATE_INTERVAL = 15 * 60 * 1000

interface Props {
  showAdd: boolean
  setShowAdd: (v: boolean) => void
}

function TableList({ showAdd, setShowAdd }: Props) {
  const {
    loading,
    devices,
    currentPage,
    search,
    liveUpdate,
    deviceType,
    locationId,
    dispatch
  } = useDeviceContext()

  const [grouppedDevices, setGrouppedDevices] = useState<any>({})
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])
  const [editDevice, setEditDevice] = useState<Device | null>(null)
  const [deleteDevice, setDeleteDevice] = useState<Device | null>(null)
  const [viewLivestream, setViewLivestream] = useState<number | null>(null)

  const { stats } = useAppSelector(state => state.stats)
  const appDispatch = useAppDispatch()
  const liveUpdateRef = useRef<number>()

  useEffect(() => {
    handleGetDevices()
    // eslint-disable-next-line
  }, [search, currentPage, deviceType, locationId])

  useEffect(() => {
    if (!liveUpdate) {
      return window.clearInterval(liveUpdateRef.current)
    }
    liveUpdateRef.current = window.setInterval(
      handleGetDevices,
      LIVE_UPDATE_INTERVAL
    )
    return () => {
      window.clearInterval(liveUpdateRef.current)
    }
    // eslint-disable-next-line
  }, [search, currentPage, deviceType, locationId, liveUpdate])

  const handleGetDevices = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    try {
      const response = await getDevices({
        search,
        currentPage,
        deviceType,
        location: locationId
      })
      dispatch({
        type: 'SET_DEVICES',
        payload: response.data
      })
      const groupped = groupDevicesByLocation(response.data.data)
      setGrouppedDevices(groupped)
      setExpandedKeys(Object.keys(groupped))
    } catch {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleToggleRecording = async (id: number, isRecord: number) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await toggleDeviceRecording(id, isRecord)
      await handleGetDevices()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleUpdateLicense = async (id: number, isLicensed: number) => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await updateDeviceLicense(id, isLicensed)
      appDispatch(updateCamlicenseCount(!isLicensed ? -1 : 1))
      await handleGetDevices()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRecording = ({ id, enable_recording, is_licensed }: Device) => {
    if (!is_licensed) {
      return (
        <span className="text-bold text-placeholder">
          <ClockCircleFilled /> OFF
        </span>
      )
    }
    return (
      <Switch
        checkedChildren="ON"
        unCheckedChildren="OFF"
        checked={!!enable_recording}
        onChange={v => handleToggleRecording(id, +v)}
      />
    )
  }

  const renderLiveStream = (device: Device) => {
    if (!!device.is_licensed && !!device.deviceLiveStream?.is_streaming) {
      return (
        <Button
          className="ant-btn-secondary"
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => setViewLivestream(device.id)}
        >
          <PlayFilled />
          Play
        </Button>
      )
    }
    if (
      !device.is_licensed &&
      (stats?.camera_licenses.used || 0) >= (stats?.camera_licenses.total || 0)
    ) {
      return (
        <Link
          to="/settings/payment-settings/plans"
          className="text-500 text-primary hoverable"
        >
          Buy license
        </Link>
      )
    }
    if (!device.is_licensed) {
      return (
        <span
          className="text-500 text-primary hoverable"
          onClick={() => handleUpdateLicense(device.id, 1)}
        >
          Assign license
        </span>
      )
    }
    return (
      <span className="text-bold text-placeholder">
        <StopFilled className="text-placeholder" /> OFF
      </span>
    )
  }

  const handleDeleteDevice = async () => {
    if (!deleteDevice) return
    dispatch({ type: 'SET_LOADING', payload: true })

    try {
      if (!!deleteDevice.is_licensed) {
        await updateDeviceLicense(deleteDevice.id, 0)
        appDispatch(updateCamlicenseCount(-1))
      }

      await deleteDeviceService(deleteDevice.id)
      appDispatch(updateDeviceCount(-1))

      if (devices.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetDevices()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = (device: Device) => (
    <React.Fragment>
      <Button className="ant-btn-ternary" onClick={() => setEditDevice(device)}>
        Edit
      </Button>
      <Button
        className={cx('ant-btn-ternary', {
          'text-danger': device.is_licensed
        })}
        onClick={() =>
          handleUpdateLicense(device.id, device.is_licensed ? 0 : 1)
        }
      >
        {device.is_licensed ? 'Remove' : 'Assign'} License
      </Button>
      <Button
        className="ant-btn-ternary text-danger"
        onClick={() => setDeleteDevice(device)}
      >
        Delete Device
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        loading={loading}
        empty={!Object.keys(grouppedDevices).length}
        className="table-device"
        expandable={{
          expandedKeys: expandedKeys,
          onExpand: newKeys => setExpandedKeys(newKeys as string[])
        }}
      >
        {Object.keys(grouppedDevices).map(k => {
          const { locationName, devices } = grouppedDevices[k]
          return (
            <Table.RowGroup key={locationName} groupKey={locationName}>
              <Table.RowHead>
                {locationName} ({devices.length})
              </Table.RowHead>
              {devices.map((d: Device) => (
                <Table.Row
                  key={d.id}
                  rowKey={d.id}
                  rowActions={renderRowActions(d)}
                >
                  <Table.Col width="5rem" className="col-device-thumbnail">
                    <Thumbnail rounded src={d.thumbnail_url || undefined} />
                  </Table.Col>
                  <Table.Col className="col-device-info">
                    <DeviceInfo
                      showLicense
                      device={d}
                      to={`/devices/${d.id}?tab=device-details`}
                    />
                    <div>
                      <div>Recording</div>
                      <div>{renderRecording(d)}</div>
                    </div>
                    <div>
                      <div>Livestream</div>
                      <div>{renderLiveStream(d)}</div>
                    </div>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.RowGroup>
          )
        })}
      </Table>
      <AnimatePresence>
        {showAdd && (
          <ModalDeviceWizard
            onCancel={() => setShowAdd(false)}
            onSuccess={(usedLicense: boolean) => {
              usedLicense && appDispatch(updateCamlicenseCount(1))
              handleGetDevices()
            }}
          />
        )}
        {editDevice && (
          <ModalUpdateDevice
            device={editDevice}
            onCancel={() => setEditDevice(null)}
            onSuccess={(usedLicense: boolean) => {
              usedLicense && appDispatch(updateCamlicenseCount(1))
              handleGetDevices()
            }}
          />
        )}
        {deleteDevice && (
          <ModalConfirm
            icon={<DeleteFilled className="text-danger" />}
            title="Are you sure you want to delete this device?"
            description="Action can’t be undone"
            onConfirm={handleDeleteDevice}
            onCancel={() => setDeleteDevice(null)}
          />
        )}
        {viewLivestream && (
          <ModalLivestream
            deviceId={viewLivestream}
            onCancel={() => setViewLivestream(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
