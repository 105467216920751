import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function VoucherFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M11.5 10.5C11.7761 10.5 12 10.2761 12 10C12 9.72386 11.7761 9.5 11.5 9.5C11.2239 9.5 11 9.72386 11 10C11 10.2761 11.2239 10.5 11.5 10.5Z'
          fill='url(#paint0_linear)'
        />
        <path
          d='M8.5 6.5C8.77614 6.5 9 6.27614 9 6C9 5.72386 8.77614 5.5 8.5 5.5C8.22386 5.5 8 5.72386 8 6C8 6.27614 8.22386 6.5 8.5 6.5Z'
          fill='url(#paint1_linear)'
        />
        <path
          d='M15 2.5H5.5C5.367 2.5 5.24 2.553 5.146 2.646L4.5 3.293L3.854 2.646C3.76 2.553 3.633 2.5 3.5 2.5H1C0.449 2.5 0 2.949 0 3.5V12.5C0 13.052 0.449 13.5 1 13.5H3.5C3.633 13.5 3.76 13.447 3.854 13.354L4.5 12.707L5.146 13.353C5.24 13.447 5.367 13.5 5.5 13.5H15C15.552 13.5 16 13.052 16 12.5V3.5C16 2.949 15.552 2.5 15 2.5ZM5 11.5H4V10.5H5V11.5ZM5 9.5H4V8.5H5V9.5ZM5 7.5H4V6.5H5V7.5ZM5 5.5H4V4.5H5V5.5ZM8.5 4.5C9.327 4.5 10 5.173 10 6C10 6.827 9.327 7.5 8.5 7.5C7.673 7.5 7 6.827 7 6C7 5.173 7.673 4.5 8.5 4.5ZM7.5 11.5C7.387 11.5 7.274 11.462 7.18 11.384C6.968 11.207 6.939 10.892 7.116 10.68L12.116 4.68C12.294 4.467 12.609 4.441 12.82 4.616C13.032 4.793 13.06 5.108 12.883 5.321L7.883 11.321C7.785 11.438 7.643 11.5 7.5 11.5ZM11.5 11.5C10.673 11.5 10 10.827 10 10C10 9.173 10.673 8.5 11.5 8.5C12.327 8.5 13 9.173 13 10C13 10.827 12.327 11.5 11.5 11.5Z'
          fill='url(#paint2_linear)'
        />
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='11'
            y1='9.5'
            x2='11'
            y2='10.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='paint1_linear'
            x1='8'
            y1='5.5'
            x2='8'
            y2='6.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='paint2_linear'
            x1='0'
            y1='2.5'
            x2='0'
            y2='13.5'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
