import { WarningFilled } from 'components/Icons'

interface Props {
  action?: React.ReactNode
}

const WarningCard: React.FC<Props> = ({ action, children }) => {
  if (!children) {
    return null
  }
  return (
    <div className='warning-card-wrapper'>
      <div className='card-content'>
        <div className='card-icon'>
          <WarningFilled type='warning' />
        </div>
        <div className='card-message'>{children}</div>
      </div>
      {action && <div className='card-action'>{action}</div>}
    </div>
  )
}

export default WarningCard
