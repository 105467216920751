import { useEffect } from 'react'

import './index.scss'
import { Layout, Spinner } from 'components/Shared'
import { PLAN_TYPE } from 'utils/plan'
import { getPlans } from 'store/slice/plansSlice'
import { useAppDispatch, useAppSelector } from 'store'

import Plans from './Plans'
import Features from './Features'

function PlanList() {
  const dispatch = useAppDispatch()
  const { loading, plans } = useAppSelector(state => state.plans)

  useEffect(() => {
    dispatch(getPlans())
    // eslint-disable-next-line
  }, [])

  if (loading || !plans.length) {
    return <Spinner height='90vh' />
  }

  const free = plans.find(p => p.name === PLAN_TYPE.FREE)
  const basic = plans.find(p => p.name === PLAN_TYPE.BASIC)
  const plus = plans.find(p => p.name === PLAN_TYPE.PLUS)
  const pro = plans.find(p => p.name === PLAN_TYPE.PRO)
  const enterprise = plans.find(p => p.name === PLAN_TYPE.ENTERPRISE)

  if (!free || !basic || !plus || !pro || !enterprise) {
    return null
  }

  return (
    <Layout className='plan-list-page'>
      <Layout.Header goBack bordered>
        Update Subcription Plan
      </Layout.Header>
      <Layout.Body>
        <div className='plan-list-wrapper'>
          <Plans free={free} basic={basic} plus={plus} pro={pro} />
          <div className='divider' style={{ margin: '2.5rem 0' }} />
          <Features
            free={free}
            basic={basic}
            plus={plus}
            pro={pro}
            enterprise={enterprise}
          />
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default PlanList
