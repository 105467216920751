import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import { VideoCameraFilled, WifiOffline, WifiOnline } from 'components/Icons'
import StatisticsCard from 'components/Cards/StatisticsCard'
import ModalManageDevices from 'components/Modal/Device/ManageDevices'
import { getDeviceStatus } from 'utils/device'
import { notiError } from 'utils/notification'
import { Device, getDevices } from 'services/devices'

interface Props {
  stats: React.ReactNode
}

function ManageDevices({ stats }: Props) {
  const [loading, setLoading] = useState(true)
  const [onlineDevices, setOnlineDevices] = useState<Device[]>([])
  const [offlineDevices, setOfflineDevices] = useState<Device[]>([])
  const [viewDeviceType, setViewDeviceType] = useState<string | null>(null)

  useEffect(() => {
    handleGetDevices()
  }, [])

  const handleGetDevices = async () => {
    try {
      const online: Device[] = []
      const offline: Device[] = []
      const response = await getDevices({ pageSize: 1000 })

      ;(response.data.data as Device[]).forEach(device => {
        ;(getDeviceStatus(device) === 'online' ? online : offline).push(device)
      })

      setOnlineDevices(online)
      setOfflineDevices(offline)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <StatisticsCard
        icon={<VideoCameraFilled type='primary' />}
        title='Devices'
        content={stats}
        action={{
          content: <Link to='/devices?tab=device-list'>Manage</Link>,
        }}
        extra={
          <div className='device-statuses'>
            <hr className='divider' style={{ margin: '.75rem 0' }} />
            <div className='device-status'>
              <WifiOnline type='success' />
              <span className='text-placeholder'>Devices online</span>
              <span className='text-500'>
                {loading ? '---' : onlineDevices.length}
              </span>
              {!!onlineDevices.length && (
                <span
                  className='text-primary text-500 hoverable'
                  onClick={() => setViewDeviceType('online')}
                >
                  View
                </span>
              )}
            </div>
            <hr className='divider' style={{ margin: '.75rem 0' }} />
            <div className='device-status text-danger'>
              <WifiOffline type='danger' />
              <span className='text-placeholder'>Devices offline</span>
              <span className='text-500'>
                {loading ? '---' : offlineDevices.length}
              </span>
              {!!offlineDevices.length && (
                <span
                  className='text-primary text-500 hoverable'
                  onClick={() => setViewDeviceType('offline')}
                >
                  View
                </span>
              )}
            </div>
          </div>
        }
      />
      <AnimatePresence>
        {!!viewDeviceType && (
          <ModalManageDevices
            type={viewDeviceType}
            devices={
              viewDeviceType === 'online' ? onlineDevices : offlineDevices
            }
            onCancel={() => setViewDeviceType(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default ManageDevices
