import { useState, useEffect } from 'react'

import './index.scss'
import MicrosoftTeam from 'assets/brand-logos/microsoft-team.svg'
import Slack from 'assets/brand-logos/slack.svg'
import Webhook from 'assets/brand-logos/webhook.svg'
import WhatsApp from 'assets/brand-logos/whatsapp.svg'

import { EmailFilled, SmsFilled } from 'components/Icons'
import {
  Layout,
  DateTimeSelection,
  ChannelSelection,
  Spinner,
} from 'components/Shared'
import { notiError } from 'utils/notification'
import { formatPhoneNumber } from 'utils/functions'
import { getScheduleDetails, Schedule } from 'services/schedules'

import BaseModal from '../BaseModal'

interface Props {
  scheduleId: number
  onCancel: () => void
}

function ViewSchedule({ scheduleId, onCancel }: Props) {
  const [loading, setLoading] = useState(true)
  const [schedule, setSchedule] = useState<Schedule | null>(null)

  useEffect(() => {
    handleGetScheduleDetails()
    // eslint-disable-next-line
  }, [scheduleId])

  const handleGetScheduleDetails = async () => {
    try {
      setLoading(true)
      const response = await getScheduleDetails(scheduleId)
      setSchedule(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-view-schedule'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header sticky bordered goBack onGoBack={onCancel}>
          Notification Schedule Detail
        </Layout.Header>
        {loading && (
          <Layout.Body fullHeight>
            <Spinner />
          </Layout.Body>
        )}
        {!loading && !!schedule && (
          <Layout.Body>
            <h3>Notification schedule detail</h3>
            <div className='detail-group'>
              <h4 className='group-title'>Schedule Name</h4>
              <span>{schedule.name}</span>
            </div>
            <div className='detail-group'>
              <h4 className='group-title'>Schedule Date & Time</h4>
              <DateTimeSelection
                readOnly
                is247={!!schedule.is_24_7}
                selectedDays={schedule.days || undefined}
                startTime={schedule.start_time || undefined}
                endTime={schedule.end_time || undefined}
              />
            </div>
            <div className='detail-group'>
              <h4 className='group-title'>Channel</h4>
              <ChannelSelection
                readOnly
                popupContainer={() => document.querySelector('.m-modal-inner')!}
                selectedChannels={schedule.channels || undefined}
              />
            </div>
            <div className='divider' />
            <h3 style={{ marginTop: '2rem' }}>Recipients</h3>
            {schedule.channels?.includes('phone') && (
              <div className='recipient-detail'>
                <h4>
                  <SmsFilled type='primary' />
                  <span>SMS Recipient</span>
                </h4>
                <div className='recipients'>
                  {schedule.phone_recipients?.map(r => (
                    <span key={r.id}>{formatPhoneNumber(r.phone)}</span>
                  ))}
                </div>
              </div>
            )}
            {schedule.channels?.includes('whatsapp') && (
              <div className='recipient-detail'>
                <h4>
                  <img src={WhatsApp} alt='WhatsApp' />
                  <span>WhatsApp Recipient</span>
                </h4>
                <div className='recipients'>
                  {schedule.whatsapp_recipients?.map(r => (
                    <span key={r.id}>{formatPhoneNumber(r.phone)}</span>
                  ))}
                </div>
              </div>
            )}
            {schedule.channels?.includes('email') && (
              <div className='recipient-detail'>
                <h4>
                  <EmailFilled type='primary' />
                  <span>Email Recipient</span>
                </h4>
                <div className='recipients'>
                  {schedule.email_recipients?.map(r => (
                    <span key={r.id}>{r.email}</span>
                  ))}
                </div>
              </div>
            )}
            {schedule.channels?.includes('slack') && (
              <div className='recipient-detail'>
                <h4>
                  <img src={Slack} alt='Slack' />
                  <span>Slack Webhook</span>
                </h4>
                <div className='recipients'>
                  <span>{schedule.slack_webhook}</span>
                </div>
              </div>
            )}
            {schedule.channels?.includes('webhook') && (
              <div className='recipient-detail'>
                <h4>
                  <img src={Webhook} alt='Webhook' />
                  <span>Webhook</span>
                </h4>
                <div className='recipients'>
                  <span>{schedule.webhook}</span>
                </div>
              </div>
            )}
            {schedule.channels?.includes('microsoft_teams') && (
              <div className='recipient-detail'>
                <h4>
                  <img src={MicrosoftTeam} alt='MicrosoftTeam' />
                  <span>Microsoft Team Webhook</span>
                </h4>
                <div className='recipients'>
                  <span>{schedule.teams_webbhook}</span>
                </div>
              </div>
            )}
          </Layout.Body>
        )}
      </Layout>
    </BaseModal>
  )
}

export default ViewSchedule
