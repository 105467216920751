import { useState } from 'react'
import cx from 'classnames'

import './index.scss'
import { Layout } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { Button, Input } from 'antd'

type Tab = {
  key: number
  name: string
  layout: number
}

interface Props {
  tab: Tab
  onChange: (updatedTab: Tab) => void
  onCancel: () => void
}

function ModalEditTabName({ tab, onChange, onCancel }: Props) {
  const [name, setName] = useState(tab.name)

  const handleSave = () => {
    onChange({ ...tab, name })
    onCancel()
  }

  const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value
    setName(prev => (value.length > 24 ? prev : value))
  }

  const nameCount = 24 - name.length
  const buttonDisabled = !name || name.length < 4

  return (
    <BaseModal noPadding className='modal-edit-tab-name' onCancel={onCancel}>
      <Layout>
        <Layout.Header
          bordered
          rounded
          actionRight={
            <span className='text-primary text-500' onClick={onCancel}>
              Cancel
            </span>
          }
        >
          Edit Page Name
        </Layout.Header>
        <Layout.Body>
          <Input
            size='large'
            value={name}
            placeholder='Name the page'
            onChange={handleNameChange}
          />
          <div
            className={cx(
              'name-count',
              `text-${!nameCount ? 'danger' : 'placeholder'}`,
            )}
          >
            {nameCount}
          </div>
          <Button
            block
            size='large'
            type='primary'
            disabled={buttonDisabled}
            onClick={buttonDisabled ? undefined : handleSave}
          >
            Save
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalEditTabName
