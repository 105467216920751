import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Burger({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <rect x='1' y='4' width='24' height='2.2' rx='1.1' fill={c} />
        <rect x='1' y='10.6001' width='24' height='2.2' rx='1.1' fill={c} />
        <rect x='1' y='17.2002' width='24' height='2.2' rx='1.1' fill={c} />
      </svg>
    </SVGUniqueID>
  )
}
