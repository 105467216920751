import { useEffect, useReducer } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'

import './index.scss'
import { TicketFilled } from 'components/Icons'
import { Layout } from 'components/Shared'
import Table from 'components/Table'
import Pagination from 'components/Pagination'
import { notiError } from 'utils/notification'
import { MM_DD_YYYY_HH_MM_P } from 'utils/dateTime'
import { getTransactionHistory, Transaction } from 'services/transactions'

interface State {
  loading: boolean
  transactions: Transaction[]
  currentPage: number
  totalPage: number
}

const initState = {
  loading: false,
  transactions: [],
  currentPage: 1,
  totalPage: 1,
}

function TransactionHistory() {
  const [state, setState] = useReducer(
    (s: State, a: Partial<State>) => ({ ...s, ...a }),
    initState,
  )

  const { loading, transactions, currentPage, totalPage } = state

  useEffect(() => {
    handleGetTransactionHistory()
    // eslint-disable-next-line
  }, [currentPage])

  const handleGetTransactionHistory = async () => {
    try {
      setState({ loading: true })
      const { data } = await getTransactionHistory({
        pageSize: 10,
        sort: 'id',
        sortDirection: 'desc',
        currentPage,
      })
      setState({
        transactions: data.data,
        totalPage: data.meta.last_page,
        loading: false,
      })
    } catch (err: any) {
      setState({ loading: false })
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  const renderRowActions = ({ receipt_url }: Transaction) => (
    <Link
      target='_blank'
      to={{ pathname: receipt_url }}
      className='ant-btn ant-btn-ternary'
    >
      View Receipt Details
    </Link>
  )

  return (
    <Layout className='transaction-history'>
      <Layout.Header bordered goBack>
        Transaction History
      </Layout.Header>
      <Layout.Body>
        <div className='table-list-header sticky'>
          <Pagination
            disabled={loading}
            current={currentPage}
            total={totalPage}
            onPageChange={newPage => setState({ currentPage: newPage })}
          />
        </div>
        <Table loading={loading} empty={!transactions.length}>
          {transactions.map(t => (
            <Table.Row
              key={t.id}
              rowKey={t.id}
              rowActions={renderRowActions(t)}
            >
              <Table.Col>
                <div className='transaction-id text-500'>
                  <TicketFilled /> {t.transaction_id}
                </div>
                <div className='date-paid'>
                  <span>Date Paid</span>
                  <span>{moment(t.date).format(MM_DD_YYYY_HH_MM_P)}</span>
                </div>
                <div className='amount-paid'>
                  <span>Amount Paid</span>
                  <span>${t.amount}</span>
                </div>
                <div className='card-number'>
                  <span>Card Number</span>
                  <span>{t.card_last_four}</span>
                </div>
              </Table.Col>
            </Table.Row>
          ))}
        </Table>
      </Layout.Body>
    </Layout>
  )
}

export default TransactionHistory
