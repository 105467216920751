import { useState } from 'react'
import cx from 'classnames'
import { PlusOutlined } from '@ant-design/icons'

import { Layout } from 'components/Shared'
import TableListHeader from './TableListHeader'
import TableList from './TableList'
import { useTagsContext } from './TagContext'

function NotificationTags() {
  const { loading } = useTagsContext()
  const [addTag, setAddTag] = useState(false)

  return (
    <Layout className='notification-tags'>
      <Layout.Header
        goBack
        bordered
        actionRight={
          <PlusOutlined
            disabled={loading}
            className={cx({ hoverable: !loading })}
            onClick={!loading ? () => setAddTag(true) : undefined}
          />
        }
      >
        Notification Tags
      </Layout.Header>
      <Layout.Body>
        <TableListHeader />
        <TableList addTag={addTag} setAddTag={setAddTag} />
      </Layout.Body>
    </Layout>
  )
}

export default NotificationTags
