import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Close({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.07054 0.707107C1.46107 0.316582 2.09423 0.316582 2.48476 0.707107L15.2926 13.515C15.6831 13.9055 15.6831 14.5387 15.2926 14.9292C14.9021 15.3197 14.2689 15.3197 13.8784 14.9292L1.07054 2.12132C0.680019 1.7308 0.680018 1.09763 1.07054 0.707107Z'
          fill={c}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.707107 14.9292C0.316582 14.5387 0.316582 13.9055 0.707107 13.515L13.515 0.707101C13.9055 0.316577 14.5387 0.316577 14.9292 0.707101C15.3197 1.09763 15.3197 1.73079 14.9292 2.12131L2.12132 14.9292C1.7308 15.3197 1.09763 15.3197 0.707107 14.9292Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
