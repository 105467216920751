import { useEffect, useRef, useState } from 'react'
import moment, { Moment } from 'moment'
import { Button, DatePicker, TimePicker } from 'antd'

import './index.scss'
import { InfoCircle } from 'components/Icons'
import { Layout } from 'components/Shared'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import { getFirstAndLastDateInMonth, MM_DD_YYYY, YYYY_MM_DD } from 'utils/dateTime'
import { notiError } from 'utils/notification'
import { validateTimeRange } from 'utils/validation'
import { getRecordsCalender } from 'services/recordingPlayback'

interface Props {
  deviceIds: number[]
  dateRange: Moment[]
  onChange: (dateRange: [Moment, Moment]) => void
  onCancel: () => void
}

function ModalSelectDuration({ dateRange, onChange, onCancel, deviceIds }: Props) {
  const [fromDate, setFromDate] = useState<Moment | null>(dateRange[0])
  const [fromTime, setFromTime] = useState<Moment | null>(dateRange[0])
  const [toDate, setToDate] = useState<Moment | null>(dateRange[1])
  const [toTime, setToTime] = useState<Moment | null>(dateRange[1])
  const [records, setRecords] = useState<{ [key: string]: number }>({})

  const mountedRef = useRef(true)

  const mergeDateTime = (date: Moment, time: Moment) => {
    return moment(date).hours(time.hours()).minutes(time.minutes()).seconds(0)
  }

  const handleSelect = () => {
    if (!fromDate || !fromTime || !toDate || !toTime) {
      return notiError({
        message: 'Invalid duration',
        description: 'Please select duration',
      })
    }

    const newFromDate = mergeDateTime(fromDate, fromTime)
    const newToDate = mergeDateTime(toDate, toTime)
    const validateResult = validateTimeRange(newFromDate, newToDate)

    if (!validateResult.success) {
      return notiError({
        message: 'Invalid duration',
        description: validateResult.message,
      })
    }

    onChange([newFromDate, newToDate])
    setTimeout(onCancel, 10)
  }

  const handleGetRecordsCalendar = async (date?: moment.Moment) => {
    const { firstDate, lastDate } = getFirstAndLastDateInMonth(date, YYYY_MM_DD)
    if ((firstDate as string) in records) {
      return
    }
    try {
      const response = await getRecordsCalender({
        start_date: firstDate as string,
        end_date: lastDate as string,
        device_ids: deviceIds,
      })
      if (mountedRef.current) {
        setRecords(prev => ({ ...prev, ...response.data.data }))
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handlePanelChange = (
    values: moment.Moment,
  ) => {
    if (!values || deviceIds.length === 0) {
      return
    }
    return handleGetRecordsCalendar(values)
  }
  useEffect(() => {
    if (!!deviceIds.length) {
      handleGetRecordsCalendar()
    }
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line
  }, [deviceIds])

  return (
    <BaseModal className='modal-select-duration' noPadding onCancel={onCancel}>
      <Layout>
        <Layout.Header rounded bordered>
          Change Duration
        </Layout.Header>
        <Layout.Body>
          <div className='maximum-duration-note text-success'>
            <InfoCircle type='success' />
            <span>Maximum duration is 4 hours</span>
          </div>
          <div className='date-group'>
            <label className='text-500'>From</label>
            <DatePicker
              size='large'
              inputReadOnly
              showToday={false}
              value={fromDate}
              format={MM_DD_YYYY}
              className='date-picker'
              onChange={v => setFromDate(v)}
              getPopupContainer={getPopupContainer}
              onPanelChange={handlePanelChange}
              dateRender={d => {
                return (
                  <div className='ant-picker-cell-inner'>
                    {!!records[d.format(YYYY_MM_DD)] && (
                      <span className='contains-record' />
                    )}
                    {d.format('D')}
                  </div>
                )
              }}
            />
            <TimePicker
              size='large'
              inputReadOnly
              format='HH:mm'
              value={fromTime}
              showSecond={false}
              className='time-picker'
              onChange={v => setFromTime(v)}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <hr className='divider' style={{ margin: '1rem 0' }} />
          <div className='date-group'>
            <label className='text-500'>To</label>
            <DatePicker
              size='large'
              inputReadOnly
              showToday={false}
              value={toDate}
              format={MM_DD_YYYY}
              className='date-picker'
              onChange={v => setToDate(v)}
              getPopupContainer={getPopupContainer}
              onPanelChange={handlePanelChange}
              dateRender={d => {
                return (
                  <div className='ant-picker-cell-inner'>
                    {!!records[d.format(YYYY_MM_DD)] && (
                      <span className='contains-record' />
                    )}
                    {d.format('D')}
                  </div>
                )
              }}
            />
            <TimePicker
              size='large'
              inputReadOnly
              format='HH:mm'
              value={toTime}
              showSecond={false}
              className='time-picker'
              onChange={v => setToTime(v)}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <Button
            block
            size='large'
            type='primary'
            className='select-duration'
            onClick={handleSelect}
          >
            Select
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalSelectDuration
