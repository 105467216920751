import React from 'react'
import cx from 'classnames'

import { TableRowProps } from './TableTypes'
import { useTableContext } from './TableContext'
import TableCol from './TableCol'

const TableRow: React.FC<TableRowProps> = ({
  rowKey,
  className,
  rowActions,
  children,
}) => {
  const { activeRowKey, setActiveRowKey } = useTableContext()

  const handleRowActionActive = () => {
    setActiveRowKey((prev: number) => (prev === rowKey ? 0 : rowKey))
  }

  return (
    <div
      className={cx(
        'm-table-row-wrap',
        { 'show-action': activeRowKey === rowKey },
        className,
      )}
    >
      <div className='m-table-row'>
        {children}
        {rowActions && (
          <TableCol
            className='col-action'
            width='1.25rem'
            onColClick={handleRowActionActive}
          >
            <span>|||</span>
          </TableCol>
        )}
      </div>
      {rowActions && <div className='m-table-row-actions'>{rowActions}</div>}
    </div>
  )
}

export default TableRow
