import React from 'react'
import { Form, Input } from 'antd'

import { ChatCircle, InfoCircle } from 'components/Icons'
import PlanInfoCard from 'components/Cards/PlanInfoCard'

interface Props {
  isLicensed: boolean
  remaining: number
  onAssignLicense: () => void
}

function CloudSurveillance({ isLicensed, remaining, onAssignLicense }: Props) {
  return (
    <React.Fragment>
      {isLicensed && (
        <PlanInfoCard showUpgrade>
          You have{' '}
          <span className='text-bold'>
            {remaining} cloud surveillance licenses
          </span>{' '}
          remaining
        </PlanInfoCard>
      )}
      {!isLicensed && remaining > 0 && (
        <PlanInfoCard>
          You have{' '}
          <span className='text-bold'>
            {remaining} cloud surveillance licenses
          </span>{' '}
          remaining
          <div
            style={{ marginTop: 2 }}
            className='text-primary text-underline hoverable text-500'
            onClick={onAssignLicense}
          >
            Assign license
          </div>
        </PlanInfoCard>
      )}
      {!isLicensed && !remaining && (
        <PlanInfoCard showUpgrade>
          You do not currently have any{' '}
          <span className='text-bold'>cloud surveillance licenses</span>. A
          license is required in order to record and livestream your camera.
        </PlanInfoCard>
      )}

      {isLicensed && (
        <div style={{ padding: '1rem 8px' }}>
          <Form.Item name='stream_url' label='RTSP Link'>
            <Input
              size='large'
              placeholder='Enter device RTSP link then click check'
            />
          </Form.Item>
          <div className='help-info'>
            <InfoCircle color='#808080' />
            <span>
              RTSP alllow this device record & livestream.{' '}
              <a className='text-placeholder text-500 text-underline' href='/'>
                See how to get device RTSP Link
              </a>
            </span>
          </div>
          <div className='help-info'>
            <ChatCircle style={{ transform: 'scale(1.1)' }} type='primary' />
            <span className='text-primary text-500 hoverable'>
              Chat with technical support
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default CloudSurveillance
