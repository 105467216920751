import { Button } from 'antd'

interface Props {
  loading: boolean
  onSubmit: () => void
}

function CheckoutWithDefaultCard({ loading, onSubmit }: Props) {
  return (
    <Button
      type='primary'
      size='large'
      block
      className='checkout-submit'
      disabled={loading}
      onClick={!loading ? onSubmit : undefined}
    >
      Confirm
    </Button>
  )
}

export default CheckoutWithDefaultCard
