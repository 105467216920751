import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function WifiOffline({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M5.2403 6.32706C5.17043 6.58324 5.13161 6.85496 5.13161 7.13443C5.13161 7.98839 5.48096 8.76471 6.03991 9.33142L4.93753 10.4338C4.0991 9.58761 3.57897 8.42313 3.57897 7.13443C3.57897 6.41245 3.74199 5.73705 4.02923 5.12376L2.88804 3.98256C2.33685 4.90639 2.02632 5.98548 2.02632 7.13443C2.02632 8.8501 2.72501 10.4027 3.85068 11.5207L2.7483 12.623C1.34316 11.2179 0.473679 9.27708 0.473679 7.13443C0.473679 5.55073 0.947235 4.08349 1.76237 2.8569L0.00012207 1.09464L1.09474 3.05176e-05L15.3635 14.2688L14.2689 15.3634L5.2403 6.32706ZM12.4446 9.14511C12.7318 8.53181 12.8948 7.85641 12.8948 7.13443C12.8948 5.84574 12.3747 4.68125 11.5285 3.84283L10.4261 4.9452C10.9928 5.50416 11.3422 6.28048 11.3422 7.13443C11.3422 7.41391 11.3034 7.68562 11.2335 7.94181L12.4446 9.14511ZM14.4475 7.13443C14.4475 8.28339 14.1369 9.36248 13.5858 10.2863L14.7114 11.412C15.5266 10.1854 16.0001 8.71813 16.0001 7.13443C16.0001 4.99178 15.1306 3.05098 13.7255 1.64583L12.6231 2.74821C13.7488 3.86612 14.4475 5.41876 14.4475 7.13443Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
