import { useState } from 'react'
import { useHistory } from 'react-router'
import { Button, Slider } from 'antd'

import { TagFilled } from 'components/Icons'
import { CHARGE_PER_CAM } from 'utils/device'
import { Plan } from 'services/subscription'
import { useAppSelector } from 'store'

interface Props {
  free: Plan
  basic: Plan
  plus: Plan
  pro: Plan
}

function Plans({ free, basic, plus, pro }: Props) {
  const { push } = useHistory()
  const { stats } = useAppSelector(state => state.stats)
  const { subscription: sub } = useAppSelector(state => state.subscription)

  const [cameraCount, setCameraCount] = useState(
    stats?.camera_licenses.total || 0,
  )

  const calcTotalPrice = (basePrice: number) => {
    const total = basePrice / 100 + cameraCount * CHARGE_PER_CAM
    return total
  }

  const handleChoosePlan = (id: number) => {
    push('/settings/payment-settings/checkout', {
      planId: id,
      camCount: id === 1 ? 0 : cameraCount,
    })
  }

  const isCurrentPlan = (planId: number) => {
    const currentPlanId = sub?.subscription?.plan_id || 1
    const totalCamCount = stats?.camera_licenses.total || 0
    return planId === currentPlanId && totalCamCount === cameraCount
  }

  return (
    <div className='plan-list'>
      {/* FREE */}
      <div className='plan'>
        <h3 className='label'>Free</h3>
        <div className='price'>
          <span>${free.price / 100}</span>/month
        </div>
        <div className='description'>
          Notifications with schedules and recipients for 1 device
        </div>
        <div className='cam-selection'></div>
        <Button block type='primary' size='large' disabled>
          {isCurrentPlan(free.id) ? 'Current Plan' : 'Choose Plan'}
        </Button>
      </div>

      {/* BASIC */}
      <div className='plan'>
        <h3 className='label'>Basic</h3>
        <div className='price'>
          <span>${calcTotalPrice(basic.price)}</span>
          /month
        </div>
        <div className='description'>Best for individual users</div>
        <div className='cam-selection'>
          <span>
            You have selected <b>{cameraCount} IP camera licenses</b>
            <br />
            <span className='text-placeholder'>
              <span className='text-bold text-body'>$6</span> / mo each
            </span>
          </span>
          <Slider
            min={0}
            max={16}
            tooltipVisible={false}
            value={cameraCount}
            onChange={setCameraCount}
          />
        </div>
        <Button
          block
          type='primary'
          size='large'
          disabled={isCurrentPlan(basic.id)}
          onClick={
            isCurrentPlan(basic.id)
              ? undefined
              : () => handleChoosePlan(basic.id)
          }
        >
          {isCurrentPlan(basic.id) ? 'Current Plan' : 'Choose Plan'}
        </Button>
      </div>

      {/* PLUS */}
      <div className='plan plus-plan'>
        <h3 className='label'>
          Plus
          <span className='text-primary text-bold popular'>
            <TagFilled type='primary' /> Popular
          </span>
        </h3>
        <div className='price'>
          <span>${calcTotalPrice(plus.price)}</span>/month
        </div>
        <div className='description'>Multiple users, enhanced support</div>
        <div className='cam-selection'>
          <span>
            You have selected <b>{cameraCount} IP camera licenses</b>
            <br />
            <span className='text-placeholder'>
              <span className='text-bold text-body'>$6</span> / mo each
            </span>
          </span>
          <Slider
            min={0}
            max={16}
            tooltipVisible={false}
            value={cameraCount}
            onChange={setCameraCount}
          />
        </div>
        <Button
          block
          type='primary'
          size='large'
          disabled={isCurrentPlan(plus.id)}
          onClick={
            isCurrentPlan(plus.id) ? undefined : () => handleChoosePlan(plus.id)
          }
        >
          {isCurrentPlan(plus.id) ? 'Current Plan' : 'Choose Plan'}
        </Button>
      </div>

      {/* PRO */}
      <div className='plan professional-plan'>
        <h3 className='label'>Professional</h3>
        <div className='price'>
          <span>${calcTotalPrice(pro.price)}</span>/month
        </div>
        <div className='description'>Best plan for the pros</div>
        <div className='cam-selection'>
          <span>
            You have selected <b>{cameraCount} IP camera licenses</b>
            <br />
            <span className='text-placeholder'>
              <span className='text-bold text-body'>$6</span> / mo each
            </span>
          </span>
          <Slider
            min={0}
            max={16}
            tooltipVisible={false}
            value={cameraCount}
            onChange={setCameraCount}
          />
        </div>
        <Button
          block
          type='primary'
          size='large'
          disabled={isCurrentPlan(pro.id)}
          onClick={
            isCurrentPlan(pro.id) ? undefined : () => handleChoosePlan(pro.id)
          }
        >
          {isCurrentPlan(pro.id) ? 'Current Plan' : 'Choose Plan'}
        </Button>
      </div>

      {/* CUSTOM */}
      <div className='plan custom-plan'>
        <h3 className='label'>Custom</h3>
        <div className='description'>
          Amplify your notifications and video with one-on-one support and
          custom integration
        </div>
        <Button
          type='primary'
          size='large'
          onClick={() => window.open('https://broadflow.co/contact', '_blank')}
        >
          Contact Us
        </Button>
      </div>
    </div>
  )
}

export default Plans
