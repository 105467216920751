import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as stats from 'services/stats'

export const getAccountStats: any = createAsyncThunk(
  'auth/getAccountStats',
  async () => {
    const response = await stats.getAccountStats()
    return response
  },
)

interface State {
  loading: boolean
  error: boolean
  stats: stats.AccountStats | null
}

const initialState: State = {
  loading: true,
  error: false,
  stats: null,
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    updateCamlicenseCount: ({ stats }, { payload }: { payload: number }) => {
      if (!stats) {
        return
      }
      stats.camera_licenses.used = stats.camera_licenses.used + payload
    },
    updateDeviceCount: ({ stats }, { payload }: { payload: number }) => {
      if (!stats) {
        return
      }
      stats.devices.used = stats.devices.used + payload
    },
  },
  extraReducers: {
    [getAccountStats.pending.type]: state => {
      state.loading = true
      state.error = false
    },
    [getAccountStats.rejected.type]: state => {
      state.loading = false
      state.error = true
    },
    [getAccountStats.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.stats = payload.data.data
    },
  },
})

export const { updateCamlicenseCount, updateDeviceCount } = statsSlice.actions
export default statsSlice.reducer
