import { useState } from 'react'

import './index.scss'
import { Layout } from 'components/Shared'
import { notiError, notiSuccess } from 'utils/notification'
import { toggleDeviceNoti } from 'services/accounts'
import { togglePromoNoti } from 'services/auth'
import { updateDeviceNoti, updatePromoNoti } from 'store/slice/authSlice'
import { useAppDispatch, useAppSelector } from 'store'
import { Button, Radio, Space } from 'antd'

function Notifications() {
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)

  const [loading, setLoading] = useState(false)
  const [deviceNoti, setDeviceNoti] = useState(
    currentUser?.account.enabled_notifications || 0,
  )
  const [promoNoti, setPromoNoti] = useState(
    currentUser?.enable_promotional_notifications || 0,
  )

  const handleChangeAccountNoti = async () => {
    try {
      setLoading(true)
      const isDeviceNotiChanged =
        deviceNoti !== currentUser!.account.enabled_notifications
      const isPromoNotiChanged =
        promoNoti !== currentUser!.enable_promotional_notifications

      if (!isDeviceNotiChanged && !isPromoNotiChanged) {
        return
      }

      const promises = []
      if (isDeviceNotiChanged) {
        promises.push(toggleDeviceNoti(currentUser!.id, deviceNoti))
      }
      if (isPromoNotiChanged) {
        promises.push(togglePromoNoti(promoNoti))
      }

      await Promise.all(promises)
      if (isDeviceNotiChanged) {
        await dispatch(updateDeviceNoti(deviceNoti))
      }
      if (isPromoNotiChanged) {
        await dispatch(updatePromoNoti(promoNoti))
      }

      notiSuccess({ message: 'Notification setting updated' })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  if (!currentUser) {
    return null
  }

  return (
    <Layout className='notification-settings-page'>
      <Layout.Header goBack bordered>
        Notification Settings
      </Layout.Header>
      <Layout.Body>
        <div className='row'>
          <h3>Device Notifications</h3>
          <Radio.Group
            value={deviceNoti}
            onChange={e => setDeviceNoti(e.target.value)}
          >
            <Space direction='vertical' style={{ gap: 16 }}>
              <Radio value={1}>
                <span className='text-success text-bold'>On</span>
                <span className='text-placeholder' style={{ marginLeft: 8 }}>
                  All notifications from devices will be sent to recipients
                </span>
              </Radio>
              <Radio value={0}>
                <span className='text-placeholder text-bold'>Off</span>
                <span className='text-placeholder' style={{ marginLeft: 8 }}>
                  All notifications from devices will NOT be sent to recipients
                </span>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <div className='divider' />
        <div className='row' style={{ paddingTop: 8 }}>
          <h3>Promotional Notifications</h3>
          <Radio.Group
            value={promoNoti}
            onChange={e => setPromoNoti(e.target.value)}
          >
            <Space direction='vertical' style={{ gap: 16 }}>
              <Radio value={1}>
                <span className='text-success text-bold'>On</span>
                <span className='text-placeholder' style={{ marginLeft: 8 }}>
                  You will receive marketing & promotional email from Broadflow
                </span>
              </Radio>
              <Radio value={0}>
                <span className='text-placeholder text-bold'>Off</span>
                <span className='text-placeholder' style={{ marginLeft: 8 }}>
                  You will no longer receive marketing & promotional email from
                  Broadflow
                </span>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
        <Button
          size='large'
          type='primary'
          disabled={loading}
          onClick={loading ? undefined : handleChangeAccountNoti}
        >
          Save
        </Button>
      </Layout.Body>
    </Layout>
  )
}

export default Notifications
