import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import cx from 'classnames'
import { Button } from 'antd'
import {
  DoubleRightOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'

import {
  ExitFullscreen,
  Fullscreen,
  RatioFilled,
  ScreenshotFilled,
  SpeakerLouderFilled,
  SpeakerMutedFilled,
  VideoCameraFilled,
} from 'components/Icons'
import { useFullscreen } from 'hooks'
import ModalAddDevices from 'components/Modal/Livestream/AddDevices'
import { lockScreen, unlockScreen, videoCapture } from 'utils/functions'
import { Device } from 'services/devices'
import { useAppSelector } from 'store'

import Player from './Player'
import { Tab, Player as IPlayer } from './Types'

interface Props {
  tab: Tab
  onEditTab: (updatedTab: Tab) => void
}

function PlayerTab({ tab, onEditTab }: Props) {
  const { stats } = useAppSelector(state => state.stats)
  const { isFullscreen, requestFullscreen, exitFullscreen } = useFullscreen()

  const [tabFullscreen, setTabFullscreen] = useState(false)
  const [selectedItemKey, setSelectedItemKey] = useState<number | null>(null)
  const [showAddDevice, setShowAddDevice] = useState(false)
  const [selectedPlayers, setSelectedPlayers] = useState<
    (IPlayer | undefined)[]
  >([])

  const playerTabRef = useRef<HTMLDivElement>(null)

  const { layout } = tab
  const activePlayer = selectedPlayers[selectedItemKey ?? -1]

  useEffect(() => {
    if (isFullscreen) {
      lockScreen('landscape')
    } else {
      lockScreen('portrait', unlockScreen)
      setTabFullscreen(false)
      setSelectedPlayers(prev =>
        prev.map(p => (!!p ? { ...p, fullscreen: false } : p)),
      )
    }
  }, [isFullscreen])

  const renderTabItem = (player?: IPlayer) => {
    if (player)
      return (
        <Player player={player} layout={layout} tabFullscreen={tabFullscreen} />
      )
    return (
      <div className='flex-centered'>
        <PlusOutlined className='text-placeholder' style={{ fontSize: 20 }} />
      </div>
    )
  }

  const handleAddDevices = (devices: (Device | undefined)[]) => {
    setSelectedPlayers(
      devices.map((d, idx) =>
        !!d
          ? { device: d, index: idx, muted: true, fullscreen: false }
          : undefined,
      ),
    )
  }

  const handleTabItemActive = (tabIdx: number) => {
    setSelectedItemKey(prev => (prev === tabIdx ? null : tabIdx))
  }

  const handleVideoCapture = () => {
    if (!activePlayer) return
    const video = document.querySelector(
      `.player-wrapper#player-${activePlayer.index} .player video`,
    )
    if (!video) return
    videoCapture(video as HTMLVideoElement)
  }

  const handleToggleVolume = () => {
    if (!activePlayer) return
    setSelectedPlayers(prev =>
      prev.map(p =>
        p?.index === activePlayer.index
          ? { ...activePlayer, muted: !activePlayer.muted }
          : p,
      ),
    )
  }

  const handleFullscreen = () => {
    if (!activePlayer) return
    const el = document.querySelector(
      `.player-wrapper#player-${activePlayer.index} .player`,
    )
    if (!el) return
    requestFullscreen(el, () => {
      setSelectedPlayers(prev =>
        prev.map(p =>
          p?.index === activePlayer.index ? { ...p, fullscreen: true } : p,
        ),
      )
    })
  }

  const handleRemovePlayer = () => {
    if (!activePlayer) return
    setSelectedPlayers(prev => {
      return prev.map((p, idx) => (idx === selectedItemKey ? undefined : p))
    })
  }

  const handleLayoutChange = (num: number) => {
    setSelectedItemKey(null)
    setSelectedPlayers([])
    onEditTab({ ...tab, layout: num })
  }

  const handleTabFullscreen = () => {
    if (!playerTabRef.current) return
    requestFullscreen(playerTabRef.current.querySelector('.tab-layout')!, () =>
      setTabFullscreen(true),
    )
  }

  return (
    <React.Fragment>
      <div ref={playerTabRef} className='player-tab-wrapper'>
        <div
          className='tab-layout'
          style={{ gridTemplateColumns: `repeat(${layout}, 1fr)` }}
        >
          {Array.from({ length: layout * layout }).map((_, idx) => (
            <div
              key={idx}
              id={`player-${idx}`}
              onClick={() => handleTabItemActive(idx)}
              className={cx('player-wrapper', {
                active: selectedItemKey === idx,
              })}
            >
              {renderTabItem(selectedPlayers[idx])}
            </div>
          ))}

          <AnimatePresence initial={false}>
            {tabFullscreen && selectedItemKey !== null && (
              <motion.div
                initial={{ x: '-50%', y: '100vh' }}
                animate={{ x: '-50%', y: 0 }}
                transition={{ type: 'tween', duration: 0.2 }}
                exit={{ x: '-50%', y: '100vh' }}
                className='fullscreen-controls'
              >
                <div
                  className={cx({ disabled: !activePlayer })}
                  onClick={handleVideoCapture}
                >
                  <ScreenshotFilled color='#ffffff' />
                </div>
                <div
                  className={cx({ disabled: !activePlayer })}
                  onClick={handleToggleVolume}
                >
                  {activePlayer?.muted ? (
                    <SpeakerMutedFilled color='#ffffff' />
                  ) : (
                    <SpeakerLouderFilled color='#ffffff' />
                  )}
                </div>
                <div onClick={() => exitFullscreen()}>
                  <ExitFullscreen color='#ffffff' />
                </div>
                <div className='divider' />
                <div onClick={() => setSelectedItemKey(null)}>
                  <DoubleRightOutlined rotate={90} />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className='player-controls'>
          {selectedItemKey !== null ? (
            <React.Fragment>
              <div
                className={cx('control-item', { disabled: !activePlayer })}
                onClick={handleVideoCapture}
              >
                <ScreenshotFilled type='secondary' />
                <span className='control-label'>Screenshot</span>
              </div>
              <div
                className={cx('control-item', { disabled: !activePlayer })}
                onClick={handleToggleVolume}
              >
                {activePlayer?.muted ? (
                  <SpeakerMutedFilled type='secondary' />
                ) : (
                  <SpeakerLouderFilled type='secondary' />
                )}
                <span className='control-label'>Volume</span>
              </div>
              <div
                className={cx('control-item', { disabled: !activePlayer })}
                onClick={handleFullscreen}
              >
                <Fullscreen type='secondary' />
                <span className='control-label'>Full screen</span>
              </div>
              <div
                className='control-item'
                onClick={
                  !activePlayer
                    ? () => setShowAddDevice(true)
                    : handleRemovePlayer
                }
              >
                {!activePlayer && (
                  <React.Fragment>
                    <PlusCircleOutlined className='text-placeholder' />
                    <span className='control-label'>Add</span>
                  </React.Fragment>
                )}
                {activePlayer && (
                  <React.Fragment>
                    <MinusCircleOutlined className='text-placeholder' />
                    <span className='control-label'>Remove</span>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          ) : (
            <div className='control-message'>Tap any box above to interact</div>
          )}
        </div>
      </div>

      <div className='tab-option'>
        <div>
          <RatioFilled className='text-placeholder' />
          Change Layout
        </div>
        <div>
          {[1, 2, 3, 4].map(num => (
            <Button
              key={num}
              size='large'
              onClick={() => handleLayoutChange(num)}
              className={cx('btn-selection', {
                selected: num === layout,
              })}
            >
              {num}x{num}
            </Button>
          ))}
        </div>
      </div>
      <div className='tab-option hoverable' onClick={handleTabFullscreen}>
        <div>
          <Fullscreen style={{ transform: 'scale(.9)' }} />
          Enter Fullscreen Mode
        </div>
      </div>
      <div className='tab-option'>
        <div>
          <VideoCameraFilled color='#808080' />
          Camera licenses:{' '}
          <span className='text-500'>
            {stats?.camera_licenses.used}/{stats?.camera_licenses.total}
          </span>
        </div>
        <Link
          to='/settings/payment-settings/plans'
          style={{ marginLeft: 8 }}
          className='text-500 text-underline'
        >
          Upgrade
        </Link>
      </div>

      <AnimatePresence>
        {showAddDevice && (
          <ModalAddDevices
            mode='livestream'
            max={layout * layout}
            index={selectedItemKey!}
            devices={selectedPlayers.map(p => p?.device)}
            onChange={handleAddDevices}
            onCancel={() => setShowAddDevice(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default PlayerTab
