import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function RecordStart({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M7.99998 0C3.58173 0 0 3.58173 0 7.99998C0 12.4182 3.58173 16 7.99998 16C12.4182 16 16 12.4182 16 7.99998C16 3.58173 12.4183 0 7.99998 0ZM7.99998 14.528C4.39466 14.528 1.47199 11.6053 1.47199 7.99998C1.47199 4.39466 4.39466 1.47199 7.99998 1.47199C11.6035 1.47641 14.5236 4.3965 14.528 7.99998C14.528 11.6053 11.6053 14.528 7.99998 14.528Z'
          fill={c}
        />
        <path
          d='M7.99997 10.4C9.32544 10.4 10.4 9.32544 10.4 7.99997C10.4 6.67449 9.32544 5.59998 7.99997 5.59998C6.67449 5.59998 5.59998 6.67449 5.59998 7.99997C5.59998 9.32544 6.67449 10.4 7.99997 10.4Z'
          fill={c}
        />
        <path
          d='M7.99997 4.79999C6.23267 4.79999 4.79999 6.23267 4.79999 7.99997C4.79999 9.76727 6.23267 11.2 7.99997 11.2C9.76727 11.2 11.2 9.76727 11.2 7.99997C11.2 6.23267 9.76731 4.79999 7.99997 4.79999ZM7.99997 9.59998C7.11632 9.59998 6.39996 8.88362 6.39996 7.99997C6.39996 7.11632 7.11632 6.39996 7.99997 6.39996C8.88362 6.39996 9.59998 7.11632 9.59998 7.99997C9.59998 8.88362 8.88362 9.59998 7.99997 9.59998Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
