import { SubscriptionDetails } from 'services/subscription'

export enum PLAN_TYPE {
  FREE = 'free_plan',
  BASIC = 'basic_plan',
  PLUS = 'plus_plan',
  PRO = 'professional_plan',
  ENTERPRISE = 'enterprise'
}

export function isPlanExpired(sub: SubscriptionDetails | null) {
  if (!sub) {
    return true
  }
  if (sub.is_new_account) {
    return false
  }
  if (!sub.subscription) {
    return true
  }
  if (!!sub.subscription.is_active && !sub.subscription.ends_at) {
    return false
  }
  return true
}
