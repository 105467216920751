import cx from 'classnames'
import React from 'react'

import MasterCard from 'assets/brand-logos/master-card.svg'
import Visa from 'assets/brand-logos/visa.svg'

interface Props {
  active?: boolean
  brand?: string | null | undefined
  text?: string | React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

function CreditCard({ active, text, brand, onClick }: Props) {
  const renderCardIcon = () => {
    if (brand === 'visa') {
      return <img src={Visa} alt='visa' />
    }
    if (brand === 'mastercard') {
      return <img src={MasterCard} alt='mastercard' />
    }
    return <span className='text-placeholder'>---</span>
  }

  return (
    <div className={cx('credit-card-wrapper', { active })} onClick={onClick}>
      <div className='card-header'>{renderCardIcon()}</div>
      <div className='card-spacer' />
      <div className='card-content'>{text}</div>
    </div>
  )
}

export default CreditCard
