import axios from './axios'

export interface RecordPlayback {
  end_timestamp: string
  playlist_url: string
  start_timestamp: string
  gaps: { start_time: string; end_time: string }[]
}

export interface RecordPlaybackV2 {
  notifications: PlaybackNoti[]
  playback: PlaybackSegment[]
}

export interface PlaybackSegment {
  endtime: string
  starttime: string
  url: string
}

export interface PlaybackNoti {
  id: number
  starttime: string
  subject: string
  tags: {
    color: string
    id: number
    name: string
    pivot: any
    stroke_color: string
    text_color: string
  }[]
}

export const getRecordingPlayback = (
  id: number,
  params: { start_timestamp: string; end_timestamp: string },
) => {
  return axios.post(`/api/devices/${id}/recordingPlayback`, params)
}

export const getRecordingPlaybackV2 = (
  id: number,
  params: { start_timestamp: string; end_timestamp: string },
) => {
  return axios.post(`/api/devices/${id}/recordingPlayback/v2`, params)
}

export const getRecordsCalender = ({
  start_date,
  end_date,
  device_ids,
}: {
  start_date: string
  end_date: string
  device_ids: number[]
}) => {
  return axios.get(
    `api/recordingsCalender?start_date=${start_date}&end_date=${end_date}&device_ids=${[
      ...new Set(device_ids),
    ].join(',')}`,
  )
}
