import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Quote({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M16 6.14287L12.5714 6.14287L14.8572 1.57146L11.4286 1.57146L9.14286 6.14287V13L16 13V6.14287Z'
          fill={c}
        />
        <path
          d='M6.85718 13L6.85718 6.14287L3.4286 6.14287L5.71434 1.57146L2.28577 1.57146L3.29018e-05 6.14287L3.29018e-05 13L6.85718 13Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
