import { useEffect } from 'react'
import moment from 'moment'

import {
  FileTextFilled,
  RecordStart,
  RecordStop,
  WifiOffline,
  WifiOnline,
} from 'components/Icons'
import Table from 'components/Table'
import { MM_DD_YYYY_HH_MM_P, YYYY_MM_DD } from 'utils/dateTime'
import { upperFirstLetter } from 'utils/functions'
import { getDeviceLogs, ActivityLog } from 'services/activityLogs'

import { useLogContext } from './LogContext'

interface Props {
  deviceId: number
}

function TableList({ deviceId }: Props) {
  const { loading, logs, search, dateRange, currentPage, dispatch } =
    useLogContext()

  useEffect(() => {
    handleGetDeviceLogs()
    // eslint-disable-next-line
  }, [search, currentPage, dateRange])

  const handleGetDeviceLogs = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      const [start, end] = dateRange
      const respose = await getDeviceLogs(deviceId, {
        search,
        currentPage,
        startDate: start ? moment(start).format(YYYY_MM_DD) : '',
        endDate: end ? moment(end).format(YYYY_MM_DD) : '',
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 10,
      })
      dispatch({ type: 'SET_LOGS', payload: respose.data })
    } catch {
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderLogIcon = (desc: string) => {
    let Icon = FileTextFilled
    if (desc.endsWith('started')) Icon = RecordStart
    if (desc.endsWith('stopped')) Icon = RecordStop
    if (desc.endsWith('online')) Icon = WifiOnline
    if (desc.endsWith('offline')) Icon = WifiOffline
    return <Icon />
  }

  return (
    <Table
      loading={loading}
      empty={!logs.length}
      className='table-device-activity-logs'
    >
      {logs.map((log: ActivityLog) => (
        <Table.Row key={log.id} rowKey={log.id}>
          <Table.Col>
            <div className='log-desc'>
              {renderLogIcon(log.description)}
              <span>{upperFirstLetter(log.description)}</span>
            </div>
            <div className='ip-address'>
              <span>IP Address</span>
              <span>{log.properties?.ip || '---'}</span>
            </div>
            <div className='happened-at'>
              <span>Happened at</span>
              <span>{moment(log.created_at).format(MM_DD_YYYY_HH_MM_P)}</span>
            </div>
          </Table.Col>
        </Table.Row>
      ))}
    </Table>
  )
}

export default TableList
