import React from 'react'
import cx from 'classnames'

import BaseModal from './BaseModal'
import { Close } from 'components/Icons'

interface Props {
  className?: string
  onAnimationEnd?: () => void
  onCancel: () => void
}

const ModalPlayer: React.FC<Props> = ({
  className,
  onAnimationEnd,
  onCancel,
  children,
}) => {
  return (
    <BaseModal
      noPadding
      className={cx('modal-player', className)}
      onCancel={onCancel}
      onAnimationEnd={onAnimationEnd}
    >
      <div className='close-icon' onClick={onCancel}>
        <Close color='#ffffff' />
      </div>
      {children}
    </BaseModal>
  )
}

export default ModalPlayer
