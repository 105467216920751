import { useState, useEffect } from 'react'
import cx from 'classnames'
import { Button } from 'antd'

import './index.scss'
import { Layout, Spinner } from 'components/Shared'
import { deviceTypes } from 'utils/selectOptions'
import { notiError } from 'utils/notification'
import { Location, getLocations } from 'services/locations'

import BaseModal from '../BaseModal'

interface Props {
  deviceType?: string
  locationId?: number
  onChange?: (filters: { deviceType?: string; locationId?: number }) => void
  onCancel: () => void
}

function ModalFilterDevices({
  deviceType,
  locationId,
  onChange,
  onCancel,
}: Props) {
  const [loading, setLoading] = useState(false)
  const [locations, setLocations] = useState<Location[]>([])
  const [selectedType, setSelectedType] = useState(deviceType)
  const [selectedLocation, setSelectedLocation] = useState(locationId)

  useEffect(() => {
    handleGetLocations()
    // eslint-disable-next-line
  }, [])

  const handleGetLocations = async () => {
    try {
      setLoading(true)
      const response = await getLocations({
        sort: 'id',
        sortDirection: 'desc',
        pageSize: 1000,
        currentPage: 1,
        search: '',
      })
      setLocations(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = (resetFilter = false) => {
    onChange &&
      onChange(
        resetFilter
          ? { deviceType: undefined, locationId: undefined }
          : { deviceType: selectedType, locationId: selectedLocation },
      )
    onCancel()
  }

  return (
    <BaseModal noPadding className='modal-filter-devices' onCancel={onCancel}>
      <Layout>
        <Layout.Header
          bordered
          rounded
          goBack
          onGoBack={onCancel}
          actionRight={
            <span
              className='text-primary text-500 hoverable'
              onClick={() => handleFilter(true)}
            >
              Reset Filter
            </span>
          }
        >
          Quick Filters
        </Layout.Header>
        <Layout.Body>
          <div className='device-type'>
            <h4>Device Type</h4>
            <div className='device-type-list'>
              {deviceTypes.map(type => (
                <Button
                  key={type}
                  size='large'
                  className={cx('btn-selection', {
                    selected: type === selectedType,
                  })}
                  onClick={() => setSelectedType(type)}
                >
                  {type}
                </Button>
              ))}
            </div>
          </div>
          <hr className='divider' />
          <div className='device-location'>
            <h4>Location</h4>
            <div className='device-location-list'>
              {loading && <Spinner />}
              {!loading && !locations.length && (
                <span className='text-placeholder'>No locations found</span>
              )}
              {!loading &&
                !!locations.length &&
                locations.map(location => (
                  <Button
                    key={location.id}
                    size='large'
                    className={cx('btn-selection', {
                      selected: selectedLocation === location.id,
                    })}
                    onClick={() => setSelectedLocation(location.id)}
                  >
                    {location.name}
                  </Button>
                ))}
            </div>
          </div>
          <Button
            type='primary'
            size='large'
            block
            onClick={() => handleFilter()}
          >
            Apply
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalFilterDevices
