import React, { useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Badge, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import Pagination from 'components/Pagination'
import ModalSearch from 'components/Modal/ModalSearch'

import { useUserContext } from './UserContext'

function TableListHeader() {
  const [showSearch, setShowSearch] = useState(false)
  const { loading, search, currentPage, totalPage, dispatch } = useUserContext()

  const handleSearch = (value: string) => {
    if (value !== search) {
      dispatch({ type: 'SET_SEARCH', payload: value })
    }
  }

  const handlePageChange = (newPage: number) => {
    dispatch({ type: 'SET_CURRENT_PAGE', payload: newPage })
  }

  return (
    <React.Fragment>
      <div className='table-list-header sticky'>
        <div className='left'>
          <Pagination
            disabled={loading}
            current={currentPage}
            total={totalPage}
            onPageChange={handlePageChange}
          />
        </div>
        <div className='right'>
          <Button
            disabled={loading}
            size='large'
            className='ant-btn-secondary'
            onClick={() => setShowSearch(true)}
          >
            <Badge dot={!!search}>
              <SearchOutlined />
            </Badge>
          </Button>
        </div>
      </div>
      <AnimatePresence>
        {showSearch && (
          <ModalSearch
            title='Search Users'
            placeholder='Search users'
            value={search}
            onSearch={handleSearch}
            onCancel={() => setShowSearch(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableListHeader
