import axios from './axios'
import { Device } from './devices'

export interface Vault {
  account_id: number
  created_at: string
  device: Device
  device_id: number
  file_name: string
  file_path: string
  file_size_mb: string
  file_type: string
  file_url: string
  id: number
  updated_at: string
}

interface VaultViewer {
  email: string
  recipient_id?: number
  user_id?: number
}

export interface SharedVaultFile {
  id: number
  account_id: number
  device_id: number
  file_name: string
  file_path: string
  file_type: string
  file_size_mb: string
  created_at: string
  updated_at: string
  file_url: string
}

export const getVaultFiles = ({
  search,
  deviceId,
  sort,
  sortDirection,
  pageSize,
  currentPage,
}: {
  search: string
  deviceId?: number
  sort: string
  sortDirection: string
  pageSize: number
  currentPage: number
}) => {
  return axios.get(
    `/api/vaultFiles?include=device&filters[search]=${search}&filters[device_id]=${
      deviceId || ''
    }&order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`,
  )
}

export const addRecordToVault = async (recordId: number) => {
  await axios.get('/api/csrf-cookie')
  return axios.post('/api/vaultFiles', { recording_download_id: recordId })
}

export const deleteRecord = (recordId: number) => {
  return axios.delete(`/api/vaultFiles/${recordId}`)
}

export const getVaultViewers = (
  vaultId: number,
  {
    sort,
    sortDirection,
    pageSize,
    currentPage,
  }: {
    sort: string
    sortDirection: string
    pageSize: number
    currentPage: number
  },
) => {
  return axios.get(
    `/api/vaultFiles/${vaultId}/vaultFileViewers?order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`,
  )
}

export const shareVaultFile = async (
  vaultId: number,
  data: {
    expiry_number: number
    expiry_type: string
    message?: string
    viewers: VaultViewer[]
  },
) => {
  await axios.get('/api/csrf-cookie')
  return axios.post(`/api/vaultFiles/${vaultId}/share`, data)
}

export const getSharedVaultFile = (
  id: number,
  email: string,
  signature: string,
) => {
  return axios.get(
    `/api/external/sharedVaultFiles/${id}?email=${encodeURIComponent(
      email,
    )}&signature=${signature}`,
  )
}
