import { FC, useState } from 'react'
import cx from 'classnames'
import { Empty } from 'antd'

import './index.scss'
import { Spinner } from 'components/Shared'

import * as Types from './TableTypes'
import TableContext from './TableContext'
import TableRowGroup from './TableRowGroup'
import TableRowHead from './TableRowHead'
import TableRow from './TableRow'
import TableCol from './TableCol'

const Table: FC<Types.TableProps> & Types.TableComposition = ({
  className,
  loading,
  empty,
  expandable,
  children
}) => {
  const [activeRowKey, setActiveRowKey] = useState(0)

  const ctx = {
    activeRowKey,
    setActiveRowKey,
    expandable: !!expandable,
    ...expandable
  }

  return (
    <TableContext.Provider value={ctx}>
      <div className={cx('m-table', { loading, empty }, className)}>
        {loading && (
          <div className="table-loading">
            <Spinner />
          </div>
        )}
        {empty ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : children}
      </div>
    </TableContext.Provider>
  )
}

Table.RowGroup = TableRowGroup
Table.RowHead = TableRowHead
Table.Row = TableRow
Table.Col = TableCol

export default Table
