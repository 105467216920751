import { useState } from 'react'
import cx from 'classnames'

import './index.scss'
import ModalPlayer from 'components/Modal/ModalPlayer'

interface Props {
  fileUrl: string
  onCancel: () => void
}

function ModalPlayback({ fileUrl, onCancel }: Props) {
  const [slideUp, setSlideUp] = useState(true)

  return (
    <ModalPlayer
      onCancel={onCancel}
      className='modal-playback'
      onAnimationEnd={() => setSlideUp(false)}
    >
      <div className={cx('player-wrapper', { show: !slideUp })}>
        <video src={fileUrl} controls muted />
      </div>
    </ModalPlayer>
  )
}

export default ModalPlayback
