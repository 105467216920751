import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function PauseFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <rect
          width='4'
          height='20'
          rx='1'
          transform='matrix(-1 0 0 1 19 3)'
          fill={c}
        />
        <rect
          width='4'
          height='20'
          rx='1'
          transform='matrix(-1 0 0 1 11 3)'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
