import { Link } from 'react-router-dom'
import cx from 'classnames'
import { RightOutlined } from '@ant-design/icons'

import './index.scss'
import { fullPageMenu, MenuItem } from 'configs/navigation'
import { Avatar, Layout } from 'components/Shared'
import { logoutUser } from 'store/slice/authSlice'
import { useAppSelector, useAppDispatch } from 'store'

function AppMenu() {
  const dispatch = useAppDispatch()
  const { currentUser } = useAppSelector(state => state.auth)

  const handleLogout = async () => {
    await dispatch(logoutUser())
    window.location.href = '/login'
  }

  const renderMenuItem = (item: MenuItem) => {
    const { key, navLink, externalLink, type, label, Icon } = item
    return (
      <Link
        key={key}
        className='app-menu-item'
        target={!!externalLink ? '_blank' : '_self'}
        to={!!externalLink ? { pathname: externalLink } : navLink || '/'}
      >
        <Icon
          color={type === 'primary' ? '#5156D3' : '#808080'}
          className={cx(
            'menu-icon',
            `text-${type === 'primary' ? 'primary' : 'placeholder'}`,
          )}
        />
        <span className='text-body'>{label}</span>
        <RightOutlined className='text-body' />
      </Link>
    )
  }

  return (
    <Layout className='app-menu'>
      <Layout.Body>
        <h1>Menu</h1>
        {currentUser && (
          <div className='app-menu-group'>
            <Link to='/me' className='app-menu-item current-user'>
              <Avatar
                size={36}
                username={currentUser.name}
                src={currentUser.avatar_url || undefined}
              />
              <span className='user-name text-body text-medium'>
                {currentUser.name}
              </span>
              <RightOutlined className='text-body' />
            </Link>
          </div>
        )}

        {fullPageMenu.map(({ title, children }) => (
          <div key={title} className='app-menu-group'>
            <h3 className='group-title'>{title}</h3>
            {children.map(renderMenuItem)}
          </div>
        ))}
      </Layout.Body>

      {currentUser && (
        <div className='logout'>
          <span
            className='text-danger text-500 hoverable'
            onClick={handleLogout}
          >
            Log out
          </span>
        </div>
      )}
    </Layout>
  )
}

export default AppMenu
