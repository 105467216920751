import axios from './axios'

export interface ConfirmPasswordStatus {
  confirmed: boolean
}

export interface QRCode {
  svg: string
}

export type RecoveryCodes = string[]

export interface BrowserSession {
  browser: string
  ip_address: string
  is_current_device: boolean
  is_desktop: boolean
  last_active: string
  last_activity_timestamp: string
  platform: string
}

export interface LoggedInMobileDevice {
  id: number
  last_used: string | null
  last_used_at: string | null
  name: string
}

export const checkConfirmPassword = () => {
  return axios.get('/api/confirmed-password-status')
}

export const confirmPassword = (password: string) => {
  return axios.post('/api/confirm-password', { password })
}

export const enable2FA = () => {
  return axios.post('/api/two-factor-authentication')
}

export const disable2FA = () => {
  return axios.delete('/api/two-factor-authentication')
}

export const getQrCode = () => {
  return axios.get('/api/two-factor-qr-code')
}

export const getRecoveryCodes = () => {
  return axios.get('/api/two-factor-recovery-codes')
}

export const regenRecoveryCodes = () => {
  return axios.post('/api/two-factor-recovery-codes')
}

export const verify2FACode = (body: {
  code?: string
  recovery_code?: string
}) => {
  return axios.post('/api/two-factor-challenge', body)
}

export const getBrowserSessions = () => {
  return axios.get('/api/browserSessions')
}

export const getLoggedInMobileDevices = () => {
  return axios.get('/api/accessTokens')
}

export const logOutOtherBrowserSessions = () => {
  return axios.delete('/api/browserSessions')
}

export const deleteMobileDeviceToken = (id: number) => {
  return axios.delete(`/api/accessTokens/${id}`)
}
