export const deviceTypes = [
  'Security Camera',
  'Network Video Recorder',
  'Digital Video Recorder',
  'IDS',
  'IoT Gateway',
  'Fire Alarm',
  'Network Appliance',
  'Surge Protector',
  'Other'
]

export const timezones = [
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Adak'
]

export const manufacturers = [
  'ACTi',
  'Arecont',
  'Avigilon',
  'Axis',
  'Bosch',
  'Dahua',
  'Digital Watchdog',
  'Eclipse',
  'FLIR',
  'GE',
  'Hanwha',
  'Hikvision',
  'Honeywell',
  'Mobotix',
  'Other',
  'Panasonic',
  'Pelco',
  'Samsung',
  'Sony',
  'Speco',
  'Uniview',
  'Vivotek'
]

export const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]
