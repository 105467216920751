import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { MoreOutlined } from '@ant-design/icons'

import { EmptyList } from 'components/Icons'
import { Spinner } from 'components/Shared'
import ModalInboundNotifDetail from 'components/Modal/InboundNotification/InboundDetail'
import { notiError } from 'utils/notification'
import { getInboundNotis, InboundNoti } from 'services/notifications'

import ModalNotifMoreOptions from './ModalNotifMoreOptions'
import ModalAddNote from 'components/Modal/InboundNotification/AddNote'

function LastestDeviceNotifs() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<InboundNoti[]>([])

  const [showOptions, setShowOptions] = useState<number | null>(null)
  const [previewNotif, setPreviewNotif] = useState<number | null>(null)
  const [addNote, setAddNote] = useState<number | null>(null)

  useEffect(() => {
    handleGetInboundNotis()
    // eslint-disable-next-line
  }, [])

  const handleGetInboundNotis = async () => {
    try {
      const response = await getInboundNotis()
      setData(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const renderNotif = () =>
    data.map(notif => (
      <div key={notif.id} className='notif-list-item'>
        <div className='notif-tag'>
          {(notif.tags || []).map(({ id, stroke_color, color }, idx) => {
            const tagLength = notif.tags!.length
            return (
              <div
                key={id}
                style={{
                  background: stroke_color!,
                  borderRadius:
                    tagLength === 1
                      ? 8
                      : idx === 0
                      ? '8px 8px 0px 0px'
                      : idx + 1 === tagLength
                      ? '0px 0px 8px 8px'
                      : 0,
                }}
              >
                <div
                  style={{
                    padding: 0,
                    background: color!,
                    borderRadius:
                      tagLength === 1
                        ? 8
                        : idx === 0
                        ? '8px 8px 0px 0px'
                        : idx + 1 === tagLength
                        ? '0px 0px 8px 8px'
                        : 0,
                  }}
                />
              </div>
            )
          })}
        </div>
        <div className='notif-content'>
          <div className='text-placeholder'>
            {notif.device?.type || '---'} &gt; {notif.device?.name || '---'}
          </div>
          <div className='text-500'>{notif.subject}</div>
          <div className='text-placeholder'>
            {moment(notif.created_at).fromNow()}
          </div>
        </div>
        <div
          className='notif-actions hoverable'
          onClick={() => setShowOptions(notif.id)}
        >
          <MoreOutlined
            rotate={90}
            className='text-placeholder'
            style={{ fontSize: '1.4rem' }}
          />
        </div>
      </div>
    ))

  return (
    <React.Fragment>
      <div className='lastest-device-notifications'>
        <h3>Latest Device Notifications</h3>
        {loading && <Spinner />}
        {!loading && !data.length && (
          <div className='no-notif'>
            <EmptyList type='success' />
            <span className='text-placeholder'>
              There is no device notification at the moment
            </span>
          </div>
        )}
        {!loading && !!data.length && (
          <div className='notif-list'>
            {renderNotif()}
            <Link
              to='/notifications?tab=all-device-notification'
              className='ant-btn ant-btn-block ant-btn-lg ant-btn-secondary lighter'
            >
              View All Notifications
            </Link>
          </div>
        )}
      </div>
      <AnimatePresence>
        {!!showOptions && (
          <ModalNotifMoreOptions
            onPreview={() => setPreviewNotif(showOptions)}
            onAddNote={() => setAddNote(showOptions)}
            onCancel={() => setShowOptions(null)}
          />
        )}
        {previewNotif && (
          <ModalInboundNotifDetail
            notifId={previewNotif}
            onCancel={() => setPreviewNotif(null)}
          />
        )}
        {addNote && (
          <ModalAddNote notifId={addNote} onCancel={() => setAddNote(null)} />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default LastestDeviceNotifs
