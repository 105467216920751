import axios from './axios'

export interface Note {
  body: string | null
  created_at: string
  created_by: {
    account_id: number
    avatar_url: string | null
    avatar: string | null
    created_at: string
    email: string
    email_verified_at: string | null
    id: number
    is_admin: number
    name: string
    two_factor_recovery_codes: string | null
    two_factor_secret: string | null
    updated_at: string | null
  }
  device_inbound_notification_id: number
  id: number
  notify: number
  updated_at: string
}

export const getInboundNotiNotes = (inboundNotiId: number) => {
  return axios.get(`/api/deviceInboundNotifications/${inboundNotiId}/notes`)
}

export const addNewNote = async (
  inboundNotiId: number,
  note: { body: string; notify: boolean },
) => {
  await axios.get('/api/csrf-cookie')
  return axios.post(
    `/api/deviceInboundNotifications/${inboundNotiId}/notes`,
    note,
  )
}
