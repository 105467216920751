import axios from './axios'
import { Recipient } from './recipients'

export interface Schedule {
  created_at: string
  days: null | string[]
  channels: null | string[]
  is_active: number
  device_id: number
  end_time: null | string
  id: number
  is_24_7: number
  name: string
  recipients_count: number
  start_time: null | string
  updated_at: string
  email_recipients?: Recipient[]
  phone_recipients?: Recipient[]
  whatsapp_recipients?: Recipient[]
  slack_webhook?: string | null
  teams_webbhook?: string | null
  webhook?: string | null
}

export const getDeviceSchedules = ({
  deviceId,
  sort,
  sortDirection,
  pageSize,
  search,
  currentPage,
}: {
  deviceId: number
  sort: string
  sortDirection: string
  pageSize: number
  search: string
  currentPage: number
}) => {
  return axios.get(
    `/api/deviceSchedules?filters[device_id]=${deviceId}&order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&filters[search]=${search}&page=${currentPage}`,
  )
}

export const getScheduleDetails = (scheduleId: number) => {
  return axios.get(
    `api/deviceSchedules/${scheduleId}?include=emailRecipients,phoneRecipients,whatsappRecipients`,
  )
}

export const createSchedule = async (schedule: {
  name: string
  device_id: number
  is_24_7: boolean
  channels: string[]
  start_time?: string
  end_time?: string
  days?: string[]
  recipient_ids?: string[]
  is_active?: boolean
  webhook?: string
  slack_webhook?: string
  teams_webbhook?: string
  email_recipient_ids?: number[]
  phone_recipient_ids?: number[]
  whatsapp_recipient_ids?: number[]
}) => {
  await axios.get('/api/csrf-cookie')
  return axios.post('/api/deviceSchedules', schedule)
}

export const updateSchedule = async (
  id: number,
  schedule: {
    name?: string
    device_id?: number
    is_24_7?: boolean
    channels?: string[]
    start_time?: string
    end_time?: string
    days?: string[]
    recipient_ids?: string[]
    is_active?: boolean
    webhook?: string
    slack_webhook?: string
    teams_webbhook?: string
    email_recipient_ids?: number[]
    phone_recipient_ids?: number[]
    whatsapp_recipient_ids?: number[]
  },
) => {
  await axios.get('/api/csrf-cookie')
  return axios.put(`api/deviceSchedules/${id}`, schedule)
}

export const deleteSchedule = (id: number) => {
  return axios.delete(`api/deviceSchedules/${id}}`)
}
