import React, { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import { Button } from 'antd'
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteFilled,
} from '@ant-design/icons'

import Table from 'components/Table'
import { Avatar } from 'components/Shared'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalCreateRecipient from 'components/Modal/UserRecipient/CreateRecipient'
import ModalUpdateRecipient from 'components/Modal/UserRecipient/UpdateRecipient'
import ModalViewRecipientProfile from 'components/Modal/UserRecipient/ViewRecipientProfile'
import { notiError } from 'utils/notification'
import { formatPhoneNumber } from 'utils/functions'
import { deleteRecipient, getRecipients, Recipient } from 'services/recipients'
import { useAppSelector } from 'store'

import { useRecipientContext } from './RecipientContext'

interface Props {
  showAdd: boolean
  setShowAdd: (v: boolean) => void
}

function TableList({ showAdd, setShowAdd }: Props) {
  const { currentUser } = useAppSelector(state => state.auth)
  const { loading, recipients, currentPage, search, dispatch } =
    useRecipientContext()

  const [viewRecipient, setViewRecipient] = useState<Recipient | null>(null)
  const [editRecipient, setEditRecipient] = useState<Recipient | null>(null)
  const [deleteRecId, setDeleteRecId] = useState<number | null>(null)

  useEffect(() => {
    handleGetRecipients()
    // eslint-disable-next-line
  }, [search, currentPage])

  const handleGetRecipients = async () => {
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      const response = await getRecipients({
        currentPage,
        pageSize: 10,
        sort: 'id',
        sortDirection: 'desc',
        search,
      })
      dispatch({ type: 'SET_RECIPIENTS', payload: response.data })
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const handleDeleteRecipient = async () => {
    if (!deleteRecId) return
    try {
      dispatch({ type: 'SET_LOADING', payload: true })
      await deleteRecipient(deleteRecId)
      if (recipients.length === 1 && currentPage > 1) {
        return dispatch({ type: 'SET_CURRENT_PAGE', payload: currentPage - 1 })
      }
      handleGetRecipients()
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      dispatch({ type: 'SET_LOADING', payload: false })
    }
  }

  const renderRowActions = (r: Recipient) => (
    <React.Fragment>
      <Button className='ant-btn-ternary' onClick={() => setViewRecipient(r)}>
        View
      </Button>
      {currentUser && (!!currentUser.is_super || !!currentUser.is_admin) && (
        <Button className='ant-btn-ternary' onClick={() => setEditRecipient(r)}>
          Edit
        </Button>
      )}
      <Button
        className='ant-btn-ternary text-danger'
        onClick={() => setDeleteRecId(r.id)}
      >
        Delete
      </Button>
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Table
        loading={loading}
        empty={!recipients.length}
        className='table-recipient-management'
      >
        {recipients.map((r: Recipient) => (
          <Table.Row key={r.id} rowKey={r.id} rowActions={renderRowActions(r)}>
            <Table.Col>
              <div className='user-info-wrapper'>
                <Avatar size='large' username={r.name} />
                <div className='user-info'>
                  <div className='name'>{r.name}</div>
                  <div className='email'>
                    <span>Email</span>
                    <span>{r.email}</span>
                  </div>
                  <div className='email'>
                    <span>Phone number</span>
                    <span>{formatPhoneNumber(r.phone)}</span>
                  </div>
                  <div className='status'>
                    <span>Status</span>
                    <span
                      className={cx(
                        !!r.email_verified_at ? 'text-success' : 'text-warning',
                      )}
                    >
                      {!!r.email_verified_at ? (
                        <CheckCircleOutlined />
                      ) : (
                        <ClockCircleOutlined />
                      )}
                      {!!r.email_verified_at
                        ? 'Verified'
                        : 'Verification pending'}
                    </span>
                  </div>
                </div>
              </div>
            </Table.Col>
          </Table.Row>
        ))}
      </Table>
      <AnimatePresence>
        {showAdd && (
          <ModalCreateRecipient
            onSuccess={handleGetRecipients}
            onCancel={() => setShowAdd(false)}
          />
        )}
        {viewRecipient && (
          <ModalViewRecipientProfile
            recipient={viewRecipient}
            onCancel={() => setViewRecipient(null)}
          />
        )}
        {editRecipient && (
          <ModalUpdateRecipient
            recipient={editRecipient}
            onSuccess={handleGetRecipients}
            onCancel={() => setEditRecipient(null)}
          />
        )}
        {deleteRecId && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this recipient?'
            description='This recipient will no longer receive any notification after deleted'
            onConfirm={handleDeleteRecipient}
            onCancel={() => setDeleteRecId(null)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default TableList
