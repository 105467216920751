import React from 'react'
import { Button } from 'antd'

import BaseModal from './BaseModal'

interface Props {
  icon?: React.ReactNode
  title?: string
  description?: string
  onConfirm?: () => void
  onCancel: () => void
}

function ModalConfirm({
  icon,
  title,
  description,
  onConfirm,
  onCancel,
}: Props) {
  return (
    <BaseModal className='modal-confirm' onCancel={onCancel}>
      {icon && <div className='icon'>{icon}</div>}
      {title && <h4 className='title'>{title}</h4>}
      {description && <span className='description'>{description}</span>}
      {onConfirm && (
        <div className='actions'>
          <Button onClick={onCancel} size='large' block type='primary'>
            No
          </Button>
          <Button
            onClick={() => {
              onConfirm()
              onCancel()
            }}
            size='large'
            block
            className='ant-btn-secondary'
          >
            Yes
          </Button>
        </div>
      )}
    </BaseModal>
  )
}

export default ModalConfirm
