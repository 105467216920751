import { Redirect, Switch } from 'react-router-dom'

import Route from 'components/Route'
import { useScrollTop } from 'hooks'

// MAIN PAGES
import AppMenu from 'pages/AppMenu'
import Dashboard from 'pages/Dashboard'
import Livestream from 'pages/Livestream'
import Playback from 'pages/Playback'
import SystemNotifications from 'pages/SystemNotifications'
import DeviceManagement from 'pages/DeviceManagement'
import DeviceDetail from 'pages/DeviceDetail'
import Notifications from 'pages/Notifications'
import CloudStorage from 'pages/CloudStorage'
import UsersRecipients from 'pages/UsersRecipients'
import ActivityLogs from 'pages/ActivityLogs'
import TransactionHistory from 'pages/TransactionHistory'
import Settings from 'pages/Settings'

// AUTH PAGES
import UserProfile from 'pages/Auth/UserProfile'
import Login from 'pages/Auth/Login'
import Register from 'pages/Auth/Register'
import ResetPassword from 'pages/Auth/ResetPassword'
import ResendVerifyLink from 'pages/Auth/VerifyEmail/ResendVerifyLink'
import VerifyEmail from 'pages/Auth/VerifyEmail'

// EXTERNAL PAGES
import Embed from 'pages/Embed'
import VerifyRecipientEmail from 'pages/VerifyRecipientEmail'
import SharedFile from 'pages/SharedFlie'

// 404
import PageNotFound from 'pages/NotFound'

function App() {
  useScrollTop()

  return (
    <Switch>
      {/* MAIN PAGES */}
      <Route
        exact
        path="/menu"
        component={AppMenu}
        title="Menu | Broadflow Streams"
      />
      <Route
        exact
        path="/"
        component={Dashboard}
        title="Dashboard | Broadflow Streams"
      />
      <Route
        exact
        path="/livestream"
        component={Livestream}
        title="Live Streaming | Broadflow Streams"
      />
      <Route
        exact
        path="/playback"
        component={Playback}
        title="Playback | Broadflow Streams"
      />
      <Route
        exact
        path="/system-notifications"
        component={SystemNotifications}
        title="System Notifications | Broadflow Streams"
      />
      <Route
        exact
        path="/devices"
        component={DeviceManagement}
        title="Device Management | Broadflow Streams"
      />
      <Route
        exact
        path="/devices/:deviceId"
        component={DeviceDetail}
        title="Dashboard | Broadflow Streams"
      />
      <Route
        exact
        path="/notifications"
        component={Notifications}
        title="Notification Management | Broadflow Streams"
      />
      <Route
        exact
        path="/cloud-storage"
        component={CloudStorage}
        title="Cloud Storage & Video Search | Broadflow Streams"
      />
      <Route
        exact
        path="/users-recipients"
        component={UsersRecipients}
        title="User & Recipient Management | Broadflow Streams"
      />
      <Route
        exact
        path="/activity-logs"
        component={ActivityLogs}
        title="Activity Log | Broadflow Streams"
      />
      <Route
        exact
        path="/transaction-history"
        component={TransactionHistory}
        title="Transaction History | Broadflow Streams"
      />
      <Route
        path="/settings"
        component={Settings}
        title="Settings | Broadflow Streams"
      />

      {/* AUTH PAGES */}
      <Route
        exact
        path="/me"
        component={UserProfile}
        title="Dashboard | Broadflow Streams"
      />
      <Route
        exact
        path="/login"
        layout="BlankLayoutWithoutAuth"
        component={Login}
        title="Login | Broadflow Streams Cloud Video Surveillance"
      />
      <Route
        exact
        path="/register"
        layout="BlankLayoutWithoutAuth"
        component={Register}
        title="Register | Broadflow Streams Cloud Video Surveillance"
      />
      <Route
        exact
        path="/verify-email"
        layout="BlankLayoutWithAuth"
        component={ResendVerifyLink}
        title="Verify Email | Broadflow Streams Cloud Video Surveillance"
      />
      <Route
        exact
        path="/verify-email/:id/:hash"
        layout="BlankLayoutWithAuth"
        component={VerifyEmail}
        title="Verify Email | Broadflow Streams Cloud Video Surveillance"
      />
      <Route
        exact
        path="/reset-password"
        layout="BlankLayout"
        component={ResetPassword}
        title="Reset Password | Broadflow Streams Cloud Video Surveillance"
      />

      {/* EXTERNALS */}
      <Route
        exact
        path="/embed/:url"
        layout="BlankLayout"
        component={Embed}
        title="Broadflow Embedded Livestream"
      />
      <Route
        exact
        path="/recipient/verify-email/:id/:hash"
        layout="BlankLayoutWithAuth"
        component={VerifyRecipientEmail}
        title="Verify Recipient Email | Broadflow Streams Cloud Video Surveillance"
      />
      <Route
        exact
        path="/shared-file/:id"
        layout="BlankLayout"
        component={SharedFile}
        title="Broadflow View Shared File"
      />

      {/* 404 */}
      <Route
        exact
        path="/404"
        layout="BlankLayout"
        component={PageNotFound}
        title="Not Found | Broadflow Streams"
      />
      <Redirect to="/404" />
    </Switch>
  )
}

export default App
