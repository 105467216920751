import React from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import moment from 'moment'
import { CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons'

import './index.scss'
import Crown from 'assets/images/crown.svg'
import { ClockFilled, EmailFilled, UserFilled, Status } from 'components/Icons'
import { Layout, Avatar } from 'components/Shared'
import BaseModal from 'components/Modal/BaseModal'
import { MM_DD_YYYY } from 'utils/dateTime'
import { User } from 'services/users'

interface Props {
  user: User
  onCancel: () => void
}

function ViewUserProfile({ user, onCancel }: Props) {
  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-view-user-profile'
      onCancel={onCancel}
    >
      <Layout className='user-profile'>
        <Layout.Header sticky bordered goBack onGoBack={onCancel}>
          {user.name}
        </Layout.Header>
        <div
          className='profile-cover'
          style={{ backgroundImage: `url(${user.avatar_url})` }}
        />
        <Layout.Body>
          <Avatar
            src={user.avatar_url || undefined}
            username={user.name}
            fontSize='2rem'
          />
          <div className='user-info'>
            <div className='info'>
              <div>
                <EmailFilled color='#BABBBF' />
                <span className='text-500'>Email</span>
              </div>
              <div>{user.email}</div>
            </div>
            <hr className='divider' />
            <div className='info status'>
              <div>
                <UserFilled color='#BABBBF' />
                <span className='text-500'>User Role</span>
              </div>
              <div>
                {!!user.is_admin ? (
                  <React.Fragment>
                    <img src={Crown} alt='admin' />
                    Admin
                  </React.Fragment>
                ) : (
                  'Standard User'
                )}
              </div>
            </div>
            <hr className='divider' />
            <div className='info status'>
              <div>
                <Status color='#BABBBF' />
                <span className='text-500'>Status</span>
              </div>
              <div
                className={cx(
                  !!user.email_verified_at ? 'text-success' : 'text-warning',
                )}
              >
                {!!user.email_verified_at ? (
                  <CheckCircleOutlined />
                ) : (
                  <ClockCircleOutlined />
                )}
                {!!user.email_verified_at ? 'Verified' : 'Verification pending'}
              </div>
            </div>
            <hr className='divider' />
            <div className='info'>
              <div>
                <ClockFilled color='#BABBBF' />
                <span className='text-500'>Created At</span>
              </div>
              <div>{moment(user.created_at).format(MM_DD_YYYY)}</div>
            </div>
            <hr className='divider' />
          </div>
          <div className='view-activity-log'>
            <Link
              to={`activity-logs?user-id=${user.id}`}
              className='ant-btn ant-btn-primary ant-btn-lg ant-btn-block'
            >
              <ClockFilled color='#ffffff' />
              View Activity Log
            </Link>
          </div>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ViewUserProfile
