import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function CameraFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='15'
        height='13'
        viewBox='0 0 15 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M9.77014 7.01199C9.77014 8.32592 8.70127 9.39479 7.38734 9.39479C6.07341 9.39479 5.00494 8.32592 5.00494 7.01199C5.00494 5.69806 6.07341 4.62919 7.38734 4.62919C8.70127 4.62919 9.77014 5.69846 9.77014 7.01199ZM14.7747 3.62852V10.3963C14.7747 11.2985 14.0432 12.03 13.1409 12.03H1.63373C0.731504 12.03 0 11.2985 0 10.3963V3.62852C0 2.72629 0.731504 1.99479 1.63373 1.99479H3.64323V1.42952C3.64323 0.640015 4.28283 0 5.07274 0H9.70193C10.4918 0 11.1314 0.640015 11.1314 1.42952V1.99438H13.1409C14.0432 1.99479 14.7747 2.72629 14.7747 3.62852ZM10.9954 7.01199C10.9954 5.02251 9.37682 3.40388 7.38734 3.40388C5.39827 3.40388 3.77964 5.02251 3.77964 7.01199C3.77964 9.00147 5.39827 10.6201 7.38734 10.6201C9.37682 10.6201 10.9954 9.00147 10.9954 7.01199Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
