import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import moment, { Moment } from 'moment'
import { RangeValue } from 'rc-picker/lib/interface'
import { Button, Checkbox, TimePicker } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

import { Clock } from 'components/Icons'
import { getPopupContainer } from 'components/Modal/BaseModal'
import { days as DAYS } from 'utils/selectOptions'

interface Props {
  readOnly?: boolean
  is247?: boolean
  selectedDays?: string[]
  startTime?: string
  endTime?: string
  onChange?: (data: {
    is247: boolean
    selectedDays: string[]
    startTime: string
    endTime: string
  }) => void
  popupContainer?: string
}

export function DateTimeSelection({
  readOnly,
  is247,
  selectedDays,
  startTime,
  endTime,
  onChange,
  popupContainer,
}: Props) {
  const [isAllTime, setIsAllTime] = useState(is247 || false)
  const [days, setDays] = useState<string[]>(() => {
    return (selectedDays || []).map(
      d => d.slice(0, 1) + d.slice(1).toLowerCase(),
    )
  })
  const [start, setStart] = useState<Moment | null>(() => {
    if (startTime) {
      const [hour, minute] = startTime.split(':')
      return moment().set({ hour: +hour, minute: +minute })
    }
    return null
  })
  const [end, setEnd] = useState<Moment | null>(() => {
    if (endTime) {
      const [hour, minute] = endTime.split(':')
      return moment().set({ hour: +hour, minute: +minute })
    }
    return null
  })

  useEffect(() => {
    onChange &&
      onChange({
        is247: isAllTime,
        selectedDays: days,
        startTime: start?.format('HH:mm') || '',
        endTime: end?.format('HH:mm') || '',
      })
    // eslint-disable-next-line
  }, [isAllTime, start, end, days])

  const handleDayChange = (day: string) => {
    setDays(prev =>
      prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day],
    )
  }

  const handleTimeChange = (values: RangeValue<moment.Moment>) => {
    setStart(values![0])
    setEnd(values![1])
  }

  if (readOnly) {
    return (
      <div className='datetime-selection-wrapper read-only'>
        {isAllTime && (
          <Button className='btn-selection selected read-only'>
            24/7 (all the time)
          </Button>
        )}
        {!isAllTime && (
          <React.Fragment>
            <div className='days'>
              {DAYS.map(d => (
                <Button
                  key={d}
                  size='large'
                  disabled={!days.includes(d)}
                  className={cx('btn-selection read-only', {
                    selected: days.includes(d),
                  })}
                >
                  {d.slice(0, 3)}
                </Button>
              ))}
            </div>
            <hr className='divider' />
            <div className='times'>
              <Button size='large' className='btn-selection selected read-only'>
                <Clock type='primary' /> {start?.format('HH:mm A')}
              </Button>
              <ArrowRightOutlined />
              <Button size='large' className='btn-selection selected read-only'>
                <Clock type='primary' /> {end?.format('HH:mm A')}
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>
    )
  }

  return (
    <div className='datetime-selection-wrapper'>
      <Checkbox
        className='checkbox-large'
        checked={isAllTime}
        onChange={e => setIsAllTime(e.target.checked)}
      >
        24/7 (all the time)
      </Checkbox>
      {!isAllTime && (
        <React.Fragment>
          <hr className='divider' />
          <div className='days'>
            {DAYS.map(d => (
              <Button
                key={d}
                size='large'
                onClick={() => handleDayChange(d)}
                className={cx('btn-selection', { selected: days.includes(d) })}
              >
                {d.slice(0, 3)}
              </Button>
            ))}
          </div>
          <TimePicker.RangePicker
            inputReadOnly
            size='large'
            allowClear={false}
            className='time-picker'
            format='HH:mm'
            value={[start, end]}
            placeholder={['From', 'To']}
            getPopupContainer={popupContainer ? getPopupContainer : undefined}
            onChange={handleTimeChange}
          />
        </React.Fragment>
      )}
    </div>
  )
}
