import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import * as subscription from 'services/subscription'

export const getSubscription: any = createAsyncThunk(
  'auth/getSubscription',
  async () => {
    const responses = await subscription.getSubscriptionDetails()
    return responses
  },
)

interface State {
  loading: boolean
  error: boolean
  subscription: subscription.SubscriptionDetails | null
}

const initialState: State = {
  loading: false,
  error: false,
  subscription: null,
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: {
    [getSubscription.pending.type]: state => {
      state.loading = true
      state.error = false
    },
    [getSubscription.rejected.type]: state => {
      state.loading = false
      state.error = true
    },
    [getSubscription.fulfilled.type]: (state, { payload }) => {
      state.subscription = payload.data
      state.loading = false
    },
  },
})

export default subscriptionSlice.reducer
