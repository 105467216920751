import axios from './axios'
import { Device } from './devices'

export interface VideoRecord {
  device: Device
  device_id: number
  end_timestamp: string
  expiry: string
  failed_reason: string | null
  file_urls: string[] | null
  files: { path: string; size: number }[] | null
  id: number
  start_timestamp: string
  status: string
  user_id: number
}

export const getVideoRecords = ({
  sort,
  sortDirection,
  pageSize,
  currentPage,
}: {
  sort: string
  sortDirection: string
  pageSize: number
  currentPage: number
}) => {
  return axios.get(
    `/api/recordingDownloads?include=device&order_by=${sort}&sort=${sortDirection}&limit=${pageSize}&page=${currentPage}`,
  )
}

export const searchRecord = async (data: {
  device_id: number
  start_timestamp: string
  end_timestamp: string
}) => {
  await axios.get('/api/csrf-cookie')
  return axios.post('/api/recordingDownloads', data)
}
