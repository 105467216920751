import { useState } from 'react'
import { Form, Input, Button } from 'antd'

import history from 'configs/history'
import { Layout } from 'components/Shared'
import EmailNotif from 'assets/images/email-notif.svg'
import { notiError } from 'utils/notification'
import { forgotPassword } from 'services/auth'

function FormSendResetLink() {
  const [loading, setLoading] = useState(false)
  const [sendSuccess, setSendSuccess] = useState(false)

  const handleSubmit = async ({ email }: { email: string }) => {
    setLoading(true)
    try {
      await forgotPassword(email)
      setSendSuccess(true)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  if (sendSuccess) {
    return (
      <Layout className='recover-password-page'>
        <div className='success-message'>
          <img src={EmailNotif} alt='recover-password' />
          <h3>Password reset link sent</h3>
          <span>Please click the link received to reset your password</span>
          <Button
            type='primary'
            block
            size='large'
            onClick={() => history.push('/login')}
          >
            Back to login
          </Button>
        </div>
      </Layout>
    )
  }

  return (
    <Layout className='recover-password-page'>
      <Layout.Header bordered goBack>
        Recover Password
      </Layout.Header>
      <Layout.Body fullHeight>
        <Form onFinish={handleSubmit} className='form-recovery-password'>
          <h4>Please enter your email address to receive a password reset link</h4>
          <Form.Item
            name='email'
            rules={[
              { required: true, message: 'This field is required' },
              { type: 'email', message: 'Invalid email address' },
            ]}
          >
            <Input size='large' placeholder='Enter your email address' />
          </Form.Item>
          <Button
            disabled={loading}
            htmlType='submit'
            type='primary'
            block
            size='large'
          >
            Continue
          </Button>
        </Form>
      </Layout.Body>
    </Layout>
  )
}

export default FormSendResetLink
