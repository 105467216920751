import cx from 'classnames'
import React from 'react'

import { DEFAULT_TAG_COLOR } from 'configs/tagColors'

interface Props {
  className?: string
  strokeColor?: string | null
  textColor?: string | null
  color?: string | null
  onClick?: React.MouseEventHandler
}

export const Tag: React.FC<Props> = ({
  children,
  strokeColor,
  textColor,
  color,
  className,
  onClick,
}) => {
  const tagColor = {
    color: color || DEFAULT_TAG_COLOR.color,
    strokeColor: strokeColor || DEFAULT_TAG_COLOR.strokeColor,
    textColor: textColor || DEFAULT_TAG_COLOR.textColor,
  }

  return (
    <div
      className={cx('tag', { clickable: !!onClick }, className)}
      style={{ background: tagColor.strokeColor }}
      onClick={onClick ? onClick : undefined}
    >
      <span style={{ background: tagColor.color, color: tagColor.textColor }}>
        {children}
      </span>
    </div>
  )
}
