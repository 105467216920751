import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import { AnimatePresence } from 'framer-motion'
import { Tabs } from 'antd'
import { DeleteFilled, MoreOutlined } from '@ant-design/icons'

import './index.scss'
import { Layout, Spinner } from 'components/Shared'
import ModalConfirm from 'components/Modal/ModalConfirm'
import ModalUpdateDevice from 'components/Modal/Device/UpdateDevice'
import { notiError } from 'utils/notification'
import { Device, getDeviceDetails, deleteDevice } from 'services/devices'
import { useAppDispatch } from 'store'
import {
  updateCamlicenseCount,
  updateDeviceCount,
} from 'store/slice/statsSlice'

import ModalOptions from './ModalOptions'
import DeviceDetails from './DeviceDetails'
import DeviceSchedules from './DeviceSchedules'
import InboundNotifications from './InboundNotifications'
import VideoVault from './VideoVault'
import ActivityLog from './ActivityLog'

function DeviceDetail() {
  const { location, push, replace } = useHistory()
  const { deviceId } = useParams<{ deviceId: string }>()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('')
  const [device, setDevice] = useState<Device | null>(null)

  const [showOptions, setShowOptions] = useState(false)
  const [, setShowLivestream] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  useEffect(() => {
    const tab = location.search.replace('?tab=', '') || 'device-details'
    setActiveTab(tab)
  }, [location.search])

  useEffect(() => {
    if (deviceId && !isNaN(+deviceId)) {
      handleGetDeviceDetails()
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleGetDeviceDetails = async () => {
    setLoading(true)
    try {
      const response = await getDeviceDetails(+deviceId)
      setDevice(response.data.data)
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const handleTabChange = (activeKey: string) => {
    replace(location.pathname + '?tab=' + activeKey)
  }

  const handleDeleteDevice = async () => {
    try {
      setLoading(true)
      await deleteDevice(device!.id)
      dispatch(updateDeviceCount(-1))
      if (device!.is_licensed) {
        dispatch(updateCamlicenseCount(-1))
      }
      push('/devices?tab=device-list')
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
      setLoading(false)
    } finally {
    }
  }

  if (loading) {
    return <Spinner height='90vh' />
  }

  if (!device) {
    return <Redirect to='/404' />
  }

  return (
    <React.Fragment>
      <Layout className='page-device-detail'>
        <Layout.Header
          goBack
          actionRight={<MoreOutlined onClick={() => setShowOptions(true)} />}
        >
          <div>{device.name}</div>
          <div className='sub-header'>{!!device.is_licensed && 'Licensed'}</div>
        </Layout.Header>
        <Layout.Body>
          <Tabs
            style={{ overflow: 'unset' }}
            type='card'
            activeKey={activeTab}
            onChange={handleTabChange}
          >
            <Tabs.TabPane tab='Device Details' key='device-details'>
              <DeviceDetails
                device={device}
                onRefresh={handleGetDeviceDetails}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Device Schedules' key='device-schedules'>
              <DeviceSchedules deviceId={device.id} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab='Inbound Notifications'
              key='inbound-notifications'
            >
              <InboundNotifications deviceId={device.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Video Vault' key='video-vault'>
              <VideoVault deviceId={device.id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab='Activity Log' key='activity-log'>
              <ActivityLog deviceId={device.id} />
            </Tabs.TabPane>
          </Tabs>
        </Layout.Body>
      </Layout>
      <AnimatePresence>
        {showOptions && (
          <ModalOptions
            onLivestream={() => setShowLivestream(true)}
            onEdit={() => setShowEdit(true)}
            onDelete={() => setShowConfirm(true)}
            onCancel={() => setShowOptions(false)}
          />
        )}
        {showEdit && (
          <ModalUpdateDevice
            device={device}
            onSuccess={handleGetDeviceDetails}
            onCancel={() => setShowEdit(false)}
          />
        )}
        {showConfirm && (
          <ModalConfirm
            icon={<DeleteFilled className='text-danger' />}
            title='Are you sure you want to delete this device?'
            description='Action can’t be undone'
            onConfirm={handleDeleteDevice}
            onCancel={() => setShowConfirm(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default DeviceDetail
