import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function SupportFilled({ color, type, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c

  return (
    <SVGUniqueID>
      <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M15.0002 10.4746C15.0002 8.73729 14.0036 7.18681 12.5109 6.43262C12.4645 9.76364 9.76389 12.4643 6.43286 12.5106C7.18706 14.0033 8.73753 15 10.4748 15C11.2894 15 12.0815 14.783 12.7775 14.3708L14.9805 14.9802L14.3711 12.7772C14.7833 12.0812 15.0002 11.2891 15.0002 10.4746Z'
          fill='url(#paint0_linear)'
        />
        <path
          d='M11.6914 6.3457C11.6914 3.39803 9.29338 1 6.3457 1C3.39803 1 1 3.39803 1 6.3457C1 7.30637 1.25571 8.24171 1.74127 9.06288L1.01965 11.6716L3.62852 10.9501C4.44969 11.4357 5.38504 11.6914 6.3457 11.6914C9.29338 11.6914 11.6914 9.29338 11.6914 6.3457ZM5.52539 5.10156H4.70508C4.70508 4.19687 5.44101 3.46094 6.3457 3.46094C7.2504 3.46094 7.98633 4.19687 7.98633 5.10156C7.98633 5.56075 7.79193 6.00209 7.4528 6.31227L6.75586 6.95015V7.58984H5.93555V6.58891L6.89899 5.70708C7.07117 5.54953 7.16602 5.33452 7.16602 5.10156C7.16602 4.64922 6.79805 4.28125 6.3457 4.28125C5.89336 4.28125 5.52539 4.64922 5.52539 5.10156ZM5.93555 8.41016H6.75586V9.23047H5.93555V8.41016Z'
          fill='url(#paint1_linear)'
        />
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='6.43286'
            y1='6.43262'
            x2='6.43286'
            y2='15'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id='paint1_linear'
            x1='1'
            y1='1'
            x2='1'
            y2='11.6914'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
