import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import General from './General'
import Notifications from './Notifications'
import Checkout from './PaymentSettings/Checkout'
import CurrentPlan from './PaymentSettings/CurrentPlan'
import PlanList from './PaymentSettings/PlanList'
import SecurityLogin from './SecurityLogin'

function Settings() {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/general`} component={General} />
      <Route exact path={`${path}/security-login`} component={SecurityLogin} />
      <Route exact path={`${path}/payment-settings`} component={CurrentPlan} />
      <Route
        exact
        path={`${path}/payment-settings/plans`}
        component={PlanList}
      />
      <Route
        exact
        path={`${path}/payment-settings/checkout`}
        component={Checkout}
      />
      <Route exact path={`${path}/notifications`} component={Notifications} />
      <Redirect to={`${path}/general`} />
    </Switch>
  )
}

export default Settings
