import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function Location({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='9'
        height='12'
        viewBox='0 0 9 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 4.5325C0 2.033 2.019 0 4.5 0C6.981 0 9 2.033 9 4.5325C9 8.084 4.923 11.751 4.7495 11.9055C4.678 11.9685 4.589 12 4.5 12C4.411 12 4.322 11.9685 4.2505 11.905C4.077 11.751 0 8.084 0 4.5325ZM1.99985 4.50001C1.99985 5.87851 3.12135 7.00001 4.49985 7.00001C5.87835 7.00001 6.99985 5.87851 6.99985 4.50001C6.99985 3.1215 5.87835 2 4.49985 2C3.12135 2 1.99985 3.1215 1.99985 4.50001Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
