import { SVGUniqueID } from 'react-svg-unique-id'

import { ICON_GRADIENTS } from 'configs/iconColors'

interface Props {
  color?: [start: string, stop: string] | string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  fillId?: [first: string, last: string]
  [key: string]: any
}

export function BellFilled({ color, type, fillId, ...props }: Props) {
  const c = color || ICON_GRADIENTS[type || 'secondary']
  const selectedColor = typeof c === 'string' ? [c, c] : c
  const fillIds = fillId || ['bell1_linear', 'bell2_linear']

  return (
    <SVGUniqueID>
      <svg
        width='18'
        height='20'
        viewBox='0 0 18 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M16.8159 14.0942C15.5559 13.0292 14.8334 11.4725 14.8334 9.82333V7.5C14.8334 4.5675 12.6551 2.14 9.83342 1.73333V0.833333C9.83342 0.3725 9.46008 0 9.00008 0C8.54008 0 8.16675 0.3725 8.16675 0.833333V1.73333C5.34425 2.14 3.16675 4.5675 3.16675 7.5V9.82333C3.16675 11.4725 2.44425 13.0292 1.17675 14.1008C0.852581 14.3783 0.666748 14.7817 0.666748 15.2083C0.666748 16.0125 1.32091 16.6667 2.12508 16.6667H15.8751C16.6793 16.6667 17.3334 16.0125 17.3334 15.2083C17.3334 14.7817 17.1476 14.3783 16.8159 14.0942Z'
          fill={`url(#${fillIds[0]})`}
        />
        <path
          d='M9.00014 20C10.5093 20 11.7718 18.9242 12.0618 17.5H5.93848C6.22848 18.9242 7.49098 20 9.00014 20Z'
          fill={`url(#${fillIds[1]})`}
        />
        <defs>
          <linearGradient
            id={fillIds[0]}
            x1='0.666748'
            y1='0'
            x2='0.666748'
            y2='16.6667'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
          <linearGradient
            id={fillIds[1]}
            x1='5.93848'
            y1='17.5'
            x2='5.93848'
            y2='20'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor={selectedColor[0]} />
            <stop offset='1' stopColor={selectedColor[1]} />
          </linearGradient>
        </defs>
      </svg>
    </SVGUniqueID>
  )
}
