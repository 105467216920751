import { useEffect, useState } from 'react'
import moment, { Moment } from 'moment'
import cx from 'classnames'
import { Button, DatePicker, notification } from 'antd'

import { Layout, Spinner } from 'components/Shared'
import BaseModal, { getPopupContainer } from 'components/Modal/BaseModal'
import { notiError } from 'utils/notification'
import { MM_DD_YYYY, YYYY_MM_DD } from 'utils/dateTime'
import { getTags, Tag } from 'services/tags'

interface Props {
  dateRange?: string[]
  tags?: Tag[]
  onChange?: (filters: { dateRange?: string[]; tags?: Tag[] }) => void
  onCancel: () => void
}

function ModalFilterInboundNotifs({
  dateRange = [],
  tags: pTags = [],
  onChange,
  onCancel,
}: Props) {
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState<Tag[]>([])
  const [selectedTags, setSelectedTags] = useState<Tag[]>(pTags)
  const [from, setFrom] = useState(() =>
    dateRange[0] ? moment(dateRange[0]) : null,
  )
  const [to, setTo] = useState(() =>
    dateRange[1] ? moment(dateRange[0]) : null,
  )

  useEffect(() => {
    handleGetTags()
    // eslint-disable-next-line
  }, [])

  const handleGetTags = async () => {
    try {
      setLoading(true)
      const response = await getTags({ pageSize: 10000, currentPage: 1 })
      setTags(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const handleFilter = (isReset = false) => {
    notification.destroy()
    const range: Moment[] = []
    if (!!from) range.push(from)
    if (!!to) range.push(to)
    if (
      range.length === 1 ||
      (range.length === 2 && range[1].diff(range[0]) < 0)
    ) {
      return notiError({ message: 'Error', description: 'Invalid duration' })
    }

    onChange &&
      onChange(
        isReset
          ? {}
          : {
              dateRange:
                range.length === 2
                  ? [range[0].format(YYYY_MM_DD), range[1].format(YYYY_MM_DD)]
                  : undefined,
              tags: !!selectedTags.length ? selectedTags : undefined,
            },
      )
    onCancel()
  }

  const handleTagSelect = (tag: Tag) => {
    setSelectedTags(prev =>
      !!prev.find(p => p.id === tag.id)
        ? prev.filter(p => p.id !== tag.id)
        : [...prev, tag],
    )
  }

  const renderTags = () => {
    return tags.map(t => (
      <Button
        key={t.id}
        size='large'
        onClick={() => handleTagSelect(t)}
        className={cx('btn-selection', {
          selected: !!selectedTags.find(pt => pt.id === t.id),
        })}
      >
        #{t.name}
      </Button>
    ))
  }

  return (
    <BaseModal
      noPadding
      className='modal-filter-inbound-notifs'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          bordered
          rounded
          goBack
          onGoBack={onCancel}
          actionRight={
            <span
              className='text-primary text-500 hoverable'
              onClick={() => handleFilter(true)}
            >
              Reset Filter
            </span>
          }
        >
          Quick Filters
        </Layout.Header>
        <Layout.Body>
          <h4>Duration</h4>
          <div className='date-picker-group'>
            <label className='text-500'>From</label>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={from}
              onChange={v => setFrom(v)}
              placeholder='From'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <hr className='divider' style={{ margin: '1rem 0' }} />
          <div className='date-picker-group'>
            <label className='text-500'>To</label>
            <DatePicker
              inputReadOnly
              showToday={false}
              size='large'
              value={to}
              onChange={v => setTo(v)}
              placeholder='To'
              format={MM_DD_YYYY}
              getPopupContainer={getPopupContainer}
            />
          </div>
          <div className='divider' />
          <h4>Tags</h4>
          {loading && <Spinner />}
          {!loading && !!tags.length && (
            <div className='tag-list'>{renderTags()}</div>
          )}
          <Button
            size='large'
            block
            type='primary'
            onClick={() => handleFilter()}
          >
            Apply
          </Button>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalFilterInboundNotifs
