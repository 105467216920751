import { useEffect, useState } from 'react'

import './index.scss'
import { Spinner } from 'components/Shared'
import { getSharedVaultFile, SharedVaultFile } from 'services/vault'
import { notiError } from 'utils/notification'
import { Redirect } from 'react-router'

interface Props {
  id: number
  email: string
  signature: string
}

function ViewSharedFile({ id, email, signature }: Props) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<SharedVaultFile | null>(null)

  useEffect(() => {
    handleGetSharedVaultFile()
    // eslint-disable-next-line
  }, [])

  const handleGetSharedVaultFile = async () => {
    try {
      const response = await getSharedVaultFile(id, email, signature)
      setData(response.data.data)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Spinner height='100vh' />
  }

  if (!data) {
    return <Redirect to='/404' />
  }

  return (
    <div className='shared-vault-file'>
      <video controls muted src={data.file_url} />
    </div>
  )
}

export default ViewSharedFile
