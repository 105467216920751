import { useState } from 'react'
import { Button, Form, Input } from 'antd'

import { Layout } from 'components/Shared'
import { Location, createLocation, updateLocation } from 'services/locations'
import { notiError, notiSuccess } from 'utils/notification'
import { useAppSelector } from 'store'

import BaseModal from '../BaseModal'

interface Props {
  location?: Location
  onSuccess?: () => void
  onCancel: () => void
}

interface FormValues {
  name: string
  zipcode?: string
}

const rules = [{ required: true, message: 'This field is required' }]

function ModalCreateUpdateLocation({ location, onSuccess, onCancel }: Props) {
  const [loading, setLoading] = useState(false)
  const { account_id } = useAppSelector(state => state.auth.currentUser)!
  const isUpdate = !!location && location.id

  const handleSubmit = async (values: FormValues) => {
    setLoading(true)
    try {
      if (isUpdate) {
        await updateLocation(location.id, { ...values, account_id })
      } else {
        await createLocation({ ...values, account_id })
      }
      notiSuccess({
        message: `${values.name} was ${isUpdate ? 'updated' : 'created'}`,
      })
      setLoading(false)
      onCancel()
      onSuccess?.()
    } catch (err: any) {
      setLoading(false)
      const { message, errors } = err
      notiError({ message, description: errors })
    }
  }

  return (
    <BaseModal
      noPadding
      height='100vh'
      className='modal-create-update-location'
      onCancel={onCancel}
    >
      <Layout>
        <Layout.Header
          sticky
          bordered
          goBack
          onGoBack={loading ? () => {} : onCancel}
        >
          {isUpdate ? 'Update Location' : 'Create Location'}
        </Layout.Header>
        <Layout.Body>
          <Form
            layout='vertical'
            initialValues={{ name: location?.name, zipcode: location?.zipcode }}
            onFinish={handleSubmit}
          >
            <Form.Item label='Location Name' name='name' rules={rules}>
              <Input size='large' placeholder='Name the location' />
            </Form.Item>
            <Form.Item label='Zipcode' name='zipcode'>
              <Input size='large' placeholder='Enter location zipcode' />
            </Form.Item>
            <Button
              disabled={loading}
              htmlType='submit'
              size='large'
              block
              type='primary'
            >
              Save
            </Button>
          </Form>
        </Layout.Body>
      </Layout>
    </BaseModal>
  )
}

export default ModalCreateUpdateLocation
