import CloudStorage from 'assets/images/cloud-storage.svg'

import StatisticsCard from 'components/Cards/StatisticsCard'
import { AccessDenied, Layout } from 'components/Shared'
import { useRestrictRoute } from 'hooks'
import { useAppSelector } from 'store'

import TableListHeader from './TableListHeader'
import TableList from './TableList'

function MyVault() {
  const { stats } = useAppSelector(state => state.stats)
  const { canAccess, message } = useRestrictRoute()

  return (
    <Layout className='vault-storage'>
      <Layout.Header goBack bordered>
        My Vault
      </Layout.Header>
      {!canAccess && !!message && (
        <Layout.Body>
          <AccessDenied {...message} />
        </Layout.Body>
      )}
      {canAccess && (
        <Layout.Body>
          <div className='vault-stats'>
            <StatisticsCard
              imgSrc={CloudStorage}
              title='My Vault Storage'
              content={`${stats?.vault.used}/${stats?.vault.total}GB`}
            />
          </div>
          <TableListHeader />
          <TableList />
        </Layout.Body>
      )}
    </Layout>
  )
}

export default MyVault
