import { useEffect, useState } from 'react'
import { Radio } from 'antd'
import moment from 'moment'
import ReactApexChart from 'react-apexcharts'

import { ArrowFall, ArrowRise, InfoCircle } from 'components/Icons'
import AsyncSelect from 'components/AsyncSelect'
import { Spinner } from 'components/Shared'
import { HH_MM_A, MMM_DD_YYYY } from 'utils/dateTime'
import { notiError } from 'utils/notification'
import { getDevices, Device } from 'services/devices'
import {
  getTodayInboundNotis,
  getTodayOutboundNotis,
} from 'services/notifications'

const options = {
  colors: ['#FFC123'],
  chart: {
    height: 300,
    toolbar: {
      show: false,
    },
  },
  grid: {
    borderColor: 'rgba(0, 0, 0, 0.25)',
    strokeDashArray: 5,
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
  tooltip: {
    enabled: true,
    custom({ series, _, dataPointIndex }: any) {
      return `
        <div class="chart-tooltip custom-tooltip">
          <span>${moment()
            .hour(dataPointIndex)
            .minute(0)
            .format(`${MMM_DD_YYYY} | ${HH_MM_A}`)}</span>
          <span>Notifications: ${series[0][dataPointIndex]}</span>
        </div>
      `
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: Array.from({ length: 24 }).map((_, idx) =>
      idx % 3 === 0 ? idx : idx === 23 ? 23 : '',
    ),
    labels: {
      style: {
        colors: '#fff',
        fontSize: '12px',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#fff',
        fontSize: '12px',
      },
      formatter(val: number) {
        return val.toFixed(0)
      },
    },
  },
}

function DeviceNotification() {
  const [notiType, setNotiType] = useState('inbound')
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<number[]>([])

  useEffect(() => {
    getTodayNotiCount()
    // eslint-disable-next-line
  }, [notiType, selectedId])

  const getTodayNotiCount = async () => {
    setLoading(true)
    setData([])
    try {
      let promise: any
      if (notiType === 'inbound') {
        promise = getTodayInboundNotis
      } else {
        promise = getTodayOutboundNotis
      }
      const response = await promise(selectedId)
      setData(Object.values(response.data.data))
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setLoading(false)
    }
  }

  const handleGetDevices = async ({ search = '', page = 1 } = {}) => {
    try {
      const response = await getDevices({
        search,
        currentPage: page,
      })
      return {
        data: response.data.data,
        totalPage: response.data.meta.last_page,
      }
    } catch {
      return {
        data: [],
        totalPage: 1,
      }
    }
  }

  const mapDevices = (device: Device[]) => {
    return device.map(d => ({ value: d.id, label: d.name, device: d }))
  }

  const calcTotalNoti = () => {
    return data.reduce((acc, cur) => (acc += cur), 0)
  }

  return (
    <div className='device-notification'>
      <h3>Device Notification ({moment().format(MMM_DD_YYYY)})</h3>
      <AsyncSelect
        allowClear
        size='large'
        fetcher={handleGetDevices}
        handleSearch={handleGetDevices}
        handleLoadMore={handleGetDevices}
        mapOptions={mapDevices}
        onChange={id => setSelectedId(id)}
        placeholder='All Devices'
      />
      <div className='notif-summary'>
        <Radio.Group
          size='large'
          value={notiType}
          onChange={e => setNotiType(e.target.value)}
        >
          <Radio.Button value='inbound'>
            <ArrowFall /> Inbound
          </Radio.Button>
          <Radio.Button value='outbound'>
            <ArrowRise /> Outbound
          </Radio.Button>
        </Radio.Group>
        <div className='summary-title'>
          <InfoCircle color='#ffffff' />
          <span>
            The below data represents all notifications sent as of{' '}
            {moment().format(MMM_DD_YYYY)}
          </span>
        </div>
        <div className='total-today-notif'>
          <h4>Total Notification Today</h4>
          <span>{loading ? '---' : calcTotalNoti()}</span>
        </div>
        <div className='notif-chart' style={{ height: 300 }}>
          {loading ? (
            <Spinner />
          ) : (
            <ReactApexChart
              series={[{ data }]}
              type='bar'
              height={300}
              options={{
                ...options,
                yaxis: { ...options.yaxis, show: !data.every(d => d === 0) },
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DeviceNotification
