import './index.scss'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'

import CreditCard from 'components/Cards/CreditCard'
import { CreditCardFilled } from 'components/Icons'
import { Spinner } from 'components/Shared'
import { Voucher } from 'services/subscription'
import { useAppSelector } from 'store'
import { notiError } from 'utils/notification'

import CheckoutWithDefaultCard from './CheckoutWithDefaultCard'
import CheckoutWithNewCard from './CheckoutWithNewCard'

interface Props {
  loading: boolean
  voucher?: Voucher
  onVoucherChange?: (code: string) => void
  onSubmit: (payload?: { paymentId: string; voucher?: Voucher }) => void
}

function CheckoutForm(props: Props) {
  const { subscription } = useAppSelector(state => state.subscription)
  const { config } = useAppSelector(state => state.appConfig)

  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [initLoading, setInitLoading] = useState(true)
  const [useDefaultCard, setUseDefaultCard] = useState(!!subscription?.card)

  useEffect(() => {
    initStripe()
    // eslint-disable-next-line
  }, [])

  const initStripe = async () => {
    try {
      setInitLoading(true)
      const stripe = await loadStripe(config!.stripe_key)
      setStripe(stripe)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ message, description: errors })
    } finally {
      setInitLoading(false)
    }
  }

  if (initLoading) {
    return <Spinner />
  }
  if (!stripe) {
    return null
  }

  return (
    <Elements stripe={stripe}>
      <div className='checkout-form'>
        <div className='form-inner'>
          <h3 className='form-title'>
            <CreditCardFilled type='primary' /> Enter payment detail
          </h3>

          <div className='form-group'>
            <label style={{ marginBottom: 12 }}>
              Choose bank account to check out
            </label>
            <div style={{ display: 'flex', gap: 8 }}>
              <CreditCard
                active={!useDefaultCard}
                text={<span className='text-primary text-500'>New Card</span>}
                onClick={() => setUseDefaultCard(false)}
              />
              {!!subscription?.card && (
                <CreditCard
                  active={useDefaultCard}
                  brand={subscription.card.brand}
                  text={subscription.card.last_4.padStart(12, '*')}
                  onClick={() => setUseDefaultCard(true)}
                />
              )}
            </div>
          </div>

          {useDefaultCard ? (
            <CheckoutWithDefaultCard {...props} />
          ) : (
            <CheckoutWithNewCard {...props} />
          )}

          <div className='text-placeholder auto-renewed'>
            Plan will be automatically renewed each month
          </div>
        </div>
      </div>
    </Elements>
  )
}

export default CheckoutForm
