import { useState } from 'react'
import cx from 'classnames'
import { PlusOutlined } from '@ant-design/icons'

import Checked from 'assets/images/checked.svg'
import { VideoCameraFilled } from 'components/Icons'

import { Layout } from 'components/Shared'
import StatisticsCard from 'components/Cards/StatisticsCard'
import { useAppSelector } from 'store'

import TableListHeader from './TableListHeader'
import TableList from './TableList'
import { useDeviceContext } from './DeviceListContext'

function DeviceList() {
  const [showAdd, setShowAdd] = useState(false)

  const { loading } = useDeviceContext()
  const { stats } = useAppSelector(state => state.stats)

  return (
    <Layout className='device-management'>
      <Layout.Header
        goBack
        bordered
        actionRight={
          <PlusOutlined
            disabled={loading}
            className={cx({ hoverable: !loading })}
            onClick={!loading ? () => setShowAdd(true) : undefined}
          />
        }
      >
        All Devices
      </Layout.Header>
      <Layout.Body>
        <div className='device-stats'>
          <StatisticsCard
            icon={<VideoCameraFilled type='primary' />}
            title='Devices'
            content={`${stats?.devices.available || stats?.devices.used || 0}/${
              stats?.devices.total || 0
            }`}
          />
          <StatisticsCard
            imgSrc={Checked}
            title='Camera Licenses'
            content={`${
              stats?.camera_licenses.available ||
              stats?.camera_licenses.used ||
              0
            }/${stats?.camera_licenses.total || 0}`}
          />
        </div>
        <TableListHeader />
        <TableList showAdd={showAdd} setShowAdd={setShowAdd} />
      </Layout.Body>
    </Layout>
  )
}

export default DeviceList
