import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Button } from 'antd'

import { PencilFilled } from 'components/Icons'
import { Device } from 'services/devices'
import ModalUpdateDevice from 'components/Modal/Device/UpdateDevice'

interface Props {
  device: Device
  onRefresh: () => void
}

function DeviceDetails({ device, onRefresh }: Props) {
  const [showEdit, setShowEdit] = useState(false)
  const [showContent, setShowContent] = useState(false)

  return (
    <React.Fragment>
      <div className='device-stats'>
        <div className='stat-title'>
          <h3>Device Details</h3>
          <Button
            className='ant-btn-secondary lighter'
            onClick={() => setShowEdit(true)}
          >
            <PencilFilled /> Edit
          </Button>
        </div>
        <div className='stat-item'>
          <span>Device Name</span>
          <span>{device.name}</span>
        </div>
        <div className='stat-item'>
          <span>Device Type</span>
          <span>{device.type}</span>
        </div>
        <div className='stat-item'>
          <span>RTSP Link</span>
          <span>
            {!showContent ? '******' : device.stream_url || '---'}
            <p
              className='text-underline hoverable'
              onClick={() => setShowContent(prev => !prev)}
            >
              {!showContent ? 'Show' : 'Hide'}
            </p>
          </span>
        </div>
        <div className='stat-item'>
          <span>Device Timezone</span>
          <span>{device.timezone}</span>
        </div>
        <div className='stat-item'>
          <span>Manufacturer</span>
          <span>{device.manufacturer || '---'}</span>
        </div>
        <div className='stat-item'>
          <span>Device IP Address</span>
          <span>{device.ip_address || '---'}</span>
        </div>
        <div className='stat-item'>
          <span>Device Location</span>
          <span>
            {device.location?.name ? (
              <Link to='/devices?tab=device-location'>
                {device.location.name}
              </Link>
            ) : (
              '---'
            )}
          </span>
        </div>
      </div>
      <AnimatePresence>
        {showEdit && (
          <ModalUpdateDevice
            device={device}
            onSuccess={onRefresh}
            onCancel={() => setShowEdit(false)}
          />
        )}
      </AnimatePresence>
    </React.Fragment>
  )
}

export default DeviceDetails
