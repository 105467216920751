import React, { useState, useMemo, useEffect } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { isNumber } from 'lodash'
import { Button, Tooltip } from 'antd'

import MicrosoftTeam from 'assets/brand-logos/microsoft-team.svg'
import Slack from 'assets/brand-logos/slack.svg'
import Webhook from 'assets/brand-logos/webhook.svg'
import WhatsApp from 'assets/brand-logos/whatsapp.svg'

import { EmailFilled, SmsFilled } from 'components/Icons'
import { useAppSelector } from 'store'

type Stats = {
  enabled?: boolean
  total: number
  available: number
  used: number
}

interface Props {
  readOnly?: boolean
  selectedChannels?: string[]
  onChange?: (channels: string[]) => void
  popupContainer?: () => HTMLElement
}

export function ChannelSelection({
  readOnly,
  onChange,
  selectedChannels: s,
  popupContainer,
}: Props) {
  const { stats } = useAppSelector(state => state.stats)
  const [selectedChannels, setSelectedChannels] = useState(s || [])

  useEffect(() => {
    onChange && onChange(selectedChannels)
    // eslint-disable-next-line
  }, [selectedChannels])

  const canSelect = (channel: 'sms' | 'whatsapp' | 'email') => {
    const { total, enabled, available, used } = stats![channel] as Stats
    if (enabled !== undefined) return !!enabled
    if (isNumber(available)) return total > 0
    return total > used
  }

  const channels = useMemo(() => {
    return [
      {
        label: 'SMS',
        value: 'phone',
        disabled: !canSelect('sms'),
        Icon: SmsFilled,
      },
      {
        label: 'WhatsApp',
        value: 'whatsapp',
        disabled: !canSelect('whatsapp'),
        imgSrc: WhatsApp,
      },
      {
        label: 'Email',
        value: 'email',
        disabled: !canSelect('email'),
        Icon: EmailFilled,
      },
      {
        label: 'Slack',
        value: 'slack',
        disabled: !stats?.slack_enabled,
        imgSrc: Slack,
      },
      {
        label: 'Webhook',
        value: 'webhook',
        disabled: !stats?.webhook_enabled,
        imgSrc: Webhook,
      },
      {
        label: 'Microsoft Teams',
        value: 'microsoft_teams',
        disabled: !stats?.teams_enabled,
        imgSrc: MicrosoftTeam,
      },
    ]
    // eslint-disable-next-line
  }, [stats])

  const handleSelectChannel = (c: string) => {
    setSelectedChannels(prev =>
      prev.includes(c) ? prev.filter(p => p !== c) : [...prev, c],
    )
  }

  const renderChannel = (c: typeof channels[0]) => {
    const disabled = readOnly ? !selectedChannels.includes(c.value) : c.disabled
    const Channel = (
      <Button
        size='large'
        key={c.value}
        disabled={disabled}
        onClick={
          readOnly
            ? undefined
            : !disabled
            ? () => handleSelectChannel(c.value)
            : undefined
        }
        className={cx('btn-selection', {
          selected: selectedChannels.includes(c.value),
        })}
      >
        {c.Icon ? (
          <c.Icon type='primary' />
        ) : (
          <img src={c.imgSrc} alt={c.label} />
        )}
        <span>{c.label}</span>
      </Button>
    )
    return c.disabled && !readOnly ? (
      <Tooltip
        key={c.value}
        getPopupContainer={popupContainer}
        title={
          <React.Fragment>
            <p style={{ marginBottom: 3 }}>
              To use {c.label} as a notification channel
            </p>
            <Link
              style={{ color: '#868bff' }}
              to='/settings/payment-settings/plans'
            >
              Upgrade your plan
            </Link>
          </React.Fragment>
        }
      >
        <span>{Channel}</span>
      </Tooltip>
    ) : (
      Channel
    )
  }

  return (
    <div className={cx('channel-selection-wrapper', { 'read-only': readOnly })}>
      {channels.map(renderChannel)}
      <div className='channel-message text-success'>
        We will send notifications through your selected channels
      </div>
    </div>
  )
}
