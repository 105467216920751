import { createContext, useContext } from 'react'

import * as Types from './TableTypes'

const TableContext = createContext<Types.TableContext | undefined>(undefined)

const useTableContext = () => {
  const context = useContext(TableContext)
  if (context === undefined) {
    throw Error('Invalid context call')
  }
  return context
}

export { TableContext as default, useTableContext }
