import { useState } from 'react'
import { pick } from 'lodash'
import { Button, Checkbox, Form, Input, Radio } from 'antd'
import { CheckOutlined, RightOutlined } from '@ant-design/icons'

import { FilterFilled, InfoCircle, TagFilled } from 'components/Icons'
import { Tag } from 'components/Shared'
import tagColors from 'configs/tagColors'

import { FormValues, rules } from './FormHelpers'

interface Props {
  initValue: FormValues['tagDetails']
  onChange: (k: 'tagDetails', v: FormValues['tagDetails']) => void
  onStepChange: (num: number) => void
}

function TagDetails({ initValue, onChange, onStepChange }: Props) {
  const [form] = Form.useForm()
  const [tagColor, setTagColor] = useState(() => {
    return pick(initValue, ['stroke_color', 'text_color', 'color'])
  })

  const handleTagColorChange = (c: typeof tagColors[0]) => {
    setTagColor({
      stroke_color: c.strokeColor,
      text_color: c.textColor,
      color: c.color,
    })
  }

  const handleSubmit = (values: FormValues['tagDetails']) => {
    const { search_in, subject_keywords, body_keywords } = values
    if (search_in === 'subject_and_body') {
      if (!subject_keywords) {
        return form.setFields([
          { name: 'subject_keywords', errors: ['This field is required'] },
        ])
      }
      if (!body_keywords) {
        return form.setFields([
          { name: 'body_keywords', errors: ['This field is required'] },
        ])
      }
    }

    if (search_in === 'subject_or_body') {
      if (!body_keywords && !subject_keywords) {
        return form.setFields([
          {
            name: 'body_keywords',
            errors: ['Please choose at least one criteria'],
          },
          {
            name: 'subject_keywords',
            errors: ['Please choose at least one criteria'],
          },
        ])
      }
    }

    onChange('tagDetails', { ...values, ...tagColor })
    onStepChange(1)
  }

  return (
    <Form
      form={form}
      layout='vertical'
      style={{ padding: '1rem 8px' }}
      initialValues={initValue}
      onFinish={handleSubmit}
    >
      <div className='form-group-title'>
        <TagFilled type='primary' />
        <h3>Tag Info</h3>
      </div>
      <Form.Item
        name='name'
        label='Tag name'
        rules={rules}
        extra={
          <span>
            <InfoCircle color='#808080' />
            Keep the name short and meaningful for clean categorization
          </span>
        }
      >
        <Input size='large' placeholder='Name your tag' />
      </Form.Item>
      <Form.Item label='Tag Color'>
        <div className='tag-colors'>
          {tagColors.map(c => (
            <Tag
              key={c.color}
              strokeColor={c.strokeColor}
              textColor={c.textColor}
              color={c.color}
              onClick={() => handleTagColorChange(c)}
            >
              {tagColor.color === c.color &&
                tagColor.stroke_color === c.strokeColor &&
                tagColor.text_color === c.textColor && <CheckOutlined />}
            </Tag>
          ))}
        </div>
      </Form.Item>
      <div className='divider' />

      <div className='form-group-title'>
        <FilterFilled type='primary' />
        <h3>Matching Criteria</h3>
      </div>
      <Form.Item name='subject_keywords' label='In Title'>
        <Input.TextArea
          rows={3}
          size='large'
          placeholder='Enter a keyword to search for, e.g. motion, tamper, etc'
        />
      </Form.Item>
      <Form.Item name='search_in' label='Matching Method'>
        <Radio.Group
          optionType='button'
          buttonStyle='solid'
          options={[
            { label: 'Or', value: 'subject_or_body' },
            { label: 'And', value: 'subject_and_body' },
          ]}
        />
      </Form.Item>
      <Form.Item name='body_keywords' label='In Content'>
        <Input.TextArea
          rows={3}
          size='large'
          placeholder='Enter a keyword to search for, e.g. motion, tamper, etc'
        />
      </Form.Item>
      <Form.Item
        valuePropName='checked'
        label='Other Criteria'
        name='has_attachments'
        colon={false}
      >
        <Checkbox className='checkbox-large'>Contains attachment</Checkbox>
      </Form.Item>
      <Button size='large' block type='primary' htmlType='submit'>
        Next <RightOutlined />
      </Button>
    </Form>
  )
}

export default TagDetails
