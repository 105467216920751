import { Button } from 'antd'

import './index.scss'
import Heart from 'assets/heart.png'
import BaseModal from 'components/Modal/BaseModal'

interface Props {
  title: string
  description: string
}

function ModalPlanUpdated({ title, description }: Props) {
  const handleRedirect = () => {
    window.location.href = '/settings/payment-settings'
  }

  return (
    <BaseModal className='modal-plan-updated' onCancel={() => {}}>
      <img src={Heart} alt='thankyou' />
      <h1>{title}</h1>
      <span>{description}</span>
      <Button block type='primary' size='large' onClick={handleRedirect}>
        Ok
      </Button>
    </BaseModal>
  )
}

export default ModalPlanUpdated
