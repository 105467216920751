import { AccessDenied, Layout } from 'components/Shared'
import { useRestrictRoute } from 'hooks'

import './index.scss'
import TableListHeader from './TableListHeader'
import TableList from './TableList'
import LogContextProvider from './LogContext'

function ActivityLogs() {
  const { canAccess, message } = useRestrictRoute()

  return (
    <Layout className='activity-logs'>
      <Layout.Header goBack bordered>
        Activity Log
      </Layout.Header>
      {!canAccess && !!message && (
        <Layout.Body>
          <AccessDenied {...message} />
        </Layout.Body>
      )}
      {canAccess && (
        <Layout.Body>
          <LogContextProvider>
            <TableListHeader />
            <TableList />
          </LogContextProvider>
        </Layout.Body>
      )}
    </Layout>
  )
}

export default ActivityLogs
