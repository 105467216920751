import { SVGUniqueID } from 'react-svg-unique-id'

import { ICONS_SOLIDS } from 'configs/iconColors'

interface Props {
  color?: string
  type?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info'
  [key: string]: any
}

export function TrashFilled({ color, type, ...props }: Props) {
  const c = color || ICONS_SOLIDS[type || 'secondary']

  return (
    <SVGUniqueID>
      <svg
        width='10'
        height='12'
        viewBox='0 0 10 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path
          d='M0.666617 10.6666C0.666617 11.4033 1.26327 12 1.99996 12H7.3333C8.06996 12 8.66662 11.4033 8.66662 10.6666V2.66663H0.666617V10.6666Z'
          fill={c}
        />
        <path
          d='M7 0.666656L6.33331 0H3L2.33331 0.666656H0V2H9.33331V0.666656H7Z'
          fill={c}
        />
      </svg>
    </SVGUniqueID>
  )
}
