import React, { useState } from 'react'
import { omit } from 'lodash'
import { Form, Input, Button } from 'antd'

import PlanInfoCard from 'components/Cards/PlanInfoCard'
import { ChannelSelection, DateTimeSelection } from 'components/Shared'
import { useAppSelector } from 'store'

import {
  FormValues,
  totalMsg,
  rules,
  RecipientFormItem,
  WebhhookFormItem,
  getRecipientsByType,
  mapValuesToOptions,
} from './FormHelpers'

interface Props {
  initValue: FormValues['notifications']
  onChange: (k: 'notifications', v: FormValues['notifications']) => void
  onStepChange: (num: number) => void
}

function Schedule({ initValue, onChange, onStepChange }: Props) {
  const [form] = Form.useForm()
  const { stats } = useAppSelector(state => state.stats)

  const [channels, setChannels] = useState(initValue.channels)
  const [dateTime, setDateTime] = useState(initValue.dateTime)

  const getRecipientsHasPhone = getRecipientsByType({ phone: 1 })
  const getRecipientsHasEmail = getRecipientsByType({ email: 1 })

  const handleSubmit = (values: FormValues['notifications']) => {
    const { is247, startTime, endTime, selectedDays } = dateTime

    const errors = []
    if (!is247 && (!startTime || !endTime || !selectedDays.length)) {
      errors.push({ name: 'dateTime', errors: ['This field is required'] })
    }
    if (!channels.length) {
      errors.push({ name: 'channels', errors: ['This field is required'] })
    }
    if (errors.length) {
      return form.setFields(errors)
    }

    onChange('notifications', {
      name: values.name,
      dateTime,
      is_active: true,
      channels,
      phone_recipients: values.phone_recipients,
      email_recipients: values.email_recipients,
      whatsapp_recipients: values.whatsapp_recipients,
      webhook: values.webhook,
      slack_webhook: values.slack_webhook,
      teams_webbhook: values.teams_webbhook,
    })
    onStepChange(1)
  }

  return (
    <React.Fragment>
      <PlanInfoCard showUpgrade>
        {totalMsg(
          stats?.sms.total || 0,
          stats?.email.total || 0,
          stats?.whatsapp.total || 0,
        )}
      </PlanInfoCard>
      <Form
        form={form}
        layout='vertical'
        style={{ padding: '1rem 8px' }}
        onFinish={handleSubmit}
        initialValues={omit(initValue, ['dateTime', 'channels'])}
      >
        <h4 className='form-title'>Notification schedule detail</h4>
        <Form.Item name='name' label='Schedule Name' rules={rules}>
          <Input size='large' placeholder='Name your schedule' />
        </Form.Item>
        <Form.Item
          required
          name='dateTime'
          label='Schedule Date & Time'
          className='schedule-datetime-field'
        >
          <DateTimeSelection
            {...dateTime}
            onChange={v => {
              form.setFields([{ name: 'dateTime', errors: undefined }])
              setDateTime(v)
            }}
            popupContainer='.m-modal-inner'
          />
        </Form.Item>
        <Form.Item required name='channels' label='Channel'>
          <ChannelSelection
            popupContainer={() => document.querySelector('.m-modal-inner')!}
            selectedChannels={channels}
            onChange={v => {
              form.setFields([{ name: 'channels', errors: undefined }])
              setChannels(v)
            }}
          />
        </Form.Item>

        <div className='divider' />
        <h4 className='form-title'>
          Recipients
          {!channels.length && (
            <div
              className='text-danger text-400'
              style={{ fontSize: '0.875rem', marginTop: 8 }}
            >
              Please select at least 1 channels above
            </div>
          )}
        </h4>
        {channels.includes('phone') && (
          <RecipientFormItem
            name='phone_recipients'
            channel='SMS'
            fetcher={getRecipientsHasPhone}
            mapper={mapValuesToOptions}
          />
        )}
        {channels.includes('whatsapp') && (
          <RecipientFormItem
            name='whatsapp_recipients'
            channel='WhatsApp'
            fetcher={getRecipientsHasPhone}
            mapper={mapValuesToOptions}
          />
        )}
        {channels.includes('email') && (
          <RecipientFormItem
            name='email_recipients'
            channel='Email'
            fetcher={getRecipientsHasEmail}
            mapper={mapValuesToOptions}
          />
        )}
        {channels.includes('slack') && (
          <WebhhookFormItem
            name='slack_webhook'
            label='Slack'
            placeholder='Enter Slack Webhook URL'
            extraUrl='https://api.slack.com/messaging/webhooks'
            extraText='See How to get Slack Webhook URL'
          />
        )}
        {channels.includes('webhook') && (
          <WebhhookFormItem
            name='webhook'
            label='Webhook'
            placeholder='Enter Webhook URL'
            extraUrl='https://developers.facebook.com/docs/messenger-platform/webhook'
            extraText='See How to get Webhook URL'
          />
        )}
        {channels.includes('microsoft_teams') && (
          <WebhhookFormItem
            name='teams_webbhook'
            label='Microsoft Teams Webhook'
            placeholder='Enter Mircosoft Team Webhook URL'
            extraUrl='https://docs.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook'
            extraText='See how to create a Mircosoft Teams Webhook'
          />
        )}
        <Button size='large' block type='primary' htmlType='submit'>
          Save
        </Button>
      </Form>
    </React.Fragment>
  )
}

export default Schedule
