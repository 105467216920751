import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { Tooltip } from 'antd'

import CheckoutForm from 'components/CheckoutForm'
import { InfoCircle, TagFilled } from 'components/Icons'
import ModalPlanUpdated from 'components/Modal/Checkout/ModalPlanUpdated'
import {
  createSubscription,
  getVoucherInfo,
  Voucher,
} from 'services/subscription'
import { useAppSelector } from 'store'
import { CHARGE_PER_CAM } from 'utils/device'
import { notiError } from 'utils/notification'

type RouteParams = {
  planId: number
  camCount: number
}

function BuyNewPlan() {
  const { location } = useHistory()
  const [loading, setLoading] = useState(false)
  const [voucher, setVoucher] = useState<Voucher | null>(null)
  const [showModal, setShowModal] = useState(false)

  const { plans } = useAppSelector(state => state.plans)

  const { planId, camCount } = location.state as RouteParams
  const selectedPlan = plans.find(p => p.id === planId)!

  const handleVoucherChange = async (code: string) => {
    if (!code) {
      return setVoucher(null)
    }

    try {
      setLoading(true)
      const response = await getVoucherInfo(code)
      const data = response.data.data as Voucher
      if (!validateVoucher(data)) {
        throw new Error()
      }
      setVoucher(data)
    } catch {
      notiError({ description: 'Your provided voucher is not valid' })
    } finally {
      setLoading(false)
    }
  }

  const validateVoucher = ({ plan_id, camera_licenses }: Voucher) => {
    if (!plan_id && !camera_licenses) {
      return true
    }
    if (plan_id !== planId || camera_licenses !== camCount) {
      return false
    }
    return true
  }

  const calcTotalPrice = () => {
    const base = (selectedPlan?.price || 0) / 100 + camCount * CHARGE_PER_CAM
    if (!!voucher) {
      const { percent_off, amount_off } = voucher
      return percent_off
        ? Math.round(base * ((100 - percent_off) / 100))
        : base - (amount_off || 0)
    }
    return base
  }

  const calcDiscount = () => {
    const base = (selectedPlan?.price || 0) / 100 + camCount * CHARGE_PER_CAM
    const total = calcTotalPrice()
    return base - total
  }

  const handleSubmit = async (v?: { paymentId: string; voucher?: Voucher }) => {
    if (!v) {
      return
    }
    try {
      setLoading(true)
      await createSubscription({
        payment_token: v.paymentId,
        plan_id: planId,
        camera_licenses: camCount,
        coupon: v.voucher?.code || undefined,
      })
      setShowModal(true)
      setLoading(false)
    } catch (err: any) {
      const { message, errors } = err
      notiError({ description: errors || message })
      setLoading(false)
    }
  }

  return (
    <div className='checkout-wrapper new-plan'>
      <div className='checkout-details'>
        <h3>Selected Plan</h3>
        <div className='plan-info'>
          <span className='plan-label text-500'>{selectedPlan.label}</span>
          {selectedPlan.id === 4 && (
            <span className='popular text-primary text-bold'>
              <TagFilled type='primary' /> Popular
            </span>
          )}
        </div>
        <ul className='summary'>
          <li>
            <span>Camera Licenses</span>
            <span>
              <b className='text-500 text-body' style={{ fontSize: '1rem' }}>
                {camCount} x ${CHARGE_PER_CAM}
              </b>
              /month
            </span>
          </li>
          <li className='divider' />
          <li>
            <span>Base Plan</span>
            <span>
              <b className='text-500 text-body' style={{ fontSize: '1rem' }}>
                ${selectedPlan.price / 100}
              </b>
              /month
            </span>
          </li>
          {!!voucher && (
            <React.Fragment>
              <li className='divider' />
              <li>
                <span>Discount</span>
                <span className='text-500 text-body with-tooltip'>
                  <span style={{ fontSize: '1rem', width: 'auto' }}>
                    -${calcDiscount()}
                  </span>
                  <Tooltip title={voucher.message}>
                    <InfoCircle type='success' />
                  </Tooltip>
                </span>
              </li>
            </React.Fragment>
          )}
          <li className='divider' style={{ border: '1px solid #DEDEDE' }} />
          <li>
            <span>Total</span>
            <span>
              <b style={{ fontSize: '1.5rem' }} className='text-500 text-body'>
                ${calcTotalPrice()}
              </b>
              /month
            </span>
          </li>
        </ul>
      </div>
      <div className='divider' style={{ margin: '2rem 0 1.5rem 0' }} />
      <CheckoutForm
        loading={loading}
        voucher={voucher || undefined}
        onVoucherChange={handleVoucherChange}
        onSubmit={handleSubmit}
      />
      <AnimatePresence>
        {showModal && (
          <ModalPlanUpdated
            title='Your subscription have been updated'
            description='Thank you for using BroadFlow service'
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export default BuyNewPlan
